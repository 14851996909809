/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
  FormText,
  Spinner,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactTable from "react-table";
import QRCode from "react-qr-code";
import { validateEmail } from "components/Utilities/utilities.js";
import moment from "moment";
import api from "../../../components/API/api";
import { Modal } from "@mui/material";
import AgencyContext from "../../../context/AgencyContext";
import Permission from "components/permissions/Permission";
import { Delete } from "@mui/icons-material";
import AssignedWorker from "./AssignedWorker";
import { compare2Objects } from "components/Utilities/utilities";

class StaffProfile extends React.Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.state = {
      //setting states for profile data
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      driver: "",
      agencyId: "",
      vehicleCapacity: "",
      phone: "",
      reactTableState: {
        page: 1,
        size: 10,
        field: "createdAt",
        sort: "asc",
        filter: "",
      },
      pages: -1,
      loading: false,

      //history
      changeLog: [],


      canDeleteStaff: false, // check delete permission for staff

      //Errors
      errorFirstName: "",
      errorLastName: "",
      errorPhone: "",
      errorEmail: "",
      errorVehicleCapacity: "",

      // Loading
      isLoading: false,
      pageTabs: "profile",

      //modal data
      openDeleteModal: false,
      openPasswordResetModal: false,
    };
  }

  componentDidMount() {
    this.fetchData();
    this.fetchChangeLog();
    //check logged in user permissions

    let selectedUrl = window.location.pathname.split("/");
    let selectedDocId = selectedUrl[4];

    this.setState({ selectedDocId });

    //popup warning if user reloads or closes the page
    //window.addEventListener("beforeunload", this.handleUserLeave);
  }

  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  fetchChangeLog = async (filterOptions = this.state.reactTableState) => {
    let selectedUrl = window.location.pathname.split("/");
    let selectedDocId = selectedUrl[4];
    this.setState({ loading: true });
    const resp = await api().get("/log/staff/change-log", {
      params: {
        userId: selectedDocId,
        ...filterOptions,
      },
    });
    let data = resp.data;

    const changeLog = data.logs.map((log, index) => {
      let action = "";
      if (log.method === "POST" && log.action.includes("permissions")) {
        action = `User permissions: ${this.compareLogWithLast(data.logs, index)}`;
      } else if (log.method === "POST") action = "User created";
      if (log.method === "PATCH") {
        action = `User edited: ${this.compareLogWithLast(data.logs, index)}`;
      }
      if (log.method === "DELETE") action = "User deleted";
      return {
        ...log,
        admin: `${log.user.firstName} ${log.user.lastName} (${log.user.email})`,
        time: moment(log.createdAt).format("L LT"),
        action,
      };
    });
    this.setState({
      changeLog,
      pages: data.noPages,
      loading: false,
    });
  };


  compareLogWithLast = (logs, index) => {
    let log = logs[index];
    let lastLog = null;
    let nextIndex = index + 1;
    while (nextIndex < logs.length && lastLog === null) {
      if (log.action.includes("permissions")) {
        if (logs[nextIndex].method == "POST") {
          lastLog = logs[nextIndex];
        }
      } else {
        if (
          !logs[nextIndex].action.includes("permissions") &&
          (logs[nextIndex].method == "POST" || logs[nextIndex].method == "PATCH")
        ) {
          lastLog = logs[nextIndex];
        }
      }
      nextIndex++;
    }
    if (!lastLog) {
      return "No updates";
    }
    const data = JSON.parse(log.data);
    const lastData = JSON.parse(lastLog.data);
    let changes = [];
    if (log.action.includes("permissions")) {
      changes = data.permissions.map((p) => p.module);
      changes = [...new Set(changes)];
    } else {
      const keys = Object.keys(data);
      changes = keys.filter((key) => !compare2Objects(data[key], lastData[key]));
    }
    if (changes.length === 0) {
      return "No updates";
    }
    return changes.join(", ").replace(/([a-z])([A-Z])/g, "$1 $2");
  };

  fetchData = () => {
    this.setState({ isLoading: true });
    //geting staff Id from URL
    let selectedUrl = window.location.pathname.split("/");
    let selectedDocId = selectedUrl[4];
    api()
      .get(`users/driver/${selectedDocId}`)
      .then(async ({ data }) => {
       
        this.setState({
          driverId: data._id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          vehicleCapacity: data.vehicleCapacity
        });
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });
  };

  // Delete user from state and server
  deleteUser = async () => {
    this.setState({ isLoading: true });

    try {
      // api().patch("/users", { active: false, userId: this.props.match.params.id });
      api().delete("/users/agency", { params: { userId: this.props.match.params.id } });
      this.setState({
        isLoading: false,
        alert: <SweetAlert success title="User successfully Deleted" onConfirm={this.hideAlert} />,
      });
      let path = `/${this.context.agency.agency.slug}/admin/all-staff`;
      this.props.history.push(path);
      this.handleCloseDeleteModal();
    } catch (error) {
      this.setState({
        isLoading: false,
        alert: (
          <SweetAlert
            error
            title="User couldn't be deleted please try again."
            onConfirm={this.hideAlert}
          />
        ),
      });
    }
  };

  ////////////////////////////////////////////////////
  // DELETE USER
  // Popup for DELETING user from database then state.
  ////////////////////////////////////////////////////
  deleteUserModal = () => {
    return (
      <Modal
        open={this.state.openDeleteModal}
        onClose={this.handleCloseDeleteModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Delete {this.state.firstName} {this.state.lastName}?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => this.handleCloseDeleteModal()}>Cancel</Button>
              <Button color="danger" onClick={() => this.deleteUser()}>
                Delete
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  // Send Password reset email
  sendPassWordResetEmail = async () => {
    this.setState({ isLoading: true });

    try {
      let dataToSend = {
        email: this.state.email,
        slug: this.state.slug,
      };
      api().post("/users/request/reset-password", dataToSend);
      this.setState({
        isLoading: false,
        alert: (
          <SweetAlert
            success
            title="Password Reset email successfully sent"
            onConfirm={this.hideAlert}
          />
        ),
      });
      this.handleClosePasswordResetModal();
    } catch (error) {
      this.setState({
        isLoading: false,
        alert: (
          <SweetAlert
            error
            title="Password email couldn't be sent, pleas try again."
            onConfirm={this.hideAlert}
          />
        ),
      });
      this.handleClosePasswordResetModal();
    }
  };

  ////////////////////////////////////////////////////
  // PASSWORD RESET MODAL
  // Popup for to send password rest email
  ////////////////////////////////////////////////////
  passwordResetModal = () => {
    return (
      <Modal
        open={this.state.openPasswordResetModal}
        onClose={this.handleClosePasswordResetModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Send password reset email to {this.state.firstName} {this.state.lastName}?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => this.handleClosePasswordResetModal()}>Cancel</Button>
              <Button color="danger" onClick={() => this.sendPassWordResetEmail()}>
                Send
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  // Opens modal to remove user to soft delete
  // Deletes data
  handleOpenDeleteModal = () => {
    this.setState({ openDeleteModal: true });
  };

  handleCloseDeleteModal = () => {
    this.setState({ openDeleteModal: false });
  };

  // Opens modal to send password reset link
  handleOpenPasswordResetModal = () => {
    this.setState({ openPasswordResetModal: true });
  };

  handleClosePasswordResetModal = () => {
    this.setState({ openPasswordResetModal: false });
  };
  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = async (docId) => {
    this.setState({ isLoading: true });
    let tempState = this.state; // Copy of state
    let hasError = false;

    // Start of Error Handling
    if (tempState.firstName == null || tempState.firstName.trim() == "") {
      this.setState({ errorFirstName: "* Must have a first name." });
      hasError = true;
    } else {
      this.setState({ errorFirstName: "" });
    }

    if (tempState.lastName == null || tempState.lastName.trim() == "") {
      this.setState({ errorLastName: "* Must have a last name." });
      hasError = true;
    } else {
      this.setState({ errorLastName: "" });
    }

    if (tempState.phone == null || tempState.phone.trim() == "") {
      this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
      hasError = true;
    } else {
      if (isNaN(tempState.phone) == true || tempState.phone.length != 10 || tempState.phone < 0) {
        this.setState({ errorPhone: "* Please input a valid phone number." }); //invalid phone number
        hasError = true;
      } else {
        this.setState({ errorPhone: "" });
      }
    }

    if (tempState.email == null || tempState.email.trim() == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }

    // if (tempState.vehicleCapacity == null || tempState.vehicleCapacity.trim() == "") {
    //   this.setState({ errorVehicleCapacity: "* Must have a vehicle capacity." });
    //   hasError = true;
    // } else {
    //   this.setState({ errorVehicleCapacity: "" });
    // }

    if (hasError) {
      this.setState({
        isLoading: false,
        alert: (
          <SweetAlert
            warning
            title="Please ensure all mandatory fields (*) are filled"
            onConfirm={this.hideAlert}
          />
        ),
      });
      return;
    } else {
      let dataToUpdate = null;

      dataToUpdate = {
        userId: tempState.driverId,
        firstName: tempState.firstName,
        lastName: tempState.lastName,
        phone: tempState.phone,
        vehicleCapacity: tempState.vehicleCapacity,
      };

      try {
        await api().patch("/users", dataToUpdate);

        this.fetchChangeLog();
        this.setState({
          isLoading: false,
          alert: (
            <SweetAlert
              success
              title="Driver profile successfully updated"
              onConfirm={this.hideAlert}
            />
          ),
        });
      } catch (e) {
        this.setState({
          isLoading: false,
          alert: (
            <SweetAlert danger title={e.response.data.message || e.message} onConfirm={this.hideAlert} />
          ),
        });
        console.log(e);
      }
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  render() {
    // console.log(this.state.permissionsList);
    // console.log(this.state.pages)
    return (
      <>
        <div className="content">
          {this.state.isLoading ? (
            this.loadingOverlay()
          ) : (
            <Row>
              <Col>
                <Card className="no-transition">
                  <CardHeader>
                    <Row>
                      <Col md="9">
                        <CardTitle tag="h4">
                          Profile of {`${this.state.firstName}`} {`${this.state.lastName}`}
                        </CardTitle>
                      </Col>
                      <Col md="2" align="right">
                        <Permission module="Staff" action="PasswordReset">
                          <Button
                            onClick={() => {
                              this.handleOpenPasswordResetModal();
                            }}
                            color="warning"
                            size="md"
                          >
                            Send Password Reset Email
                          </Button>
                        </Permission>
                      </Col>
                      <Permission module="Staff" action="Delete">
                        <Col md="1" align="center">
                          <Button
                            onClick={() => {
                              this.handleOpenDeleteModal();
                            }}
                            color="danger"
                            size="md"
                            className="btn-link remove btn-icon"
                          >
                            <Delete style={{ marginLeft: -1 }} />
                          </Button>
                        </Col>
                      </Permission>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Nav
                      className="nav-pills-primary nav-pills-icons justify-content-center"
                      pills
                      vehicleCapacity="tablist"
                    >
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          role="tablist"
                          className={this.state.pageTabs === "profile" ? "active" : ""}
                          onClick={() => this.setState({ pageTabs: "profile" })}
                        >
                          <i className="now-ui-icons objects_umbrella-13" />
                          Edit Profile
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          role="tablist"
                          className={this.state.pageTabs === "workers" ? "active" : ""}
                          onClick={() => this.setState({ pageTabs: "workers" })}
                        >
                          <i className="now-ui-icons objects_umbrella-13" />
                          Assigned Workers
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          role="tablist"
                          className={this.state.pageTabs === "changeLog" ? "active" : ""}
                          onClick={() => this.setState({ pageTabs: "changeLog" })}
                        >
                          <i className="now-ui-icons ui-2_settings-90" />
                          Change Log
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      className="tab-space tab-subcategories"
                      activeTab={this.state.pageTabs}
                    >
                      <TabPane tabId="profile">
                        <CardHeader>
                          <Row>
                            <Col md="8">
                              <CardTitle tag="h4">Edit Profile</CardTitle>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Form action="/" className="form-horizontal" method="get">
                            <Row>
                              <Label sm="2">*First Name</Label>
                              <Col sm="5">
                                <FormGroup>
                                  <Input
                                    defaultValue={`${this.state.firstName}`}
                                    onChange={(e) => this.setState({ firstName: e.target.value })}
                                    placeholder="First Name"
                                    type="text"
                                  />
                                  <FormText color="danger" tag="span">
                                    {this.state.errorFirstName}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label sm="2">*Last Name</Label>
                              <Col sm="5">
                                <FormGroup>
                                  <Input
                                    defaultValue={`${this.state.lastName}`}
                                    onChange={(e) => this.setState({ lastName: e.target.value })}
                                    placeholder="Last Name"
                                    type="text"
                                  />
                                  <FormText color="danger" tag="span">
                                    {this.state.errorLastName}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label sm="2">* Phone Number</Label>
                              <Col md="5">
                                <FormGroup>
                                  <MaskedInput
                                    mask={[
                                      "(",
                                      /[1-9]/,
                                      /\d/,
                                      /\d/,
                                      ")",
                                      "-",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                    ]}
                                    defaultValue={`${this.state.phone}`}
                                    onChange={(e) => {
                                      let input = e.target.value;
                                      let replacedPhone = input.replace(/[^0-9]/g, "");
                                      this.setState({ phone: replacedPhone });
                                    }}
                                    placeholder="Phone Number"
                                    className="form-control"
                                    maxLength="15"
                                  />
                                  <FormText color="danger" tag="span">
                                    {this.state.errorPhone}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label sm="2">* Email</Label>
                              <Col sm="5">
                                <FormGroup>
                                  <Input
                                    disabled
                                    defaultValue={`${this.state.email}`}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    placeholder="Contact Email"
                                    type="text"
                                  />
                                  <FormText color="danger" tag="span">
                                    {this.state.errorEmail}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Label sm="2">Vehicle Capacity (Optional)</Label>
                              <Col sm="5">
                                <FormGroup>
                                  <Input
                                    type="text"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      //
                                      this.setState({ vehicleCapacity: e.target.value });
                                    }}
                                    value={this.state.vehicleCapacity}
                                  />
                                  <FormText color="danger" tag="span">
                                    {this.state.errorVehicleCapacity}
                                  </FormText>
                                </FormGroup>
                              </Col>
                            </Row>

                            <FormGroup align="right">
                              <Button color="default" outline onClick={() => this.handleSubmit()}>
                                Save Changes
                              </Button>
                            </FormGroup>
                          </Form>
                        </CardBody>
                      </TabPane>
                      <TabPane tabId="workers">
                        <AssignedWorker />
                      </TabPane>
                      <TabPane tabId="changeLog">
                        <CardHeader>
                          <CardTitle tag="h4">Change Log</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <ReactTable
                            data={this.state.changeLog}
                            filterable
                            columns={[
                              {
                                Header: "Admin",
                                accessor: "admin",
                              },
                              {
                                Header: "Time Changed",
                                accessor: "time",
                              },
                              {
                                Header: "Action Taken",
                                accessor: "action",
                                Cell: (row) => {
                                  let text = row.original.action;
                                  return (
                                    <div
                                      style={{
                                        overflow: "visible",
                                      }}
                                    >
                                      {text}
                                    </div>
                                  );
                                },
                              },
                            ]}
                            manual
                            defaultPageSize={10}
                            showPaginationTop={true}
                            showPaginationBottom={false}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                              let searchParams = {
                                page: state.page + 1,
                                size: state.pageSize,
                                field: state.sorted.length ? state.sorted[0].id : "",
                                sort: state.sorted.length && state.sorted[0].desc ? "desc" : "asc",
                                filter: state.filtered,
                              };
                              this.setState({
                                reactTableState: searchParams,
                              });
                              this.fetchChangeLog(searchParams);
                            }}
                            style={{ height: "auto" }}
                            className="-striped -highlight primary-pagination"
                          />
                        </CardBody>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {this.state.alert}
          {this.deleteUserModal()}
          {this.passwordResetModal()}
        </div>
      </>
    );
  }
}

export default StaffProfile;

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Login from "views/Agency/pages/Login.jsx";
import React from "react";
import NotImplemented from "views/Super/NotImplemented.js";
import AllAdmins from "../../views/Super/Users/AllAdmins";
import CreateNewAdmin from "../../views/Super/Users/CreateNewAdmin";
import AdminProfile from "../../views/Super/Users/AdminProfile";
import AllAgencies from "../../views/Super/Agencies/AllAgencies";
import AllBusiness from "../../views/Super/Businesses/AllBusiness";
import AllBusinessesStaff from "../../views/Super/Businesses/AllBusinessesStaff";
import CreateNewBusinessStaff from "../../views/Super/Businesses/CreateNewBusinessStaff";
import CreateNewBusiness from "../../views/Super/Businesses/CreateNewBusiness";
import EditBusiness from "../../views/Super/Businesses/EditBusiness";
import AllTrainings from "../../views/Super/Training/AllTrainings";
import CreateNewTraining from "../../views/Agency/AllTrainings/CreateNewTraining";
import EditTraining from "../../views/Agency/AllTrainings/EditTraining";
import AllBugReports from "../../views/Super/BugReport/AllReport";
import Logout from "../../views/Agency/pages/Logout";

let modules = {
  Announcement: "Announcement",
  Business: "Business",
  Cheque: "Cheque",
  Log: "Log",
  Notification: "Notification",
  Training: "Training",
  SelectForWork: "SelectForWork",
  Shifts: "Shifts",
  Users: "Users",
  BugReport: "BugReport",
};
let permissionAction = {
  View: "View",
  Edit: "Edit",
  Create: "Create",
  Delete: "Delete",
};

export const routes = [
  {
    collapse: true,
    name: "Business",
    icon: "nc-icon nc-briefcase-24",
    state: "businessCollapse",
    access: ["Admin"],
    views: [
      {
        path: "/all-businesses",
        name: "All Business",
        mini: "All",
        component: AllBusiness,
        layout: "/admin",
        hidden: false,
        access: ["Admin"],
        module: modules.Users,
        action: [permissionAction.View],
      },
      {
        path: "/create-new-business",
        name: "Register New Global Business",
        mini: "New",
        component: CreateNewBusiness,
        layout: "/admin",
        hidden: false,
        access: ["Admin"],
        module: modules.Users,
        action: [permissionAction.Create],
      },
      {
        path: "/business-profile/:id",
        name: "Profile",
        mini: "Usr",
        component: EditBusiness,
        layout: "/admin",
        hidden: true,
        access: ["Admin"],
        module: modules.Users,
        action: [permissionAction.Edit],
      },
      {
        path: "/all-business-staff",
        name: "All Businesses Staff",
        mini: "ALL",
        component: AllBusinessesStaff,
        layout: "/admin",
        hidden: false,
        access: ["Admin"],
        module: modules.Business,
        action: [permissionAction.View],
      },
      {
        path: "/new-business-staff",
        name: "Create New Business Staff",
        mini: "NEW",
        component: CreateNewBusinessStaff,
        layout: "/admin",
        hidden: false,
        access: ["Admin"],
        module: modules.Business,
        action: [permissionAction.Create],
      },
      {
        path: "/business-staff-profile/:id",
        name: "Create New Business Staff",
        mini: "NEW",
        component: AdminProfile,
        layout: "/admin",
        hidden: true,
        access: ["Admin"],
        module: modules.Business,
        action: [permissionAction.Create],
      },
    ],
  },
  {
    collapse: true,
    name: "Agencies",
    icon: "nc-icon nc-briefcase-24",
    state: "agenciesCollapse",
    access: ["Admin"],
    views: [
      {
        path: "/all-agencies",
        name: "All Agencies",
        mini: "All",
        component: AllAgencies,
        layout: "/admin",
        hidden: false,
        access: ["Admin"],
        module: modules.Users,
        action: [permissionAction.View],
      },
    ],
  },
  {
    collapse: true,
    name: "Trainings",
    icon: "nc-icon nc-single-02",
    state: "trainingCollapse",
    access: ["Admin"],
    views: [
      {
        path: "/all-trainings",
        name: "All Trainings",
        mini: "ALL",
        component: AllTrainings,
        layout: "/admin",
        hidden: false,
        access: ["Admin"],
      },
      {
        path: "/create-new-training",
        name: "Create New Training",
        mini: "New",
        component: CreateNewTraining,
        layout: "/admin",
        hidden: false,
        access: ["Admin"],
      },
      {
        path: "/edit-training/:id",
        name: "Edit Training",
        mini: "New",
        component: EditTraining,
        layout: "/admin",
        hidden: true,
        access: ["Admin"],
      },
    ],
  },
  {
    collapse: true,
    name: "Users",
    icon: "nc-icon nc-money-coins",
    state: "usersCollapse",
    access: ["Admin"],
    module: modules.Shifts,
    action: [permissionAction.View],
    views: [
      {
        path: "/all-admins",
        name: "All Admins",
        mini: "All",
        component: AllAdmins,
        layout: "/admin",
        hidden: false,
        access: ["Admin"],
        module: modules.Shifts,
        action: [permissionAction.View],
      },
      {
        path: "/create-admin",
        name: "Create New Admin",
        mini: "New",
        layout: "/admin",
        component: CreateNewAdmin,
        hidden: false,
        access: ["Admin"],
        module: modules.Shifts,
        action: [permissionAction.Create],
      },
      {
        path: "/admin-profile/:id",
        name: "View Admin Profile",
        mini: "View",
        component: AdminProfile,
        layout: "/admin",
        hidden: true,
        access: ["Admin"],
      },
    ],
  },
  {
    collapse: true,
    name: "Bug Reports",
    icon: "nc-icon nc-money-coins",
    state: "bugReportState",
    access: ["Admin"],
    module: modules.BugReport,
    action: [permissionAction.View],
    views: [
      {
        path: "/all-bugs",
        name: "All Bugs",
        mini: "All",
        component: AllBugReports,
        layout: "/admin",
        hidden: false,
        access: ["Admin"],
        module: modules.Shifts,
        action: [permissionAction.View],
      },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "nc-icon nc-minimal-left",
    component: Logout,
    layout: "/admin",
    access: ["Admin", "Owner", "Staff"],
  },
];

export default routes;

import React from "react";
import { Row, Col } from "reactstrap";

export default function NotAllowed() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">Error 403! You are not allowed to access this page.</Col>
        </Row>
      </div>
    </>
  );
}

import React from "react";
import ReactTable from "react-table";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { Link } from "react-router-dom";
import AgencyContext from "./../../../context/AgencyContext";
// import { fs } from "components/Firebase/firebase.js";
import { Add, Check, Delete, Visibility, Comment, Forum, Feedback } from "@mui/icons-material";
import { Modal } from "@mui/material";
import api, { useEventSource } from "components/API/api";
import * as moment from "moment-timezone";
import Permission from "components/permissions/Permission";
import CommentsTab from "views/Agency/AllUsers/UserProfile/Comments";
import SweetAlert from "react-bootstrap-sweetalert";
import CreateChequeIssue from "./CreateChequeIssue";

let chequeIssuesEnum = {
  notStarted: "Not Started", //V1 equivalent to 1
  started: "Started", //V1 equivalent to 2
  emailedClient: "Emailed Client", // V1 equivalent to 3
  completed: "Completed", //V1 equivalent to 4
  dismissed: "Dismissed", //V1 equivalent to 5
};
class ChequeIssues extends React.Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.state = {
      chequeData: [],
      addComment: "",
      errorAddComment: "",

      //Selected data
      selectedChequeTicket: null,
      selectedIssueId: null,
      selectedDocId: null,
      selectedComments: null,
      selectedFirstName: null,
      selectedLastName: null,
      selectedAdminNoteHistory: [],
      selectedSingleAdminNote: null,

      //Modals
      confirmViewCommentsModalOpen: false,
      confirmAdminNotesModalOpen: false,
      confirmIssueStatusModal: false,
      confirmViewSingleAdminNote: false,
      createChequeIssueModal: false,
      alert: null,
      reactTableState: {
        page: 1,
        size: 10,
        field: "chequeTicket",
        sort: "asc",
        filter: [],
      },
      pages: -1,
      loading: false,
      newNote: "",
      timer: null,
    };
    this.event = null;
  }

  // componentDidMount() {
  //   // this.chequeDataIssuesRef = fs.collection("cheque_issues");
  //   // this.chequeDataIssuesHistoryRef = fs.collection("cheque_issues_history")
  //   // this.userDataRef = fs.collection("user_data");
  //   // this.loadCheques();
  //   let agency =
  //     this.context.agency && this.context.agency.agency && this.context.agency.agency._id;
  //   this.source = useEventSource(`/cheque-issues/listen?agency=${agency}`, (data) => {
  //     if (data.changed) {
  //       this.setState({ loading: true });
  //       this.loadCheques();
  //     }
  //   });
  // }

  componentWillUnmount() {
    // this.event && this.event.close();
    clearTimeout(this.state.timer);
  }

  loadCheques = async (filterOptions = this.state.reactTableState) => {
    try {
      const { data } = await api().get("/cheque-issues", {
        params: {
          ...filterOptions,
          unprocessed: true,
        },
      });
      console.log(data);
      //Looping through the business data for action button implementation
      let tempChequeIssuesData = data.chequeRequest.map((request) => {
        request.actions = this.renderActionButton(
          request.chequeTicket,
          request._id,
          request.firstName,
          request.lastName,
          request.comment,
          request.notes,
          request.user,
        );
        request.issueStatus = request.status;
        request.createdDate = moment(request.createdAt).format("YYYY-MM-DD, hh:mm a");
        request.weekStart = moment(request.weekStart).format("YYYY-MM-DD");
        request.weekEnd = moment(request.weekEnd).format("YYYY-MM-DD");
        request.lastAdminEdit = request.lastEditedBy || "No edits yet";
        return request;
      });
      this.setState({
        chequeData: tempChequeIssuesData,
        pages: data.noPages,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  //Updates firebase, then updates state
  changeIssueStatus = async (_id, issueStatus) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await api().patch(`/cheque-issues/${_id}`, {
        status: issueStatus,
      });

      this.setState({ isLoading: false });

      this.setState((prevState) => {
        return {
          ...prevState,
          chequeData: prevState.chequeData.filter((c) => {
            if (c._id === _id) c.issueStatus = issueStatus;
            return c;
          }),
        };
      });
      this.setState({
        alert: (
          <SweetAlert
            success
            title="Successfully changed the status."
            onConfirm={this.hideAlert}
            showConfirm={false}
          />
        ),
        isLoading: false,
      });
      setTimeout(this.hideAlert, 1000);
    } catch (e) {
      this.setState({
        alert: (
          <SweetAlert
            error
            title="Couldn't update the status. Please try again"
            onConfirm={this.hideAlert}
            showConfirm={false}
          />
        ),
        isLoading: false,
      });
      setTimeout(this.hideAlert, 1000);
    }

    this.handleCloseIssueStatusModal();
  };

  renderActionButton = (
    chequeTicket,
    _id,
    firstName,
    lastName,
    comments,
    adminNoteHistory,
    userId,
  ) => {
    return (
      <div className="actions-right">
        <Permission
          module="Cheque"
          action="Edit"
          error="Not enough permission to perform this action."
        >
          <Button
            onClick={() => {
              this.handleOpenIssueStatusModal(chequeTicket, _id, firstName, lastName);
            }}
            color="secondary"
            size="md"
            className="btn-link btn-icon"
          >
            <Add style={{ marginLeft: -1 }} />
          </Button>
        </Permission>
        <Button
          onClick={() => {
            this.handleOpenAdminNotesModal(
              chequeTicket,
              _id,
              firstName,
              lastName,
              adminNoteHistory,
              userId,
            );
          }}
          color="info"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Comment style={{ marginLeft: -1 }} />
        </Button>
        <Button
          tag={Link}
          to={`/${this.context.agency.agency.slug}/admin/user-profile/${userId}/?tab=chat`}
          color="default"
          size="md"
          className="btn-link btn-icon"
        >
          <Forum style={{ marginLeft: -1 }} />
        </Button>
        <Permission
          module="Cheque"
          action="Delete"
          error="You don't have enough permission to perform this action."
        >
          <Button
            onClick={() => {
              this.handleOpenViewCommentsModal(chequeTicket, _id, firstName, lastName, comments);
            }}
            color="info"
            size="md"
            className="btn-link remove btn-icon"
          >
            <Feedback style={{ marginLeft: -1 }} />
          </Button>
        </Permission>
      </div>
    );
  };

  //Opens confirmation modal for the updatePickupStatueTrue function
  handleOpenIssueStatusModal = (chequeTicket, _id, firstName, lastName) => {
    this.setState({
      confirmIssueStatusModal: true,
      selectedChequeTicket: chequeTicket,
      selectedIssueId: _id,
      selectedFirstName: firstName,
      selectedLastName: lastName,
    });
  };

  handleCloseIssueStatusModal = () => {
    this.setState({
      confirmIssueStatusModal: false,
      selectedChequeTicket: null,
      selectedIssueId: null,
      selectedFirstName: null,
      selectedLastName: null,
    });
  };

  issueStatusModal = () => {
    return (
      <Modal
        open={this.state.confirmIssueStatusModal}
        onClose={this.handleCloseIssueStatusModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Change the status of {this.state.selectedFirstName} {this.state.selectedLastName}'s
                ticket with ID: {this.state.selectedChequeTicket}?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Col md="4">
                <Button
                  onClick={() =>
                    this.changeIssueStatus(this.state.selectedIssueId, chequeIssuesEnum.notStarted)
                  }
                >
                  Unassign Issue
                </Button>
              </Col>
              <Col md="4">
                <Button
                  color="warning"
                  onClick={() =>
                    this.changeIssueStatus(this.state.selectedIssueId, chequeIssuesEnum.started)
                  }
                >
                  Assign Issue
                </Button>
              </Col>
              <Col md="4">
                <Button
                  color="warning"
                  onClick={() =>
                    this.changeIssueStatus(
                      this.state.selectedIssueId,
                      chequeIssuesEnum.emailedClient,
                    )
                  }
                >
                  Emailed Client
                </Button>
              </Col>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Col md="4">
                <Button
                  color="danger"
                  onClick={() =>
                    this.changeIssueStatus(this.state.selectedIssueId, chequeIssuesEnum.dismissed)
                  }
                >
                  Dismiss Issue
                </Button>
              </Col>
              <Col md="4"></Col>
              <Col md="4">
                <Button
                  color="success"
                  onClick={() =>
                    this.changeIssueStatus(this.state.selectedIssueId, chequeIssuesEnum.completed)
                  }
                >
                  Issue Complete
                </Button>
              </Col>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Col sm="4" md="4" lg="4">
                <Button size="md" onClick={() => this.handleCloseIssueStatusModal()}>
                  Cancel
                </Button>
              </Col>
              <Col sm="8" md="8" lg="8">
                {" "}
              </Col>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  // Deletes data
  deleteCheque = (chequeTicket, _id) => {
    console.log("Start Delete for : ", chequeTicket, _id);
    let data = this.state.chequeData;
    // data.find((o, i) => {
    //   if (o.chequeTicket === chequeTicket) {
    //     // Delete from Server, if it worked, then delete from local state
    //     this.chequeDataIssuesRef
    //       .doc(_id)
    //       .delete()
    //       .then(() => {
    //         data.splice(i, 1);
    //         this.setState({ chequeData: data });
    //         setTimeout(this.hideAlert, 1000);
    //         console.log("Document successfully deleted!");
    //       })
    //       .catch((error) => {
    //         console.error("Error removing document: ", error);
    //       });
    //   }
    // });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  //Opens Modal containing USER COMMENTS in a readonly format
  handleOpenViewCommentsModal = (chequeTicket, _id, firstName, lastName, comments) => {
    this.setState({
      confirmViewCommentsModalOpen: true,
      selectedChequeTicket: chequeTicket,
      selectedIssueId: _id,
      selectedFirstName: firstName,
      selectedLastName: lastName,
      selectedComments: comments,
    });
  };

  handleCloseViewCommentsModal = () => {
    this.setState({
      confirmViewCommentsModalOpen: false,
      selectedChequeTicket: null,
      selectedIssueId: null,
      selectedFirstName: null,
      selectedLastName: null,
      selectedComments: null,
    });
  };

  viewCommentsModal = () => {
    return (
      <Modal
        open={this.state.confirmViewCommentsModalOpen}
        onClose={this.handleCloseViewCommentsModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                {this.state.selectedFirstName} {this.state.selectedLastName}'s comments for ticket
                with ID: {this.state.selectedChequeTicket}.
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <textarea
                name="comments"
                cols="40"
                rows="5"
                autoComplete="off"
                readOnly={true}
                value={this.state.selectedComments}
              ></textarea>
            </div>
          </div>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={() => this.handleCloseViewCommentsModal()}
              >
                Close
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  //Opens Modal containing ADMIN NOTES ONLY
  handleOpenAdminNotesModal = (
    chequeTicket,
    _id,
    firstName,
    lastName,
    adminNoteHistory,
    userId,
  ) => {
    this.setState({
      confirmAdminNotesModalOpen: true,
      selectedChequeTicket: chequeTicket,
      selectedIssueId: _id,
      selectedFirstName: firstName,
      selectedLastName: lastName,
      selectedAdminNoteHistory: adminNoteHistory,
      addComment: "",
      selectedUserId: userId,
    });
  };

  handleCloseAdminNotesModal = () => {
    this.setState({
      confirmAdminNotesModalOpen: false,
      selectedChequeTicket: null,
      selectedIssueId: null,
      selectedFirstName: null,
      selectedLastName: null,
      selectedAdminNoteHistory: [],
      addComment: "",
      selectedUserId: null,
    });
  };

  adminNotesModal = () => {
    return (
      <Modal
        open={this.state.confirmAdminNotesModalOpen}
        onClose={this.handleCloseAdminNotesModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 600, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Admin notes for {this.state.selectedFirstName} {this.state.selectedLastName}'s
                ticket with ID: {this.state.selectedChequeTicket}.
              </h5>
            </div>
            <CommentsTab
              comments={this.state.selectedAdminNoteHistory}
              isLoading={this.state.loading}
            />
            <Row>
              <Col md="12">
                <FormGroup>
                  <Input
                    value={`${this.state.addComment}`}
                    onChange={(e) =>
                      this.setState({
                        addComment: e.target.value,
                      })
                    }
                    placeholder="Add a comment for this user"
                    type="text"
                  />
                  <FormText color="danger" tag="span">
                    {this.state.errorAddComment}
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                onClick={() => this.setState({ confirmAdminNotesModalOpen: false })}
              >
                Close
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  if (!this.state.addComment) {
                    this.setState({ errorAddComment: "Please enter comment" });
                    return;
                  }
                  this.handleAddComment(
                    this.state.selectedIssueId,
                    this.state.selectedUserId,
                    this.state.addComment,
                  );
                }}
              >
                Add
              </Button>
            </div>
            <Row>
              <Col>
                {/* <ReactTable
                    data={this.state.selectedAdminNoteHistory}
                    filterable
                    columns={[
                      {
                        Header: "Admin",
                        accessor: "noteBy",
                        maxWidth: 160,
                      },
                      {
                        Header: "Comment",
                        accessor: "note",
                      },
                      {
                        Header: "Time",
                        accessor: "createdAt",
                        maxWidth: 160,
                      },
                    ]}
                    defaultPageSize={3}
                    showPaginationTop={true}
                    showPaginationBottom={false}
                    showPageSizeOptions={false}
                    className="-striped -highlight primary-pagination"
                  /> */}
              </Col>
            </Row>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  handleAddComment = async (_id, user, newNote) => {
    try {
      this.setState({ isLoading: true });
      const { data } = await api().patch(`/cheque-issues/${_id}`, { note: newNote });

      this.setState({
        selectedAdminNoteHistory: data.notes,
        chequeData: this.state.chequeData.map((chequeData) =>
          chequeData._id === data._id ? data : chequeData,
        ),
        // alert: (
        //   <SweetAlert
        //     success
        //     title="Successfully changed the status."
        //     onConfirm={this.hideAlert}
        //     showConfirm={false}
        //   />
        // ),
        addComment: "",
        isLoading: false,
      });
      // setTimeout(this.hideAlert, 1000);
    } catch (e) {
      console.log(e);
      this.setState({
        // alert: (
        //   <SweetAlert
        //     error
        //     title="Error adding notes"
        //     onConfirm={this.hideAlert}
        //     showConfirm={false}
        //   />
        // ),
        isLoading: false,
      });
      // setTimeout(this.hideAlert, 1000);
    }
  };

  //Opens Modal with a textarea containing an admin's comment (needed because longer comments DO NOT display correctly on the table)
  handleOpenViewSingleAdminNote = (comment) => {
    this.setState({
      confirmViewSingleAdminNote: true,
      selectedComment: comment,
      selectedSingleAdminNote: this.renderSingleAdminNote(comment),
    });
  };

  handleCloseViewSingleAdminNote = () => {
    this.setState({
      confirmViewSingleAdminNote: false,
      selectedSingleAdminNote: null,
      selectedComment: null,
    });
  };

  viewSingleAdminNote = () => {
    return (
      <Modal
        open={this.state.confirmViewSingleAdminNote}
        onClose={this.handleCloseViewSingleAdminNote}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <textarea
              name="singleAdminComment"
              cols="40"
              rows="5"
              autoComplete="off"
              readOnly={true}
              value={this.state.selectedComment}
            ></textarea>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  renderSingleAdminNote = (comment) => {
    return (
      <Button
        onClick={() => {
          this.handleOpenViewSingleAdminNote(comment);
        }}
        className="form-control"
        size="sm"
        style={{
          maxHeight: "40px",
          position: "relative",
          height: "40px",
          fontWeight: 400,
          marginTop: -7,
          textAlign: "left",
          backgroundColor: "transparent",
          color: "#2c2c2c",
          textTransform: "none",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {comment}
      </Button>
    );
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Col md="10">
                      <CardTitle tag="h4">Cheque Issues</CardTitle>
                    </Col>
                    <Col md="2">
                      <Button
                        color="secondary"
                        onClick={() => this.setState({ createChequeIssueModal: true })}
                      >
                        Add New Issue
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    filterable
                    data={this.state.chequeData}
                    getTrProps={(state, rowInfo, column) => {
                      if (rowInfo && rowInfo.row) {
                        if (rowInfo.row._original.issueStatus === "Started") {
                          return {
                            style: {
                              color: "brown",
                            },
                          };
                        } else if (rowInfo.row._original.issueStatus === "Emailed Client") {
                          return {
                            style: {
                              color: "orange",
                            },
                          };
                        } else {
                          return {
                            style: {
                              color: "black",
                            },
                          };
                        }
                      }
                      return {};
                    }}
                    columns={[
                      {
                        Header: "HDID",
                        accessor: "hdId",
                      },
                      {
                        Header: "Created Date",
                        accessor: "createdDate",
                        filterable: false,
                        sortable: false,
                      },
                      {
                        Header: "First Name",
                        accessor: "firstName",
                      },
                      {
                        Header: "Last Name",
                        accessor: "lastName",
                      },
                      {
                        Header: "Phone",
                        accessor: "phone",
                      },
                      {
                        Header: "Email",
                        accessor: "email",
                      },
                      {
                        Header: "Workplace",
                        accessor: "businessName",
                        sortable: false,
                      },
                      {
                        Header: "Shift",
                        accessor: "shift",
                      },
                      {
                        Header: "Supervisor",
                        accessor: "supervisor",
                      },
                      {
                        Header: "Line",
                        accessor: "lineNumber",
                      },
                      {
                        Header: "Pin or Badge",
                        accessor: "pin",
                      },
                      {
                        Header: "Last Edit By",
                        accessor: "lastEditName",
                        filterable: false,
                        sortable: false,
                      },
                      {
                        Header: "Status",
                        accessor: "issueStatus",
                        filterable: false,
                        sortable: false,
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        filterable: false,
                        sortable: false,
                        width: 200,
                      },
                    ]}
                    pages={this.state.pages}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom
                    loading={this.state.loading}
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight primary-pagination"
                    manual // informs React Table that you'll be handling sorting and pagination server-side
                    onFetchData={(state, instance) => {
                      let searchParams = {
                        page: state.page + 1,
                        size: state.pageSize,
                        field: state.sorted.length ? state.sorted[0].id : "chequeTicket",
                        sort: state.sorted.length && state.sorted[0].desc ? "desc" : "asc",
                        filter: state.filtered,
                      };
                      this.setState({
                        reactTableState: searchParams,
                      });
                      clearTimeout(this.state.timer);
                      this.setState({
                        timer: setTimeout(() => {
                          this.setState({ loading: true });
                          this.loadCheques(searchParams);
                        }, 1000),
                      });
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.viewCommentsModal()}
          {this.adminNotesModal()}
          {this.issueStatusModal()}
          {this.viewSingleAdminNote()}
          {this.state.alert}

          <CreateChequeIssue
            openModal={this.state.createChequeIssueModal}
            closeModal={() => this.setState({ createChequeIssueModal: false })}
            loadCheques={this.loadCheques}
          />
        </div>
      </>
    );
  }
}

export default ChequeIssues;

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
  FormText,
  Spinner,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactTable from "react-table";
import QRCode from "react-qr-code";
import { validateEmail } from "components/Utilities/utilities.js";
import api from "../../../components/API/api";

class ManagerProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //setting states for profile data
      firstName: "",
      lastName: "",
      managerType: "",
      phone: "",
      email: "",
      managerType: "",
      managerId: "",

      //Business data
      selectedBusiness: "",
      businessList: [],

      //shift data
      selectedShift: "",
      shiftsList: [],

      //profile data
      selectedProfile: null,
      selectedProfileType: null,
      profilesList: [],

      //agency data
      selectedAgency: null,
      agenciesList: [],

      //history
      changeLog: [],

      //Errors
      errorFirstName: "",
      errorLastName: "",
      errorManagerType: "",
      errorPhone: "",
      errorEmail: "",
      errorSelectedProfile: "",

      // Loading
      isLoading: false,
      pageTabs: "profile",
    };
  }

  componentDidMount() {
    this.fetchProfiles();
    this.fetchBusiness();
    this.fetchShifts();
    this.fetchAgencies();
    this.fetchData();
    // //popup warning if user reloads or closes the page
    window.addEventListener("beforeunload", this.handleUserLeave);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  fetchData = () => {
    //geting manager Id from URL
    let selectedUrl = window.location.pathname.split("/");
    let selectedmanagerId = selectedUrl[3];

    api()
      .get(`/users/managers/${selectedmanagerId}`)
      .then((res) => {
        let data = res.data[0];
        console.log(data);
        this.setState({
          managerId: data._id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
        });

        data.profile.map((p) => {
          let role = p.role;
          let profileList = {
            value: role,
            // to split the value at uppercase and join to match other
            label: role.match(/[A-Z][a-z]+|[0-9]+/g).join(" "),
          };
          this.setState({
            selectedProfile: profileList,
            selectedProfileType: profileList.value,
          });
        });

        data.profile.map((p) => {
          let businessId = p.business;
          if (businessId) {
            let businessList = this.state.businessList;
            businessList.map((b) => {
              let businessList;
              if (b._id === businessId) {
                businessList = {
                  label: b.name,
                  value: b._id,
                };
                this.setState({
                  selectedBusiness: businessList,
                });
              }
            });
          }
        });

        data.profile.map((p) => {
          let agencyId = p.agency;
          if (agencyId) {
            let agenciesList = this.state.agenciesList;
            agenciesList.map((a) => {
              let agencyList;
              if (a._id === agencyId) {
                agencyList = {
                  label: a.name,
                  value: a._id,
                };
                this.setState({
                  selectedAgency: agencyList,
                });
              }
            });
          }
        });

        data.profile.map((p) => {
          let shiftId = p.shift;
          if (shiftId) {
            let shiftsList = this.state.shiftsList;
            shiftsList.map((s) => {
              let shiftList;
              if (s._id === shiftId) {
                shiftList = {
                  label: s.description,
                  value: s._id,
                };
                this.setState({
                  selectedShift: shiftList,
                });
              }
            });
          }
        });
      })
      .catch((err) => console.log(err));
  };

  fetchProfiles = async () => {
    try {
      const { data } = await api().get("/profile/Description");
      console.log("allProfiles", data);

      //filter the agency profiles from profiles list except AgencyAdmin
      let agencyProfileList = [];
      let roles = data.roles;
      roles.map((r) => {
        let name = r.name;
        if (name.includes("Agency") && !name.includes("AgencyAdmin")) agencyProfileList.push(r);
      });

      //  console.log(agencyProfileList)
      this.setState({ profilesList: agencyProfileList });
    } catch (error) {
      console.log(error);
    }
  };

  fetchBusiness = async () => {
    try {
      const { data } = await api().get("/business/list");
      console.log("allBusiness", data);

      this.setState({ businessList: data });
    } catch (error) {
      console.log(error);
    }
  };

  fetchShifts = async () => {
    try {
      const { data } = await api().get("/shift/list");
      console.log("allShifts", data);
      this.setState({ shiftsList: data });
    } catch (error) {
      console.log(error);
    }
  };

  fetchAgencies = async () => {
    try {
      const { data } = await api().get("/agency/list");
      console.log("allAgencies", data);
      this.setState({ agenciesList: data });
    } catch (error) {
      console.log(error);
    }
  };

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = (docId) => {
    this.setState({ isLoading: true });
    let tempState = this.state; // Copy of state
    let hasError = false;

    // Start of Error Handling
    if (tempState.firstName == null || tempState.firstName.trim() == "") {
      this.setState({ errorFirstName: "* Must have a first name." });
      hasError = true;
    } else {
      this.setState({ errorFirstName: "" });
    }

    if (tempState.lastName == null || tempState.lastName.trim() == "") {
      this.setState({ errorLastName: "* Must have a last name." });
      hasError = true;
    } else {
      this.setState({ errorLastName: "" });
    }

    if (tempState.phone == null || tempState.phone.trim() == "") {
      this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
      hasError = true;
    } else {
      if (isNaN(tempState.phone) == true || tempState.phone.length != 10 || tempState.phone < 0) {
        this.setState({ errorPhone: "* Please input a valid phone number." }); //invalid phone number
        hasError = true;
      } else {
        this.setState({ errorPhone: "" });
      }
    }

    if (tempState.email == null || tempState.email.trim() == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }

    if (tempState.selectedProfile == null || tempState.selectedProfile == "") {
      this.setState({ errorSelectedProfile: "* Must have a Profile" });
      hasError = true;
    } else {
      this.setState({ errorSelectedProfile: "" });
    }

    if (hasError) {
      return;
    } else {
      let dataToUpdate = null;
      
      dataToUpdate = {
        userId: tempState.managerId,
        firstName: tempState.firstName,
        lastName: tempState.lastName,
        phone: tempState.phone,
        email: tempState.email,
        // profileType: tempState.selectedProfile.value
      };
      api()
        .patch("/users/", dataToUpdate)
        .then(() => {
          console.log("successfully saved Data");
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                success
                title="Manager data successfully updated"
                onConfirm={this.hideAlert}
              />
            ),
          });
        })
        .catch((err) => console.warn("Problem while updating business: ", err));
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  render() {
    return (
      <>
        <div className="content">
          {this.state.isLoading ? (
            this.loadingOverlay()
          ) : (
              <Row>
                <Col>
                  <Card className="no-transition">
                    <CardHeader>
                      <Row>
                        <Col md="6">
                          <CardTitle tag="h4">
                            {" "}
                          Profile of {`${this.state.firstName}`} {`${this.state.lastName}`}
                          </CardTitle>
                        </Col>
                        <Col md="6" align="right">
                          {/* <Button
                        type="submit"
                        color="default"
                        outline
                        onClick={this.loadShiftHistory}
                      >
                        Full Shift History
                      </Button> */}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Nav
                        className="nav-pills-primary nav-pills-icons justify-content-center"
                        pills
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            role="tablist"
                            className={this.state.pageTabs === "profile" ? "active" : ""}
                            onClick={() => this.setState({ pageTabs: "profile" })}
                          >
                            <i className="now-ui-icons objects_umbrella-13" />
                          Edit Profile
                        </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            role="tablist"
                            className={this.state.pageTabs === "changeLog" ? "active" : ""}
                            onClick={() => this.setState({ pageTabs: "changeLog" })}
                          >
                            <i className="now-ui-icons ui-2_settings-90" />
                          Change Log
                        </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        className="tab-space tab-subcategories"
                        activeTab={this.state.pageTabs}
                      >
                        <TabPane tabId="profile">
                          <CardHeader>
                            <CardTitle tag="h4">Edit Profile</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <Form action="/" className="form-horizontal" method="get">
                              <Row>
                                <Label sm="2">*First Name</Label>
                                <Col sm="5">
                                  <FormGroup>
                                    <Input
                                      defaultValue={`${this.state.firstName}`}
                                      onChange={(e) => this.setState({ firstName: e.target.value })}
                                      placeholder="First Name"
                                      type="text"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorFirstName}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2">*Last Name</Label>
                                <Col sm="6">
                                  <FormGroup>
                                    <Input
                                      defaultValue={`${this.state.lastName}`}
                                      onChange={(e) => this.setState({ lastName: e.target.value })}
                                      placeholder="Last Name"
                                      type="text"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorLastName}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2">* Phone Number</Label>
                                <Col md="5">
                                  <FormGroup>
                                    <MaskedInput
                                      mask={[
                                        "(",
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ")",
                                        "-",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        "-",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                      ]}
                                      defaultValue={`${this.state.phone}`}
                                      onChange={(e) => {
                                        let input = e.target.value;
                                        let replacedPhone = input.replace(/[^0-9]/g, "");
                                        this.setState({ phone: replacedPhone });
                                      }}
                                      placeholder="Phone Number"
                                      className="form-control"
                                      maxLength="15"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorPhone}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2">* Email</Label>
                                <Col sm="6">
                                  <FormGroup>
                                    <Input
                                      defaultValue={`${this.state.email}`}
                                      onChange={(e) => this.setState({ email: e.target.value })}
                                      placeholder="Contact Email"
                                      type="text"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorEmail}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2">* Profile</Label>
                                <Col sm="5">
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    placeholder="Choose profile Type"
                                    name="singleSelect"
                                    value={this.state.selectedProfile}
                                    defaultValue={`${this.state.selectedProfile}`}
                                    onChange={(value) => {
                                      this.setState({ selectedProfile: value });
                                    }}
                                    options={this.state.profilesList.map((p) => ({
                                      label: p.displayName,
                                      value: p.name,
                                    }))}
                                  />
                                  <FormText color="danger" tag="span">
                                    {this.state.errorSelectedProfile}
                                  </FormText>
                                </Col>
                              </Row>

                              {this.state.selectedProfileType ? (
                                this.state.selectedProfileType.includes("Agency") ||
                                  this.state.selectedProfileType.includes("Worker") ? (
                                    <Row>
                                      <Label sm="2">* Agency</Label>
                                      <Col sm="5">
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          placeholder="Choose Agency"
                                          name="singleSelect"
                                          value={this.state.selectedAgency}
                                          onChange={(value) => {
                                            this.setState({ selectedAgency: value });
                                          }}
                                          options={this.state.agenciesList.map((a) => ({
                                            label: a.name,
                                            value: a._id,
                                          }))}
                                        />
                                        <FormText color="danger" tag="span">
                                          {this.state.errorSelectedAgency}
                                        </FormText>
                                      </Col>
                                    </Row>
                                  ) : null
                              ) : null}

                              {this.state.selectedProfileType ? (
                                this.state.selectedProfileType.includes("Business") ? (
                                  <Row>
                                    <Label sm="2">* Business</Label>
                                    <Col sm="5">
                                      <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Choose Business"
                                        name="singleSelect"
                                        value={this.state.selectedBusiness}
                                        onChange={(value) => {
                                          this.setState({ selectedBusiness: value });
                                        }}
                                        options={this.state.businessList.map((b) => ({
                                          label: b.name,
                                          value: b._id,
                                        }))}
                                      />
                                      <FormText color="danger" tag="span">
                                        {this.state.errorSelectedBusiness}
                                      </FormText>
                                    </Col>
                                  </Row>
                                ) : null
                              ) : null}

                              {this.state.selectedProfileType ? (
                                this.state.selectedProfileType.includes("BusinessSupervisor") ? (
                                  <Row>
                                    <Label sm="2">* Shift</Label>
                                    <Col sm="5">
                                      <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        placeholder="Choose Shift"
                                        name="selectShift"
                                        value={this.state.selectedShift}
                                        onChange={(value) => {
                                          this.setState({ selectedShift: value });
                                        }}
                                        options={this.state.shiftsList.map((s) => ({
                                          label: s.description,
                                          value: s._id,
                                        }))}
                                      />
                                      <FormText color="danger" tag="span">
                                        {this.state.errorSelectedShift}
                                      </FormText>
                                    </Col>
                                  </Row>
                                ) : null
                              ) : null}

                              <FormGroup align="right">
                                <Button color="default" outline onClick={() => this.handleSubmit()}>
                                  Save Changes
                              </Button>
                              </FormGroup>
                            </Form>
                          </CardBody>
                        </TabPane>
                        <TabPane tabId="changeLog">
                          <CardHeader>
                            <CardTitle tag="h4">Change Log</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <ReactTable
                              data={this.state.changeLog}
                              filterable
                              columns={[
                                {
                                  Header: "Admin",
                                  accessor: "admin",
                                },
                                {
                                  Header: "Time Changed",
                                  accessor: "time",
                                },
                                {
                                  Header: "Action Taken",
                                  accessor: "action",
                                },
                              ]}
                              defaultPageSize={10}
                              showPaginationTop={true}
                              showPaginationBottom={false}
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight primary-pagination"
                            />
                          </CardBody>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                  <Col md="4">
                    <Card style={{ width: "286px" }} className="no-transition">
                      <CardHeader>
                        <CardTitle tag="h4">QR Code for Profile</CardTitle>
                      </CardHeader>
                      <CardBody align="left">
                        <QRCode value={`${this.state.managerId}`} />
                      </CardBody>
                    </Card>
                  </Col>
                </Col>
              </Row>
            )}
          {this.state.alert}
        </div>
      </>
    );
  }
}

export default ManagerProfile;

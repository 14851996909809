import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { Bar, defaults } from "react-chartjs-2";
import api from "../../../components/API/api";
import * as moment from "moment-timezone";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import CustomCalendar from "components/Calendar/CustomCalenderForAnalytics.jsx";

function StaffStatistics() {
  const [pageTabs, setPageTabs] = useState("day");
  const [date, setDate] = useState(new Date());
  const [staffId, setStaffId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    scheduled: 0,
    unScheduled: 0,
    chequesHandedOut: 0,
    chequesRejected: 0,
  });

  const DATA = {
    labels: ["Workers Scheduled", "Workers UnScheduled", "Cheques Handed out", "Cheques Rejected"],
    datasets: [
      {
        label: pageTabs + " Statistics",
        data: [data.scheduled, data.unScheduled, data.chequesHandedOut, data.chequesRejected],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(95, 99, 132, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(255, 99, 132, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(155, 106, 86, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
      },
    ],
  };
  defaults.global.animation = false;

  useEffect(() => {
    // get the staffId from URL
    let selectedUrl = window.location.pathname.split("/");
    let selectedDocId = selectedUrl[4];
    setStaffId(selectedDocId);
    loadStatistics(selectedDocId);
  }, []);

  useEffect(() => {
    // console.log("running");
    loadStatistics(staffId);
  }, [pageTabs, staffId, date]);

  const getThisDates = (date) => {
    return {
      from: moment(date).startOf(pageTabs).toDate(),
      till: moment(date).endOf(pageTabs).toDate(),
    };
  };

  // get daily stats using staffId
  const loadStatistics = async (staffId) => {
    setIsLoading(true);
    let tempDate = getThisDates(date);
    tempDate.staffId = staffId;
    let params = { ...tempDate };
    const { data } = await api().get("/log/staff/count/details", {
      params,
    });
    // console.log("params", params);
    // console.log("data", data);
    calculateTotalStats(data);
    setIsLoading(false);
  };

  // total stats
  const calculateTotalStats = (data) => {
    if (data.length > 0) {
      let totalSelect = 0;
      let totalUnselect = 0;
      let totalCheque = 0;
      let totalChequeRejected = 0;
      data.map((d) => {
        totalSelect = totalSelect + d.select;
        totalUnselect = totalUnselect + d.unselect;
        totalCheque = totalCheque + d.cheque;
        totalChequeRejected = totalChequeRejected + d.chequeReject;
      });
      setData({
        scheduled: totalSelect,
        unScheduled: totalUnselect,
        chequesHandedOut: totalCheque,
        chequesRejected: totalChequeRejected,
      });
    } else {
      setData({
        scheduled: 0,
        unScheduled: 0,
        chequesHandedOut: 0,
        chequesRejected: 0,
      });
    }
  };

  const _renderStats = () => {
    return (
      <Bar
        data={DATA}
        width={400}
        height={200}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    );
  };

  //Renders the nav for each of the tabs.
  const renderNavTabs = () => {
    let tabList = [
      { name: "Daily", id: "day" },
      { name: "Weekly", id: "week" },
      { name: "Monthly", id: "month" },
      { name: "Yearly", id: "year" },
    ];
    return (
      <>
        {tabList.map((tab) => {
          return (
            <NavItem key={tab.id}>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={pageTabs === tab.id ? "active" : ""}
                onClick={() => {
                  setPageTabs(tab.id);
                }}
              >
                <i className="now-ui-icons ui-2_settings-90" />
                {tab.name}
              </NavLink>
            </NavItem>
          );
        })}
      </>
    );
  };

  const onChangeDate = (date) => {
    console.log(date);
    setDate(date);
  };

  const renderTabPane = (title) => {
    return (
      <Row id={pageTabs}>
        <Col>
          <CardHeader>
            <Row>
              <Col md="6">
                <CardTitle tag="h4">{title + " Statistics"}</CardTitle>
              </Col>
              <Col md="6">
                <CustomCalendar
                  inputProps={{
                    placeholder: "Date Picker Here",
                  }}
                  pageTabs={pageTabs}
                  disableFutureDate={true}
                  initialDate={date}
                  myDate={date}
                  onChangeDate={onChangeDate}
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>{_renderStats()}</Col>
            </Row>
          </CardBody>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">Staff Analytics</CardTitle>
                </CardHeader>
                <CardBody>
                  <Nav
                    className="nav-pills-primary nav-pills-icons justify-content-center"
                    pills
                    role="tablist"
                  >
                    {renderNavTabs()}
                  </Nav>
                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col md="10">
                      <TabContent className="tab-space tab-subcategories" activeTab={pageTabs}>
                        <TabPane tabId="day">{renderTabPane("Daily")}</TabPane>
                        <TabPane tabId="week">{renderTabPane("Weekly")}</TabPane>
                        <TabPane tabId="month">{renderTabPane("Monthly")}</TabPane>
                        <TabPane tabId="year">{renderTabPane("Yearly")}</TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default StaffStatistics;

import React, { useState, useEffect } from "react";
// reactstrap components
import {
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
  Input,
  Button,
  FormGroup,
} from "reactstrap";
import { Comment } from "@mui/icons-material";
import { Modal } from "@mui/material";
import ReactTable from "react-table";
import api from "components/API/api";
import moment from "moment";
import CustomCalendar from "components/Calendar/CustomerCalenderForMonth";
import { statusEnum } from "utils/statusVariables";

const statusEnumText = ["Applied", "Selected", "Backup", "Rejected", "UnScheduled", "Dropped"];

function UserChequeHistory({ userId, agency }) {
  const [chequeHistory, setChequeHistory] = useState([]);
  //react table states
  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 10,
    field: "start",
    sort: "desc",
    filter: [],
  });
  const [loadingTable, setLoadingTable] = useState(false);
  const [date, setDate] = useState(new Date());
  const [pages, setPages] = useState(-1);
  const [filter, setFilter] = useState({
    status: null,
    from: new Date(date.getFullYear(), date.getMonth(), 1),
    till: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  });
  const [commentsModal, setCommentsModal] = useState(false);
  const [selectedAdminNotes, setSelectedAdminNotes] = useState([]);
  const [chequeNumber, setChequeNumber] = useState(null);

  useEffect(() => {
    // if (!isFirstMount) {
    getWorkerChequeHistory();
    setLoadingTable(true);
    // }
    // console.log("filter", filter);
  }, [filter.status, filter.from, filter.till]);

  //function to get history
  const getWorkerChequeHistory = async (filterOptions = reactTableState) => {
    // isFirstMount && setIsFirstMount(false);
    try {
      let d = new Date();
      let params;
      if (Number.isInteger(filter.status)) {
        params = {
          ...filterOptions,
          ...filter,
          userId,
          agency,
        };
      } else {
        params = {
          ...filterOptions,
          from: filter.from,
          till: filter.till,
          userId,
          agency,
        };
      }

      console.log("params", params);
      const { data } = await api().get(`/cheque-pickup/${userId}`, { params });
      console.log("data", data);
      let tempChequeRequestData = data.chequeRequest.map((request) => {
        request.actions = renderActionButton(
          request._id,
          request.notes,
          request.chequePickUpNumber,
        );

        if (request.duplicated) {
          request.lastName = request.lastName + "-" + request.duplicated;
        }
        request.pickedUp = request.pickedUp ? "Yes" : "No";
        request.isRegistered = request.userId ? true : false;
        request.lastAdminEdit = request.lastEditBy || "No edits yet";
        request.createdAt = request.createdAt
          ? moment(request.createdAt).format("YYYY-MM-DD, hh:mm a")
          : "";
        request.startOfWeek = request.weekStart
          ? moment(request.weekStart).format("YYYY-MM-DD")
          : "";
        request.endOfWeek = request.weekEnd ? moment(request.weekEnd).format("YYYY-MM-DD") : "";
        request.note = request.notes?.length ? request.notes.map((n) => n.note).join(",") : "";
        // console.log(notes);
        return request;
      });

      setPages(data.noPages);
      setChequeHistory(tempChequeRequestData);
      setLoadingTable(false);
    } catch (e) {
      console.log(e);
      setLoadingTable(false);
    }
  };

  const renderActionButton = (_id, adminNotes, chequePickUpNumber, firstName, lastName) => {
    return adminNotes?.length > 0 ? (
      <div className="actions-center">
        <Button
          onClick={() => {
            handleOpenViewCommentsModal(_id, adminNotes, chequePickUpNumber, firstName, lastName);
          }}
          color="info"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Comment style={{ marginLeft: -1 }} />
        </Button>{" "}
      </div>
    ) : null;
  };

  const handleOpenViewCommentsModal = (_id, adminNotes, chequePickUpNumber) => {
    setCommentsModal(true);
    setSelectedAdminNotes(adminNotes);
    setChequeNumber(chequePickUpNumber);
  };

  const handleCloseViewCommentsModal = () => {
    setCommentsModal(false);
    setSelectedAdminNotes([]);
    setChequeNumber(null);
  };

  const onChangeDate = (date) => {
    // console.log(new Date(date).getFullYear());
    let tempDate = new Date(date);
    setDate(tempDate);
    setFilter({
      ...filter,
      from: new Date(tempDate.getFullYear(), tempDate.getMonth(), 1),
      till: new Date(tempDate.getFullYear(), tempDate.getMonth() + 1, 0),
    });
  };

  const viewCommentsModal = () => {
    return (
      <Modal
        open={commentsModal}
        onClose={handleCloseViewCommentsModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Admin notes for Cheque request with ID: {chequeNumber}.</h5>
            </div>
            <Row>
              <Col align="center">
                {selectedAdminNotes && selectedAdminNotes.length > 0 ? (
                  selectedAdminNotes.map((n, i) => {
                    return (
                      <div key={n._id} className="d-flex justify-content-between">
                        <p>{n.note}</p>
                        <p>-{n.adminName}</p>
                      </div>
                    );
                  })
                ) : (
                  <p>No notes :)</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col align="center">
                <Button
                  color="secondary"
                  style={{ marginRight: "12px" }}
                  onClick={() => handleCloseViewCommentsModal()}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const loadingOverlay = () => {
    return (
      <div style={{ display: "flex" }}>
        <Spinner color="primary" style={{ margin: "300px auto", width: "3rem", height: "3rem" }} />
      </div>
    );
  };

  return (
    <div className="content">
      <>
        <CardHeader>
          <CardTitle tag="h4">Cheque History</CardTitle>
        </CardHeader>
        <CardBody>
          {/* <Row className="justify-content-between">
            <Col lg="6" md="6"></Col>
            <Col lg="6" md="6">
              <div style={{ textAlign: "right" }}>
                <FormGroup row className="align-items-right">
                  <Label for="Search" sm={3} style={{ color: "black", fontSize: "14px" }}>
                    Status
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="select"
                      name="status"
                      onChange={(e) => {
                        setFilter({ ...filter, status: parseInt(e.target.value) });
                      }}
                      value={filter.status}
                    >
                      <option key={10} value={null}>
                        Default (Selected and Backup)
                      </option>
                      {Object.values(statusEnum.selectedForWork)
                        // .filter((s) => s !== statusEnum.suspended) //don't show suspended status on drw
                        .map((b, i) => {
                          return (
                            <option key={i} value={b}>
                              {statusEnumText[i]}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                </FormGroup>
              </div>
            </Col>
          </Row>
          <CustomCalendar myDate={date} onChangeDate={onChangeDate} disableFutureDate={true} /> */}

          <Row style={{ marginTop: 20 }}>
            <Col>
              <ReactTable
                filterable
                columns={[
                  {
                    Header: "Created Date",
                    accessor: "createdAt",
                    filterable: false,
                  },

                  {
                    Header: "Workplace",
                    accessor: "businessName",
                    filterable: false,
                  },
                  {
                    Header: "Pin#",
                    accessor: "pin",
                    maxWidth: "100",
                  },
                  {
                    Header: "Start of Week",
                    accessor: "startOfWeek",
                    filterable: false,
                  },
                  {
                    Header: "End of Week",
                    accessor: "endOfWeek",
                    filterable: false,
                  },
                  {
                    Header: "PickedUp?",
                    accessor: "pickedUp",
                    sortable: false,
                    maxWidth: 100,
                  },
                  {
                    Header: "Last Admin Edit",
                    accessor: "lastEditName",
                    filterable: false,
                    sortable: false,
                    maxWidth: 150,
                  },
                  {
                    Header: "Notes",
                    accessor: "note",
                    sortable: false,
                  },
                  {
                    accessor: "actions",
                    filterable: false,
                    sortable: false,
                    width: 65,
                  },
                ]}
                manual
                data={chequeHistory}
                pages={pages}
                loading={loadingTable}
                defaultPageSize={10}
                pageSizeOptions={[10, 15, 30, 60]}
                showPaginationTop={true}
                showPaginationBottom={false}
                onFetchData={(state, instance) => {
                  let searchParams = {
                    page: state.page + 1,
                    size: state.pageSize,
                    field: "start",
                    sort: state.sorted.length ? (state.sorted[0].desc ? "desc" : "asc") : "desc",
                    filter: state.filtered,
                  };
                  setReactTableState(searchParams);
                  console.log("fetch");
                  // setTimer(
                  //   setTimeout(() => {
                  //     setLoadingTable(true);
                  getWorkerChequeHistory(searchParams);
                  //   }, 1000),
                  // );
                  // clearTimeout(timer);
                }}
                className="-striped -highlight primary-pagination"
              />
            </Col>
          </Row>
        </CardBody>
      </>
      {viewCommentsModal()}
    </div>
  );
}

export default UserChequeHistory;

import React from "react";
import ReactTable from "react-table";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Comment, Visibility } from "@mui/icons-material";
import { Modal } from "@mui/material";
import api, { useEventSource } from "components/API/api";
import moment from "moment";
import ImageDisplay from "components/ImageDisplay";
import AgencyContext from "./../../../context/AgencyContext";
import LoadingOverlay from "components/OverLay/LoadingOverlay";

const DocumentType = {
  id: 0,
  workPermit: 1,
  sin: 2,
  studyPermit: 3,
  sinApplicationProof: 4,
  signature: 5,
};
class ChequePickupHistory extends React.Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.numDataLoaded = 10;
    this.state = {
      chequeData: [],
      alert: null,
      totalProcessed: 0,
      chequesToProcess: 0,
      selectedChequePickupNumber: null,
      selectedDocId: null,
      selectedFirstName: null,
      selectedLastName: null,
      selectedAdminNotes: [],
      selectedId: null,
      selectedCheque: null,
      images: [],
      verificationModal: false,
      confirmDeleteModalOpen: false,
      confirmViewCommentsModalOpen: false,
      reactTableState: {
        page: 1,
        size: 10,
        field: "name",
        sort: "asc",
        filter: "",
      },
      pages: -1,
      loading: false,
      timer: null,
    };
    this.source = null;
  }

  // componentDidMount() {
  //   // this.loadCheques();
  //   let agency =
  //     this.context.agency && this.context.agency.agency && this.context.agency.agency._id;
  //   this.source = useEventSource(`/cheque-pickup/listen?agency=${agency}`, (data) => {
  //     if (data.changed) {
  //       this.loadCheques();
  //     }
  //   });
  // }

  componentWillUnmount() {
    // this.source.close();
    clearTimeout(this.state.timer);
  }

  loadCheques = async (filterOptions = this.state.reactTableState) => {
    try {
      console.log(filterOptions);
      this.setState({ loading: true });
      const { data } = await api().get("/cheque-pickup", {
        params: {
          ...filterOptions,
        },
      });
      console.log("data", data);
      //Looping through the business data for action button implementation
      let tempChequeRequestData = data.chequeRequest.map((request) => {
        request.actions = this.renderActionButton(
          request._id,
          request.note,
          request.chequePickUpNumber,
          request.firstName,
          request.lastName,
          request,
        );

        if (request.duplicated) {
          request.lastName = request.lastName + "-" + request.duplicated;
        }
        request.pickedUp = request.pickedUp ? "Yes" : "No";
        request.isRegistered = request.userId ? true : false;
        request.lastAdminEdit = request.lastEditBy || "No edits yet";
        request.createdAt = request.createdAt
          ? moment(request.createdAt).format("YYYY-MM-DD, hh:mm a")
          : "";
        request.startOfWeek = request.weekStart
          ? moment(request.weekStart).format("YYYY-MM-DD")
          : "";
        request.endOfWeek = request.weekEnd ? moment(request.weekEnd).format("YYYY-MM-DD") : "";
        request.notes = request.note?.length ? request.note.map((n) => n.note).join(",") : "";
        // console.log(notes);
        return request;
      });

      this.setState({
        chequeData: tempChequeRequestData,
        pages: data.noPages,
        loading: false,
        chequesToProcess: data.waiting,
        totalProcessed: data.processed,
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadImage = async (userId, signature) => {
    // console.log(signature)
    try {
      this.setState({ loading: true });
      let tempImages = [];
      if (userId && signature) {
        const { data } = await api().get(`/users/documents?userId=${userId}`);

        data.map((doc) => {
          if (doc.type === DocumentType.signature) {
            console.log(doc);
            tempImages.push({
              id: doc.id,
              img: doc.url,
              displayName: "Original Signature",
            });
          }
        });
        const proof = await api().get(
          `image/sign-s3/get?file-path=signature/${userId}/${signature}`,
        );
        tempImages.push({
          id: "proof",
          displayName: "Signature Proof",
          img: proof?.data?.signedUrl,
        });
        this.setState({
          images: tempImages,
          loading: false,
        });
      }
    } catch (e) {
      this.setState({
        images: null,
        loading: false,
      });
    }
  };

  renderActionButton = (_id, adminNotes, chequePickUpNumber, firstName, lastName, request) => {
    return adminNotes?.length > 0 ? (
      <div className="actions-center">
        {request.pickupBy ? (
          <Button
            tag={Link}
            onClick={() => {
              if (request?.proof?.signature)
                this.loadImage(request.userId, request?.proof?.signature);
              this.setState({
                selectedCheque: request,
                verificationModal: true,
              });
            }}
            color="info"
            size="md"
            className="btn-link remove btn-icon"
          >
            <Visibility style={{ marginLeft: -1 }} />
          </Button>
        ) : null}
        <Button
          onClick={() => {
            this.handleOpenViewCommentsModal(
              _id,
              adminNotes,
              chequePickUpNumber,
              firstName,
              lastName,
            );
          }}
          color="info"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Comment style={{ marginLeft: -1 }} />
        </Button>{" "}
      </div>
    ) : null;
  };

  handleOpenViewCommentsModal = (_id, adminNotes, chequePickUpNumber, firstName, lastName) => {
    this.setState({
      confirmViewCommentsModalOpen: true,
      selectedId: _id,
      selectedAdminNotes: adminNotes,
      selectedChequePickupNumber: chequePickUpNumber,
      selectedFirstName: firstName,
      selectedLastName: lastName,
    });
  };

  handleCloseViewCommentsModal = () => {
    this.setState({
      confirmViewCommentsModalOpen: false,
      selectedId: null,
      selectedAdminNotes: [],
      selectedChequePickupNumber: null,
      selectedFirstName: null,
      selectedLastName: null,
    });
  };

  viewCommentsModal = () => {
    return (
      <Modal
        open={this.state.confirmViewCommentsModalOpen}
        onClose={this.handleCloseViewCommentsModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Admin notes for {this.state.selectedFirstName} {this.state.selectedLastName}'s
                request with ID: {this.state.selectedChequePickupNumber}.
              </h5>
            </div>
            <Row>
              <Col align="center">
                {this.state.selectedAdminNotes && this.state.selectedAdminNotes.length > 0 ? (
                  this.state.selectedAdminNotes.map((n, i) => {
                    return (
                      <div key={n._id} className="d-flex justify-content-between">
                        <p>{n.note}</p>
                        <p>-{n.adminName}</p>
                      </div>
                    );
                  })
                ) : (
                  <p>No notes :)</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col align="center">
                <Button
                  color="secondary"
                  style={{ marginRight: "12px" }}
                  onClick={() => this.handleCloseViewCommentsModal()}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  handleCloseVerifyModal = () => {
    this.setState({
      verificationModal: false,
      selectedCheque: null,
      images: [],
    });
  };

  verifyUserSignatureModal = () => {
    if (!this.state.selectedCheque) return null;
    console.log(this.state.selectedCheque);
    return (
      <Modal
        open={this.state.verificationModal}
        onClose={this.handleCloseVerifyModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "scroll",
          paddingTop: 80,
          paddingBottom: 20,
        }}
      >
        {this.state.loading ? (
          <LoadingOverlay />
        ) : (
          <FormGroup style={{ width: 600, backgroundColor: "#fff", borderRadius: 10 }}>
            <div style={{ padding: 15 }}>
              {/* <div style={{ justifyContent: "space-between", display: "flex" }}> */}
              <Row
                md="12"
                style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}
              >
                <Col md="10">
                  <h5 style={{ fontWeight: "bold" }}>
                    Cheque Request Info of {this.state.selectedCheque.firstName}{" "}
                    {this.state.selectedCheque.lastName}
                  </h5>
                </Col>
              </Row>

              {this.state.selectedCheque?.pickupBy?.owner ? (
                <div style={{ justifyContent: "center", padding: 30 }}>
                  <h6 style={{ textAlign: "center" }}>Picked Up By Owner</h6>

                  <Row
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    Full Name:{" "}
                    {this.state.selectedCheque?.firstName +
                      " " +
                      this.state.selectedCheque?.lastName}
                  </Row>
                </div>
              ) : (
                <div style={{ justifyContent: "center", padding: 30 }}>
                  <h6 style={{ textAlign: "center" }}>Picked Up By Other</h6>
                  <Row
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    Full Name:
                    {this.state.selectedCheque?.pickupBy?.firstName +
                      " " +
                      this.state.selectedCheque?.pickupBy?.lastName}
                  </Row>
                  <Row>Relationship: {this.state.selectedCheque?.pickupBy?.relationship}</Row>
                </div>
              )}
              {this.state.images.length ? (
                <>
                  <div>
                    Date Signed:{" "}
                    {moment(this.state.selectedCheque?.proof?.date).format("MMM DD YYYY, hh:mm a")}
                  </div>
                  <Row>
                    {this.state.images?.map((doc) => doc.img && <ImageDisplay document={doc} />)}
                  </Row>

                  <span>* please click on image to enlarge</span>
                </>
              ) : (
                <div>No Signatures found for this user</div>
              )}
            </div>
          </FormGroup>
        )}
      </Modal>
    );
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">Cheque Requests</CardTitle>
                </CardHeader>
                <CardBody>
                  <div>
                    Total Cheques Processed Today: {this.state.totalProcessed} <br></br>
                  </div>
                  <ReactTable
                    data={this.state.chequeData}
                    filterable
                    getTrProps={(state, rowInfo, column) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          style: {
                            background: rowInfo.row._original.assignedTo ? "#EDCCB7" : "white",
                            color: rowInfo.row._original.userId ? "black" : "red",
                          },
                        };
                      }
                      return {};
                    }}
                    columns={[
                      //getProps purpose: If user is registered, row is black, if user is NOT registered, row is red
                      // {
                      //   Header: "ID",
                      //   accessor: "chequePickUpNumber",
                      //   maxWidth: 50,
                      // },
                      {
                        Header: "HDID",
                        accessor: "hdId",
                        maxWidth: 50,
                      },
                      {
                        Header: "Created Date",
                        accessor: "createdAt",
                        filterable: false,
                      },
                      {
                        Header: "First Name",
                        accessor: "firstName",
                      },
                      {
                        Header: "Last Name",
                        accessor: "lastName",
                      },
                      {
                        Header: "Phone",
                        accessor: "phone",
                      },
                      {
                        Header: "Workplace",
                        accessor: "business",
                        filterable: false,
                      },
                      {
                        Header: "Pin#",
                        accessor: "pin",
                        maxWidth: "100",
                      },
                      {
                        Header: "Start of Week",
                        accessor: "startOfWeek",
                        filterable: false,
                      },
                      {
                        Header: "End of Week",
                        accessor: "endOfWeek",
                        filterable: false,
                      },
                      {
                        Header: "PickedUp?",
                        accessor: "pickedUp",
                        sortable: false,
                        maxWidth: 100,
                      },
                      {
                        Header: "Last Admin Edit",
                        accessor: "lastAdminEdit",
                        filterable: false,
                        sortable: false,
                        maxWidth: 150,
                      },
                      {
                        Header: "Notes",
                        accessor: "notes",
                        sortable: false,
                      },
                      {
                        accessor: "actions",
                        filterable: false,
                        sortable: false,
                        width: 105,
                      },
                    ]}
                    pages={this.state.pages}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom
                    loading={this.state.loading}
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight primary-pagination"
                    manual // informs React Table that you'll be handling sorting and pagination server-side
                    onFetchData={(state, instance) => {
                      // let searchParams = {
                      //   page: state.page + 1,
                      //   size: state.pageSize,
                      //   field: state.sorted.length ? state.sorted[0].id : "",
                      //   sort: state.sorted.length && state.sorted[0].desc ? "desc" : "asc",
                      //   // filter: state.filtered,
                      // };
                      // this.setState({
                      //   reactTableState: searchParams,
                      // });
                      // this.loadCheques(searchParams);
                      let searchParams = {
                        page: state.page + 1,
                        size: state.pageSize,
                        field: state.sorted.length ? state.sorted[0].id : "chequePickUpNumber",
                        sort: state.sorted.length && state.sorted[0].desc ? "desc" : "asc",
                        filter: state.filtered,
                      };
                      this.setState({
                        reactTableState: searchParams,
                      });
                      clearTimeout(this.state.timer);
                      this.setState({
                        timer: setTimeout(() => {
                          this.loadCheques(searchParams);
                        }, 1000),
                      });
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.viewCommentsModal()}
          {this.verifyUserSignatureModal()}
        </div>
      </>
    );
  }
}

export default ChequePickupHistory;

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Label,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import api from "../../../components/API/api";
import LoadingOverlay from './../../../components/OverLay/LoadingOverlay';
class CreatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      tokenVerified: false,
      token: "",
      passwordCreated: false,
      //errors
      errorPassword: "",
      errorConfirmPassword: "",
      errorPasswordMatch: "",
    };
  }

  componentDidMount() {
    this.verifyToken();
  }

  verifyToken = async () => {
    //to get token param from url
    let urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('reset');

    try {
      const { data } = await api().post("/users/validate-token", { token: token });
      console.log(data);
      this.setState({
        tokenVerified: true,
        token: token
      })
    } catch (error) {
      this.setState({
        alert: <SweetAlert warning title="Invalid token" onConfirm={this.hideAlert} />,
      })
    }
  }

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    console.log(tempState.password, tempState.confirmPassword)
    // Start of Error Handling
    if (tempState.password == null || tempState.password == "") {
      this.setState({ errorPassword: "* Must have a Password." });
      hasError = true;
    } else {
      this.setState({ errorPassword: "" });
    }
    if (tempState.confirmPassword == null || tempState.confirmPassword == "") {
      this.setState({ errorConfirmPassword: "* Must have a Confirm Password." });
      hasError = true;
    } else {
      this.setState({ errorConfirmPassword: "" });
    }
    if (tempState.password === tempState.confirmPassword) {
      console.log("passwords matched")
    } else {
      this.setState({ errorPasswordMatch: "Passwords not matched" });
      hasError = true
    }
    if (hasError) {
      return
    } else {
      this.sendToServer(tempState)
    }

  }

  sendToServer = async (tempState) => {
    let dataToSend = null;
    dataToSend = {
      token: tempState.token,
      password: tempState.password
    }
    try {
      const { data } = await api().post("/users/reset-password", dataToSend);
      this.setState({
        token: null,
        verifyToken: false,
        alert: <SweetAlert success title="password successfully created" onConfirm={this.hideAlert} />,
        passwordCreated: true,
      })

    } catch (error) {
      console.log(error);
      this.setState({
        alert: <SweetAlert warning title="Sorry, couldn't set your password" onConfirm={this.hideAlert} />,
      })

    }

  }


  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  render() {

    return (
      <>
        <div className="content">
          {!this.state.tokenVerified ? (
            <LoadingOverlay />
          ) : this.state.passwordCreated ? (
            <div>
              <Row>
                <Col md="3"></Col>
                <Col md="6" className="align-center">
                  <Card className="no-transition">
                    <CardHeader>
                      <CardTitle tag="h4" style={{ textAlign: "center" }}>
                        Password Created successfully!
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="4"></Col>
                        <Col sm="4">
                          <a href="/auth/login" class="btn btn-primary">
                            Please login here
                          </a>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
                <Row style={{ marginTop: "100px" }}>
                  <Col md="3"></Col>
                  <Col md="6" className="align-center">
                    <Card className="no-transition" style={{ margin: "8px" }}>
                      <CardHeader>
                        <CardTitle tag="h4" style={{ textAlign: "center" }}>
                          Reset your password
                    </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <Form action="#" className="form-horizontal" method="#">
                          <Row>
                            <Label sm="3">Password</Label>
                            <Col sm="9" md="6">
                              <FormGroup>
                                <Input
                                  type="password"
                                  autoComplete="off"
                                  onChange={(e) => this.setState({ password: e.target.value })}
                                />
                                <FormText color="danger" tag="span">
                                  {this.state.errorPassword}
                                </FormText>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="3">Confirm Password</Label>
                            <Col sm="9" md="6">
                              <FormGroup>
                                <Input
                                  type="password"
                                  autoComplete="off"
                                  onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                                />
                                <FormText color="danger" tag="span">
                                  {this.state.errorConfirmPassword}
                                </FormText>
                                <FormText color="danger" tag="span">
                                  {this.state.errorPasswordMatch}
                                </FormText>
                              </FormGroup>
                            </Col>
                          </Row>
                          <div style={{ textAlign: "right" }}>
                            <Button color="success" onClick={() => this.handleSubmit()}>
                              Change Password
                              </Button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
          {this.state.alert}
        </div>
      </>
    );
  }
}


export default CreatePassword;


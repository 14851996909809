/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
//import { Prompt } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingOverlay from "components/OverLay/LoadingOverlay";

import * as utils from "components/Utilities/utilities.js";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  CustomInput,
} from "reactstrap";

// import { firebase, fs } from "components/Firebase/firebase.js";

import { Modal } from "@mui/material";
import Select from "react-select";
import api from "components/API/api";

//sendsms function
// const fn_sendSmsManual = firebase.functions().httpsCallable("sendSmsManual");

class CreateManualNotification extends Component {
  constructor(props) {
    super(props);
    this.today = new Date();
    this.tomorrow = new Date();
    this.tomorrow.setDate(this.today.getDate() + 1);
    this.allowedChar = 140;
    this.currentlySendingFrom = 0;
    //this.untrainedMessageSample = `Your HD group registration is incomplete, will you be completing your training in order to be scheduled for work?${"\n"} Reply: yes/no.${"\n"}- HD Group`;
    //this.trainedMessageSample = `Workers needed, morning shift, Vaughan. Let us know if you can attend.${"\n"}  Reply: yes/no ${"\n"} - HD Group`;

    //This will use this designated number for now. for trained and untrained users.
    // this.designatedNumbers = {
    //   //trained: "8106710072", //Sajit's number
    //   trained: "6474243531",
    //   untrained: "2892742003",
    // };
    this.fromNumber = "6476911023";
    // this.fromNumber = "+18106710072";

    this.limit = 50;
    this.totalSent = 0;
    this.totalErrors = 0;
    this.state = {
      message: "",
      isLoading: false,
      errorMessage: "",
      error: false,
      charLeft: this.allowedChar,
      keyPressed: null,
      sendSmsModalOpen: false,

      alert: null,
      loadingMessage: "",
      noSent: 0,
      noErrors: 0,

      numberList: [],
      numberText: "",
      currentlySendingFrom: 0,

      //State only for form
    };
  }

  componentDidMount() {
    // Database References
    document.addEventListener("keydown", this.KeyCheck);

    //load all businesses at the beginning (This feature is not available now so just commented)
    //this.businessDataRef = fs.collection("business_data");
    //this.loadBusinesses();
  }

  KeyCheck = (event) => {
    this.setState({ keyPressed: event.keyCode });
  };

  handleSubmit = () => {
    //let autoFilledEmptyMessage = false;
    let { message } = this.state;
    //if message exceeds length
    if (message.length > 140) {
      this.setState({
        errorMessage: "Length exceeds characters. Please make sure length is less than 140",
      });
      return;
    }
    if (this.state.numberList.length <= 0) {
      this.setState({
        errorMessage: "Please add at least 1 number to send the message.",
      });
      return;
    }

    if (!message) {
      this.setState({
        errorMessage: "Please add a message.",
      });
      return;
    }

    this.setState({ sendSmsModalOpen: true });
  };

  //actually send sms to firestore function.
  sendSms(message, fromNumber, numbers) {
    // this.setState((prevState) => {
    //   return { currentlySending: prevState.currentlySending + this.limit };
    // });
    let sendingNumbers = numbers.filter((num, i) => {
      if (i >= this.currentlySendingFrom && i < this.currentlySendingFrom + this.limit) {
        return true;
      }
    });

    if (sendingNumbers.length > 0) {
      this.currentlySendingFrom += this.limit;
      //this.setState({ currentlySendingFrom });
      console.log("sent " + this.currentlySendingFrom, sendingNumbers);
      //this.sendSms(message, fromNumber, numbers);
      // fn_sendSmsManual({
      //   message,
      //   fromNumber,
      //   numbers: sendingNumbers,
      // })
      api()
        .post("/notification/sms/send", {
          sendTo: sendingNumbers,
          message,
          number: fromNumber,
        })

        .then((result) => {
          console.log(result.data);

          //Successfully sent the message
          this.totalSent = this.totalSent + result.data.sent.length;
          this.totalErrors = this.totalErrors + result.data.errors.length;

          this.setState((prevState) => {
            return {
              isLoading: true,
              loadingMessage: `Success: ${this.totalSent} ${"\n"} Fail: ${this.totalErrors}.`,
            };
          });
          //recursively send sms until all the message are sent
          this.sendSms(message, fromNumber, numbers);

          //console.log(result.data);
        })
        .catch((e) => {
          //console.log(e);
          this.currentlySendingFrom = 0;
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                warning
                title={`There was an error sending the message message. If the issue persists please contact tech support.`}
                onConfirm={this.hideAlert}
                showConfirm={false}
              />
            ),
          });
          //Hide the alert after some time.
          setTimeout(() => {
            this.setState({
              alert: "",
            });
          }, 2000);
        });
    } else {
      this.currentlySendingFrom = 0;
      this.setState({
        isLoading: false,
        alert: (
          <SweetAlert
            success
            title={`Successfully sent message to ${
              // result.data.sent && result.data.sent.length
              this.totalSent
            } users. Failed to send to ${this.totalErrors} numbers of users.`}
            onConfirm={this.hideAlert}
            showConfirm={true}
          />
        ),
      });
      this.totalSent = 0;
      this.totalErrors = 0;
      //Hide the alert after some time.
      setTimeout(() => {
        this.setState({
          alert: "",
        });
      }, 9000);
    }
  }

  //Send Message
  send = (message, fromNumber, numbers) => {
    //type->trained/untrained/all
    //message->message
    //availability->true/false/availabilibty
    //number->number to be sent

    this.setState({
      isLoading: true,
      loadingMessage: "Hold tight! the message is getting sent.",
    });
    //first add the message data to firestore
    // fs.collection("notification_data")
    //   .add({
    //     message,
    //     messageDate: new Date(),
    //     messageSentBy: localStorage.getItem("adminEmail"),
    //     sentNumber: this.fromNumber,
    //     manual: true,
    //   })
    //   .then(() => {
    //     console.log("Ready to send data");

    //send sms
    this.sendSms(message, fromNumber, numbers);
    // })
    // .catch((e) => {
    //   console.log(e);
    //   this.setState({
    //     isLoading: false,
    //     alert: (
    //       <SweetAlert
    //         warning
    //         title={`There was an error sending the message message. If the issue persists please contact tech support.`}
    //         onConfirm={this.hideAlert}
    //         showConfirm={false}
    //       />
    //     ),
    //   });
    //   //Hide the alert after some time.
    //   setTimeout(() => {
    //     this.setState({
    //       alert: "",
    //     });
    //   }, 2000);
    // });
  };

  parseNumbers = (numbers) => {
    var newNumber = numbers.replace(/\+1|[\sa-zA-Z-.()+]/g, "");
    var numberList = newNumber.split(",");
    var newNumberList = numberList.filter((number) => {
      if (number.match(/^\d{10}$/g)) {
        return number;
      } else if (number.match(/^1[0-9]{10}$/)) {
        return number.replace(/1/, "");
      }
    });
    this.setState({ numberList: newNumberList });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
      isLoading: false,
    });
  };

  sendSmsModal = () => {
    return (
      <Modal
        open={this.state.sendSmsModalOpen}
        onClose={() => this.setState({ sendSmsModalOpen: false })}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 400, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div
              style={{ justifyContent: "center", display: "flex" }}
            >{`Are you sure you want to send sms?`}</div>
            <div style={{ justifyContent: "space-between", display: "flex" }}></div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => this.setState({ sendSmsModalOpen: false })}>Cancel</Button>
              <Button
                color="danger"
                onClick={() => {
                  if (
                    this.state.message &&
                    this.state.message.length < 160 &&
                    this.state.numberList.length > 0
                  ) {
                    this.send(this.state.message, this.fromNumber, this.state.numberList);
                    this.setState({
                      userType: null,
                      message: "",
                      sendSmsModalOpen: false,
                    });
                  } else if (this.state.numberList.length <= 0) {
                    this.setState({
                      sendSmsModalOpen: false,
                      errorMessage: "You must add at least one number to send the message.",
                    });
                  } else {
                    this.setState({
                      sendSmsModalOpen: false,
                      errorMessage: "Sorry, couldn't send the message.",
                    });
                  }
                }}
              >
                Send
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  render() {
    return (
      <div className="content">
        {this.state.isLoading ? (
          <LoadingOverlay message={this.state.loadingMessage} />
        ) : (
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">Send SMS Manually</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="/" className="form-horizontal" method="get">
                    <Row>
                      <Label sm="4">*Numbers(comma separated):</Label>
                      <Col sm="6">
                        <FormGroup>
                          <Input
                            type="textarea"
                            name="numbers"
                            id="numbers"
                            value={this.state.numberText}
                            style={{ maxHeight: "200px" }}
                            rows="10"
                            onChange={(e) => {
                              const { name, value } = e.target;
                              this.setState({ numberText: value });
                              this.parseNumbers(value);
                            }}
                          />
                        </FormGroup>
                        <FormText tag="span">{this.state.numberList.length} Numbers</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="4">* Message</Label>
                      <Col sm="6">
                        <FormGroup>
                          <Input
                            type="textarea"
                            name="message"
                            id="message"
                            value={this.state.message}
                            style={{ maxHeight: "200px" }}
                            rows="10"
                            onChange={(e) => {
                              const { name, value } = e.target;

                              if (
                                this.state.charLeft > 0 ||
                                this.state.keyPressed === 8 || //if charleft<=0 only allowed to use backspace or delete
                                this.state.keyPressed === 46
                              ) {
                                this.setState((prevState) => {
                                  return {
                                    charLeft: this.allowedChar - value.length,
                                    errorMessage: "",
                                    message: value,
                                  };
                                });
                              }
                            }}
                          />
                        </FormGroup>
                        <FormText color={this.state.charLeft < 10 ? "danger" : "green"} tag="span">
                          {this.state.charLeft} characters remaining.
                        </FormText>
                        <FormText color="danger" tag="span">
                          {this.state.errorMessage}
                        </FormText>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="4"></Label>
                      <Col sm="3">
                        <Button className="btn-success" onClick={this.handleSubmit}>
                          Send
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {this.state.alert}
        {this.sendSmsModal()}
      </div>
    );
  }
}

export default CreateManualNotification;

import RecurringDays, { RECURRING_DAYS_ARRAY } from "components/RecurringDays";
import useFetchBusiness from "hooks/useFetchBusiness";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import api from "../../../components/API/api";
import ShiftFunction from "./ShiftFunction";
import { useModal } from "context/ModalContext";
import { startOfDay } from "utils/dateUtils";

const shiftTypeEnum = { once: "once", recurrent: "recurrent" };

function CreateShifts() {
  const [data, setData] = useState({
    _id: null,
    startTime: null,
    endTime: null,
    requiredPeople: "",
    shiftName: "",
    description: "",
    expires: null,
    qualifications: [],
    selectedBusiness: null,
    recurrent: false,
    shiftBoard: true,
    shiftFunctions: [],
    payRate: 0,
    emails: [],
  });

  const [shiftFunctions, setShiftFunctions] = useState([]);
  const [qualificationsList, setQualificationList] = useState([]);
  const [error, setError] = useState({
    descriptionError: "",
    shiftNameError: "",
    qualificationsError: "",
    selectedBusinessError: "",
    availabilityError: "",
    recurrentError: "",
    startTimeError: "",
    endTimeError: "",
    shiftFunctionError: "",
  });
  const [effectDate, setEffectDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [availability, setAvailability] = useState([...RECURRING_DAYS_ARRAY]);
  const [defaultAvailability, setDefaultAvailability] = useState([...RECURRING_DAYS_ARRAY]);

  const businesses = useFetchBusiness();
  const { addAlert } = useModal();


  const fetchShift = async () => {
    setIsLoading(true);
    //geting shift Id from URL
    let selectedUrl = window.location.pathname.split("/");
    let shiftInfo = selectedUrl[4];
    let selectedShiftId = shiftInfo.split("-")[1]

    const url = shiftInfo.split("-")[0] === "single" ? `/shift/${selectedShiftId}` : `/recurrent-shift/${selectedShiftId}`
    api()
      .get(url)
      .then(async (res) => {
        const qualificationsList = await fetchQualifications(); // fetch data
        let data = res.data.shift || res.data;
        let shiftBusiness = data.type === shiftTypeEnum.once ? data.business._id : data.business;

        let tempQualificationsArray = [];
        data.qualifications.map((q) => {
          const option = qualificationsList.find((l) => l.value === q);
          if (option) tempQualificationsArray.push(option);
        });

        setDefaultAvailability((prevAvailability) => {
          return prevAvailability.map((a) => {
            if (data.weekSchedule) {
              let daySchedule = data.weekSchedule.find((schedule) => schedule.day === a.day);
              if (daySchedule) {
                return {
                  active: true,
                  day: daySchedule.day,
                  start: daySchedule.start,
                  end: daySchedule.end,
                };
              }
              return a;
            }
          });
        });
        setData({
          ...data,
          _id: data._id,
          expires: moment(data.expires),
          requiredPeople: data.noRequiredWorkers,
          shiftName: data.shiftName,
          description: data.description,
          qualifications: tempQualificationsArray,
          selectedBusiness: businesses.find((b) => b._id === shiftBusiness),
          recurrent: data.type ? data.type === "once" ? false : true : data.weekSchedule?.length ? true : false,
          shiftBoard: !!data.shiftBoard,
          shiftFunctions: data.shiftFunctions,
          payRate: data.payRate,
          startTime: moment(data.start),
          endTime: moment(data.end)
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    businesses.length && fetchShift();
  }, [businesses]);

  const fetchQualifications = async () => {
    try {
      const { data } = await api().get("/qualification");
      let tempArray = [];
      data.qualifications.map((q) => {
        let qualification = {
          label: q.name,
          value: q._id,
        };
        tempArray.push(qualification);
      });

      setQualificationList(tempArray);
      return tempArray;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    let hasError = false;
    //Setting initial error in tempErrors
    let tempErrors = { ...error };

    if (data.recurrent) {
      availability.forEach((a) => {
        if (a.active) {
          if (!a.start || !a.end) {
            hasError = true;
            tempErrors = {
              ...tempErrors,
              recurrentError: "* Must have both start and end time for active recurring days",
            };
          }
        }
      });
      if (!tempErrors.recurrentError) {
        if (!availability.filter((a) => a.active).length) {
          tempErrors = {
            ...tempErrors,
            recurrentError: "* Must have at least one day active",
          };
        }
      }
    }
    if (data.shiftName == null || data.shiftName.trim() == "") {
      tempErrors = { ...tempErrors, shiftNameError: "* Must Enter Shift Name " };
      hasError = true;
    } else {
      tempErrors = { ...tempErrors, shiftNameError: "" };
    }

    if (data.description == null || data.description.trim() == "") {
      tempErrors = { ...tempErrors, descriptionError: "* Must Enter Description " };
      hasError = true;
    } else {
      tempErrors = { ...tempErrors, descriptionError: "" };
    }

    if (data.qualifications == null || data.qualifications == "") {
      tempErrors = { ...tempErrors, qualificationsError: "* Must Enter Qualification " };
      hasError = true;
    } else {
      tempErrors = { ...tempErrors, qualificationsError: "" };
    }

    if (data.selectedBusiness == null || data.selectedBusiness == "") {
      tempErrors = { ...tempErrors, selectedBusinessError: "* Must Enter Business Name " };
      hasError = true;
    } else {
      tempErrors = { ...tempErrors, selectedBusinessError: "" };
    }

    if (shiftFunctions.length) {
      for (const _sf of shiftFunctions) {
        if (!_sf.name) {
          tempErrors = { ...tempErrors, shiftFunctionError: "* Must select a Shift function." };
          hasError = true;
          break;
        }
      }
    }

    if (hasError) {
      setError({ ...error, ...tempErrors });
      return;
    } else {
      prepareAndSendDataToServer(data);
    }
  };

  const prepareAndSendDataToServer = async (data) => {
    setIsLoading(true);
    let dataToUpdate = null;
    let tempQualificationsArray = data.qualifications
      ? data.qualifications.map((q) => q.value)
      : [];
    let weekSchedule = null;
    if (data.recurrent) {
      weekSchedule = availability.filter((a) => {
        if (a.active) {
          a.startTime = moment(a.start).format();
          a.endTime = moment(a.end).format();
          if (moment(a.start).isAfter(a.end)) {
            a.endTime = moment(a.end).add(1, "day").format();
          }
          a.start = {
            hour: moment(a.start).hour(),
            minute: moment(a.start).minute(),
            second: 0,
          };
          a.end = {
            hour: moment(a.end).hour(),
            minute: moment(a.end).minute(),
            second: 0,
          };
          delete a.active;
          return a;
        }
      });
    }
    dataToUpdate = {
      noRequiredWorkers: parseInt(data.requiredPeople),
      shiftName: data.shiftName,
      description: data.description,
      qualifications: tempQualificationsArray,
      business: data.selectedBusiness.value,
      payBasis: "Hourly",
      shiftFunctions,
      shiftBoard: data.shiftBoard,
      effectDate: effectDate,
    };

    if(data.recurrent){
      dataToUpdate.weekSchedule = weekSchedule;
    };

    if (data.payRate) {
      dataToUpdate.payRate = parseFloat(data.payRate);
    }

    if (data.expires) {
      dataToUpdate.expires = data.expires.toISOString();
    }
    try {
      const url = data.recurrent ? `/recurrent-shift/${data._id}` : `/shift/${data._id}`;
      await api({
        headers: { client_timezone: moment.tz?.guess(), client_timestamp: moment().format() },
      }).patch(url, dataToUpdate);

      addAlert({ title: "Shift successfully updated", success: true });

      setIsLoading(false);
      fetchShift();
    } catch (error) {
      fetchShift();
      addAlert({ title: error.response?.data?.message || error.message, error: true });
      setIsLoading(false);
      console.log(error);
    }
  };

  const disablePreviousDates = (current) => {
    const today = new Date();
    return current.isAfter(today);
  };

  const loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        showConfirm={false}
      />
    );
  };

  return (
    <>
      <div className="content" style={{ marginTop: "50px", marginBottom: "100px" }}>
        <Row>
          <Col md="12">
            <Card className="no-transition">
              <CardHeader>
                <CardTitle tag="h4">Edit {data.recurrent ? "Recurrent": "Single"} Shift</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="/" className="form-horizontal" method="get">
                  {data.recurrent && (
                    <Row>
                      <Label sm="2">Recurring Days</Label>
                      <Col sm="10">
                        <RecurringDays
                          availability={defaultAvailability}
                          onChange={(_availability) => {
                            setAvailability(_availability);
                          }}
                        />
                        <FormText color="danger" tag="span">
                          {error.recurrentError}
                        </FormText>
                      </Col>
                    </Row>
                  )}
                  {data.recurrent ? null : (
                    <Row style={{ paddingTop: "20px" }}>
                      <Label sm="2">* Start Time</Label>
                      <Col md="6">
                        <ReactDatetime
                          isValidDate={disablePreviousDates}
                          value={data.startTime}
                          onChange={(date) => {
                            setData({ ...data, startTime: date });
                            setError({ ...error, startTimeError: "" });
                          }}
                        />
                        <FormText color="danger" tag="span">
                          {error.startTimeError}
                        </FormText>
                      </Col>
                    </Row>
                  )}
                  {data.recurrent ? null : (
                    <Row style={{ paddingTop: "20px" }}>
                      <Label sm="2">* End Time </Label>
                      <Col md="6">
                        <ReactDatetime
                          isValidDate={disablePreviousDates}
                          value={data.endTime}
                          onChange={(date) => {
                            setData({ ...data, endTime: date });
                            setError({ ...error, endTimeError: "" });
                          }}
                        />
                        <FormText color="secondary" tag="span">
                          {data.recurrent &&
                            `(For Recurrent shifts end time is date till you want the shift to run. if it is going to run forever
                        please set it to farther date.)`}
                        </FormText>
                        <FormText color="danger" tag="span">
                          {error.endTimeError}
                        </FormText>
                      </Col>
                    </Row>
                  )}

                  {!data.recurrent && (
                    <Row style={{ paddingTop: "20px" }}>
                      <Label sm="2">Required Number of Workers (Optional)</Label>
                      <Col sm="6">
                        <FormGroup>
                          <Input
                            type="number"
                            min="1"
                            value={data.requiredPeople}
                            onChange={(e) => {
                              setData({ ...data, requiredPeople: e.target.value });
                              setError({ ...error, requiredPeopleError: "" });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row style={{ paddingTop: "20px" }}>
                    <Label sm="2">* Name</Label>
                    <Col sm="6">
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder="Please provide shift name."
                          value={data.shiftName}
                          onChange={(e) => {
                            setData({
                              ...data,
                              shiftName: e.target.value,
                            });
                            setError({ ...error, shiftNameError: "" });
                          }}
                        />
                        <FormText color="danger" tag="span">
                          {error.shiftNameError}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "20px" }}>
                    <Label sm="2">* Description</Label>
                    <Col sm="6">
                      <FormGroup>
                        <Input
                          type="textarea"
                          style={{ maxHeight: "700px" }}
                          rows="10"
                          value={data.description}
                          onChange={(e) => {
                            setData({
                              ...data,
                              description: e.target.value,
                            });
                            setError({ ...error, descriptionError: "" });
                          }}
                        />
                        <FormText color="danger" tag="span">
                          {error.descriptionError}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4px" }}>
                    <Label sm="2">* Qualifications</Label>
                    <Col sm="6">
                      <FormGroup>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="qualificationsSelect"
                          isMulti
                          value={data.qualifications}
                          onChange={(value) => {
                            setData({ ...data, qualifications: value });
                            setError({ ...error, qualificationsError: "" });
                          }}
                          options={qualificationsList}
                          placeholder="Select Qualifications"
                        />
                        <FormText color="danger" tag="span">
                          {error.qualificationsError}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4px" }}>
                    <Label sm="2">* Business Name</Label>
                    <Col sm="6">
                      <FormGroup>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="businesses"
                          value={data.selectedBusiness}
                          onChange={(value) => {
                            setData({ ...data, selectedBusiness: value });
                            setError({ ...error, selectedBusinessError: "" });
                          }}
                          options={businesses || []}
                          placeholder="Select Business"
                        />
                        <FormText color="danger" tag="span">
                          {error.selectedBusinessError}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4px" }}>
                    <Label sm="2">Pay/Hour (Optional)</Label>
                    <Col sm="6">
                      <FormGroup>
                        <Input
                          type="number"
                          min="15"
                          value={data.payRate}
                          onChange={(e) => {
                            setData({
                              ...data,
                              payRate: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {data.recurrent && (
                    <Row style={{ paddingTop: "20px" }}>
                      <Label sm="2">Expiry Date (Optional) </Label>
                      <Col md="6">
                        <ReactDatetime
                          isValidDate={disablePreviousDates}
                          value={data.expires}
                          timeFormat={false}
                          onChange={(date) => {
                            setData({ ...data, expires: date });
                          }}
                        />
                      </Col>
                    </Row>
                  )}

                  <Row style={{ paddingTop: "20px" }}>
                    <Label sm="2">Shift Functions</Label>
                    <Col md="6">
                      <ShiftFunction
                        defaultValue={data.shiftFunctions}
                        onChange={(shiftFunctions) =>
                          setShiftFunctions(shiftFunctions)
                        }
                      />
                      <FormText color="danger" tag="span">
                        {error.shiftFunctionError}
                      </FormText>
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: "20px" }}>
                    <Label sm="2">Show in Shift Board</Label>
                    <Col md="6" className="checkbox-radios">
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={() => setData({ ...data, shiftBoard: !data.shiftBoard })}
                            checked={data.shiftBoard}
                            id={"shiftBoard"}
                          />
                          <span className="form-check-sign" />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: "20px" }}>
                    <Label sm="6">Changes takes Effect From:</Label>
                    <Col md="6" className="checkbox-radios">
                      <ReactDatetime
                        timeFormat={false}
                        defaultValue={startOfDay()}
                        value={effectDate}
                        onChange={(date) => {
                          console.log(date);
                          setEffectDate(date.toDate());
                        }}
                        isValidDate={(current) =>
                          current.isAfter(moment().subtract(1, "day"), "day") &&
                          current.isBefore(moment().add(30, "days"), "day")
                        }
                      /> 
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "4px" }}>
                    <Col sm="9"></Col>
                    <Col sm="3">
                      <Button
                        color="success"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Edit Shift
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {isLoading && loadingOverlay()}
      </div>
    </>
  );
}

export default CreateShifts;

import React, { useState, useRef } from "react";
import ReactDatetime from "react-datetime";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormText,
  CardHeader,
  CardTitle,
} from "reactstrap";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import moment from "moment-timezone";
import api from "components/API/api";
import { getDay, startOfDay, startOfNextDay } from "utils/dateUtils.js";
import { CSVLink } from "react-csv";

function ExportRides() {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  });

  const [workersData, setWorkersData] = useState([]);
  const workerHeaders = [
    {
      label: "HDID",
      key: "hdId",
    },
    {
      label: "Full Name",
      key: "name",
    },
    {
      label: "No of Selected",
      key: "selected",
    },
    {
      label: "Total Rides",
      key: "rides",
    },
  ];

  const csvRef = useRef(null);

  const handleDownload = () => {
    let params = {
      from: startOfDay(filter.fromDate),
      till: startOfNextDay(filter.toDate),
    };
    api()
      .get("/driver-pickup/all-assigned-employees", { params })
      .then((res) => {
        // console.log(res);
        let data = res.data.employees;
        // console.log(data);
        let tempData = [];
        data.map((user) => {
          if (user) {
            const employeeList = tempData.filter((e) => e._id === user?.assigned?.employee);
            // console.log(employeeList)
            if (employeeList.length === 0) {
              tempData.push({
                _id: user?.assigned?.employee,
                hdId: user?.assigned?.employeeHdId,
                name: user?.assigned?.employeeName,
                rides:
                  user?.assigned?.firstPickup && user?.assigned?.secondPickup
                    ? 2
                    : user?.assigned?.firstPickup || user?.assigned?.secondPickup
                    ? 1
                    : 0,
                selected: user?.assigned?.autoAssigned ? 0 : 1,
              });
              // console.log(tempData)
            } else {
              // console.log(tempData)
              tempData.map((u) => {
                if (u._id === user?.assigned?.employee) {
                  const rides =
                    user?.assigned?.firstPickup && user?.assigned?.secondPickup
                      ? 2
                      : user?.assigned?.firstPickup || user?.assigned?.secondPickup
                      ? 1
                      : 0;
                  // console.log(rides);
                  u.rides = u.rides + rides;
                  u.selected = user?.assigned?.autoAssigned ? u.selected : u.selected + 1;
                  // console.log(u);
                }
              });
            }
          }
        });
        // console.log(tempData);
        setWorkersData(tempData);
        // console.log(csvRef.current);
        csvRef.current.link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="content">
      {isLoading && <LoadingOverlay />}
      <Row>
        <Col md="12">
          <Card className="no-transition">
            <CardHeader>
              <Row>
                <Col md="6">
                  <CardTitle tag="h4">Export All Rides</CardTitle>
                  <CardBody>
                    Export all workers who has been taken rides at least once within the following
                    date range. This page only exports workers who checked in on the driver's panel,
                    it will not show anyone who did not check in.
                  </CardBody>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                md="12"
              >
                <Col md="4">
                  <FormGroup>
                    <Label>From</Label>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Select From date",
                      }}
                      timeFormat={false}
                      value={filter.fromDate}
                      onChange={(date) => {
                        setFilter({ ...filter, fromDate: moment(date) });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>To</Label>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Select To date",
                      }}
                      timeFormat={false}
                      value={filter.toDate}
                      onChange={(date) => {
                        setFilter({ ...filter, toDate: moment(date) });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="2" style={{ marginTop: 8 }}>
                  <CSVLink
                    data={workersData}
                    headers={workerHeaders}
                    ref={csvRef}
                    filename={"Rides_Data.csv"}
                  ></CSVLink>
                  <Button color="default" onClick={() => handleDownload()}>
                    Download
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ExportRides;

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useState } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect, withRouter, useParams } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import NotAllowed from "views/pages/NotAllowed.jsx";

import routes from "./routes.js";
import AgencyContext from "../../context/AgencyContext";

import { checkAccessSideBar } from "../../utils/checkAccess";
import api from "components/API/api.js";
import { Modal } from "@mui/material";
import { FormGroup } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Label from "reactstrap/lib/Label";
import Col from "reactstrap/lib/Col";
import Button from "reactstrap/lib/Button";
import { useEffect } from "react";
import { useRef } from "react";
import moment from "moment";
// import withMotionDetection from "hoc/withMotionDetection.js";
var ps;

function AutoLogout({ logout }) {
  const [expireModal, setExpireModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const extendTime = async () => {
    //
    try {
      await api().get("/users/login/validate-token");
      setExpireModal(false);
    } catch (e) {
      logout();
    }
  };
  useEffect(() => {
    /****set timer to logout */

    const interval = setInterval(() => {
      const expiresAt = JSON.parse(localStorage.getItem("_expire"));
      const _timeLeft = -moment().diff(expiresAt, "second") - 10;
      setTimeLeft(() => {
        return _timeLeft;
      });
      setExpireModal((expireModal) => {
        if (_timeLeft < 300 && !expireModal) {
          return true;
        }
        if (_timeLeft > 300 && expireModal) {
          return false;
        }
        return expireModal;
      });

      if (_timeLeft <= 1) {
        logout();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const closeExpireModal = () => {
    setExpireModal(false);
  };

  return (
    <Modal
      open={expireModal}
      onClose={closeExpireModal}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
        <div style={{ padding: 15 }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>
              Your are inactive for more than 10 minutes. The website will logout in{" "}
              {Math.floor(timeLeft / 60)} minute(s) and {Math.floor(timeLeft % 60)} seconds
            </h5>
          </div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <Button color="secondary" style={{ marginRight: "12px" }} onClick={extendTime}>
              Extend time
            </Button>{" "}
            <Button
              color="primary"
              onClick={() => {
                logout();
              }}
            >
              Logout
            </Button>
          </div>
        </div>
      </FormGroup>
    </Modal>
  );
}

function Admin(props) {
  // const agencyContext = useContext(AgencyContext);
  // let { slug } = useParams();
  const { agency, agencyDispatch } = useContext(AgencyContext);
  const [backgroundColor, setBackgroundColor] = useState("black");
  const [activeColor, setActiveColor] = useState("info");
  const [sidebarMini, setSideBarMini] = useState(false);
  const [adminName, setAdminName] = useState(null);
  const [adminPicture, setAdminPicture] = useState(null);
  const [adminId, setAdminId] = useState(null);
  const [adminEmail, setAdminEmail] = useState(null);
  const [role, setRole] = useState("");

  // const timeLeftRef = useRef(0);
  const mainPanel = useRef(null);
  let { slug } = useParams();

  const processLoginData = ({ user, token }) => {
    let profile = user.profile.find(
      (p) =>
        (p.role === "Admin" ||
          p.role === "Owner" ||
          p.role === "AgencyAdmin" ||
          p.role === "Supervisor" ||
          p.role === "Recruiter" ||
          p.role === "CallCentreStaff" ||
          p.role == "Staff") &&
        (p.agency.slug === slug || p.agency._id === agency._id),
    );
    if (profile && profile.agency && profile.agency._id) {
      let agencyId = profile.agency._id;
      localStorage.setItem("Token", token);
      localStorage.setItem("AgencyId", agencyId);
      agencyDispatch({ type: "SET_USER", payload: { user: user } });
      agencyDispatch({
        type: "SET_PROFILE",
        payload: { profile: profile },
      });
    } else {
      // noSuccessAlert("Invalid email or password, please try again.");
      //localStorage.Token = null;
      // agencyDispatch({ type: "REMOVE_USER" });
      // props.history.push(`${slug}/auth/login`);
    }
  };

  useEffect(() => {
    if (Boolean(localStorage.getItem("Token"))) {
      api().get("/users/login/validate-token").then(({ data }) => {
        // console.log(data);
        const token = localStorage.getItem("Token");
        processLoginData({
          user: data,
          token,
        });
      }).catch((e) => {
        console.error(e);
        //localStorage.Token = null;
        agencyDispatch({ type: "REMOVE_USER" });
        props.history.push(`${slug}/auth/login`);
      });
    }
    // const slug = props.match.params.slug.toLowerCase();
    // this.setState({ slug });
  }, []);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel.current);
    }

    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, []);

  const access = (arr1, role) => {
    let access = arr1.find((r) => {
      if (r == role) return true;
    });
    return access;
  };

  useEffect(() => {
    if (props.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainPanel.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("Token");
    if (agency.user && token && agency.profile) {
      //Continue
      // updateUnreadMessagesOnContext();
      const user = agency.user;
      const profile = agency.profile;
      setRole(profile.role);
    }
  }, []);

  const logout = () => {
    // api()
    //   .post("/users/logout")
    //   .then(() => {
    //     console.log("logged out from server");
    //     return true;
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
    localStorage.clear();
    agencyDispatch({ type: "REMOVE_USER" });
    props.history.push(`/${props.match.params.slug}/auth/login`);
  };

  const getRoutes = (routes) => {
    //If the user is not logged in i.e. localstorage has no "Token" then the page will redirect to Login page
    if (!Boolean(localStorage.getItem("Token"))) {
      return <Redirect to={`/${props.match.params.slug}/auth/login`} />;
    }

    return routes.map((route, key) => {
      if (route.collapse) {
        return getRoutes(route.views);
      }
      if (
        route.layout === "/admin" &&
        checkAccessSideBar(
          route.module,
          route.action,
          route.access,
          agency && agency.profile && agency.profile.permissions,
          agency && agency.profile && agency.profile.role,
        )
      ) {
        return (
          <Route
            path={"/" + props.match.params.slug + route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      } else {
        return (
          <Route
            path={"/" + props.match.params.slug + route.layout + route.path}
            component={NotAllowed}
            key={key}
          />
        );
      }
    });
  };
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleBgClick = (color) => {
    setBackgroundColor(color);
  };
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSideBarMini(false);
    } else {
      setSideBarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
        role={role}
        onLogout={() => {
          logout();
        }}
      />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar {...props} handleMiniClick={handleMiniClick} />
        <Switch>{getRoutes(routes)}</Switch>
        {
          // we don't want the Footer to be rendered on full screen maps page
          props.location.pathname.indexOf("full-screen-map") !== -1 ? null : <Footer fluid />
        }
      </div>
      {/* <FixedPlugin
          bgColor={backgroundColor}
          activeColor={activeColor}
          sidebarMini={sidebarMini}
          handleActiveClick={handleActiveClick}
          handleBgClick={handleBgClick}
          handleMiniClick={handleMiniClick}
        /> */}
      <AutoLogout logout={logout} />
    </div>
  );
}

export default Admin;
// export default withMotionDetection(Admin);

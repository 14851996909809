import { Modal } from "@mui/material";
import { Clear, PersonAdd, Visibility } from "@mui/icons-material";
import api from "components/API/api";
import BusinessTab from "components/BusinessTab";
import CustomCalendar from "components/Calendar/CustomCalendar";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import AgencyContext from "context/AgencyContext";
import usePermissions, { useHasPermissions } from "hooks/usePermissions";
import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";
import {
  afternoonTimeRange,
  morningTimeRange,
  nightTimeRange,
  shiftTimeType,
  startOfDay,
  startOfNextDay,
} from "utils/dateUtils";
// import matchSorter from 'match-sorter';
import { UnScheduleReasonList } from "utils/statusVariables";
import { shiftFunctionList } from "../AllShifts/ShiftFunction";
import CheckInTimeCell from "./CheckInTimeCell";

// import RemoveUserModal from "../AllUsers/AllUsers/RemoveUserModal.jsx";

const selectedForWorkStatus = {
  Applied: 0 /****when user applies to a shift */,
  selected: 1 /***when user is selected for shift */,
  Backup: 2, //when backup is created
  Rejected: 3, //when rejected
  UnScheduled: 4, //when unscheduled by admins
  Dropped: 5, //when shift dropped by user
};

export const unScheduleReasonText = [
  "Employee bailed",
  "Employee didn't pickup call",
  "Employee can't go for other reasons.",
  "Employee was scheduled by Mistake.",
  "Shift Cancelled due to less work",
  "Shift was Cancelled by plant",
  "Employee was sent back",
];

const LiveSelectedUsers = (props) => {
  const agencyContext = useContext(AgencyContext);
  let { isAdmin } = usePermissions();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [pages, setPages] = useState(0);
  const [timer, setTimer] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [sorted, setSorted] = useState([]);
  const [isFirstMount, setIsFirstMount] = useState(true);
  const [filter, setFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
    query: "",
    businessId: null,
    date: moment().add(1, "days"),
    fromTime: null,
    toTime: null,
    shiftFunction: null,
  });

  const canRemoveSelectForWork = useHasPermissions({
    module: "SelectForWork",
    action: "Delete",
  });

  const canAddSelectForWork = useHasPermissions({
    module: "SelectForWork",
    action: "Add",
  });

  const [isAdvancedView, setIsAdvancedView] = useState(false);

  const [selectedForWorkData, setSelectedForWorkData] = useState([]);
  const [selectedShiftFilter, setSelectedShiftFilter] = useState("All");
  const [workStatus, setWorkStatus] = useState("All"); /**All, Confirmed, Backup */

  const [selectedHdId, setSelectedHdId] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);
  const [openModal, setOpenModal] = useState({
    userModal: false,
    removeModal: false,
  });

  // const [shiftLoading, setShiftLoading] = useState(false);
  const [loadingShiftUser, setLoadingShiftUser] = useState(false);
  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 10,
    field: "hdId",
    sort: "desc",
    filter: [],
  });
  const [addUserToNewBusiness, setAddUserToNewBusiness] = useState(false);
  const [shiftsList, setShiftsList] = useState([]);

  /****this saves user's selected for work data for a day ******/
  const [userSelectedForWorkForADay, setUserSelectedForWorkForADay] = useState(null);

  useEffect(() => {
    let timeRange;
    switch (selectedShiftFilter) {
      case "Morning":
        timeRange = morningTimeRange(filter.startDate);
        break;
      case "Afternoon":
        timeRange = afternoonTimeRange(filter.startDate);
        break;
      case "Night":
        timeRange = nightTimeRange(filter.startDate);
        break;
      default:
        timeRange = {
          start: startOfDay(filter.startDate),
          end: startOfNextDay(filter.startDate),
        };
    }
    setFilter({
      ...filter,
      fromTime: timeRange.start && moment(timeRange.start).format(),
      toTime: timeRange.end && moment(timeRange.end).format(),
    });
  }, [selectedShiftFilter, filter.startDate]);

  useEffect(() => {
    if (!isFirstMount) {
      fetchData();
    }
  }, [
    filter.startDate,
    filter.endDate,
    filter.businessId,
    filter.fromTime,
    filter.toTime,
    filter.shiftFunction,
    workStatus,
  ]);

  const [formErrors, setFormErrors] = useState({});
  const fetchData = async (filterOptions = reactTableState) => {
    setLoadingTable(true);
    isFirstMount && setIsFirstMount(false);

    const statuses = [];
    if (workStatus === "All" || workStatus === "Backup") {
      statuses.push(selectedForWorkStatus.Backup);
    }
    if (workStatus === "All" || workStatus === "Selected") {
      statuses.push(selectedForWorkStatus.selected);
    }
    try {
      const businessId = filter.businessId || null;
      const { data } = await api().get("/selected-for-work", {
        params: {
          ...filterOptions,
          slug: agencyContext.agency.agency.slug,
          from: filter.fromTime || startOfDay(filter.startDate),
          till:
            filter.toTime ||
            (isAdvancedView && filter.endDate
              ? startOfNextDay(filter.endDate)
              : startOfNextDay(filter.startDate)),
          businessId,
          shiftFunction: filter.shiftFunction,
          status: statuses,
        },
      });
      console.log(data);
      setSelectedForWorkData(transformRawData(data.selectedForWorks));
      setPages(data.noPages);
      setLoadingTable(false);
    } catch (error) {
      console.log("error", error);
      setLoadingTable(false);
    }
  };

  const transformRawData = (shiftRequest) => {
    return shiftRequest.map((d) => {
      const clockIn = d.check?.in;
      const clockOut = d.check?.out;
      return {
        _id: d._id,
        userId: d.user._id,
        hdId: d.user.hdId,
        firstName: d.user.firstName,
        lastName: d.user.lastName,
        verified: d.user.documentStatus?.status,
        duplicated: d.user.duplicated,
        address: d.user.address,
        phone: d.user.phone,
        city: d.user.city,
        workerPin: d.user.workerPin,
        business: d.business,
        businessName: d.businessName,
        shift: d.shift._id,
        shiftName: d.shift.shiftName,
        status: d.status,
        lastAppActive: d.user.lastAppActive && moment(d.user.lastAppActive).calendar(),
        start: isAdvancedView
          ? moment(d.start).format("L hh:mm a")
          : moment(d.start).format("hh:mm a"),
        end: isAdvancedView ? moment(d.end).format("L hh:mm a") : moment(d.end).format("hh:mm a"),
        lastAppLoginTime: d.user.lastAppLogin
          ? moment(d.user.lastAppLogin).format("MMM Do YYYY, h:mm a")
          : null,
        actions: renderActionButtons(d),
        rowColor:
          d.status === selectedForWorkStatus.Backup
            ? "#51CBCE"
            : d.user?.sinExpiry
            ? moment(d?.user.sinExpiry).isBefore(moment().add(30, "days"), "days")
              ? "#6A0DA0"
              : moment(d?.user.sinExpiry).isBefore(moment().add(60, "days"), "days")
              ? "#000080"
              : null
            : null,
        sinExpiry: d.user.sinExpiry,
        shiftFunction:
          d.shiftFunction?.name || d.shiftFunction?.name === 0 ? d.shiftFunction?.name : "-",

        clockIn: clockIn,
        clockOut: clockOut,
        startTime: d.start,
        endTime: d.end,
        selectedToWorkByName: d.selectedToWorkByName,
      };
    });
  };

  const openUserProfile = (userId) => {
    props.history.push(`/${agencyContext.agency.agency.slug}/admin/user-profile/${userId}`);
  };

  const handleOpenAddUserModal = (hdId) => {
    setSelectedHdId(hdId);
    setSelectedShift(null);
    setOpenModal({ userModal: true });
    setFormErrors({});
  };

  const handleOpenRemoveModal = (hdId) => {
    setSelectedHdId(hdId);
    setSelectedShift(null);
    setOpenModal({ removeModal: true });
    setFormErrors({});
  };

  const handleCloseAddUserModal = () => {
    clearShiftAndUserForADay();
    setSelectedShift(null);
    setAddUserToNewBusiness(false);
    setOpenModal({});
    setFormErrors({});
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const addUserToSelected = async (status, userSelectedForWorkForADay, shiftFunction) => {
    setIsLoading(true);
    const selectedForWorkId = selectedForWorkData.find((u) => u.hdId === selectedHdId)._id;
    const userId = selectedForWorkData.find((u) => u.hdId === selectedHdId).userId;

    const shift = selectedShift;
    const selectedShiftData = shiftsList.find(
      (s) => selectedShift && s._id === selectedShift.value,
    );
    if (!shift) {
      setFormErrors({ ...formErrors, selectedData: "You must select a shift" });
      return;
    }
    if (!userId) return;
    if (selectedShiftData.shiftFunctions?.length && !shiftFunction) {
      setFormErrors({ ...formErrors, selectedData: "You must select a shift function/line!" });
      setIsLoading(false);
      return;
    }
    const selectedShiftFunction = selectedShiftData.shiftFunctions?.length
      ? selectedShiftData.shiftFunctions.find(
          (_shiftFunction) => _shiftFunction._id === shiftFunction.value,
        )
      : undefined;

    /****If user was already selected for that day  then update previous selected otherwise create new*/
    if (userSelectedForWorkForADay) {
      api()
        .patch(`/selected-for-work/${userSelectedForWorkForADay._id}`, {
          business: selectedShiftData.business,
          businessName: selectedShiftData.businessName,
          status: status,
          shift: selectedShiftData._id,
          start: selectedShiftData.start, //start and end of shift
          end: selectedShiftData.end,
          shiftFunction: selectedShiftFunction,
          userId,
        })
        .then(({ data }) => {
          let resp = data;
          setAlert(
            <SweetAlert
              success
              title="User successfully updated"
              onConfirm={hideAlert}
              showConfirm={true}
            />,
          );
          setIsLoading(false);
          handleCloseAddUserModal();
          fetchData();
        })
        .catch((error) => {
          setAlert(
            <SweetAlert
              error
              title={error.response?.data?.message || error.message}
              onConfirm={hideAlert}
              showConfirm={true}
            />,
          );
          setIsLoading(false);
          handleCloseAddUserModal();
        });
    } else {
      api({
        headers: { client_timezone: moment.tz?.guess(), client_timestamp: moment().format() },
      })
        .post("/selected-for-work", {
          user: userId,
          business: selectedShiftData.business,
          businessName: selectedShiftData.businessName,
          shiftName: selectedShiftData.shiftName,
          shift: selectedShiftData._id,
          start: selectedShiftData.start, //start and end of shift
          end: selectedShiftData.end,
          dayStart: moment(startOfDay(filter.date)).format(), //start and end of current day
          dayEnd: moment(startOfNextDay(filter.date)).format(),
          status: status,
          shiftFunction: selectedShiftFunction,
        })
        .then(({ data }) => {
          let resp = data;
          setAlert(
            <SweetAlert
              success
              title="User successfully updated"
              onConfirm={hideAlert}
              showConfirm={true}
            />,
          );
          setIsLoading(false);
          handleCloseAddUserModal();
          fetchData();
        })
        .catch((error) => {
          setAlert(
            <SweetAlert
              error
              title={error.response?.data?.message || error.message}
              onConfirm={hideAlert}
              showConfirm={true}
            />,
          );
          setIsLoading(false);
          handleCloseAddUserModal();
        });
    }
  };

  const AddUserModal = () => {
    const selectedUser = selectedForWorkData.find((u) => u.hdId === selectedHdId);
    const selectedShiftData = shiftsList.find(
      (s) => selectedShift && s._id === selectedShift.value,
    );
    const sinStartsWith9 = selectedUser?.sin?.startsWith("9");
    const sinExpired =
      sinStartsWith9 && selectedUser?.sinExpiry
        ? moment(selectedUser?.sinExpiry).isBefore(moment(), "days")
        : false;
    const [myAdminPassword, setMyAdminPassword] = useState("");
    const [adminPasswordError, setAdminPasswordError] = useState("");
    const [selectedShiftFunction, setSelectedShiftFunction] = useState(null);
    const reactSelectStyles = {
      option: (styles, { data, isFocused, isSelected }) => {
        if (data.shift && data.shift.closed) {
          return {
            ...styles,
            color: "#c5093d",
            backgroundColor: isFocused ? "#eee" : null,
          };
        }
        if (selectedUser?.workerPin?.length) {
          if (!selectedUser.workerPin.some((wPin) => wPin.business === data.business)) {
            //if the user hasn't been selected for work in another business then make the color red
            return {
              ...styles,
              color: "#f5593d",
              backgroundColor: isFocused ? "#eee" : null,
            };
          }
        }

        return {
          ...styles,
          cursor: "pointer",
          color: isSelected ? "#51cbce" : null,
          backgroundColor: isFocused ? "#eee" : null,
        };
      },
    };

    console.log(selectedShift);
    return (
      <Modal
        open={openModal.userModal}
        onClose={handleCloseAddUserModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {selectedUser ? (
          !sinExpired ? (
            <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
              <div style={{ padding: 25 }}>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <h5>
                    Add {selectedUser.firstName} {selectedUser.lastName} to Live Selected Workers?
                  </h5>
                </div>
                <div style={{ margin: "15px 0" }}>
                  <CustomCalendar
                    inputProps={{
                      placeholder: "Date Picker Here",
                    }}
                    initialDate={filter.date}
                    onChangeDate={(date) => {
                      setFilter({ ...filter, date: moment(date) });
                      setAddUserToNewBusiness(false);
                      setSelectedShift(null);
                      // fetchShifts(moment(date));
                      fetchShiftAndUserDataForADay(moment(date), selectedUser._id);
                    }}
                    disablePreviousDate={true}
                  />
                </div>
                {userSelectedForWorkForADay &&
                  (() => {
                    //
                    switch (userSelectedForWorkForADay.status) {
                      case selectedForWorkStatus.selected:
                        return `Employee has already been selected for a shift from ${moment(
                          userSelectedForWorkForADay.start,
                        ).format("hh:mm a")}-${moment(userSelectedForWorkForADay.end).format(
                          "hh:mm a",
                        )} at ${
                          userSelectedForWorkForADay.businessName
                        }. If you schedule the worker for another shift previously selected shift will be overwritten.`;
                      // case selectedForWorkStatus.Applied:
                      //   return "Employee has been already applied for a shift. Please change the status of employee.";
                      case selectedForWorkStatus.Backup:
                        return `Employee has already been selected as backup for the shift from ${moment(
                          userSelectedForWorkForADay.start,
                        ).format("hh:mm a")}-${moment(userSelectedForWorkForADay.end).format(
                          "hh:mm a",
                        )} at ${
                          userSelectedForWorkForADay.businessName
                        }. If you schedule the worker for another shift previously selected shift will be overwritten.`;
                      // case selectedForWorkStatus.Dropped:
                      //   return "Employee has already dropped the shift.";
                      // case selectedForWorkStatus.Rejected:
                      //   return "Employee has been already been rejected for the shift.";
                      case selectedForWorkStatus.UnScheduled:
                        return `Employee was unscheduled from a shift on this day.
                              ${
                                userSelectedForWorkForADay.unscheduleReason ||
                                userSelectedForWorkForADay.unscheduleReason === 0
                                  ? `The unschedule reason was "${
                                      unScheduleReasonText[
                                        userSelectedForWorkForADay.unscheduleReason
                                      ]
                                    }"`
                                  : ""
                              }`;
                    }
                  })()}
                {moment(filter.date).add(1, "days").isAfter(new Date()) ? (
                  loadingShiftUser ? (
                    <div className="d-flex justify-content-center">
                      <Spinner />
                    </div>
                  ) : (
                    <Select
                      classNamePrefix="react-select"
                      name="selectShift"
                      value={selectedShift}
                      onChange={(value) => {
                        setSelectedShift(value);
                        if (
                          selectedUser.workerPin &&
                          selectedUser.workerPin.length > 0 &&
                          !selectedUser.workerPin.some((wPin) => wPin.business === value.business)
                        ) {
                          setAddUserToNewBusiness(true);
                        } else {
                          setAddUserToNewBusiness(false);
                        }
                      }}
                      styles={reactSelectStyles}
                      options={shiftsList.map((b) => {
                        let timeOfDay = shiftTimeType(b.start);

                        return {
                          label:
                            b.businessName +
                            " : " +
                            timeOfDay +
                            ` @${moment(b.start).format("hh:mm a")}-${moment(b.end).format(
                              "hh:mm a",
                            )}`,
                          value: b._id,
                          business: b.business,
                          shift: b,
                        };
                      })}
                      placeholder="Select shift"
                      style={{ margin: "15px 0" }}
                    />
                  )
                ) : (
                  "You cannot add workers to past shifts."
                )}

                {selectedShiftData && (
                  <div style={{ marginTop: "8px" }}>
                    <h5>Shift</h5>
                    <p>
                      <b>Business:</b>
                      {selectedShiftData.businessName}
                    </p>
                    <p>
                      <b>Start:</b>
                      {moment(selectedShiftData.start).format("LLLL")}
                    </p>
                    <p>
                      <b>End:</b>
                      {moment(selectedShiftData.end).format("LLLL")}
                    </p>
                    {selectedShiftData.shiftFunctions?.length ? (
                      <Select
                        classNamePrefix="react-select"
                        name="selectShiftFunction"
                        value={selectedShiftFunction}
                        onChange={(value) => {
                          //
                          setSelectedShiftFunction(value);
                        }}
                        options={selectedShiftData.shiftFunctions?.map((sf) => {
                          return {
                            label: sf.name,
                            value: sf._id,
                            shiftFunction: sf,
                          };
                        })}
                        placeholder="Select shift Function"
                        style={{ margin: "15px 0" }}
                      />
                    ) : null}
                  </div>
                )}
                {selectedShift && selectedShift.shift && selectedShift.shift.closed ? (
                  <>
                    <Label style={{ color: "black" }}>
                      {selectedShift &&
                      selectedShift.shift &&
                      selectedShift.shift.closed &&
                      addUserToNewBusiness
                        ? "*(The selected shift is closed. and the user already works at another location.)"
                        : selectedShift && selectedShift.shift && selectedShift.shift.closed
                        ? "*(The selected shift is already closed.)"
                        : addUserToNewBusiness
                        ? "*(The user already works at another location)"
                        : ""}
                    </Label>
                    {addUserToNewBusiness && (
                      <div>
                        <p style={{ color: "red" }}>
                          This employee already works at another location.
                        </p>
                      </div>
                    )}
                    <Label style={{ color: "red" }}>{adminPasswordError}</Label>
                  </>
                ) : null}

                <div style={{ justifyContent: "space-between", display: "flex" }}>
                  <Col>
                    <FormText color="danger" tag="span">
                      {formErrors.selectedData}
                    </FormText>
                  </Col>
                </div>
                <div style={{ textAlign: "center" }}>
                  <FormText color="danger" tag="span">
                    {formErrors.errorSelectedDate}
                  </FormText>
                </div>

                <div style={{ justifyContent: "space-between", display: "flex" }}>
                  <Button onClick={() => handleCloseAddUserModal()}>Cancel</Button>

                  <Button
                    color="info"
                    onClick={() => {
                      addUserToSelected(
                        selectedForWorkStatus.Backup,
                        userSelectedForWorkForADay,
                        selectedShiftFunction,
                      );
                    }}
                  >
                    Backup
                  </Button>

                  <Button
                    color="success"
                    onClick={() => {
                      addUserToSelected(
                        selectedForWorkStatus.selected,
                        userSelectedForWorkForADay,
                        selectedShiftFunction,
                      );
                    }}
                  >
                    Add User
                  </Button>
                </div>
              </div>
            </FormGroup>
          ) : (
            <FormGroup style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}>
              <div style={{ padding: 25 }}>
                The Selected user's sin number has already been expired. You cannot add the worker
                to shift. Please update the sin number through the worker's profile.
              </div>
              <div style={{ textAlign: "center" }}>
                <Button color="success" onClick={handleCloseAddUserModal}>
                  OK
                </Button>
              </div>
            </FormGroup>
          )
        ) : (
          <div>AddUserModal</div>
        )}
      </Modal>
    );
  };

  const changeStatus = async (selectedUser) => {
    try {
      setIsLoading(true);
      let status = selectedForWorkStatus.Backup;
      if (selectedUser.status === selectedForWorkStatus?.Backup) {
        status = selectedForWorkStatus.selected;
      }
      const resp = await api().patch(`/selected-for-work/${selectedUser._id}`, { status });
      handleCloseRemoveUserModal();
      setAlert(
        <SweetAlert
          success
          title="User work status successfully updated"
          onConfirm={hideAlert}
          showConfirm={true}
        />,
      );
      fetchData();
      setIsLoading(false);
    } catch (err) {
      console.log("error", err);
      setIsLoading(false);
    }
  };

  const changeShift = async (selectedForWork, selectedShiftData, status) => {
    handleCloseRemoveUserModal();
    try {
      if (isLoading) {
        return;
      }
      // console.log("selectedForWork", selectedForWork)

      setIsLoading(true);
      const resp = await api().patch(`/selected-for-work/${selectedForWork?._id}`, {
        business: selectedShiftData.business,
        businessName: selectedShiftData.businessName,
        status: status,
        shift: selectedShiftData._id,
        start: selectedShiftData.start, //start and end of shift
        end: selectedShiftData.end,
        userId: selectedForWork.userId,
      });
      setIsLoading(false);
      setAlert(
        <SweetAlert
          success
          title="User shift changed successfully"
          onConfirm={hideAlert}
          showConfirm={true}
        />,
      );
      fetchData();
    } catch (err) {
      console.log("error", err);
      handleCloseRemoveUserModal();
      setIsLoading(false);
    }
  };

  const RemoveUserModal = () => {
    const selectedUser = selectedForWorkData.find((u) => u.hdId === selectedHdId);
    const [selectedShift, setSelectedShift] = useState(null);
    const [shiftList, setShiftList] = useState([]);
    const selectedShiftData = shiftList.find((s) => s?._id === selectedShift?.value);
    const sinStartsWith9 = selectedUser?.sin?.startsWith("9");
    const sinExpired =
      sinStartsWith9 && selectedUser?.sinExpiry
        ? moment(selectedUser?.sinExpiry).isBefore(moment(), "days")
        : false;
    const [myAdminPassword, setMyAdminPassword] = useState("");
    const [adminPasswordError, setAdminPasswordError] = useState("");
    const [action, setAction] = useState("");
    const [cantAddToThisBusiness, setCantAddToThisBusiness] = useState(false);
    const [addUserToNewBusiness, setAddUserToNewBusiness] = useState(false);
    const [shiftLoading, setShiftLoading] = useState(false);
    const [unscheduleReason, setUnscheduleReason] = useState("");

    const [formErrors, setFormErrors] = useState({});

    const fetchShifts = async (date) => {
      try {
        setShiftLoading(true);
        const { data } = await api().get("/shift/list", {
          params: {
            start: startOfDay(date), //start and end of current day
            end: startOfNextDay(date),
          },
        });
        setShiftList(data);
        setShiftLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const reactSelectStyles = {
      option: (styles, { data, isFocused, isSelected }) => {
        if (data.shift && data.shift.closed) {
          return {
            ...styles,
            color: "#c5093d",
            backgroundColor: isFocused ? "#eee" : null,
          };
        }
        if (selectedUser?.workerPin?.length) {
          if (!selectedUser.workerPin.some((wPin) => wPin.business === data.business)) {
            //if the user hasn't been selected for work in another business then make the color red
            return {
              ...styles,
              color: "#f5593d",
              backgroundColor: isFocused ? "#eee" : null,
            };
          }
        }

        return {
          ...styles,
          cursor: "pointer",
          color: isSelected ? "#51cbce" : null,
          // color: isFocused ? "#51cbce" : null,
          backgroundColor: isFocused ? "#eee" : null,
        };
      },
    };

    return (
      <Modal
        open={openModal.removeModal}
        onClose={handleCloseAddUserModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {selectedUser ? (
          <div style={{ width: 550, backgroundColor: "#fff", borderRadius: 10 }}>
            <div style={{ padding: 15 }}>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <h5>
                  Do you want to change status of {selectedUser.firstName} {selectedUser.lastName}{" "}
                  from All Workers?
                </h5>
              </div>
              {selectedUser && (
                <div style={{ marginTop: "8px" }}>
                  <h5>Selected shift:</h5>
                  <p>
                    <b>Shift: </b>
                    {selectedUser.shiftName}
                  </p>
                  <p>
                    <b>Business: </b>
                    {selectedUser.businessName}
                  </p>
                  <p>
                    <b>Start: </b>
                    {selectedUser.start}
                  </p>
                  <p>
                    <b>End: </b>
                    {selectedUser.end}
                  </p>
                  {selectedUser.status === selectedForWorkStatus.Backup && (
                    <p>
                      <b>* Selected as a backup </b>
                    </p>
                  )}
                </div>
              )}
              <div style={{ marginRight: 15, marginLeft: 15, marginTop: 15 }}>
                <Row>
                  <Col className="checkbox-radios" sm="10">
                    <div className="form-check-radio" style={{ marginTop: 8 }}>
                      <Label check>
                        <Input
                          defaultValue="Unschedule"
                          id="Unschedule"
                          name="UnscheduleRadio"
                          type="radio"
                          onChange={() => setAction("Unschedule")}
                          checked={action === "Unschedule"}
                        />
                        Unschedule <span className="form-check-sign" />
                      </Label>
                    </div>
                    <div className="form-check-radio">
                      <Label check>
                        <Input
                          defaultValue="ChangeStatus"
                          id="ChangeStatus"
                          name="ChangeStatusRadio"
                          type="radio"
                          checked={action === "ChangeStatus"}
                          onChange={() => setAction("ChangeStatus")}
                        />
                        {selectedUser.status === selectedForWorkStatus.Backup
                          ? "Change to regular selected"
                          : "Change to backup"}
                        <span className="form-check-sign" />
                      </Label>
                    </div>

                    <div className="form-check-radio">
                      <Label check>
                        <Input
                          defaultValue="ChangeShift"
                          id="ChangeShift"
                          name="ChangeShiftRadio"
                          type="radio"
                          checked={action === "ChangeShift"}
                          onChange={() => {
                            fetchShifts(filter.startDate);
                            setAction("ChangeShift");
                          }}
                        />
                        Schedule to another shift/ Change shift <span className="form-check-sign" />
                      </Label>
                      <FormText color="danger" tag="span">
                        {/* {this.state.errorGender} */}
                      </FormText>
                    </div>
                  </Col>
                </Row>

                {action === "Unschedule" ? (
                  <>
                    <Label for="Search" style={{ color: "black", fontSize: "14px" }}>
                      <b>Unschedule Reason</b>
                    </Label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={unscheduleReason}
                      onChange={(value) => setUnscheduleReason(value)}
                      id="unscheduleReasonk"
                      options={UnScheduleReasonList}
                      placeholder="Select Unscheduled Reason"
                    />
                    <FormText color="danger" tag="span">
                      {formErrors.unscheduleReason}
                    </FormText>
                    <div style={{ justifyContent: "space-between", display: "flex" }}></div>
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                      <Button onClick={() => handleCloseRemoveUserModal()}>Cancel</Button>
                      <Button
                        color="danger"
                        onClick={() => {
                          if (unscheduleReason === "" || unscheduleReason === null) {
                            setFormErrors({
                              ...formErrors,
                              unscheduleReason: "* Must select a reason",
                            });
                            return;
                          }
                          removeUserFromSelected(selectedUser, unscheduleReason.value);
                          setUnscheduleReason(null);
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                ) : action === "ChangeStatus" ? (
                  <>
                    <FormGroup row className="align-items-right">
                      <Label for="Search" style={{ color: "black", fontSize: "14px" }}>
                        <b>Are you sure want to change status?</b>
                      </Label>
                    </FormGroup>
                    <div style={{ justifyContent: "space-between", display: "flex" }}></div>
                    <div style={{ justifyContent: "space-between", display: "flex" }}>
                      <Button onClick={() => handleCloseRemoveUserModal()}>Cancel</Button>
                      <Button color="danger" onClick={() => changeStatus(selectedUser)}>
                        Change
                      </Button>
                    </div>
                  </>
                ) : action === "ChangeShift" ? (
                  shiftLoading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      {selectedUser &&
                        (!sinExpired ? (
                          <FormGroup
                            style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}
                          >
                            <div style={{ padding: 25 }}>
                              <div style={{ justifyContent: "center", display: "flex" }}>
                                <h5>
                                  Add {selectedUser.firstName} {selectedUser.lastName} to Live
                                  Selected Workers?
                                </h5>
                              </div>
                              {moment(filter.date).add(1, "days").isAfter(new Date()) ? (
                                <Select
                                  classNamePrefix="react-select"
                                  name="selectShift"
                                  value={selectedShift}
                                  onChange={(value) => {
                                    setSelectedShift(value);
                                    if (
                                      selectedUser.workerPin &&
                                      selectedUser.workerPin.filter(
                                        (wPin) => wPin.agency === agencyContext.agency.agency._id,
                                      ).length > 0 &&
                                      !selectedUser.workerPin.some(
                                        (wPin) => wPin.business === value.business,
                                      )
                                    ) {
                                      setAddUserToNewBusiness(true);
                                      setCantAddToThisBusiness(false);
                                    } else if (
                                      selectedUser.workerPin.some(
                                        (wPin) =>
                                          wPin.business === value.business &&
                                          wPin.agency !== agencyContext.agency.agency._id,
                                      )
                                    ) {
                                      setAddUserToNewBusiness(false);
                                      setCantAddToThisBusiness(true);
                                    } else {
                                      setAddUserToNewBusiness(false);
                                      setCantAddToThisBusiness(false);
                                    }
                                  }}
                                  styles={reactSelectStyles}
                                  options={shiftsList.map((b) => {
                                    let timeOfDay = shiftTimeType(b.start);
                                    return {
                                      label:
                                        b.businessName +
                                        " : " +
                                        timeOfDay +
                                        ` @${moment(b.start).format("hh:mm a")}-${moment(
                                          b.end,
                                        ).format("hh:mm a")}`,
                                      value: b._id,
                                      business: b.business,
                                      shift: b,
                                    };
                                  })}
                                  placeholder="Select shift"
                                  style={{ margin: "15px 0" }}
                                />
                              ) : (
                                "You cannot add workers to past shifts."
                              )}

                              {selectedShift?.shift?.closed ? (
                                <>
                                  <Label style={{ color: "black", fontWeight: "bold" }}>
                                    {selectedShift?.shift?.closed && addUserToNewBusiness
                                      ? "* (This shift is already closed and this user already works at another location.)"
                                      : selectedShift?.shift?.closed
                                      ? "* (This shift is already closed.)"
                                      : addUserToNewBusiness
                                      ? "* (This user already works at another location.)"
                                      : ""}
                                  </Label>
                                </>
                              ) : null}
                              {cantAddToThisBusiness && (
                                <div>
                                  <p style={{ color: "red" }}>
                                    This user can't be added to this shift. He/She already works at
                                    this business through another agency.
                                  </p>
                                </div>
                              )}
                              {addUserToNewBusiness && (
                                <div>
                                  <p style={{ color: "red" }}>
                                    This employee already works at another location.
                                  </p>
                                </div>
                              )}
                              {selectedShiftData && (
                                <div style={{ marginTop: "8px" }}>
                                  <h5>Shift</h5>
                                  <p>{selectedShiftData.shiftName}</p>
                                  <p>
                                    <b>Start:</b>
                                    {moment(selectedShiftData.start).format("LLLL")}
                                  </p>
                                  <p>
                                    <b>End:</b>
                                    {moment(selectedShiftData.end).format("LLLL")}
                                  </p>
                                </div>
                              )}
                              <div style={{ justifyContent: "space-between", display: "flex" }}>
                                <Col>
                                  <FormText color="danger" tag="span">
                                    {formErrors.selectedData}
                                  </FormText>
                                </Col>
                              </div>
                              <div style={{ textAlign: "center" }}>
                                <FormText color="danger" tag="span">
                                  {formErrors.errorSelectedDate}
                                </FormText>
                              </div>

                              <div style={{ justifyContent: "space-between", display: "flex" }}>
                                <Button onClick={() => handleCloseAddUserModal()}>Cancel</Button>

                                <Button
                                  color="info"
                                  disabled={cantAddToThisBusiness}
                                  onClick={() => {
                                    if (!selectedShiftData) {
                                      setFormErrors({
                                        ...formErrors,
                                        selectedData: "You must select a shift",
                                      });
                                      return;
                                    } else {
                                      changeShift(
                                        selectedUser,
                                        selectedShiftData,
                                        selectedForWorkStatus.Backup,
                                      );
                                    }
                                  }}
                                >
                                  Backup
                                </Button>

                                <Button
                                  color="success"
                                  disabled={cantAddToThisBusiness}
                                  onClick={() => {
                                    if (!selectedShiftData) {
                                      setFormErrors({
                                        ...formErrors,
                                        selectedData: "You must select a shift",
                                      });
                                      return;
                                    } else {
                                      changeShift(
                                        selectedUser,
                                        selectedShiftData,
                                        selectedForWorkStatus.selected,
                                      );
                                    }
                                  }}
                                >
                                  Selected
                                </Button>
                              </div>
                            </div>
                          </FormGroup>
                        ) : (
                          <FormGroup
                            style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}
                          >
                            <div style={{ padding: 25 }}>
                              The Selected user's sin number has already been expired. You cannot
                              add the worker to shift. Please update the sin number through the
                              worker's profile.
                            </div>
                            <div style={{ textAlign: "center" }}>
                              <Button color="success" onClick={handleCloseAddUserModal}>
                                OK
                              </Button>
                            </div>
                          </FormGroup>
                        ))}
                    </>
                  )
                ) : (
                  <div>
                    <Button onClick={() => handleCloseRemoveUserModal()}>Cancel</Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>removeModal</div>
        )}
      </Modal>
    );
  };

  const fetchShifts = async (date) => {
    try {
      // setShiftLoading(true);
      const { data } = await api().get("/shift/list", {
        params: {
          start: startOfDay(date), //start and end of current day
          end: startOfNextDay(date),
        },
      });
      setShiftsList(data);
      // setShiftLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsersDataForADay = async (userId, date) => {
    try {
      const { data } = await api().get("/selected-for-work/day/user", {
        params: {
          start: startOfDay(date), //start and end of current day
          end: startOfNextDay(date),
          userId: userId,
        },
      });
      setUserSelectedForWorkForADay(data);
      // setShiftLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchShiftAndUserDataForADay = async (date, userId) => {
    if (!moment(date).add(1, "days").set({ hour: 1, min: 0 }).isAfter(new Date())) return;
    setLoadingShiftUser(true);
    const _fetchShifts = fetchShifts(date);
    const _fetchUsersDataForADay = fetchUsersDataForADay(userId, date);

    await Promise.all([_fetchShifts, _fetchUsersDataForADay]);
    setLoadingShiftUser(false);
  };

  const clearShiftAndUserForADay = () => {
    setShiftsList([]);
    setUserSelectedForWorkForADay(null);
  };

  const renderActionButtons = (selectedForWork) => {
    let search = new URLSearchParams();
    search.append("date", selectedForWork.start);
    search.append("shift", selectedForWork.shift);
    let slug = agencyContext.agency.agency.slug;
    const urlToDescription = `/${slug}/admin/all-live-shift-workers?${search.toString()}`;

    return (
      <div className="actions-center">
        {canAddSelectForWork ? (
          <Button
            onClick={() => {
              handleOpenAddUserModal(selectedForWork && selectedForWork.user.hdId);
              // fetchShifts();
              fetchShiftAndUserDataForADay(filter.date, selectedForWork?.user?._id);
            }}
            color="secondary"
            size="md"
            className="btn-link btn-icon"
          >
            <PersonAdd style={{ marginLeft: -1 }} />
          </Button>
        ) : null}

        {/* <Button
          tag={Link}
          to={urlToDescription}
          color="secondary"
          size="md"
          className="btn-link btn-icon"
        >
          <Description style={{ marginLeft: -1 }} />
        </Button> */}
        <Button
          onClick={() => {
            openUserProfile(selectedForWork.user._id);
          }}
          tag={Link}
          to={`/${agencyContext.agency?.agency?.slug}/admin/user-profile/${selectedForWork.user._id}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
        {canRemoveSelectForWork ? (
          <Button
            onClick={() => {
              handleOpenRemoveModal(selectedForWork && selectedForWork.user.hdId);
              setLoadingShiftUser(true);
              fetchShifts(filter.startDate)
                .then(() => {
                  setLoadingShiftUser(false);
                })
                .catch((e) => {
                  console.log(e);
                  setLoadingShiftUser(false);
                });
            }}
            color="danger"
            size="md"
            className="btn-link remove btn-icon"
          >
            <Clear style={{ marginLeft: -1 }} />
          </Button>
        ) : null}
      </div>
    );
  };

  const handleCloseRemoveUserModal = () => {
    setOpenModal({});
  };

  // remove user from selected logic
  const removeUserFromSelected = async (selectedForWork, reason) => {
    setIsLoading(true);
    try {
      const res = await api().patch(`/selected-for-work/${selectedForWork._id}`, {
        status: selectedForWorkStatus.UnScheduled,
        unscheduleReason: reason,
        userId: selectedForWork.userId,
        start: selectedForWork.startTime,
        end: selectedForWork.endTime,
        dayStart: startOfDay(filter.date), //start and end of current day
        dayEnd: startOfNextDay(filter.date),
      });

      fetchData();
      handleCloseRemoveUserModal();
      setIsLoading(false);
      setAlert(
        <SweetAlert
          success
          title="User successfully removed"
          onConfirm={hideAlert}
          showConfirm={true}
        />,
      );
    } catch (error) {
      console.log("error", error);
      handleCloseRemoveUserModal();
      setIsLoading(false);
      setAlert(
        <SweetAlert
          error
          title={error.response?.data?.message}
          onConfirm={hideAlert}
          showConfirm={true}
        />,
      );
    }
  };

  const deleteUserModal = () => {};
  const updateAnnouncementModal = () => {};

  const _renderShiftTab = () => {
    let shiftFilterList = [
      { name: "All" },
      { name: "Morning" },
      { name: "Afternoon" },
      { name: "Night" },
    ];

    return (
      <Nav
        className="nav-pills-primary nav-pills-icons justify-content-center"
        pills
        role="tablist"
      >
        {shiftFilterList.map((shift, i) => {
          return (
            <NavItem key={i}>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={selectedShiftFilter === shift.name ? "active" : ""}
                onClick={() => {
                  // setSelectedForWorkTableData(shift.data);
                  setSelectedShiftFilter(shift.name);
                }}
              >
                <i className="now-ui-icons objects_umbrella-13" />
                {shift.name}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    );
  };

  const _renderSelectedOrBackupTab = () => {
    let selectedTypeFilterList = ["All", "Selected", "Backup"];

    return (
      <Nav
        className="nav-pills-primary nav-pills-icons justify-content-center"
        pills
        role="tablist"
      >
        {selectedTypeFilterList.map((_workStatus, i) => {
          return (
            <NavItem key={i}>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={workStatus === _workStatus ? "active" : ""}
                onClick={() => {
                  // setSelectedForWorkTableData(shift.data);
                  setWorkStatus(_workStatus);
                }}
              >
                <i className="now-ui-icons objects_umbrella-13" />
                {_workStatus}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    );
  };

  const _renderShiftFunctionTab = () => {
    return (
      <Nav
        className="nav-pills-primary nav-pills-icons justify-content-center"
        pills
        role="tablist"
      >
        <NavItem>
          <NavLink
            data-toggle="tab"
            role="tablist"
            className={filter.shiftFunction === null ? "active" : ""}
            onClick={() => {
              // setSelectedForWorkTableData(shift.data);
              // setSelectedShiftFilter(shift.name);
              setFilter({ shiftFunction: null });
            }}
          >
            <i className="now-ui-icons objects_umbrella-13" />
            All
          </NavLink>
        </NavItem>
        {shiftFunctionList.map((shiftFunction) => {
          return (
            <NavItem key={shiftFunction.label}>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={filter.shiftFunction === shiftFunction ? "active" : ""}
                onClick={() => {
                  // setSelectedForWorkTableData(shift.data);
                  // setSelectedShiftFilter(shift.name);
                  setFilter({ shiftFunction });
                }}
              >
                <i className="now-ui-icons objects_umbrella-13" />
                {shiftFunction}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    );
  };

  const _renderShiftTable = () => {
    return (
      <ReactTable
        filterable
        data={selectedForWorkData}
        getTrProps={(state, rowInfo, instance) => {
          if (rowInfo?.row?._original?.rowColor) {
            return {
              style: {
                color: rowInfo?.row?._original?.rowColor,
              },
            };
          }
          return {};
        }}
        columns={[
          {
            Header: "HD Id",
            accessor: "hdId",
            maxWidth: 70,
          },
          {
            Header: "First Name",
            accessor: "firstName",
          },
          {
            Header: "Last Name",
            accessor: "lastName",
          },
          {
            Header: "verified",
            accessor: "verified",
            getProps: (state, rowInfo, column) => {
              return {
                style: {
                  backgroundColor:
                    rowInfo?.row?.verified === "Verified"
                      ? "#D2FFCB" //light green
                      : rowInfo?.row?.verified === "Invalid" || rowInfo?.row?.verified === "Expired"
                      ? "#EE9999" //light red
                      : rowInfo?.row?.verified === "Pending"
                      ? "#ffffc2" // light yellow
                      : rowInfo?.row?.verified === "Incomplete"
                      ? "#FFCBCB"
                      : "", // no data
                  fontWeight: 400,
                },
              };
            },
            filterable: false,
            sortable: false,
            maxWidth: 70,
          },
          {
            Header: "Selected By",
            accessor: "selectedToWorkByName",
          },
          {
            Header: "Address",
            accessor: "address",
          },
          {
            Header: "City",
            accessor: "city",
          },
          {
            Header: "Phone",
            accessor: "phone",
            Cell: (row) => {
              let telephone = "tel:" + row.original.phone;
              return (
                <a href={telephone} style={{ color: "#51CBCE", textDecoration: "underline" }}>
                  {row.original.phone}
                </a>
              );
            },
            maxWidth: 120,
          },
          {
            Header: "Business",
            accessor: "businessName",
            filterable: false,
          },
          {
            Header: "Shift",
            accessor: "shiftName",
            filterable: false,
          },
          {
            Header: "Shift Function",
            accessor: "shiftFunction",
            filterable: true,
          },
          {
            Header: "Start",
            accessor: "start",
            filterable: false,
          },
          {
            Header: "End",
            accessor: "end",
            filterable: false,
          },
          {
            Header: "Clocked In",
            accessor: "clockIn",
            filterable: false,
            Cell: (row) => {
              return (
                <CheckInTimeCell
                  checkIn={row.original.clockIn}
                  selectedForWorkId={row.original._id}
                  onChange={() => {
                    fetchData();
                  }}
                  defaultTime={row.original.startTime}
                  type="in"
                />
              );
            },
          },
          {
            Header: "Clocked Out",
            accessor: "clockOut",
            filterable: false,
            Cell: (row) => {
              return (
                <CheckInTimeCell
                  checkIn={row.original.clockOut}
                  selectedForWorkId={row.original._id}
                  defaultTime={row.original.endTime}
                  onChange={() => {
                    fetchData();
                  }}
                  type="out"
                />
              );
            },
          },
          {
            Header: "Last Active (App)",
            accessor: "lastAppActive",
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false,
            width: 155,
          },
        ]}
        manual
        loading={loadingTable}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom
        sorted={sorted}
        pages={pages}
        onFetchData={(state, instance) => {
          let searchParams = {
            page: state.page + 1,
            size: state.pageSize,
            field: state.sorted.length ? state.sorted[0].id : "hdId",
            sort: state.sorted.length ? (state.sorted[0].desc ? "desc" : "asc") : "desc",
            filter: state.filtered,
          };
          setReactTableState(searchParams);
          clearTimeout(timer);
          setTimer(
            setTimeout(() => {
              setLoadingTable(true);
              fetchData(searchParams);
            }, 1000),
          );
        }}
        className="-striped -highlight primary-pagination"
      />
    );
  };

  return (
    <div>
      {isLoading && <LoadingOverlay />}
      <Card className="no-transition">
        <CardHeader>
          <Row>
            <Col md="10">
              <CardTitle tag="h4"></CardTitle>
            </Col>
            <Col>
              {isAdmin ? (
                <div>
                  <Label>
                    <Input
                      type="checkbox"
                      style={{ marginTop: 2 }}
                      onChange={(e) => {
                        setIsAdvancedView(e.target.checked);
                      }}
                    ></Input>
                    <h6>Advanced Filter</h6>
                  </Label>
                </div>
              ) : null}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="d-flex justify-content-between">
            {isAdvancedView ? <h4>Filters</h4> : " "}
          </div>
          {/* <Row> */}
          <Row className="mt-1 justify-content-between">
            {!isAdvancedView ? (
              <>
                <Col md="1"></Col>
                <Col md="6">
                  {/* <Label>Date</Label> */}
                  <CustomCalendar
                    inputProps={{
                      placeholder: "Date Picker Here",
                    }}
                    initialDate={filter.startDate}
                    onChangeDate={(date) => {
                      // console.log("changed date");
                      setFilter({ ...filter, startDate: moment(date) });
                    }}
                  />
                </Col>
                <Col md="1"></Col>
              </>
            ) : (
              <>
                <Col md="4">
                  <FormGroup>
                    <Label>Start date</Label>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Date Picker Here",
                      }}
                      timeFormat={false}
                      value={filter.startDate}
                      onChange={(startDate) => {
                        setFilter({ ...filter, startDate });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>End date</Label>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Date Picker Here",
                      }}
                      timeFormat={false}
                      value={filter.endDate}
                      onChange={(endDate) => {
                        setFilter({ ...filter, endDate });
                      }}
                    />
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>
          <BusinessTab
            onSelect={(business) => {
              setFilter({ ...filter, businessId: business?.value || null });
            }}
          />
          {!isAdvancedView && (
            <div className="d-flex justify-content-center" style={{ paddingTop: 20 }}>
              <Row>{_renderShiftTab()}</Row>
            </div>
          )}

          <div className="d-flex justify-content-center" style={{ paddingTop: 20 }}>
            <Row>{_renderSelectedOrBackupTab()}</Row>
          </div>
          <div className="d-flex justify-content-center" style={{ paddingTop: 20 }}>
            <Row>{_renderShiftFunctionTab()}</Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-plain card-subcategories">
                <Row>
                  <p>
                    TEAL text workers are selected as Backup
                    <br></br>
                    BLUE text workers have a SIN that will expire in SIXTY (60) days or less
                    <br></br>
                    PURPLE text workers have a SIN that will expire in THIRTY (30) days or less
                    <br></br>
                    RED workers have an expired or missing SIN
                  </p>
                </Row>
                <CardBody>{_renderShiftTable()}</CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {alert}
      {deleteUserModal()}
      {updateAnnouncementModal()}
      <AddUserModal />
      <RemoveUserModal />
    </div>
  );
};

export default LiveSelectedUsers;

import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { FormGroup, Button, Row,  } from "reactstrap";
import { Modal } from "@mui/material";
import { Link } from "react-router-dom";
import { ArrowDropDownCircleOutlined } from "@mui/icons-material";
import LoadingOverlay from "components/OverLay/LoadingOverlay";

function PdfViewer({ pdf, onClose, open }) {
  console.log("onClose", onClose);
  console.log("open", open);
  pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";
  // setOptions({ workerSrc: '/pdf.worker.min.js', })
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "scroll",
      }}
    >
      <FormGroup style={{ width: 600, height: 600, borderRadius: 10 }}>
        <Row
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            margin: 40,
            marginRight: 0,
          }}
        >
          <a
            href={pdf}
            target="_blank"
            style={{
              color: "black",
              margin: 8,
              marginRight: 2,
              width: 150,
              height: 50,
              fontSize: 20,
              backgroundColor: "white",
            }}
          >
            <ArrowDropDownCircleOutlined /> Download
          </a>
        </Row>
       
        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} loading={<LoadingOverlay />}>
          <Page pageNumber={pageNumber} />
        </Document>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: 40,
          }}
        >
          <Button onClick={() => pageNumber !== 1 && setPageNumber(pageNumber - 1)}>
            Previous
          </Button>
          <Button onClick={() => pageNumber !== numPages && setPageNumber(pageNumber + 1)}>
            Next
          </Button>
        </div>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </FormGroup>
    </Modal>
  );
}

export default PdfViewer;

import React, { useState, useEffect } from "react";
import {
  ExpandMore,
  AddCircleOutline,
  MoreVert,
  ChevronLeft,
  ChevronRight,
  Today,
} from "@mui/icons-material";
import ReactDatetime from "react-datetime";
import moment from "moment";
import PropTypes from "prop-types";

export default function CustomCalendar({
  style,
  initialDate,
  onChangeDate,
  disablePreviousDate,
  myDate,
}) {
  //
  const [date, setDate] = useState(moment(initialDate) || moment());

  const changeDate = function (date, change) {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + change);
    return newDate;
  };

  useEffect(() => {
    if (myDate && new Date(myDate).getTime() !== new Date(date).getTime()) {
      setDate(moment(myDate));
    }
  }, [myDate]);

  useEffect(() => {
    if (new Date(date).getTime() !== new Date(initialDate).getTime()) {
      let newDate = moment(date);
      newDate.set({ hour: 1 });

      if (!onChangeDate) return;
      onChangeDate(new Date(newDate));
    }
  }, [date]);

  const disablePreviousDates = (current) => {
    const today = new Date();
    // return current.isSameOrAfter(today);
    return (
      moment(today)
        // .add(-1, "days")
        .isBefore(current)
    );
  };

  return (
    <div className="d-flex justify-content-between" style={{ ...style }}>
      <div
        style={{
          marginTop: "0.5rem",
          cursor:
            disablePreviousDate && date < new Date(moment().add(1, "days"))
              ? "not-allowed"
              : "pointer",
        }}
        onClick={() => {
          if (disablePreviousDate) {
            if (date < new Date(moment().add(1, "days"))) {
              return;
            }
          }
          setDate(changeDate(date, -1));
        }}
      >
        <ChevronLeft />
        Previous Day
      </div>
      <p style={{ cursor: "pointer" }}>
        <ReactDatetime
          isValidDate={disablePreviousDate ? disablePreviousDates : null}
          inputProps={{
            placeholder: "Date Picker Here",
          }}
          timeFormat={false}
          value={date}
          onChange={(date) => {
            setDate(date);
          }}
        />
        {/* <Today /> */}
      </p>
      <div
        flex
        row
        style={{
          cursor: "pointer",
          marginTop: "0.5rem",
        }}
        onClick={() => setDate(changeDate(date, 1))}
      >
        Next Day
        <ChevronRight />
      </div>
    </div>
  );
}

CustomCalendar.propTypes = {
  style: PropTypes.object,
  initialDate: PropTypes.object,
  onChangeDate: PropTypes.func,
  disablePreviousDate: PropTypes.bool,
  myDate: PropTypes.object, //for controlled input
};

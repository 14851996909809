import { validateEmail } from "components/Utilities/utilities.js";
import api from "components/API/api";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

export const handleSubmitErrors = ({ userData, hasError, tempError}) => {
  if (userData.firstName === null || userData.firstName.trim() === "") {
      tempError.firstName = "* Must have a first name.";
      hasError = true;
  } else {
    tempError.firstName = "";
  }

  if (userData.lastName === null || userData.lastName.trim() === "") {
    tempError.lastName = "* Must have a last name.";
    hasError = true;
  } else {
    tempError.lastName = "";
  }

  if (
    userData.availability[0].active === false &&
    userData.availability[1].active === false &&
    userData.availability[2].active === false &&
    userData.availability[3].active === false &&
    userData.availability[4].active === false &&
    userData.availability[5].active === false &&
    userData.availability[6].active === false
  ) {
    hasError = true;
    tempError.availability = "* Must have at least 1 day available for work.";
  } else {
    tempError.availability = "";
  }

  if (userData.gender === null || userData.gender === "") {
    tempError.gender = "* Must select a gender.";
    hasError = true;
  } else {
    tempError.gender = "";
  }

  if (userData.dob !== null && userData.dob !== "") {
    if (new Date().getYear() - new Date(userData.dob).getYear() < 18) {
      tempError.dob = "* Must be 18 or older.";
      hasError = true;
    } else if (new Date().getYear() - new Date(userData.dob).getYear() >= 18) {
      tempError.dob = "";
    }
  }

  if (userData.phone === null || userData.phone.trim() === "") {
    tempError.phone = "* Must have a phone number."; // No phone number
    hasError = true;
  } else {
    if (isNaN(userData.phone) === true || userData.phone?.length != 10 || userData.phone < 0) {
      tempError.phone = "* Please input a valid phone number."; //invalid phone number
      hasError = true;
    } else {
      tempError.phone = "";
    }
  }

  if (userData.email === null || userData.email.trim() === "") {
    tempError.email = "* Must have an email"; // No email text
  } else {
    if (userData.email != null && validateEmail(userData.email) === false) {
      tempError.email = "* Please input a valid email"; //invalid email text
      hasError = true;
    } else {
      tempError.email = "";
    }
  }

  if (!userData.loggedUserRole === "CallCentreStaff") {
    if (userData.address === null || userData.address.trim() === "") {
      tempError.address = "* Must have a street address.";
      hasError = true;
    } else {
      tempError.address = "";
    }
  }

  if (userData.city === null || userData.city.trim() === "") {
    tempError.city = "* Must have a city.";
    hasError = true;
  } else {
    tempError.city = "";
  }

  if (userData.province === null || userData.province === "") {
    tempError.province = "* Must have a province.";
    hasError = true;
  } else {
    tempError.province = "";
  }

  if (!userData.loggedUserRole === "CallCentreStaff") {
    if (userData.postalCode === null || userData.postalCode.trim() === "") {
      tempError.postalCode = "* Must have a postal code.";
      hasError = true;
    } else {
      let regExp = "^([A-Z]|[a-z])[0-9]([A-Z]|[a-z])[0-9]([A-Z]|[a-z])[0-9]$"; //logic for postal code format A1B2C3, accepts any case letters
      if (userData.postalCode.match(regExp) === null || userData.postalCode?.length != 6) {
        tempError.postalCode = "* Please input a valid postal code."; //invalid postal code
        hasError = true;
      } else {
        tempError.postalCode = "";
      }
    }
  }

  if (userData.legallyWork === null || userData.legallyWork === "") {
    tempError.legallyWork = "* Must select yes or no.";
    hasError = true;
  } else {
    tempError.legallyWork = "";
  }

  if (userData.emergencyFirstName === null || userData.emergencyFirstName.trim() === "") {
    tempError.emergencyFirstName = "* Must have a first name for the emergency contact.";
    hasError = true;
  } else {
    tempError.emergencyFirstName = "";
  }

  if (userData.emergencyLastName === null || userData.emergencyLastName.trim() === "") {
    tempError.emergencyLastName = "* Must have a last name for the emergency contact.";
    hasError = true;
  } else {
    tempError.emergencyLastName = "";
  }

  if (userData.emergencyPhone === null || userData.emergencyPhone.trim() === "") {
    tempError.phone = "* Must have a phone number for the emergency contact."; // No phone number
    hasError = true;
  } else {
    if (userData.emergencyPhone?.length != 10 || userData.emergencyPhone < 0) {
      tempError.phone =
        "* Please input a valid phone number in the format ###-###-#### for the emergency contact."; //invalid phone number
      hasError = true;
    } else {
      tempError.phone = "";
    }
  }

  if (userData.emergencyRelationship === null || userData.emergencyRelationship.trim() === "") {
    tempError.emergencyRelationship = "* Must have a relationship for the emergency contact.";
    hasError = true;
  } else {
    tempError.emergencyRelationship = "";
  }

  return hasError;
}

export const prepareAndSendDataToServer = async ({
  userParamId, userData, setIsLoading, setAlert, hideAlert
}) => {
  // get the qualification Id from selected qualifications
  let tempQualificationsArray = [];
  if (userData.qualifications && userData.qualifications?.length > 0) {
    tempQualificationsArray = userData.qualifications.map((q) => q?.value);
  }
  const updates = {
    userId: userParamId,
    firstName: userData.firstName,
    lastName: userData.lastName,
    availability: userData.availability,
    gender: userData.gender.value,
    dob: moment(userData.dob),
    age: userData.age,
    phone: userData.phone,
    email: userData.email,
    address: userData.address,
    city: userData.city,
    province: userData.province.value,
    postalCode: userData.postalCode,
    legallyWork: userData.legallyWork.value,
    qualifications: tempQualificationsArray,
    workStatus: userData.workStatus && userData.workStatus.value,
    duplicated: userData.duplicated,
    emergency: [
      {
        firstName: userData.emergencyFirstName,
        lastName: userData.emergencyLastName,
        relationship: userData.emergencyRelationship,
        phone: userData.emergencyPhone,
      },
      {
        firstName: userData.emergencyFirstName2,
        lastName: userData.emergencyLastName2,
        relationship: userData.emergencyRelationship2,
        phone: userData.emergencyPhone2,
      },
    ],
    date: new Date(),
    sms: !userData.smsBlocked,
  };
  try {
    await api().patch(`users`, updates);
    setIsLoading(false);
    setAlert(<SweetAlert success title="User successfully updated" onConfirm={hideAlert} />);
  } catch (e) {
    setIsLoading(false);
    setAlert(
      <SweetAlert
        warning
        title={(e.response && e.response.data && e.response.data.message) || e.message}
        onConfirm={hideAlert}
      />,
    );
  }
};
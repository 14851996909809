import { Modal } from "@mui/material";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import React, { useState, useEffect } from "react";
import { FormGroup } from "reactstrap";
import Fade from "reactstrap/lib/Fade";
import { Row } from "reactstrap";
import PropTypes from "prop-types";
import defaultAvatar from "assets/img/placeholder.jpg";

const ImageModal = ({ document }) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(document.img);
  const fileInput = React.createRef();

  const handleClick = () => {
    fileInput.current.click();
  };

  const ImageModal = () => {
    return (
      <Modal
        open={showImageModal}
        onClose={() => {
          setShowImageModal(false);
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showImageModal}>
          <div>
            <img
              src={document.img}
              alt="..."
              // style={{ maxHeight: "100%", maxWidth: "100%" }}
              style={{
                margin: "auto",
                display: "block",
                width: "auto",
                maxHeight: "90vh",
                maxWidth: "80%",
                height: "auto",
                // border: "5px solid #fff",
                borderRadius: 5,
              }}
            />
          </div>
        </Fade>
        {/* </FormGroup> */}
      </Modal>
    );
  };

  return (
    <>
      <FormGroup
        style={{ width: 220, height: 300, backgroundColor: "#fff", borderRadius: 10, margin: 20, marginTop: 10, overflow: "scroll" }}
      >
        <div style={{ padding: 25, marginTop: 20 }}>
          <Row>{document.displayName}</Row>
          <Row className="d-flex justify-content-center mt-2">
            <div className="fileinput text-center">
              <div className={"thumbnail" +  ""}>
          <img
            src={imagePreviewUrl}
            alt="..."
            style={{ cursor: "pointer", height: "300px", width: "auto", objectFit: "cover" }}
            onClick={() => {
              setShowImageModal(true);
            }}
            width="auto"
          />
        </div>
              
            </div>
          </Row>
        </div>
      </FormGroup>

      <ImageModal />
    </>
  );
};

ImageModal.propTypes = {
  avatar: PropTypes.bool,
};

export default ImageModal;

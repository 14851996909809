/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { CSVLink } from "react-csv";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  FormText,
} from "reactstrap";

import * as utils from "components/Utilities/utilities.js";
import { Visibility, Person, AddCircleOutline, Clear, Description, Check } from "@mui/icons-material";

import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "@mui/material";
import Select from "react-select";
import { useState } from 'react';
import { useEffect } from 'react';
import moment from "moment";
import api from "../../../components/API/api";
import { useContext } from 'react';
import AgencyContext from './../../../context/AgencyContext';

const ShiftRequests = (props) => {
  const agencyContext = useContext(AgencyContext);

  const [requestShiftData, setRequestShiftData] = useState([])
  const [requestShiftRaw, setRequestShiftRaw] = useState([])
  const [alert, setAlert] = useState(null)
  const [pages, setPages] = useState(0)
  const [loadingTable, setLoadingTable] = useState(false)
  const [sorted, setSorted] = useState([])
  const [lastTableState, setLastTableState] = useState({})

  const [addShiftModalOpen, setAddShiftModalOpen] = useState(false)

  const fetchData = async (state = lastTableState) => {
    setLastTableState(state)
    setLoadingTable(true)
    console.log("fetchData")
    try {

      let field = null;
      let sort = null;
      if (state.sorted.length > 0) {
        field = state.sorted[0].id;
        sort = state.sorted[0].desc ? "desc" : "asc";
      }

      const { data } = await api().get("/shiftRequest", {
        params: {
          page: state.page + 1,
          size: state.pageSize,
          field,
          sort,
          slug: agencyContext.agency.agency.slug,
          date: (new Date()).getTime()
        }
      })

      console.log("data", data)
      setRequestShiftData(transformRawData(data.shiftRequests))
      setPages(data.noPages)
      setLoadingTable(false)
    } catch (error) {
      setLoadingTable(false)
    }
  }

  const transformRawData = (shiftRequest) => {
    return shiftRequest.map(d => ({
      hdId: d._user.hdId,
      firstName: d._user.firstName,
      lastName: d._user.lastName,
      badgeNumber: d._user.badgeNumber,
      address: d._user.address,
      phone: d._user.phone,
      city: d._user.city,
      business: d._business.name,
      shift: d._shift.description,
      start: moment(d._shift.start).format("L HH:mm"),
      end: moment(d._shift.end).format("L HH:mm"),
      actions: renderActionButtons(d)
    }))
  }

  const openUserProfile = (userId) => {
    props.history.push(`/${agencyContext.agency.agency.slug}/admin/user-profile/${userId}`)
  }

  const openShiftList = (shiftId) => {
    //TODO: Implement this screen first
  }

  const acceptRequest = async (shiftRequestId) => {
    // TODO: Add confirmation modal
    setLoadingTable(true)
    try {
      const { data } = await api().post(`/shiftRequest/accept`, { shiftRequestId })
      // TODO: Add sweetalert success
      await fetchData()
    } catch (error) {
      // TODO: Add sweetalert error
      setLoadingTable(false)
    }
  }

  const rejectRequest = async (shiftRequestId) => {
    // TODO: Add confirmation modal
    setLoadingTable(true)
    try {
      const { data } = await api().post(`/shiftRequest/reject`, { shiftRequestId })
      // TODO: Add sweetalert success
      await fetchData()
    } catch (error) {
      // TODO: Add sweetalert error
      setLoadingTable(false)
    }
  }


  const renderActionButtons = (shiftRequest) => {
    return (
      <div className="actions-right">
        <Button
          onClick={() => {
            openUserProfile(shiftRequest._user._id)
          }}
          color="secondary"
          size="md"
          className="btn-link btn-icon"
        >
          <Person style={{ marginLeft: -1 }} />
        </Button>{" "}
        <Button
          onClick={() => {

          }}
          color="secondary"
          size="md"
          className="btn-link btn-icon"
        >
          <Description style={{ marginLeft: -1 }} />
        </Button>{" "}
        <Button
          onClick={() => {
            acceptRequest(shiftRequest._id)
          }}
          color="success"
          size="md"
          className="btn-link success btn-icon"
        >
          <Check style={{ marginLeft: -1 }} />
        </Button>{" "}
        <Button
          onClick={() => {
            rejectRequest(shiftRequest._id)
          }}
          color="danger"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Clear style={{ marginLeft: -1 }} />
        </Button>{" "}
      </div>
    );
  };

  const addShiftModal = () => {
    //TODO: Implement confirmation modal
  };

  const handleCloseAddShiftModal = () => {
    setAddShiftModalOpen(false)
  }

  return (<>
    <div className="content">
      <Row>
        <Col md="12">
          <FormGroup>
            {/* <CSVLink
              data={this.state.shiftsData}
              headers={this.state.dataHeaders}
              filename={"Shift_Requests.csv"}
            >
              <Button color="default" outline>
                Export All
                  </Button>
            </CSVLink> */}
          </FormGroup>
          <Card className="no-transition">
            <CardHeader>
              <CardTitle tag="h4">Shift Requests</CardTitle>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={requestShiftData}
                columns={[
                  {
                    Header: "HD Id",
                    accessor: "hdId",
                    maxWidth: 70,
                  },
                  {
                    Header: "First Name",
                    accessor: "firstName",
                    maxWidth: 120,
                  },
                  {
                    Header: "Last Name",
                    accessor: "lastName",
                    maxWidth: 120,
                  },
                  {
                    Header: "Phone",
                    accessor: "phone",
                  },
                  {
                    Header: "Address",
                    accessor: "address",
                    maxWidth: 150,
                  },
                  {
                    Header: "City",
                    accessor: "city",
                    maxWidth: 120,
                  },
                  {
                    Header: "Business",
                    accessor: "business",
                    maxWidth: 180,
                  },
                  {
                    Header: "Shift",
                    accessor: "shift",
                  },
                  {
                    Header: "Start",
                    accessor: "start",
                    maxWidth: 140,
                  },
                  {
                    Header: "End",
                    accessor: "end",
                    maxWidth: 140,
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    minWidth: 200,
                    maxWidth: 200,
                  },
                ]}
                filterable
                manual
                loading={loadingTable}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom
                sorted={sorted}
                pages={pages}
                onSortedChange={(newSort, column) => {
                  setSorted(newSort);
                }}
                onFetchData={fetchData}
                className="-striped -highlight primary-pagination"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {alert}
    </div>
  </>
  );

}

export default ShiftRequests;

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import CommentsTab from "views/Agency/AllUsers/UserProfile/Comments";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
  FormText,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Permission from "components/permissions/Permission";
import api from "../../../components/API/api";
import { validateEmail } from "components/Utilities/utilities.js";
import moment from "moment";
import Axios from "axios";
import LoadingOverlay from "components/OverLay/LoadingOverlay";

class BusinessProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessId: "",
      businessName: "",
      noEmployee: "",
      businessAddress: "",
      city: "",
      contactName: "",
      email: "",
      phone: "",
      intersection: "",
      commentHistory: [],
      addComment: "",
      entryType: "automatic", // use google maps to fill the address details

      //Errors
      businessNamError: "",
      noEmployeeError: "",
      cityError: "",
      businessAddressError: "",
      contactNameError: "",
      intersectionError: "",
      emailError: "",
      phoneError: "",
      errorAddComment: "",

      // Loading
      isLoading: false,
      businessData: null,
      latitude: null, //43.73642038966265,
      longitude: null, //-79.60535210118834,
      businessLocationError: "",
      pageTabs: "profile",
    };
  }

  componentDidMount() {
    //geting business HDID from URL
    let selectedUrl = window.location.pathname.split("/");
    this.businessId = selectedUrl[3];
    this.loadCommentHistory();
    this.fetchBusiness();
    //popup warning if user reloads or closes the page
    window.addEventListener("beforeunload", this.handleUserLeave);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    let hasError = false;

    // Start of Error Handling
    if (this.state.businessName == null || this.state.businessName.trim() == "") {
      this.setState({ businessNameError: "* Must have a business name." });
      hasError = true;
    } else {
      this.setState({ businessNameError: "" });
    }

    // if (this.state.noEmployee == null || this.state.noEmployee == "") {
    //   this.setState({ noEmployeeError: "* Must have a employee number." });
    //   hasError = true;
    // } else {
    //   this.setState({ noEmployeeError: "" });
    // }

    if (this.state.city == null || this.state.city.trim() == "") {
      this.setState({ cityError: "* Must have a business city." });
      hasError = true;
    } else {
      this.setState({ cityError: "" });
    }

    if (this.state.businessAddress == null || this.state.businessAddress.trim() == "") {
      this.setState({ businessAddressError: "* Must have a business address." });
      hasError = true;
    } else {
      this.setState({ businessAddressError: "" });
    }

    if (!this.state.latitude || !this.state.longitude) {
      this.setState({
        businessLocationError: " Must have a latitude and longitude for a business.",
      });
      hasError = true;
    } else {
      this.setState({ businessLocationError: "" });
    }

    // if (this.state.intersection == null || this.state.intersection == "") {
    //   this.setState({ intersectionError: "* Must have a nearest intersection." });
    //   hasError = true;
    // } else {
    //   this.setState({ intersectionError: "" });
    // }

    // if (this.state.contactName == null || this.state.contactName == "") {
    //   this.setState({ contactNameError: "* Must have a contact name." });
    //   hasError = true;
    // } else {
    //   this.setState({ contactNameError: "" });
    // }

    // if (this.state.phone == null || this.state.phone == "") {
    //   this.setState({ phoneError: "* Must have a phone number for the business contact." });
    //   hasError = true;
    // } else {
    //   this.setState({ phoneError: "" });
    // }

    // if (
    //   this.state.email == null ||
    //   this.state.email == "" ||
    //   validateEmail(this.state.email) == false
    // ) {
    //   this.setState({ emailError: "* Please have a valid email." });
    //   hasError = true;
    // } else {
    //   this.setState({ emailError: "" });
    // }

    if (hasError) {
      this.setState({ isLoading: false });
      return;
    } else {
      let user = localStorage.getItem("adminEmail");
      let dataToUpdate = null;
      dataToUpdate = {
        name: this.state.businessName,
        address: this.state.businessAddress,
        city: this.state.city,
        employees: this.state.noEmployee,
        contactName: this.state.contactName,
        phone: this.state.phone,
        email: this.state.email,
        intersection: this.state.intersection,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
      };

      api()
        .patch(`/business/${this.state.businessId}`, dataToUpdate)
        .then(() => {
          console.log("successfully saved Data");
          console.log("The sent data are:", dataToUpdate);
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                success
                title="Business successfully updated"
                onConfirm={this.hideAlert}
              />
            ),
          });
          //this.setState({ isLoading: false });
        })
        .catch((err) => {
          console.warn("Problem while updating business: ", err);
          this.setState({ isLoading: false });
        });
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  fetchBusiness = async () => {
    this.setState({ isLoading: true });
    //getting business Id from URL
    let selectedUrl = window.location.pathname.split("/");
    let selectedBusinessId = selectedUrl[4];

    api()
      .get(`/business/${selectedBusinessId}`)
      .then((res) => {
        console.log(res.data);
        let data = res.data.business;
        console.log("Data is:", data);

        this.setState({
          businessId: data.id,
          businessName: data.name,
          noEmployee: data.employees,
          businessAddress: data.address,
          city: data.city,
          contactName: data.contactName,
          email: data.email,
          phone: data.phone,
          intersection: data.intersection,
        });
        if (data.latitude && data.longitude) {
          this.setState({
            latitude: data.latitude,
            longitude: data.longitude,
          });
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  loadCommentHistory = () => {
    this.setState({ isLoading: true });
    api()
      .get(`/business/comment/list?business=${this.props.match.params.id}`)
      .then((res) => {
        console.log("res.data", res.data);
        console.log("res", res);

        this.setState({ isLoading: false });
        const data = res.data;
        let tempArray = [];
        data.map((q) => {
          tempArray.push({
            noteBy: q.commentBy,
            note: q.comment,
            time: moment(q.createdAt).format("MMM DD YYYY hh:mm a"),
          });
        });
        this.setState({ commentHistory: tempArray });
      })
      .catch((err) => console.log(err));
  };

  handleAddComment = (_id, newNote) => {
    if (this.state.addComment === "" || this.state.addComment === null) {
      this.setState({ errorAddComment: "Please enter comment" });
      return;
    }
    this.setState({ isLoading: true });
    api()
      .post(`/business/comment/add`, {
        business: this.props.match.params.id,
        comment: newNote,
      })
      .then((res) => {
        console.log("res", res);

        let tempCommentHistory = this.state.commentHistory;
        let newComment = {
          note: res.data.comment,
          noteBy: res.data.commentBy,
          time: moment(res.data.createdAt).format("MMM DD YYYY hh:mm a"),
        };
        tempCommentHistory.push(newComment);
        console.log(tempCommentHistory);
        this.setState({
          addComment: "",
          isLoading: false,
          commentHistory: tempCommentHistory,
        });
      })
      .catch((err) => console.log(err));
  };
// fetch the address using google geocoding API
  searchAddress = async () => {
    try {
      this.setState({ isLoading: true });
      const address = this.state.businessAddress.split(" ").join("+");
      Axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCM3d16VZW2AZpLRd3KhW2IwCHiSWcnggg`,
      ).then((res) => {
        console.log("address", res);
        if (res?.data?.results?.length > 0 && res?.data?.results[0]?.formatted_address) {
          let tempLocation = {};
          res?.data?.results[0]?.address_components.map((c) => {
            if (c.types.includes("locality")) {
              tempLocation.city = c.long_name;
            }
          });
          // console.log(tempLocation);
          this.setState({
            businessAddress: res?.data?.results[0]?.formatted_address,
            latitude: res?.data?.results[0]?.geometry?.location?.lat,
            longitude: res?.data?.results[0]?.geometry?.location?.lng,
            city: tempLocation.city,
          });
        } else
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                warning
                title={`Address not found, please try again`}
                onConfirm={this.hideAlert}
              />
            ),
          });
      });
      this.setState({ isLoading: false });
    } catch (err) {
      this.setState({
        isLoading: false,
        alert: (
          <SweetAlert
            warning
            title={`Address not found, ${err.message}`}
            onConfirm={this.hideAlert}
          />
        ),
      });
    }
  };

  render() {
    return (
      <>
        <div className="content">
          {this.state.isLoading ? (
            this.loadingOverlay()
          ) : (
            <Row>
              <Col>
                <Card className="no-transition">
                  {/* <Nav
                      className="nav-pills-primary nav-pills-icons justify-content-center"
                      pills
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          role="tablist"
                          className={this.state.pageTabs === "profile" ? "active" : ""}
                          onClick={() => {
                            this.setState({ pageTabs: "profile" });
                          }}
                        >
                          <i className="now-ui-icons ui-2_settings-90" />
                          Edit Profile
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          role="tablist"
                          className={this.state.pageTabs === "comments" ? "active" : ""}
                          onClick={() => {
                            this.setState({ pageTabs: "comments" });
                            this.loadCommentHistory();
                          }}
                        >
                          <i className="now-ui-icons ui-2_settings-90" />
                          Comments
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      className="tab-space tab-subcategories"
                      activeTab={this.state.pageTabs}
                    >
                      <TabPane tabId="profile"> */}
                  <CardHeader>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Col md="10">
                        <CardTitle tag="h4">Edit Business Profile</CardTitle>
                      </Col>
                      <Col md="2">
                        <div>
                          <Label>
                            <Input
                              type="checkbox"
                              style={{ marginTop: 2 }}
                              onChange={(e) => {
                                this.setState({
                                  entryType:
                                    this.state.entryType === "automatic" ? "manual" : "automatic",
                                });
                              }}
                            ></Input>
                            <h6>Manual Entry</h6>
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>* Business Name</label>
                            <Input
                              defaultValue={this.state && this.state.businessName}
                              onChange={(e) =>
                                this.setState({
                                  businessName: e.target.value,
                                  businessNameError: "",
                                })
                              }
                              placeholder="Business Name"
                              type="text"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.businessNameError}
                            </FormText>
                          </FormGroup>
                        </Col>
                        <Col className="pl-1" md="6">
                          <FormGroup>
                            <label>Number of Employees</label>
                            <Input
                              defaultValue={this.state && this.state.noEmployee}
                              onChange={(e) =>
                                this.setState({
                                  noEmployee: e.target.value,
                                  noEmployeeError: "",
                                })
                              }
                              placeholder="Number of Employees"
                              type="text"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.noEmployeeError}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row style={{ display: "flex", alignItems: "center" }}>
                        <Col className="pr-1" md="10">
                          <FormGroup>
                            <label>* Business Address</label>
                            <Input
                              defaultValue={this.state && this.state.businessAddress}
                              value={this.state.businessAddress}
                              onChange={(e) =>
                                this.setState({
                                  businessAddress: e.target.value,
                                  businessAddressError: "",
                                })
                              }
                              placeholder="Business Address"
                              type="text"
                            />
                            <FormText tag="span">
                              {
                                "Please enter address like 35 Woodbine and hit lookup to fill the fields"
                              }
                            </FormText>
                            <FormText color="danger" tag="span">
                              {this.state.businessAddressError}
                            </FormText>
                          </FormGroup>
                        </Col>
                        {this.state.entryType === "automatic" && (
                          <Col sm="2">
                            <FormGroup>
                              <Button color="success" size="sm" onClick={() => this.searchAddress()}>
                                Look up
                              </Button>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col className="pr-1" md="10">
                          <FormGroup>
                            <label>* Business City</label>
                            <Input
                              defaultValue={this.state && this.state.city}
                              onChange={(e) =>
                                this.setState({
                                  city: e.target.value,
                                  cityError: "",
                                })
                              }
                              disabled={this.state.entryType === "automatic"}
                              placeholder="Business City"
                              type="text"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.cityError}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Nearest Intersection</label>
                            <Input
                              defaultValue={this.state && this.state.intersection}
                              onChange={(e) =>
                                this.setState({
                                  intersection: e.target.value,
                                  intersectionError: "",
                                })
                              }
                              placeholder="Intersection"
                              type="text"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.intersectionError}
                            </FormText>
                          </FormGroup>
                        </Col>
                        <Col className="pl-1" md="6">
                          <FormGroup>
                            <label>Contact Name</label>
                            <Input
                              defaultValue={this.state && this.state.contactName}
                              onChange={(e) =>
                                this.setState({
                                  contactName: e.target.value,
                                  contactNameError: "",
                                })
                              }
                              placeholder="Contact Name"
                              type="text"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.contactNameError}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Contact Email</label>
                            <Input
                              defaultValue={this.state && this.state.email}
                              onChange={(e) =>
                                this.setState({
                                  email: e.target.value,
                                  emailError: "",
                                })
                              }
                              placeholder="Contact Email"
                              type="text"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.emailError}
                            </FormText>
                          </FormGroup>
                        </Col>
                        <Col className="pl-1" md="6">
                          <FormGroup>
                            <label>Contact Phone Number</label>
                            <Input
                              defaultValue={this.state && this.state.phone}
                              onChange={(e) =>
                                this.setState({
                                  phone: e.target.value,
                                  phoneError: "",
                                })
                              }
                              placeholder="Contact Phone Number"
                              type="text"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.phoneError}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Latitude</label>
                            <Input
                              value={this.state.latitude}
                              disabled={this.state.entryType === "automatic"}
                              onChange={(e) =>
                                this.setState({
                                  latitude: e.target.value,
                                  businessLocationError: "",
                                })
                              }
                              placeholder="Latitude"
                              type="number"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.emailError}
                            </FormText>
                          </FormGroup>
                        </Col>
                        <Col className="pl-1" md="6">
                          <FormGroup>
                            <label>Longitude</label>
                            <Input
                              value={this.state.longitude}
                              disabled={this.state.entryType === "automatic"}
                              onChange={(e) =>
                                this.setState({
                                  longitude: e.target.value,
                                  businessLocationError: "",
                                })
                              }
                              placeholder="Longitude"
                              type="text"
                            />
                            <FormText color="danger" tag="span">
                              {this.state.businessLocationError}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                    <FormGroup align="right">
                      <Button color="default" outline onClick={() => this.handleSubmit()}>
                        Save Changes
                      </Button>
                    </FormGroup>
                  </CardBody>
                  {/* </TabPane>
                      <TabPane tabId="comments"> */}
                </Card>
                <Card className="no-transition">
                  <CardHeader>
                    <CardTitle tag="h4">Comments</CardTitle>
                  </CardHeader>
                  <CardBody>
                    {this.state.isLoading ? (
                      <LoadingOverlay />
                    ) : (
                      <Form>
                        <CommentsTab
                          comments={this.state.commentHistory}
                          isLoading={this.state.isLoading}
                        />
                        <Permission module="Business" action="Edit">
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label>Add a comment</Label>
                                <Input
                                  value={`${this.state.addComment}`}
                                  onKeyDown={(e) => {
                                    // console.log(e)
                                    if (e.keyCode === 13) {
                                      e.preventDefault();
                                      this.handleAddComment(
                                        this.props.match.params.id,
                                        this.state.addComment,
                                      );
                                    }
                                  }}
                                  onChange={(e) =>
                                    this.setState({
                                      addComment: e.target.value,
                                    })
                                  }
                                  placeholder="Add a comment for this business"
                                  type="text"
                                />
                              </FormGroup>
                              <FormText color="danger" tag="span">
                                {this.state.errorAddComment}
                              </FormText>
                            </Col>
                          </Row>
                          <FormGroup align="right">
                            <Button
                              color="default"
                              outline
                              onClick={() =>
                                this.handleAddComment(
                                  this.props.match.params.id,
                                  this.state.addComment,
                                )
                              }
                            >
                              Add Comment
                            </Button>
                          </FormGroup>
                        </Permission>
                      </Form>
                    )}
                  </CardBody>
                  {/* </TabPane>
                    </TabContent> */}
                </Card>
              </Col>
            </Row>
          )}
          {this.state.alert}
        </div>
      </>
    );
  }
}

export default BusinessProfile;

// add driver address list
export const DriversAddressList = [
    { label: "College Plaza, Tim Hortons", value: "College Plaza, Tim Hortons" },
    { label: "Sports Centre", value: "Sports Centre" },
    {
      label: "Cherrycrest & Queen, Esso Gas Station",
      value: "Cherrycrest & Queen, Esso Gas Station",
    },
    {
      label: "Bramalea & Steeles, Canadian Tire Gas Station",
      value: "Bramalea & Steeles, Canadian Tire Gas Station",
    },
    { label: "281 Richvale, Tim Hortons", value: "281 Richvale, Tim Hortons" },
    {
      label: "Airport & Bovaird, Tim Hortons",
      value: "Airport & Bovaird, Tim Hortons",
    },
    {
      label: "RBC Bank, 7 Sunny Meadow Boulevard",
      value: "RBC Bank, 7 Sunny Meadow Boulevard",
    },
    {
      label: "Castlemore & Clarkway Intersection",
      value: "Castlemore & Clarkway Intersection",
    },
    {
      label: "Sunnyvale & Bovaird Intersection",
      value: "Sunnyvale & Bovaird Intersection",
    },
    {
      label: "Hurontario, Husky Gas Station",
      value: "Hurontario, Husky Gas Station",
    },
    { label: "Finch Office", value: "Finch Office" },
    { label: "6998 Rexwood Rd, Esso Pump", value: "6998 Rexwood Rd, Esso Pump" },
    { label: "Torbram Rd & Queen St E", value: "Torbram Rd & Queen St E" },
    {
      label: "James Potter Rd & William Pkwy",
      value: "James Potter Rd & William Pkwy",
    },
    {
      label: "Avondale Shopping Center",
      value: "Avondale Shopping Center",
    },
    {
      label: "205 Van Kirk Dr, Brampton",
      value: "205 Van Kirk Dr, Brampton",
    },
    {
      label: "Peter Robertson Blvd & Dixie Rd",
      value: "Peter Robertson Blvd & Dixie Rd",
    },
  ];

// worker status enum
  export const statusEnumText = [
    "Available",
    "Unavailable",
    "No Response",
    "Selected For Work",
    "Suspended",
  ];

// worker status enum
  export const workStatusEnum = {
    permanentResident: 0,
    workPermit: 1,
    studentVisa: 2,
  };

  // work status enum text
  export const workStatusEnumText = ["Permanent Resident", "Work Permit", "Student Visa"];

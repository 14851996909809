import React, { useState, useEffect } from "react";
import {
  ExpandMore,
  AddCircleOutline,
  MoreVert,
  ChevronLeft,
  ChevronRight,
  Today,
} from "@mui/icons-material";
import ReactDatetime from "react-datetime";
import moment from "moment";
import PropTypes from "prop-types";

export default function CustomCalendar({
  style,
  initialDate,
  onChangeDate,
  pageTabs,
  disableFutureDate,
  myDate,
}) {
  //
  const [displayFormat, setDisplayFormat] = useState({
    previous: "Previous Day",
    next: "Next Day",
  });
  const [dateFormat, setDateFormat] = useState(null);
  const [viewMode, setViewMode] = useState("days");
 

  useEffect(() => {
    if (pageTabs === "week") {
      setDisplayFormat({ previous: "Previous Week", next: "Next Week" });
      setViewMode("days");
      setDateFormat(null);
    } else if (pageTabs === "month") {
      setDisplayFormat({ previous: "Previous Month", next: "Next Month" });
      setDateFormat("YYYY-MM");
      setViewMode("months");
    } else if (pageTabs === "year") {
      setDisplayFormat({ previous: "Previous Year", next: "Next Year" });
      setDateFormat("YYYY");
      setViewMode("years");
    } else {
      setDisplayFormat({ previous: "Previous Day", next: "Next Day" });
      setViewMode("days");
      setDateFormat(null);
    }
  }, [pageTabs]);

  // disable future dates
  const disableFutureDates = (current) => {
    let today = moment();
    return current.isBefore(today);
  };

  // add month to date
  const addMonthToDate = (props) => {
    let tempDate = new Date(props.date);
    let currentMonth = tempDate.getMonth();
    let currentYear = tempDate.getFullYear();
    if (props.case === "next") {
      let month = currentMonth + 1;
      return new Date(currentYear, month,1,1,1);
    } else {
      let month = currentMonth - 1;
      return new Date(currentYear, month,1,1,1);
    }
  };
  // add year to date
  const addYearToDate = (props) => {
    let tempDate = new Date(props.date);
    if (props.case === "next") {
      return new Date(tempDate.setYear(tempDate.getFullYear() + 1));
    } else {
      return new Date(tempDate.setYear(tempDate.getFullYear() - 1));
    }
  };
  // add week to date
  const addWeekToDate = (props) => {
    let tempDate = new Date(props.date);
    if (props.case === "next") {
      return new Date(tempDate.setDate(tempDate.getDate() + 7));
    } else {
      return new Date(tempDate.setDate(tempDate.getDate() - 7));
    }
  };

  //add day to date
  const addDayToDate = (props) => {
    let tempDate = new Date(props.date);
    if (props.case === "next") {
      return new Date(tempDate.setDate(tempDate.getDate() + 1));
    } else {
      return new Date(tempDate.setDate(tempDate.getDate() - 1));
    }
  };

  return (
    <div className="d-flex justify-content-between" style={{ ...style }}>
      <div
        style={{
          marginTop: "0.5rem",
          cursor: "pointer"
        }}
        onClick={() => {
          if (pageTabs === "week") {
            onChangeDate(addWeekToDate({ date: myDate, case: "previous" }));
          }
          if (pageTabs === "month") {
            console.log(addMonthToDate({date: myDate, case: "previous"}))
            onChangeDate(addMonthToDate({date: myDate, case: "previous"}));
          }
          if (pageTabs === "year") {
            onChangeDate(addYearToDate({date: myDate, case: "previous" }));
          } else if(pageTabs === "day"){
            onChangeDate(addDayToDate({date: myDate, case: "previous" }));
          }
        }}
      >
        <ChevronLeft />
        {displayFormat.previous}
      </div>
      <div style={{ cursor: "pointer" }}>
        <ReactDatetime
          isValidDate={disableFutureDate ? disableFutureDates : null}
          inputProps={{
            placeholder: "Date Picker Here",
          }}
          closeOnSelect={true}
          viewMode={viewMode}
          dateFormat={dateFormat ? dateFormat : true}
          timeFormat={false}
          value={myDate}
          onChange={date=>onChangeDate(date)}
        />
        {/* <Today /> */}
      </div>
      <div
        style={{
          cursor: "pointer",
          marginTop: "0.5rem",
        }}
        onClick={() => {
          if (pageTabs === "week") {
            onChangeDate(addWeekToDate({ date: myDate, case: "next" }));
          }
          if (pageTabs === "month") {
            onChangeDate(addMonthToDate({ date: myDate, case: "next" }));
          }
          if (pageTabs === "year") {
            onChangeDate(addYearToDate({ date: myDate, case: "next" }));
          } else if(pageTabs === "daily") {
            onChangeDate(addDayToDate({ date: myDate,case: "next" }));
          }
        }}
      >
        {displayFormat.next}
        <ChevronRight />
      </div>
    </div>
  );
}

CustomCalendar.propTypes = {
  style: PropTypes.object,
  initialDate: PropTypes.object,
  onChangeDate: PropTypes.func,
  disableFutureDate: PropTypes.bool,
  myDate: PropTypes.object, //for controlled input
  pageTabs: PropTypes.string,
};

import React from 'react'
import { Label, FormGroup, Input, FormText, Row, Col, } from "reactstrap";

/**
 * Description: UI element to select a worker's availability during the week
3 * @param {ARRAY} form {USERS' PROFILE VARIABLES INPUT}
4 * @param {FUNCTION} setForm {SET VARIABLES WHEN USER FILLED FORM}
5 * @param {ARRAY} error {ERROR HANDLING VARIABLES TO INDICATE MISSING VALUE IN THE FORM}
6 * @returns Rendered availability table
 */
const AvailabilityTable = ({form, setForm, error}) => {
	// day = number between 0 (monday) to 6 (sunday)
  const toggleCheckMark = (day) => {
    let tempAvailability = form.availability;
    tempAvailability[day].active = !tempAvailability[day].active;
    setForm({...form, availability: tempAvailability });
  };

  // MAN = choice of "m", "a", "n" for morning afternoon or night
	const toggleShifts = (MAN, day) => {
	let tempAvailability = form.availability;

	MAN == "m"
		? (tempAvailability[day].m = !tempAvailability[day].m) // m
		: MAN == "a"
		? (tempAvailability[day].a = !tempAvailability[day].a) // a
		: (tempAvailability[day].n = !tempAvailability[day].n); // n

	setForm({ availability: tempAvailability });
  };

	const renderAvailability = (dayOfWeekText, day) => {
		return (
			<Row>
				<Col sm="3">
					<FormGroup check inline>
						<Label check>
							<Input type="checkbox" onChange={() => toggleCheckMark(day)} />
							<span className="form-check-sign" />
							{dayOfWeekText}
						</Label>
					</FormGroup>
				</Col>
				{form.availability[day].active ? (
					<Col sm="8">
						<FormGroup check inline>
							<Label check>
								<Input
									type="checkbox"
									checked={form.availability[day].m}
									onChange={() => toggleShifts("m", day)}
								/>
								<span className="form-check-sign" />
								Morning
							</Label>
						</FormGroup>
						<FormGroup check inline>
							<Label check>
								<Input
									type="checkbox"
									checked={form.availability[day].a}
									onChange={() => toggleShifts("a", day)}
								/>
								<span className="form-check-sign" />
								Afternoon
							</Label>
						</FormGroup>
						<FormGroup check inline>
							<Label check>
								<Input
									type="checkbox"
									checked={form.availability[day].n}
									onChange={() => toggleShifts("n", day)}
								/>
								<span className="form-check-sign" />
								Night
							</Label>
						</FormGroup>
					</Col>
				) : null}
			</Row>
		);
	};

  return (
    <Row>
			<Label sm="2">* Availability</Label>
			<Col className="checkbox-radios" sm="10">
				{renderAvailability("Monday", 0)}
				{renderAvailability("Tuesday", 1)}
				{renderAvailability("Wednesday", 2)}
				{renderAvailability("Thursday", 3)}
				{renderAvailability("Friday", 4)}
				{renderAvailability("Saturday", 5)}
				{renderAvailability("Sunday", 6)}
				<FormText color="danger" tag="span">
					{error.errorAvailability}
				</FormText>
			</Col>
		</Row>
  )
}

export default AvailabilityTable
/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";
import ReactTable from "react-table";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
  FormText,
  Spinner,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { validateEmail } from "components/Utilities/utilities.js";
import api from "components/API/api";

class StaffProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //setting states for profile data
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      businessPortalLock: false,
      role: { value: "BusinessStaff", label: "Business Staff" },
      business: null, // to select one business
      businesses: null, // to select multiple business
      rolesList: [
        { value: "BusinessStaff", label: "Business Staff" },
        { value: "BusinessHr", label: "Business Hr Manager" },
        { value: "BusinessShiftSupervisor", label: "Business Shift Supervisor" },
        { value: "BusinessQrSignin", label: "Business Qr Signin" },
      ],
      shiftTypes: [
        { value: "Morning", label: "Morning" },
        { value: "Afternoon", label: "Afternoon" },
        { value: "Night", label: "Night" },
      ],
      selectedShift: [], // supervisor shift

      //history
      changeLog: [],

      // logged in user role
      loggedUserRole: null,

      //locations list
      businessesList: [],
      permissionsList: [],

      //shifts list
      shiftsList: [],

      // role values
      profileRoleValue: 1, // Initialize the staff profile as 1
      loggedUserRoleValue: 1, // Initialize the logged user profile as 1
      disabledValue: true, // prop for Select tags

      //Errors
      errorFirstName: "",
      errorLastName: "",
      errorPhone: "",
      errorEmail: "",
      errorRole: "",
      errorBusiness: "",
      errorSelectedShift: "",

      // Loading
      isLoading: false,
      pageTabs: "profile",
    };
  }

  componentDidMount() {
    this.fetchData();
    // //popup warning if user reloads or closes the page
    window.addEventListener("beforeunload", this.handleUserLeave);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  // fetch the business list
  fetchBusinesses = async () => {
    try {
      const { data } = await api().get(`/business/associated`);
      let tempBusinessList = [];
      data.businesses.map((business) => {
        tempBusinessList.push({
          value: business._id,
          label: business.name,
        });
      });
      this.setState({ businessesList: tempBusinessList });
      return tempBusinessList;
    } catch (err) {
      console.log(err);
      console.log("Error while fetching business list");
    }
  };
  // fetch user data
  fetchData = () => {
    //geting staff Id from URL
    let selectedUrl = window.location.pathname.split("/");
    let selectedDocId = selectedUrl[4];
    // console.log("i amd,", selectedDocId);
    this.setState({ isLoading: true });
    api()
      .get(`/users/business/${selectedDocId}`)
      .then(async (res) => {
        let data = res.data[0];
        console.log("Staff profile", data);
        this.setState({
          staffId: data._id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          businessPortalLock: data.businessPortalLock,
        });
        this.state.rolesList.map((role) => {
          if (role.value === data.profile[0].role) {
            this.setState({ role: role });
          }
        });

        if (data.profile[0].role === "BusinessShiftSupervisor") {
          const tempSelectedShift = [];
          this.state.shiftTypes.map((type) => {
            if (data?.profile[0]?.shiftType && data?.profile[0]?.shiftType[`${type.value}`]) {
              tempSelectedShift.push(type);
            }
          });
          this.setState({
            selectedShift: tempSelectedShift,
          });
        }

        let profileBusiness = [];
        data.profile.map((prof) => {
          profileBusiness.push(prof.business);
        });
        const businessList = await this.fetchBusinesses();
        let tempBusinesses = [];
        // console.log("businessList", businessList);
        // console.log("profileBusiness", profileBusiness);
        profileBusiness.map((profBusiness) => {
          let business = businessList.find((b) => b.value === profBusiness);
          if (business) {
            tempBusinesses.push(business);
          }
        });
        // console.log("tempBusinesses", tempBusinesses);
        this.setState({
          isLoading: false,
          businesses: data.profile[0].role === "BusinessHr" ? tempBusinesses : tempBusinesses[0],
        });
      })
      .catch((err) => {
        console.log(err.response?.data?.message || err.message);
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = async (docId) => {
    this.setState({ isLoading: true });
    let tempState = this.state; // Copy of state
    let hasError = false;

    // Start of Error Handling
    if (tempState.firstName == null || tempState.firstName == "") {
      this.setState({ errorFirstName: "* Must have a first name." });
      hasError = true;
    } else {
      this.setState({ errorFirstName: "" });
    }

    if (tempState.lastName == null || tempState.lastName == "") {
      this.setState({ errorLastName: "* Must have a last name." });
      hasError = true;
    } else {
      this.setState({ errorLastName: "" });
    }

    if (tempState.phone == null || tempState.phone == "") {
      this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
      hasError = true;
    } else {
      if (isNaN(tempState.phone) == true || tempState.phone.length != 10 || tempState.phone < 0) {
        this.setState({ errorPhone: "* Please input a valid phone number." }); //invalid phone number
        hasError = true;
      } else {
        this.setState({ errorPhone: "" });
      }
    }

    if (tempState.email == null || tempState.email == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }
    if (tempState.role == null || tempState.role == "") {
      this.setState({ errorRole: "* Must have an role" }); // No role
      hasError = true;
    } else {
      this.setState({ errorRole: "" });
    }

    if (tempState.role?.value == "BusinessShiftSupervisor" && !tempState.selectedShift?.length) {
      this.setState({ errorSelectedShift: "* Must select a shift type" }); // No shift type
      hasError = true;
    } else {
      this.setState({ errorSelectedShift: "" });
    }

    if (tempState.businesses == null || tempState.businesses == "") {
      this.setState({ errorBusiness: "* Must have a business" }); // No role
      hasError = true;
    } else {
      this.setState({ errorBusiness: "" });
    }

    if (hasError) {
      this.setState({ isLoading: false });
      return;
    } else {
      let dataToUpdate = null;
      let reloadBusinessList = this.state.businessesList; // save business list
      // console.log("this.state.businesses", this.state.businesses);
      let tempBusinessList =
        this.state.role?.value === "BusinessHr"
          ? this.state.businesses.map((business) => business.value)
          : [this.state.businesses.value];
      // console.log(tempBusinessList);
      dataToUpdate = {
        userId: tempState.staffId,
        firstName: tempState.firstName,
        lastName: tempState.lastName,
        phone: tempState.phone,
        email: tempState.email,
        profileType: tempState.role.value,
        business: tempBusinessList,
        businessPortalLock: tempState.businessPortalLock,
      };

      if (tempState.role.value === "BusinessShiftSupervisor") {
        const shiftType = {
          Morning: false,
          Afternoon: false,
          Night: false,
        };
        this.state.selectedShift.map((shift) => {
          shiftType[`${shift.value}`] = true;
        });
        // console.log("shiftType", shiftType);
        dataToUpdate.shiftType = shiftType;
      }

      // console.log(dataToUpdate)

      try {
        const respPatch = await api().patch("/users/update-manager", dataToUpdate);
        this.setState({
          ...this.defaultState,
          businessesList: reloadBusinessList,
          isLoading: false,
          alert: (
            <SweetAlert
              success
              title="Staff data successfully updated"
              onConfirm={this.hideAlert}
            />
          ),
        });
      } catch (e) {
        console.log(e);
        this.setState({
          isLoading: false,
          alert: <SweetAlert danger title={e.message} onConfirm={this.hideAlert} />,
        });
      }
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  render() {
    return (
      <>
        <div className="content">
          {this.state.isLoading ? (
            this.loadingOverlay()
          ) : (
            <>
              <Row>
                <Col>
                  <Card className="no-transition">
                    <CardHeader>
                      <Row>
                        <Col md="6">
                          <CardTitle tag="h4">
                            {" "}
                            Profile of {`${this.state.firstName}`} {`${this.state.lastName}`}
                          </CardTitle>
                        </Col>
                        <Col md="6" align="right">
                          {/* <Button
                        type="submit"
                        color="default"
                        outline
                        onClick={this.loadShiftHistory}
                      >
                        Full Shift History
                      </Button> */}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Nav
                        className="nav-pills-primary nav-pills-icons justify-content-center"
                        pills
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            role="tablist"
                            className={this.state.pageTabs === "profile" ? "active" : ""}
                            onClick={() => this.setState({ pageTabs: "profile" })}
                          >
                            <i className="now-ui-icons objects_umbrella-13" />
                            Edit Profile
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            role="tablist"
                            className={this.state.pageTabs === "changeLog" ? "active" : ""}
                            onClick={() => this.setState({ pageTabs: "changeLog" })}
                          >
                            <i className="now-ui-icons ui-2_settings-90" />
                            Change Log
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        className="tab-space tab-subcategories"
                        activeTab={this.state.pageTabs}
                      >
                        <TabPane tabId="profile">
                          <CardHeader>
                            <CardTitle tag="h4">Edit Profile</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <Form action="/" className="form-horizontal" method="get">
                              <Row>
                                <Label sm="2">*First Name</Label>
                                <Col sm="5">
                                  <FormGroup>
                                    <Input
                                      defaultValue={`${this.state.firstName}`}
                                      onChange={(e) => this.setState({ firstName: e.target.value })}
                                      placeholder="First Name"
                                      type="text"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorFirstName}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2">*Last Name</Label>
                                <Col sm="5">
                                  <FormGroup>
                                    <Input
                                      defaultValue={`${this.state.lastName}`}
                                      onChange={(e) => this.setState({ lastName: e.target.value })}
                                      placeholder="Last Name"
                                      type="text"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorLastName}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2">* Phone Number</Label>
                                <Col md="5">
                                  <FormGroup>
                                    <MaskedInput
                                      mask={[
                                        "(",
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ")",
                                        "-",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        "-",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                      ]}
                                      defaultValue={`${this.state.phone}`}
                                      onChange={(e) => {
                                        let input = e.target.value;
                                        let replacedPhone = input.replace(/[^0-9]/g, "");
                                        this.setState({ phone: replacedPhone });
                                      }}
                                      placeholder="Phone Number"
                                      className="form-control"
                                      maxLength="15"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorPhone}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2">* Email</Label>
                                <Col sm="5">
                                  <FormGroup>
                                    <Input
                                      disabled={true}
                                      defaultValue={`${this.state.email}`}
                                      onChange={(e) => this.setState({ email: e.target.value })}
                                      placeholder="Contact Email"
                                      type="text"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorEmail}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row>
                                <Label sm="2">* Role</Label>
                                <Col sm="5">
                                  <FormGroup>
                                    <Select
                                      className="react-select primary"
                                      classNamePrefix="react-select"
                                      // isDisabled={true}
                                      options={this.state.rolesList}
                                      value={this.state.role}
                                      onChange={(value) => {
                                        if (
                                          this.state.role.value === "BusinessHr" &&
                                          value.value !== "BusinessHr"
                                        )
                                          this.setState({ businesses: null });
                                        this.setState({ role: value });
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              {this.state.role?.value === "BusinessShiftSupervisor" && (
                                <Row>
                                  <Label sm="2">* Shift Types</Label>
                                  <Col sm="5">
                                    <FormGroup>
                                      <Select
                                        className="react-select primary"
                                        classNamePrefix="react-select"
                                        isMulti
                                        options={this.state.shiftTypes}
                                        onChange={(value) => {
                                          this.setState({ selectedShift: value });
                                        }}
                                        value={this.state.selectedShift}
                                      />
                                      <FormText color="danger" tag="span">
                                        {this.state.errorSelectedShift}
                                      </FormText>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              )}

                              <Row>
                                <Label sm="2">* Businesses</Label>
                                <Col sm="5">
                                  <FormGroup>
                                    <Select
                                      className="react-select primary"
                                      classNamePrefix="react-select"
                                      isMulti={this.state.role?.value === "BusinessHr"}
                                      options={this.state.businessesList}
                                      value={this.state.businesses}
                                      onChange={(value) => {
                                        this.setState({ businesses: value });
                                      }}
                                      placeholder="Select businesses"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorBusiness}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row style={{ paddingTop: "20px" }}>
                      <Label sm="6">Hide staff from Business Portal</Label>
                      <Col sm="4" className="checkbox-radios">
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={() =>
                                this.setState({
                                  businessPortalLock: !this.state.businessPortalLock,
                                })
                              }
                              checked={this.state.businessPortalLock}
                              id={"shiftBoard"}
                            />
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>

                              <FormGroup align="right">
                                <Button color="default" outline onClick={() => this.handleSubmit()}>
                                  Save Changes
                                </Button>
                              </FormGroup>
                            </Form>
                          </CardBody>
                        </TabPane>
                        <TabPane tabId="changeLog">
                          <CardHeader>
                            <CardTitle tag="h4">Change Log</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <ReactTable
                              data={this.state.changeLog}
                              filterable
                              columns={[
                                {
                                  Header: "Admin",
                                  accessor: "admin",
                                },
                                {
                                  Header: "Time Changed",
                                  accessor: "time",
                                },
                                {
                                  Header: "Action Taken",
                                  accessor: "action",
                                },
                              ]}
                              defaultPageSize={10}
                              showPaginationTop={true}
                              showPaginationBottom={false}
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight primary-pagination"
                            />
                          </CardBody>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          {this.state.alert}
        </div>
      </>
    );
  }
}

export default StaffProfile;

import React, { useContext, useState } from 'react'
import { Modal } from "@mui/material";
import { USER_PROFILE_MODULE_TYPE } from './UserProfileConstants';
import Select from "react-select";
import {Button, FormGroup, Row, Input, FormText} from "reactstrap";
import api from "components/API/api";
import SweetAlert from "react-bootstrap-sweetalert";
import AgencyContext from 'context/AgencyContext';
import { useHistory } from "react-router-dom";

/**
 * Description: Combined modal of block / unblock / deactivate / reactivate / perm delete / 
 * warn / suspend for user profile page. 
 * @param {ARRAY} modalType {list of the type to open / close a modal with the specific action} 
 * @param {ARRAY} businesses / bannedBusiness {business the user serving, for ban / unban user from the business} 
 * @param {STRING} userParamId {user id from the url}
 * @param {ARRAY} bannedBusinessIds {business id that banned user}
 * @param {ARRAY} userSuspendStatus / userWarnStatus {user status to open / close the warn / suspend modal}
 * @returns The requested modal
 */
const UserProfileCommonModal = ({
  modalType,
  openCommonModal, setOpenCommonModal,
  selectedBusiness, setSelectedBusiness,
  setIsLoading, 
  setAlert, hideAlert,
  loadUser, 
  userData, setUserData,
  businesses,
  bannedBusiness,
  userParamId,
  bannedBusinessIds,
  userSuspendStatus,
  userWarnStatus,
}) => {
  const agencyContext = useContext(AgencyContext);
  const history = useHistory();
  const [permanentDeleteText, setPermanentDeleteText] = useState("");
  const [reason, setReason] = useState("");
  const [noDays, setNoDays] = useState(0);
  const [errorSuspendReason, setErrorSuspendReason] = useState("");
  const [errorNoDays, setErrorNoDays] = useState("");

  const handleCloseCommonModal = () => {
  setOpenCommonModal(false);
  setSelectedBusiness([]);
  setNoDays(0);
  setReason("");
  setErrorSuspendReason("");
  setErrorNoDays("");
  handleWarnClose();
  }

  const handleWarnClose = () => {
    setUserData({ ...userData, suspend: false, warn: false })
  }

  const handleAlertConfirmation = () => {
    hideAlert()
    let path = `/${agencyContext.agency.agency.slug}/admin/all-users`
    history.push(path);
  }

  const unBlockUser = async () => {
    setIsLoading(true);
    try {
      await api().post("/users/user-unBan", {
        userId: userParamId,
        businessId: selectedBusiness.map((b) => b.value),
      });
      await loadUser();
      handleCloseCommonModal();
      setIsLoading(false);
      setAlert(<SweetAlert success title="User Un banned successfully " onConfirm={hideAlert} />);
    } catch (error) {
      handleCloseCommonModal();
      setIsLoading(false);
      setAlert(
        <SweetAlert error title="User couldn't be un banned!" onConfirm={hideAlert}>
          {error?.response?.data?.message || error.message}
        </SweetAlert>,
      );
    }
  };

  const blockUser = async () => {
    setIsLoading(true);
    try {
      await api().post("/users/user-ban", {
        userId: userParamId,
        businessId: selectedBusiness.map((b) => b.value),
        dayStart: new Date(),
      });
      await loadUser();
      handleCloseCommonModal();
      setIsLoading(false);
      setAlert(<SweetAlert success title="User banned successfully " onConfirm={hideAlert} />);
    } catch (error) {
      handleCloseCommonModal();
      setIsLoading(false);
      setAlert(
        <SweetAlert error title="User couldn't be banned!" onConfirm={hideAlert}>
          {error?.response?.data?.message || error.message}
        </SweetAlert>,
      );
    }
  };

  const warnUser = () => {
    let dataToSend = {
        userId: userParamId,
        type: "warn",
        suspendedDate: new Date(),
        reason: reason,
        email: userData.email,
      };
      let error = false;
      if (!reason) {
        setErrorSuspendReason("You must enter a reason. This reason will also be sent to the user.");
        error = true;
      }
      if (userSuspendStatus) {
        if (parseInt(noDays) <= 0) {
          setErrorNoDays("You must enter the number of days the user is going to be suspended for.");
          error = true;
        }
        dataToSend.suspendedFor = parseInt(noDays);
      } else {
        dataToSend.totalWarning = (isNaN(userData.totalWarning || 0) ? 0 : parseInt(userData.totalWarning || 0)) + 1;
      }
      if (!error) {
        api()
          .post("/users/suspend", dataToSend)
          .then(() => {
            // console.log("User updated successfully");
            handleCloseCommonModal();
            setAlert(
              <SweetAlert success title="User successfully updated!" onConfirm={hideAlert} />,
            );
          })
          .catch((e) => {
            // console.log("Failed to suspend user", e);
            handleCloseCommonModal();
          });
      }
    };

  const reactivateUser = async () => {
    setIsLoading(true);
    try {
      await api().get("/users/agency/re-activate", {params: {userId: userParamId},});
      await loadUser();
      handleCloseCommonModal();
      setIsLoading(false)
      setAlert(
        <SweetAlert success title="User successfully reactivated!" onConfirm={hideAlert} />,
      );
    } catch (error) {
      handleCloseCommonModal();
      setIsLoading(false);
      setAlert(
        <SweetAlert error title="User couldn't be re-activated!" onConfirm={hideAlert}>
          {error?.response?.data?.message || error.message}
        </SweetAlert>,
      );
    }
  };

  const deleteUser = async () => {
    setIsLoading(true);
    try {
      await api().delete("/users/agency", { params: { userId: userParamId } });
      setIsLoading(false);
      setAlert(
        <SweetAlert 
          success 
          title="User successfully Deleted" 
          onConfirm={handleAlertConfirmation} 
        />
      )
    } catch (error) {
      console.log("Failed to delete user", error);
      setIsLoading(false);
      setAlert(
        <SweetAlert
          error
          title="User couldn't be deleted please try again."
          onConfirm={hideAlert}
        />,
      );
    } finally {
      handleCloseCommonModal();
    }
  };

  const permanentlyDeleteUser = async () => {
    setIsLoading(true);
    setPermanentDeleteText("");
    handleCloseCommonModal();
    try {
      await api().delete("/users/delete/permanent", {
        params: { userId: userParamId },
      });
      setIsLoading(false);
      setAlert(<SweetAlert success title="User successfully Deleted" onConfirm={handleAlertConfirmation} />);
    } catch (error) {
      setIsLoading(false);
      setAlert(
        <SweetAlert error title="User couldn't be deleted!" onConfirm={hideAlert}>
          {error?.response?.data?.message || error.message}
        </SweetAlert>,
      );
    }
  };

  const UserProfileModal = (props) => {
    return (
      <FormGroup style={formGroupGeneral}>
        <h5 style={modalHeader}>
          {props.title}
        </h5>
        {/* modal second title */}
        {props.secTitle
        ?  <h6 style={modalHeader}> 
              {props.secTitle}
            </h6>
        : null}
        
        {/* applicable to block / unblock user modal only*/}
        {props.block || props.unblock
          ? <div>
              <Select
                isMulti
                className="react-select"
                classNamePrefix="react-select"
                placeholder="Select Business"
                name="multipleSelect"
                closeMenuOnSelect={true}
                value={selectedBusiness}
                onChange={(value) => setSelectedBusiness(value)}
                options={props.block ? businesses : bannedBusinessIds || []}  
              />
              {bannedBusiness?.length ? (
                <Row style={optionText}>
                  Banned Businesses: {bannedBusiness.join(" ,")}
                </Row>
              ) : (
                <Row style={optionText}>
                  Worker has no banned businesses
                </Row>
              )}
            </div>
          : null
        }

        {/* applicable to warn / suspend user only */}
        {props.warn 
        ? <>
            <Input
              autoFocus
              type="text"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setErrorSuspendReason("");
              }}
              placeholder={userSuspendStatus ? "Reason for Suspension" : "Reason for Warn"}
              />
            <FormText color="danger" tag="span">
              {errorSuspendReason}
            </FormText>
       
            {userSuspendStatus && (
              <>
                <h5>Number of days</h5>
                <Input
                  value={noDays}
                  onChange={(e) => {
                    setNoDays(e.target.value);
                    setErrorNoDays("");
                  }}
                  placeholder="Number of days to suspend this user"
                  type="number"
                />
                <FormText color="danger" tag="span">
                  {errorNoDays}
                </FormText>
              </>
            )}
          </>
        : null}
       
        {/* applicable to perm delete user modal only*/}
        {props.permDelete
        ? <>
            <p>Type confirm</p>
            <Input
              autoFocus
              type="text"
              value={permanentDeleteText}
              onChange={(e) => setPermanentDeleteText(e.target.value)}
            />
          </>
        : null}

        <div style={formGroupBody}>
          <Button onClick={handleCloseCommonModal}>Cancel</Button>
          {props.permDelete
            ? <Button
                disabled={permanentDeleteText !== "confirm"}
                color={props.btnColor}
                onClick={props.action}
              >
                {props.btnMessage}
              </Button>
            : props.block || props.unblock
            ? <Button
                disabled={!selectedBusiness || selectedBusiness?.length < 1}
                color="danger"
                onClick={props.action}
              >
                {props.btnMessage}
              </Button>
            : <Button color={props.btnColor} onClick={props.action} > 
                {props.btnMessage}
              </Button>
          }
        </div>
      </FormGroup>
    );
  }

  const DisplayAppropriateModal = ({modalType}) => {
    switch (modalType) {
      case USER_PROFILE_MODULE_TYPE.UNBLOCK_USER:
        return <UserProfileModal 
          title={`Un Block ${userData.firstName} ${userData.lastName} from list of businesses?`}
          unblock="true"
          btnColor="success"
          action={() => unBlockUser()}
          btnMessage="UnBan" 
        />;
      case USER_PROFILE_MODULE_TYPE.BLOCK_USER:
        return <UserProfileModal 
          title={`Block ${userData.firstName} ${userData.lastName} from list of businesses?`}
          block="true"
          btnColor="danger"
          action={() => blockUser()}
          btnMessage="Ban" 
        />;
      case USER_PROFILE_MODULE_TYPE.WARN_USER:
        return <UserProfileModal 
          title="Reason"
          warn="true"
          btnColor={userWarnStatus ? "warning" : "danger"} 
          action={() => warnUser()}
          btnMessage= {userWarnStatus ? "Send Warning" : "Suspend"}
        />;
      case USER_PROFILE_MODULE_TYPE.SUSPEND_USER:
        return <UserProfileModal 
          title="Reason"
          warn="true"
          btnColor={userWarnStatus ? "warning" : "danger"} 
          action={() => warnUser()}
          btnMessage= {userWarnStatus ? "Send Warning" : "Suspend"}
        />;
      case USER_PROFILE_MODULE_TYPE.DELETE_USER:
        return <UserProfileModal 
          title={`Delete ${userData.firstName} ${userData.lastName} ?`}
          btnColor="danger"
          action={() => deleteUser()}
          btnMessage="Delete" 
        />;
      case USER_PROFILE_MODULE_TYPE.REACTIVATE_USER:
        return <UserProfileModal 
          title={`Re-activate ${userData.firstName} ${userData.lastName}'s account?`}
          btnColor="success"
          action={() => reactivateUser()}
          btnMessage="Re-Activate Account" 
        />;
      case USER_PROFILE_MODULE_TYPE.PERMANENT_DELETE_USER:
        return <UserProfileModal 
          title={`Permanently Delete ${userData.firstName} ${userData.lastName} ?`}
          secTitle="This cannot be undone. If you were intending to deactivate the user then please
          deactivate."
          permDelete="true"
          btnColor="danger"
          action={() => permanentlyDeleteUser()}
          btnMessage="Permanently Delete" 
        />
      default:
        return <></>
    }
  }

  return (
    <Modal
      open={openCommonModal}
      onClose={handleCloseCommonModal}
      style={modalStyle}
    >
      <DisplayAppropriateModal modalType = {modalType} />
    </Modal>
  )
}

const formGroupGeneral = { width: 500, backgroundColor: "#fff", borderRadius: 10, padding: 15  }
const modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 15,
  overflow: "scroll",
}
const modalHeader = {  textAlign: "center" }
const formGroupBody = { justifyContent: "space-between", display: "flex" }
const optionText = { fontSize: 16, padding: 15 }


export default UserProfileCommonModal
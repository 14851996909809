import React, { useContext, useState, useEffect, useCallback } from "react";

import AgencyContext from "context/AgencyContext";

export default function usePermissions() {
  const agencyContext = useContext(AgencyContext);
  const permissions = agencyContext.agency?.profile?.permissions;
  const role = agencyContext?.agency?.profile?.roleDisplayName;
  const isAdmin = role === "Admin" || role === "Owner" ? true : false;

  return { permissions, role, isAdmin };
}

//optional or/and operation with multiple actions needed
//in such case pass module and action as array

export function useHasPermissions({ module, action, operation }) {
  const { isAdmin, permissions } = usePermissions();
  if (isAdmin) return true;
  if (Array.isArray(module) && Array.isArray(action)) {
    if (operation === "OR")
      return permissions.some(
        (p) => module.some((m) => m === p.module) && action.some((a) => a === p.action),
      );
    else
      return permissions.some(
        (p) => module.every((m) => m === p.module) && action.every((a) => a === p.action),
      );
  } else if (typeof action === "string") {
    return permissions.some((p) => p.module === module && p.action === action);
  }
  return false;
}

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
//import { Prompt } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";
import TrainingList from "./TrainingList";
import { Modal } from "@mui/material";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import AgencyContext from "./../../../context/AgencyContext";
import api from "../../../components/API/api";
import CreateTrainingModule from "./CreateTrainingModule";
import StaffingContext from "../../../context/StaffingContext";

export default function EditTraining(props) {
  const agencyContext = useContext(AgencyContext);
  const staffingContext = useContext(StaffingContext);
  const { id } = useParams();

  const [trainingName, setTrainingName] = useState("");
  const [expiresIn, setExpiresIn] = useState(0);
  const [hasExpiry, setHasExpiry] = useState(false);
  const [modules, setModules] = useState([]);
  const [alert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setErrors] = useState({ trainingName: "", expiresIn: "" });
  const [openCreateTrainingModule, setOpenCreateTrainingModule] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [index, setIndex] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [moduleName, setModuleName] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  const [pageTabs, setPageTabs] = useState("general");
  const [isAdminLogged, setIsAdminLogged] = useState(false);

  useEffect(() => {
    try {
      if(staffingContext.staffing.user.profile.some(p => p.role === "Admin")){
        setIsAdminLogged(true)
      }
    } catch (error) { }
    training();
    console.log(staffingContext)
  }, []);

  const training = () => {
    setIsLoading(true);
    //load training;
    api()
      .get(`/training/${id}`)
      .then((res) => {
        let training = res.data;
        setTrainingName(training.name);
        if (training.expire === 10000) {
          setHasExpiry(false);
        } else {
          setExpiresIn(training.expire);
          setHasExpiry(true);
        }
        setIsPublic(training.public);
        setModules(training.content);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={hideAlert}
        showConfirm={false}
      />
    );
  };

  // const createModule = () => {
  //   let path = `/${agencyContext.agency.agency.slug}/admin/text-answer-module`;
  //   props.history.push(path);
  // };

  const deleteModal = () => {
    return (
      <Modal
        open={deleteModalOpen}
        onClose={deleteModalOpen}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      // aria-labelledby="simple-modal-title"
      // aria-describedby="simple-modal-description"
      >
        <FormGroup style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Delete {moduleName} ?</h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
              <Button color="danger" onClick={deleteModule}>
                Delete
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const handleSubmit = () => {
    let hasError = false;
    // Start of Error Handling
    if (trainingName == null || trainingName == "") {
      setErrors({ trainingName: "* Must have a training name." })
      hasError = true;
    } else if (hasExpiry === true) {
      let regExp = "^[+]?\d+([.]\d+)?$" //expression to check for negative integers
      if (expiresIn == null || expiresIn == "") {
        setErrors({ expiresIn: "* Must specify expiry period in days." })
        hasError = true;
      } else if(expiresIn.match(regExp) == null) {
        setErrors({ expiresIn: "* Must have a positive number." })
        hasError = true;
      } else {
        setErrors({ trainingName: "", expiresIn: "" })
      }
    } else {
      setErrors({ trainingName: "", expiresIn: "" })
    }

    if (hasError) {
      setAlert(
        <SweetAlert danger title="Please fill in all mandatory fields" onConfirm={hideAlert} />,
      );
      return;
    } else {
      prepareAndSendDataToServer(); // runs function after loading is set as true.
    }
  };

  const prepareAndSendDataToServer = () => {
    setIsLoading(true);
    let dataToEdit = {
      name: trainingName,
      expire: hasExpiry ? expiresIn : 10000, //default expiry is given as 10000
      public: isPublic, //default expiry is given as 10000
    };
    api()
      .patch(`/training/${id}`, dataToEdit)
      .then(() => {
        console.log("training updated");
        training();
        setIsLoading(false);
        setAlert(
          <SweetAlert success title="Training successfully updated" onConfirm={hideAlert} />,
        );
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const deleteModule = () => {
    api()
      .delete("/training/training-module", {
        params: {
          trainingId: id,
          moduleId: moduleId,
        },
      })
      .then(() => {
        console.log("deleted");
        training();
        setDeleteModalOpen(false);
      })
      .catch((e) => {
        console.log(e);
        setDeleteModalOpen(false);
      });
  };

  return (
    <div className="content">
      {/* <Prompt //prevent page jumps, temporarily disabled for testing
          when={true}
          message={() => "All the data will lost if you leave"}
        />*/}
      {isLoading ? (
        loadingOverlay()
      ) : (
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">{trainingName} Training </CardTitle>
                </CardHeader>
                <CardBody>
                  <Nav
                    className="nav-pills-primary nav-pills-icons justify-content-center"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        role="tablist"
                        className={pageTabs === "general" ? "active" : ""}
                        onClick={() => setPageTabs("general")}
                      >
                        <i className="now-ui-icons objects_umbrella-13" />
                      General
                    </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        role="tablist"
                        className={pageTabs === "trainingModule" ? "active" : ""}
                        onClick={() => setPageTabs("trainingModule")}
                      >
                        <i className="now-ui-icons ui-2_settings-90" />
                      Training Modules
                    </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent className="tab-space tab-subcategories" activeTab={pageTabs}>
                    <TabPane tabId="general">
                      <Row>
                        <Label sm="2">* Training Name</Label>

                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              defaultValue={trainingName}
                              onChange={(e) => setTrainingName(e.target.value)}
                            />
                            <FormText color="danger" tag="span">
                              {error.trainingName}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">* Expiry </Label>
                        <Row className="checkbox-radios" sm="5" style={{ marginLeft: "1.5%" }}>
                          <div className="form-check-radio" style={{ marginRight: 8 }}>
                            <Label check>
                              <Input
                                defaultValue={false}
                                id="N"
                                name="expiryRadio"
                                type="radio"
                                checked={hasExpiry === false}
                                onChange={() => setHasExpiry(false)}
                              />
                            No <span className="form-check-sign" />
                            </Label>
                          </div>
                          <div className="form-check-radio" style={{ marginRight: 8 }}>
                            <Label check>
                              <Input
                                defaultValue={true}
                                id="Y"
                                name="expiryRadio"
                                type="radio"
                                checked={hasExpiry}
                                onChange={() => setHasExpiry(true)}
                              />
                            Yes <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div></div>
                      </Row>
                    </Row>
                    {hasExpiry ? (
                      <Row>
                        <Label sm="2">* Expires In(days)</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="Number"
                              autoComplete="off"
                              defaultValue={expiresIn}
                              onChange={(e) => setExpiresIn(e.target.value)}
                            />
                            <FormText color="danger" tag="span">
                              {error.expiresIn}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}
                    {isAdminLogged ? (
                      <Row>
                        <Label sm="2">* Public training </Label>
                        <Row className="checkbox-radios" sm="5" style={{ marginLeft: "1.5%" }}>
                          <div className="form-check-radio" style={{ marginRight: 8 }}>
                            <Label check>
                              <Input
                                defaultValue={false}
                                id="N"
                                name="publicRadio"
                                type="radio"
                                checked={isPublic === false}
                                onChange={() => setIsPublic(false)}
                              />
                              No <span className="form-check-sign" />
                            </Label>
                          </div>
                          <div className="form-check-radio" style={{ marginRight: 8 }}>
                            <Label check>
                              <Input
                                defaultValue={true}
                                id="Y"
                                name="publicRadio"
                                type="radio"
                                checked={isPublic}
                                onChange={() => setIsPublic(true)}
                              />
                              Yes <span className="form-check-sign" />
                            </Label>
                          </div>
                          <div></div>
                        </Row>
                      </Row>
                    ) : null}
                    <FormGroup align="right">
                      <Button color="default" outline onClick={() => handleSubmit()}>
                        Save Changes
                      </Button>
                      </FormGroup>
                    </TabPane>
                    <TabPane tabId="trainingModule">
                      {(!modules || !modules.length) && (
                        <Row>
                          <Label sm="2">* Add Module</Label>
                          <Col sm="5">
                            <FormGroup>
                              <Button
                                color="success"
                                onClick={() => {
                                  setIndex(0);
                                  setEdit(false);
                                  setOpenCreateTrainingModule(true);
                                }}
                              >
                                Add a New Training Module
                            </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}

                      {/* <Row>
                    <Col sm="9"></Col>
                    <Col sm="3">
                      <Button color="success" onClick={() => this.handleSubmit()}>
                        Create Training
                      </Button>
                    </Col>
                  </Row> */}
                      <Row>
                        <Label sm="2"></Label>
                        <Col sm="5">
                          <TrainingList
                            modules={modules}
                            addNewModule={(index) => {
                              setIndex(index);
                              setEdit(false);
                              setOpenCreateTrainingModule(true);
                            }}
                            onEdit={(index) => {
                              console.log(index);
                              setIndex(index);
                              setEdit(true);
                              setOpenCreateTrainingModule(true);
                            }}
                            onDelete={(module) => {
                              //delete with moduleId
                              setDeleteModalOpen(true);
                              setModuleName(module.title);
                              setModuleId(module._id);
                            }}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            <CreateTrainingModule
              open={openCreateTrainingModule}
              onClose={() => {
                setOpenCreateTrainingModule(false);
              }}
              module={edit ? modules[index] : null}
              onSubmit={(module, position, edit) => {
                console.log(module, position, edit);
                // return;
                setIsLoading(true);

                edit
                  ? api()
                    .patch("/training/update-training-module", {
                      trainingId: id,
                      module: module,
                    })
                    .then((res) => {
                      setIsLoading(false);

                      let training = res.data;

                      setTrainingName(training.name || "");
                      setExpiresIn(training.expire);
                      setModules(training.content.length ? training.content : []);
                      // let newModules = [...modules];
                      // newModules.splice(position - 1, 1, module);
                    })
                    .catch(() => {
                      setIsLoading(false);
                      setAlert({
                        alert: (
                          <SweetAlert
                            title="Sorry Couldn't add new modules."
                            onConfirm={() => {
                              setAlert(null);
                            }}
                            showConfirm={false}
                            error
                          />
                        ),
                      });
                    })
                  : api()
                    .patch("/training/add-training-module", {
                      trainingId: id,
                      module: module,
                      insertAt: position,
                    })
                    .then((res) => {
                      setIsLoading(false);
                      let training = res.data;

                      setTrainingName(training.name || "");
                      setExpiresIn(training.expire);
                      setModules(training.content.length ? training.content : []);
                      // let newModules = [...modules];
                      // newModules.splice(position, 0, module);
                      // setModules(data.content);
                    })
                    .catch(() => {
                      setIsLoading(false);
                      setAlert({
                        alert: (
                          <SweetAlert
                            title="Sorry Couldn't add new modules."
                            onConfirm={() => {
                              setAlert(null);
                            }}
                            showConfirm={true}
                            error
                          />
                        ),
                      });
                    });
              }}
              index={index}
              edit={edit}
            />
          </Row>
        )}

      {deleteModal()}
      {alert}
    </div>
  );
}

import api from "components/API/api";
import React, { useState } from "react";
import { useEffect } from "react";
import defaultImage from "assets/img/image_placeholder.jpg";
import { useParams } from "react-router-dom";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Label from "reactstrap/lib/Label";
import { Modal } from "@mui/material";
import Fade from "@mui/material/Fade";
import ImageUpload from "components/CustomUpload/ImageUploadControlled.jsx";
import Axios from "axios";
import Spinner from "reactstrap/lib/Spinner";
import Button from "reactstrap/lib/Button";
import ReactDateTime from "react-datetime";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { Add, Check, ContactsOutlined, Delete, Edit } from "@mui/icons-material";
import { useModal } from "context/ModalContext";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import Select from "react-select";
import { FormGroup } from "reactstrap";
import Input from "reactstrap/lib/Input";
import EditImageModal from "./Images/EditImageModal";
import PdfViewer from "./Images/PdfViewer";

const DocumentStatus = {
  pending: 0,
  valid: 1,
  invalid: 2,
};

// interface IDocument {
//   type: DocumentType;
//   status: DocumentStatus;
//   fileName: string;
//   documentNumber?: string;
//   expires?: Date;
// }

const DocumentType = {
  id: 0,
  workPermit: 1,
  sin: 2,
  studyPermit: 3,
  sinApplicationProof: 4,
  signature: 5,
  agreement: 6,
};

const DocumentTypeString = [
  "* Government Issued Photo ID (passport or driver's license)",
  "* Work Permit",
  "* Proof of Sin",
  "* Study Permit",
  "* Sin Application Proof",
  "* Signature",
  "* Agreement PDF",
];

// const DocumentStatus = {
//   pending: 0,
//   valid: 1,
//   invalid: 2,
// };
const getDocumentType = (index) => {
  switch (index) {
    case 1:
      return DocumentType.id;
    case 3:
      return DocumentType.sin;
    case 5:
      return DocumentType.workPermit;
    case 7:
      return DocumentType.studyPermit;
    case 8:
      return DocumentType.signature;
  }
};

const Images = ({ documents, firstName, lastName, hdId }) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [pdf, setPdf] = useState("");
  const [myDocuments, setMyDocuments] = useState([]);
  const { addAlert } = useModal();
  const [removeImageModal, setRemoveImageModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [addImageModal, setAddImageModal] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);
  const [agreementExists, setAgreementExists] = useState(false);

  useEffect(() => {
    loadImages();
  }, []);

  const uploadFile = (file, signedRequest) => {
    Axios.put(signedRequest, file)
      .then(() => {
        console.log("successfully uploaded the image");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleImages = async (image, fileName, imageType) => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("file-path", `userDocs/${id}/${fileName}`);
    formData.append("file-type", image.type);
    formData.append("documentType", imageType);
    formData.append("userHdId", hdId);
    formData.append("userFirstName", firstName);
    formData.append("userLastName", lastName);
    api()
      .post("/image/sign-s3/put", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        uploadFile(image, data.signedUrl);
        return true;
      })
      .catch((e) => console.log(e));
  };

  const loadImages = async () => {
    try {
      setLoading(true);
      const { data } = await api().get(`/users/documents?userId=${id}`);
      data.map((doc) => {
        if (doc.type === DocumentType.agreement) setAgreementExists(true);
      });
      // console.log(data);
      setLoading(false);
      setMyDocuments(data.map((_doc) => ({ ..._doc, imagePreviewUrl: _doc.url })));
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const closeRemoveImageModal = () => {
    setRemoveImageModal(false);
  };

  const RemoveImageModal = () => {
    const [_loading, _setLoading] = useState(false);
    return (
      <Modal
        open={removeImageModal}
        onClose={closeRemoveImageModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 400, backgroundColor: "#fff", borderRadius: 10 }}>
          {_loading && <LoadingOverlay />}
          <div style={{ padding: 25, marginTop: 20 }}>
            <p>Are you sure you want to delete this document?</p>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                onClick={async () => {
                  _setLoading(true);
                  await handleRemoveImages(selectedDocument);

                  _setLoading(false);
                }}
                color="danger"
              >
                Delete
              </Button>
              <Button onClick={closeRemoveImageModal}>Close</Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const closeAddImageModal = () => {
    setAddImageModal(false);
  };

  const handleRemoveImages = async (image) => {
    setLoading(true);
    try {
      console.log(image);
      const { data } = await api().patch("/users/documents/delete", {
        userId: id,
        document: image,
      });

      setMyDocuments((documents) => {
        return documents.filter((_document) => _document._id !== image._id);
      });
      addAlert({
        title: "Image Successfully removed",
        success: true,
      });
    } catch (e) {
      addAlert({
        title: e.response?.data?.message || e.message,
        error: true,
      });
    } finally {
      closeRemoveImageModal();
      setLoading(false);
    }
  };

  const Image = ({ image }) => {
    return (
      <div
        className="d-flex flex-column mb-2"
        style={{ border: "1px solid #ccc", borderRadius: "5px", minHeight: "600px" }}
      >
        <Row md={12} style={{ textAlign: "center" }}>
          <Col md={10}>
            <h3>{DocumentTypeString[image.type]}</h3>
          </Col>
          <Col md={2}>
            <div
              style={{
                color: "red",
                cursor: "pointer",
                marginTop: "20px",
              }}
              onClick={() => {
                setRemoveImageModal(true);
                setSelectedDocument(image);
              }}
            >
              <Delete />
            </div>
          </Col>
        </Row>

        <div className="d-flex flex-column align-items-center mb-2">
          {(image.type === DocumentType.workPermit ||
            image.type === DocumentType.studyPermit ||
            image.type === DocumentType.sin) && (
            <Row
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col>Expiry:</Col>
              <Col>
                {/* <ReactDateTime
                defaultValue={image.expires ? moment(image.expires).format("MM/DD/YYYY") : ""}
                timeFormat={false}
                closeOnSelect={true}
                value={image.expires ? moment(image.expires).format("MM/DD/YYYY") : ""}
              /> */}
                <Input
                  type="text"
                  disabled={true}
                  value={image.expires ? moment(image.expires).format("DD MMM, YYYY") : ""}
                />
              </Col>
            </Row>
          )}
          {(image.type === DocumentType.sin || image.type === DocumentType.sinApplicationProof) && (
            <Row
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col>Document Number:</Col>
              <Col>
                <Input disabled value={image.documentNumber} />
              </Col>
            </Row>
          )}
          <Row className="mb-2">
            <Col>
              <div
                style={{
                  backgroundColor:
                    image.status === DocumentStatus.valid
                      ? "#20c997"
                      : image.status === DocumentStatus.invalid
                      ? "#dc3545"
                      : "gray",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                {image.status === DocumentStatus.invalid ? (
                  "Invalid"
                ) : image.status === DocumentStatus.valid ? (
                  <div>Valid</div>
                ) : (
                  "Pending Approval"
                )}
              </div>
            </Col>
            {(!image.expires || moment(image.expires).isBefore(moment())) && (
              <Col>
                <div
                  style={{
                    backgroundColor: "#dc3545",
                    color: "white",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  Expired
                </div>
              </Col>
            )}
          </Row>
          <ImageUpload
            localImage={image}
            index={image._id}
            handleImages={(_image) => {
              handleImages(_image, image.fileName, image.type);
            }}
            onSelect={() => {
              if (loading) return;
              setSelectedDocument(image);
              setAddImageModal(image);
            }}
          />
        </div>
        {/* </Col> */}
      </div>
    );
  };

  const Agreement = ({ image }) => {
    return (
      <div
        className="d-flex flex-column  mb-2"
        style={{ border: "1px solid #ccc", borderRadius: "5px", minHeight: "600px" }}
      >
        <Row md={12} style={{ textAlign: "center" }}>
          <Col md={10}>
            <h3>{DocumentTypeString[image.type]}</h3>
          </Col>
          <Col md={2}>
            <div
              style={{
                color: "red",
                cursor: "pointer",
                marginTop: "20px",
              }}
              onClick={() => {
                setRemoveImageModal(true);
                setSelectedDocument(image);
              }}
            >
              <Delete />
            </div>
          </Col>
        </Row>
        <div className="d-flex flex-column align-items-center mb-2">
          <Row
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col>Uploaded Date:</Col>
            <Col>
              {/* <ReactDateTime
                defaultValue={image.expires ? moment(image.expires).format("MM/DD/YYYY") : ""}
                timeFormat={false}
                closeOnSelect={true}
                value={image.expires ? moment(image.expires).format("MM/DD/YYYY") : ""}
              /> */}
              <Input
                type="text"
                disabled={true}
                value={image.uploaded ? moment(image.uploaded).format("DD MMM, YYYY") : ""}
              />
            </Col>
          </Row>
          <Button
            style={{ marginTop: "200px" }}
            color="success"
            onClick={() => {
              setAgreementModal(true);
              setPdf(image.imagePreviewUrl);
            }}
          >
            View Agreement
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {agreementExists ? (
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#00D100",
            fontSize: 14,
            fontWeight: "bold",
            marginBottom: 40,
          }}
        >
          This user has employment agreement on file
        </Row>
      ) : (
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#EE9999",
            fontSize: 14,
            fontWeight: "bold",
            marginBottom: 40,
          }}
        >
          This user has no employment agreement on file
        </Row>
      )}
      <Row style={{ minHeight: "800px" }}>
        {loading && <LoadingOverlay />}

        {myDocuments.map((image, i) => {
          return image.type === DocumentType.agreement ? (
            <Col md={6} key={i} className="h-10">
              <Agreement image={image} />
            </Col>
          ) : (
            <Col md={6} key={i} className="h-10">
              <Image image={image} />
            </Col>
          );
        })}
        <Col md={6} className="h-10">
          <div
            className="d-flex flex-column align-items-center mb-2 justify-content-center"
            style={{ border: "1px solid #ccc", borderRadius: "5px", minHeight: "600px" }}
          >
            <Button
              onClick={() => {
                setSelectedDocument(null);
                setAddImageModal(true);
              }}
            >
              <Add />
              Add
            </Button>
          </div>
        </Col>
      </Row>
      <RemoveImageModal />
      {addImageModal && (
        <EditImageModal
          addImageModal={addImageModal}
          closeAddImageModal={closeAddImageModal}
          document={selectedDocument}
          onUpdate={(updates) => {
            setMyDocuments((documents) => {
              return documents.map((_document) => {
                if (_document._id === selectedDocument._id) {
                  return {
                    ..._document,
                    ...updates,
                  };
                }
                return _document;
              });
            });
          }}
          onAdd={(newImage) => {
            setMyDocuments((docs) => [...docs, newImage]);
          }}
          firstName={firstName}
          lastName={lastName}
          hdId={hdId}
        />
      )}
      {agreementModal && (
        <PdfViewer
          pdf={pdf}
          open={agreementModal}
          onClose={() => {
            setAgreementModal(false);
            setPdf(null);
          }}
        />
      )}
    </>
  );
};

export default Images;

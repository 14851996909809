import { useHasPermissions } from "hooks/usePermissions";
import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";
import usePermissions from "hooks/usePermissions";

/*******
 * Use Permission as wrapper for any component that needs permission
 * this is specially useful for class based component where you can't use hooks
 *
 * error is optional paramter which is sent as paramter will pop up a warning message when the element is clicked.
 *
 */
export default function Permission({ children, module, action, error, operation, hide }) {
  const hasPermission = useHasPermissions({ module, action, operation });
  const [alert, setAlert] = useState(null);
  const docRef = useRef(null);

  useEffect(() => {
    if (!docRef.current) return;
    docRef.current.addEventListener("click", (e) => {
      setAlert(
        <SweetAlert
          warning
          style={{ zIndex: 10000 }}
          onConfirm={() => setAlert(null)}
          showConfirm={true}
        >
          {error}
        </SweetAlert>,
      );
      e.stopPropagation();
      e.preventDefault();
    });
  }, []);
  return (
    <>
      {hasPermission ? (
        <> {children}</>
      ) : hide ? null : error ? (
        <div ref={docRef} style={{ display: "inline-block" }}>
          {children}
        </div>
      ) : null}
      {alert}
    </>
  );
}





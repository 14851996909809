/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect, withRouter, useLocation, useHistory } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import NotAllowed from "views/pages/NotAllowed.jsx";

import routes from "./routes.js";
import AgencyContext from "../../context/AgencyContext";

import { checkAccessSideBar } from "../../utils/checkAccess";
import StaffingContext from "../../context/StaffingContext";
import { useReducer } from 'react';
import staffingReducer from './../../reducers/staffingReducer';
import { useState } from 'react';
import SidebarBusiness from "../../components/Sidebar/SidebarBusiness";
import { useEffect } from 'react';

const initialState = () => {
  const state = localStorage.getItem("staffingUser");
  if (state) {
    return { user: JSON.parse(state) };
  }
  return { user: null };
};


const Admin = (props) => {
  const [staffing, staffingDispatch] = useReducer(staffingReducer, initialState());
  const [config, setConfig] = useState({
    backgroundColor: "#a1a1a1",
    activeColor: "#515151"
  })

  useEffect(() => {
    console.log("useEffect Admin", staffing)
    if (!staffing.user || !staffing.user._id) {
      props.history.push("/staffing/login")
    }
  }, [staffing.user])

  const getRoutes = (routes) => {
    //If the user is not logged in i.e. localstorage has no "Token" then the page will redirect to Login page
    if (!Boolean(localStorage.getItem("Token")) || !staffing.user || !staffing.user.profile.some(p => p.role === "Admin")) {
      return <Redirect to={`/staffing/login`} />;
    }

    return routes.map((route, key) => {
      if (route.collapse) {
        return getRoutes(route.views);
      }
      if (
        route.layout === "/admin" &&
        staffing.user.profile.some(p => p.role === "Admin")
      ) {
        return (
          <Route
            path={"/super" + route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      } else {
        return (
          <Route
            path={"/super" + route.layout + route.path}
            component={NotAllowed}
            key={key}
          />
        );
      }
    });
  };

  return <StaffingContext.Provider value={{ staffing, staffingDispatch }}>
    {staffing.user && staffing.user._id &&
      <div className="wrapper">
        <SidebarBusiness
          {...props}
          routes={routes}
          bgColor={config.backgroundColor}
          activeColor={config.activeColor}
          role="Admin"
        />
        <div className="main-panel">
          <AdminNavbar {...props} handleMiniClick={() => { }} />
          <Switch>{getRoutes(routes)}</Switch>
          <Footer fluid />
        </div>
      </div>
    }
  </StaffingContext.Provider>
}

export default Admin;

const permissionsGuideList = [
  {
    name: "Announcement",
    description: `
    The announcement edit permission allows staff to edit the 
    announcement in the Live Selected User page for all staff to view.
    `,
  },
  {
    name: "Business",
    description: `
    The Business permission allows staff to view, edit, create or delete businesses. 
    The view permission is mandatory for edit, create or delete operations.
    `,
  },
  {
    name: "Business Sales",
    description: `
    The Business sales permission allows staff to view, edit, create or delete business sales leads. 
    The view permission is mandatory for edit, create or delete operations.
    `,
  },
  {
    name: "Business Staff",
    description: `
    The Business Staff permission allows staff to view, edit, create or delete business staff from the All Business Staff database. 
    The view permission is mandatory for edit, create or delete operations.
    `,
  },
  {
    name: "Cheque",
    description: `
    The Cheque permission allows staff to view, edit, create or delete worker cheques. 
    The view permission is mandatory for edit, create or delete operations.
    `,
  },
  {
    name: "Log",
    description: `
    The Log permission allows staff to view the audit logs of fellow staff members. 
    The audit log automatically records all staff actions and cannot be edited.
    `,
  },
  {
    name: "Notification",
    description: `
    The Notification permission allows staff to view, edit or create notifications to be pushed out to workers. 
    The view permission is mandatory for edit or create operations. Please note this function should only be given 
    to trusted individuals as SMS blasts cost money per text sent.
    `,
  },
  {
    name: "Training",
    description: `
    The Training permission allows staff to view, edit, create or delete different types of training.
    This permission affects trainings that temporary workers can take to become trained.
    It does not affect a temporary worker's completed trainings or training history. 
    The view permission is mandatory for edit, create or delete operations.
    `,
  },
  {
    name: "Select For Work",
    description: `
    The Select For Work permission allows staff to view, add, or delete Selected Workers. 
    The view permission is mandatory for  add or delete operations. 
    Adding, editing or removing workers from Selected Workers 
    does not remove them from the All Workers database.
    `,
  },
  {
    name: "Shifts",
    description: `
    The Shifts permission allows staff to view, edit, create or delete shifts for temporary workers.
    These shifts must then be filled by staff members with the appropriate Select For Work permissions.
    The view permission is mandatory for edit, create or delete operations.
    `,
  },
  {
    name: "Workers",
    description: `
    The Users permission allows staff to view, edit, create or delete temporary workers from the All Workers database. 
    The view permission is mandatory for edit, create or delete operations.
    `,
  },
  {
    name: "Manage Staff",
    description: `
    The Manage Staff permission allows staff to view, edit, create or delete staff from the All Staff database. 
    The view permission is mandatory for edit, create or delete operations.
    `,
  },
  {
    name: "Advanced Worker Search",
    description: `
    Advanced Worker Search permission allows the staff to search for worker using all fields 
    `,
  },
  {
    name: "Worker Messages",
    description: `
     Edit permission is required to view and sent messages
    `,
  },
  {
    name: "Payroll",
    description: `
     Payroll permission allows staff to view, edit, create or delete payroll
    `,
  },
  {
    name: "Analytics",
    description: `
    Analytics view  permission allows the staff to see the analytics 
    `,
  }
]

export default permissionsGuideList

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useReducer, useState } from "react";
import { Router, Route, Switch, Redirect, HashRouter } from "react-router-dom";
import { history } from "./history";
import SuperLayout from "./layouts/SuperLayout/SuperLayout";

import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// Messy merge //
// styles
import 'bootstrap/dist/css/bootstrap.min.css';
import "assets/scss/paper-kit.scss";
import "assets/demo/react-demo.css";
import "assets/css/Messages.css";
// pages
import AgencyLayout from "./layouts/AgencyLayout/AgencyLayout.jsx";
import StaffingLayout from "./layouts/StaffingLayout/StaffingLayout.jsx";

//Extra Pages
import moment from "moment-timezone";
import api from "components/API/api";
import SweetAlert from "react-bootstrap-sweetalert";
import staffingReducer from "reducers/staffingReducer";
import agencyReducer from "reducers/agencyReducer";
import StaffingContext from "context/StaffingContext";
import AgencyContext from "context/AgencyContext";
import { ModalProvider } from "context/ModalContext";

// Messy merge end//
const timeZoneFix = async () => {
  let { data } = await api().get("/server-timestamp");
  //offset to match the server time
  var offset = new Date(data.utc_timestamp).getTime() - Date.now();
  //offset to match Toronto/Canada timezone
  let currentTimeZoneUtcOffset = moment().tz(moment.tz?.guess())?.utcOffset(),
    hoursOffset = -currentTimeZoneUtcOffset / 60,
    minuteOffset = -currentTimeZoneUtcOffset % 60;

  //destination timeZone utc offset
  let torontoOffset = moment().tz("America/Toronto")?.utcOffset(),
    torontoHoursOffset = -torontoOffset / 60,
    torontoMinuteOffset = -torontoOffset % 60;

  let totalOffset =
    offset +
    hoursOffset / 60 / 60 / 1000 +
    minuteOffset / 60 / 1000 -
    torontoHoursOffset / 60 / 60 / 1000 -
    torontoMinuteOffset / 60 / 1000;
  if (Math.abs(totalOffset) > 5000) {
    //ignore if offset isn't greater than 5000ms
    moment.now = function () {
      return Date.now() + totalOffset;
    };
    console.log("true", Math.abs(totalOffset));
    return true;
  }
};

const App = (props) => {
  const [alert, setAlert] = useState(false);
  const [staffing, staffingDispatch] = useReducer(staffingReducer, { user: null });
  const [agency, agencyDispatch] = useReducer(agencyReducer, {
    user: null,
    agency: null,
    profile: null,
    liveBusinesses: null,
    selectedLiveBusiness: null,
    selectedLiveDate: null,
    selectedLiveShift: null,
    timeout: null,
    businesses: [],
  });

  useEffect(() => {
    {
      (async () => {
        if (process.env.NODE_ENV === "production" && (await timeZoneFix())) {
          setAlert(true);
        }
      })();
    }
  }, []);

  return (
    <>
      <ModalProvider>
        <StaffingContext.Provider value={{ staffing, staffingDispatch }}>
          <AgencyContext.Provider value={{ agency, agencyDispatch }}>
            <Router history={history}>
              <Switch>
                {/* Landing Page for staffing home page */}
                <Route path="/" exact render={(props) => <Redirect to="/hd-group/auth/login" />} />
                {/* Routes  for staffing*/}
                <Route path="/staffing" render={() => <StaffingLayout {...props} />} />
                <Route path="/super" render={(props) => <SuperLayout {...props} />} />
                {/* messy af merge */}
                <Route path="/:slug" render={(props) => <AgencyLayout {...props} />} />
                <Route render={(props) => <Error404 {...props} />} />
              </Switch>
            </Router>
          </AgencyContext.Provider>
        </StaffingContext.Provider>
        {alert && (
          <div>
            <SweetAlert
              warning
              title={"Time Mismatch"}
              onConfirm={() => setAlert(false)}
              showConfirm={true}
            >
              Your time isn't in sync with the server timezone.
            </SweetAlert>
          </div>
        )}
      </ModalProvider>
    </>
  );
};

export default App;

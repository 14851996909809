/**
 * Constants of default values and regular expression criteria for fields of CreateNewUser.jsx
 */

export const workStatusOptions = [
  { value: 0, label: "Permanent Residence/Citizen" },
  { value: 1, label: "Work Permit" },
  { value: 2, label: "Student Visa" },
];

export const provinceOptions = [
  { value: "Alberta", label: "Alberta" },
  { value: "British Columbia", label: "British Columbia" },
  { value: "Manitoba", label: "Manitoba" },
  { value: "New Brunswick", label: "New Brunswick" },
  {
    value: "Newfoundland and Labrador",
    label: "Newfoundland and Labrador",
  },
  { value: "Northwest Territories", label: "Northwest Territories" },
  { value: "Nova Scotia", label: "Nova Scotia" },
  { value: "Nunavut", label: "Nunavut" },
  { value: "Ontario", label: "Ontario" },
  { value: "Prince Edward Island", label: "Prince Edward Island" },
  { value: "Quebec", label: "Quebec" },
  { value: "Saskatchewan", label: "Saskatchewan" },
  { value: "Yukon", label: "Yukon" },
];

export const CSVReaderParseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.replace(/\W/g, "_"),
};

export const maskedPhone = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const maskedPostalCode = [/[A-Za-z]/, /\d/, /[A-Za-z]/, " ", /\d/, /[A-Za-z]/, /\d/];

export const maskedSin = [/\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/];

export const sinInstruction =
  "*If this worker does not have a sin but has the government document allowing them to work, please type in 000-000-000 in the sin field";

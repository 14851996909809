// const [businessDataHeaders, setBusinessDataHeaders] = useState([
//   {
//     label: "Business Name",
//     key: "businessName",
//   },

//   {
//     label: "Worker HDID",
//     key: "hdId",
//   },
//   {
//     label: "First Name",
//     key: "firstName",
//   },
//   {
//     label: "Last Name",
//     key: "lastName",
//   },
//   {
//     label: "Badge ID",
//     key: "pin",
//   },
//   {
//     label: "Email",
//     key: "email",
//   },
//   {
//     label: "Gender",
//     key: "gender",
//   },
//   {
//     label: "Phone",
//     key: "phone",
//   },
//   {
//     label: "VACCINE STATUS",
//     key: "vaccineStatus",
//   },

//   {
//     label: "In-time",
//     key: "inTime",
//   },
//   {
//     label: "Out-time",
//     key: "outTime",
//   },
//   {
//     label: "Selected By",
//     key: "selectedToWorkByName",
//   },
//   {
//     label: "Last Comment",
//     key: "comment",
//   },
//   {
//     label: "Last App Login",
//     key: "lastAppLoginTime",
//   },
//   {
//     label: "Action",
//     key: "action",
//   },
// ]);

export const csvDataHeaders = [
  {
    label: "PRODUCTION NO",
    key: "productionNo",
  },
  {
    label: "FIRST NAME",
    key: "firstName",
  },
  {
    label: "LAST NAME",
    key: "lastName",
  },
  {
    label: "BADGE ID",
    key: "workerPin",
  },
  {
    label: "HDID",
    key: "hdId",
  },
  {
    label: "TIME",
    key: "time",
  },
  {
    label: "GENDER",
    key: "gender",
  },
  {
    label: "VACCINE STATUS",
    key: "vaccineStatus",
  },
  {
    label: "AGENCY",
    key: "agency",
  },
  {
    label: "DEPT",
    key: "dept",
  },
];

export const DefaultRating = [
  { displayName: "Attitude", name: "attitude", value: 0 },
  { displayName: "Punctuality", name: "punctuality", value: 0 },
  { displayName: "Work Efficiency", name: "efficiency", value: 0 },
];

export const STATUS_OPTIONS = [
  { value: 1, label: "READY" }, /// status enum
  { value: 0, label: "PENDING" },
  { value: 2, label: "NOT READY" },
];

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { validateEmail } from "components/Utilities/utilities.js";
import * as constants from "constants.js";
import { Modal } from "@mui/material";

import api from "../../../components/API/api";

class CreateNewBusiness extends Component {

  defaultBusinessState = {
    businessName: null,
    businessAddress: null,
    businessCity: null,
    employees: null,
    contactName: null,
    phone: null,
    email: null,
    intersection: null,
    businessNo: null,
    intersection: null,
    businessNo: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.defaultBusinessState,

      //Errors
      errorbusinessName: "",
      /*
      errorbusinessCity: "",
      errorbusinessAddress: "",
      errorphone: "",
      erroremail: "",
      */

      // Loading
      isLoading: false,
    };
  }

  componentDidMount() {
    // Load data to local storage
    //popup warning if user reloads or closes the page
    window.addEventListener("beforeunload", this.handleUserLeave);
  }
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    let tempState = this.state; // Copy of state
    let hasError = false;

    // Start of Error Handling
    if (tempState.businessName == null || tempState.businessName == "") {
      this.setState({ errorbusinessName: "* Must have a business name." });
      hasError = true;
    } else {
      this.setState({ errorbusinessName: "" });
    }
    if (tempState.businessNumber == null || tempState.businessNumber == "") {
      this.setState({ errorbusinessNumber: "* Must have a business number." });
      hasError = true;
    } else {
      this.setState({ errorbusinessNumber: "" });
    }
    /*
        if (tempState.businessCity == null || tempState.businessCity == "") {
          this.setState({ errorbusinessCity: "* Must have a business city." })
          hasError = true;
        } else {
          this.setState({ errorbusinessCity: "" })
        }
    
        if (tempState.businessAddress == null || tempState.businessAddress == "") {
          this.setState({ errorbusinessAddress: "* Must have a business address." })
          hasError = true;
        } else {
          this.setState({ errorbusinessAddress: "" })
        }
    
        if (tempState.phone == null || tempState.phone == "") {
          this.setState({ errorphone: "* Must have a phone number for the business contact." })
          hasError = true;
        } else {
          this.setState({ errorphone: "" })
        }
    
        if (tempState.email == null || tempState.email == "" || validateEmail(tempState.email) == false) {
          this.setState({ erroremail: "* Please input a valid email for the business contact." })
          hasError = true;
        } else {
          this.setState({ erroremail: "" })
        }*/

    if (hasError) {
      return;
    } else {
      //Insert new business in the database
      let dataToSend = {
        name: tempState.businessName,
        address: tempState.businessAddress,
        city: tempState.businessCity,
        employees: tempState.employees,
        contactName: tempState.contactName,
        phone: tempState.phone,
        email: tempState.email,
        intersection: tempState.businessIntersection,
        businessNo: tempState.businessNumber,
        global: true,
      };
      console.log(dataToSend);
      api()
        .post("/business", dataToSend)
        .then(() => {
          console.log("successfully saved Data");
          this.setState({
            isLoading: false,
            ...this.defaultBusinessState,
            alert: (
              <SweetAlert
                success
                title="Business successfully created"
                onConfirm={this.hideAlert}
              />
            ),
          });

        })
        .catch((err) => {
          console.log("Error setting documents", err);
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                warning
                title={`Error creating business, ${err.message}`}
                onConfirm={this.hideAlert}
              />
            ),
          });
        });
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  render() {
    return (
      <div className="content">
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
            <Row>
              <Col md="12">
                <Card className="no-transition">
                  <CardHeader>
                    <CardTitle tag="h4">Create New Global Business</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form action="/" className="form-horizontal" method="get">
                      <Row>
                        <Label sm="2">* Business Name</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              value={this.state.businessName}
                              onChange={(e) => this.setState({ businessName: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorbusinessName}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Label sm="2">* Business Number</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="number"
                              autoComplete="off"
                              value={this.state.businessNumber}
                              onChange={(e) => this.setState({ businessNumber: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorbusinessNumber}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Label sm="2">Business Address</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              value={this.state.businessAddress}
                              onChange={(e) => this.setState({ businessAddress: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {/*this.state.errorbusinessAddress*/}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Label sm="2">Closer Street Intersection</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              value={this.state.businessIntersection}
                              onChange={(e) => this.setState({ businessIntersection: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {/*this.state.errorbusinessAddress*/}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Label sm="2">Business City</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              value={this.state.businessCity}
                              onChange={(e) => this.setState({ businessCity: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {/*this.state.errorbusinessCity*/}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                      <Label sm="2">Number of Employees</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="number"
                              autoComplete="off"
                              value={this.state.employees}
                              onChange={(e) => this.setState({ employees: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                      <Label sm="2">Contact Name</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              value={this.state.contactName}
                              onChange={(e) => this.setState({ contactName: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Label sm="2">Contact Phone Number</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="number"
                              autoComplete="off"
                              value={this.state.phone}
                              onChange={(e) => this.setState({ phone: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {/*this.state.errorphone*/}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Contact Email</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="email"
                              autoComplete="off"
                              value={this.state.email}
                              onChange={(e) => this.setState({ email: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {/*this.state.erroremail*/}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="9"></Col>
                        <Col sm="3">
                          <Button color="success" onClick={() => this.handleSubmit()}>
                            Create Business
                        </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        {this.state.alert}
      </div>
    );
  }
}

export default CreateNewBusiness;

import React, { useState } from "react";
import { Modal, Button } from "@mui/material";
import { Input, Label } from "reactstrap";
import PropTypes from "prop-types";
export default function EditAppointmentSlot({ onClose, open, totalAllowed, onUpdate }) {
  const [allowed, setAllowed] = useState(totalAllowed);

  return (
    <Modal
      onClose={onClose}
      open={open}
      style={{ justifyContent: "center", display: "flex", alignItems: "center" }}
    >
      <div style={{ width: "500px", backgroundColor: "#fff", padding: "40px" }}>
        <h2>Edit Appointment Slot?</h2>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Label>Total Allowed</Label>
          <Input type="text" value={allowed} onChange={(e) => setAllowed(e.target.value)} />
        </div>
        <div className="justify-content-between d-flex">
          <Button
            variant="contained"
            onClick={() => {
              onUpdate(parseInt(allowed));
            }}
          >
            Okay
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

EditAppointmentSlot.propTypes = {
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  open: PropTypes.bool,
};

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Users
import AllUsers from "views/Agency/AllUsers/AllUsers.jsx";
import CreateNewUser from "views/Agency/AllUsers/UserProfile/CreateNewUser/CreateNewUser";
import ShiftRequests from "views/Agency/ShiftRequests/ShiftRequests.jsx";
import LiveSelectedUsers from "views/Agency/LiveSelectedUsers/LiveSelectedUsers.jsx";
import UserProfile from "views/Agency/AllUsers/UserProfile.jsx";
import ChangePassword from "views/Agency/AllUsers/ChangePassword.jsx";
import AllCheques from "views/Agency/AllCheques/AllCheques.jsx";
import ExportDatabase from "views/Agency/AllUsers/ExportDatabase.jsx";
import AppliedWorkers from "views/Agency/AllUsers/AppliedWorkers/AppliedWorkers";
// import AdvancedUserSearch from "views/Agency/AllUsers/AdvancedUserSearch.jsx";

//Business
import AllBusinesses from "views/Agency/AllBusinesses/AllBusinesses.jsx";
import CreateNewBusiness from "views/Agency/AllBusinesses/CreateNewBusiness.jsx";
import BusinessProfile from "views/Agency/AllBusinesses/BusinessProfile.jsx";
import BusinessSearch from "views/Agency/AllBusinesses/BusinessSearch.jsx";
import BusinessShiftRequests from "views/Agency/ShiftRequests/BusinessShiftRequests.jsx";
import AllSalesLeads from "views/Agency/AllBusinesses/AllSalesLeads.jsx";
import CreateNewSalesLead from "views/Agency/AllBusinesses/CreateNewSalesLead.jsx";
import SalesLeadProfile from "views/Agency/AllBusinesses/SalesLeadProfile.jsx";
import CreateNewBusinessStaff from "views/Agency/AllBusinesses/AllBusinessStaff/CreateNewStaff.jsx";
import BusinessStaffProfile from "views/Agency/AllBusinesses/AllBusinessStaff/StaffProfile.jsx";
import AllBusinessStaff from "views/Agency/AllBusinesses/AllBusinessStaff/AllStaff.jsx";

// Staff
import AllStaff from "views/Agency/AllStaff/AllStaff.jsx";
import StaffProfile from "views/Agency/AllStaff/StaffProfile.jsx";
import StaffBusinessProfile from "views/Agency/AllBusinesses/BusinessStaffProfile";
import CreateNewStaff from "views/Agency/AllStaff/CreateNewStaff.jsx";
import EmployeeStatistics from "views/Agency/AllStaff/EmployeeStatistics.jsx";
import EmployeeStatisticsDetails from "views/Agency/AllStaff/EmployeeStatisticsDetails.jsx";
import StaffStatistics from "views/Agency/AllStaff/StaffStatistics.jsx";
import ManagerProfile from "views/Agency/AllStaff/ManagerProfile.jsx";
import CreateCallCentreStaff from "views/Agency/AllStaff/CallCentreStaff/CreateCallCentreStaff";
import AllCallCentreStaff from "views/Agency/AllStaff/CallCentreStaff/AllCallCentreStaff";
import CallCentreStaffProfile from "views/Agency/AllStaff/CallCentreStaff/CallCentreStaffProfile";

//Drivers
import CreateNewDriver from "views/Agency/AllDrivers/CreateNewDriver.jsx";
import AllDrivers from "views/Agency/AllDrivers/AllDrivers.jsx";
import DriverProfile from "views/Agency/AllDrivers/DriverProfile.jsx";
import AllAssignedWorkers from "views/Agency/AllDrivers/AllAssignedWorkers";
import ExportRides from "views/Agency/AllDrivers/ExportRides";

//Shifts
import CreateNewShift from "views/Agency/AllShifts/CreateNewShift.jsx";
import AllShifts from "views/Agency/AllShifts/AllShifts.jsx";
import ShiftProfile from "views/Agency/AllShifts/ShiftProfile.jsx";

// //NOtifications
import CreateNotification from "views/Agency/CreateNotifications/CreateNotification.jsx";
import ViewNotifications from "views/Agency/CreateNotifications/ViewNotifications.jsx";
import ViewReplies from "views/Agency/CreateNotifications/ViewReplies.jsx";

import CreateManualNotification from "views/Agency/CreateNotifications/CreateManualNotification";

//Training
import CreateNewTraining from "views/Agency/AllTrainings/CreateNewTraining.jsx";
import AllTrainings from "views/Agency/AllTrainings/AllTrainings.jsx";
import EditTraining from "views/Agency/AllTrainings/EditTraining.jsx";
///training PermissionModuleEnum

//Qualifications
import CreateNewQualification from "views/Agency/AllQualifications/CreateNewQualification.jsx";
import AllQualifications from "views/Agency/AllQualifications/AllQualifications.jsx";
import QualificationProfile from "views/Agency/AllQualifications/QualificationProfile.jsx";

//live business
import LiveBusiness from "views/Agency/LiveBusiness/LiveBusiness.jsx";
// import LiveShifts from "views/Agency/LiveBusiness/LiveBusinessShift.jsx";
import AllLiveShiftWorkers from "views/Agency/LiveBusiness/AllLiveShiftWorkers.jsx";

//Cheques
import ChequeIssues from "views/Agency/AllCheques/ChequeIssues.jsx";
import ChequeIssueHistory from "views/Agency/AllCheques/ChequeIssueHistory.jsx";
import ChequePickup from "views/Agency/AllCheques/ChequePickup.jsx";
import ChequePickupHistory from "views/Agency/AllCheques/ChequePickupHistory.jsx";

//apointments
import ChequePickUpBookingCalendar from "views/Agency/AllAppointments/AllChequePickupCalendar.jsx";
import RecruiterStatistics from "../../views/Agency/AllStaff/RecruiterStatistics";

//Analytics
import AgencyAnalytics from "views/Agency/AllAnalytics/AgencyWorkersAnalytics.jsx";
//import AgencyChequeAnalytics from "views/Agency/AllAnalytics/AgencyChequeAnalytics.jsx";
//import AgencyMessageAnalytics from "views/Agency/AllAnalytics/AgencyMessageAnalytics.jsx";
import AllMessages from "../../views/Agency/AllChat/AllMessages";
import QrSignIn from "views/Agency/QRSignIn/QRSignIn";

import BugReport from "views/Agency/BugReport/AllReport";
import { PermissionActionEnum } from "views/Agency/AllStaff/permissionTemplate";
import { PermissionModuleEnum } from "views/Agency/AllStaff/permissionTemplate";
import QRScanner from "views/Agency/QRSignIn/QRScanner";
import ChequePickupSettings from "views/Agency/AllAppointments/ChequePickupSettings";

// Payroll section
import AllTimeSheets from "views/Agency/PayrollSection/AllTimeSheets";
import PayrollSettings from "views/Agency/PayrollSection/PayrollSettings";
import PayrollCheque from "views/Agency/PayrollSection/PayrollCheque";
import ViewAllAppointments from "views/Agency/AllAppointments/ViewAllAppointments";
import BookAnAppointment from "views/Agency/AllAppointments/BookAnAppointment";

export const routes = [
  {
    path: "/live-business",
    name: "Live Business",
    icon: "nc-icon nc-bank",
    layout: "/admin",
    component: LiveBusiness,
    hidden: false,
    access: ["Admin", "Owner"], //default access
    module: PermissionModuleEnum.LiveBusiness, //required to check permission
    action: [PermissionActionEnum.View],
  },
  {
    path: "/all-live-shift-workers",
    name: "All Live Shift Workers",
    mini: "All",
    component: AllLiveShiftWorkers,
    layout: "/admin",
    hidden: true,
    access: ["Admin", "Owner"],
    module: PermissionModuleEnum.SelectForWork,
    action: [PermissionActionEnum.View],
  },
  // {
  //   path: "/all-live-shifts",
  //   name: "All Workers",
  //   mini: "All",
  //   component: LiveShifts,
  //   layout: "/admin",
  //   hidden: true,
  //   access: ["Admin", "Owner"],
  //   module: PermissionModuleEnum.Users,
  //   action: [PermissionActionEnum.View],
  // },

  {
    path: "/live-selected-users",
    name: "Selected Workers",
    icon: "nc-icon nc-tv-2",
    layout: "/admin",
    component: LiveSelectedUsers,
    hidden: false,
    access: ["Admin", "Owner"], //default access
    module: PermissionModuleEnum.SelectForWork, //required to check permission
    action: [PermissionActionEnum.View],
  },

  {
    collapse: true,
    name: "Workers",
    icon: "nc-icon nc-single-02",
    state: "usersCollapse",
    access: ["Admin", "Owner"],
    module: PermissionModuleEnum.Users, //required to check permission
    action: [PermissionActionEnum.View],
    views: [
      {
        path: "/all-users",
        name: "All Workers",
        mini: "All",
        component: AllUsers,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Users,
        action: [PermissionActionEnum.View],
      },
      {
        path: "/applied-users",
        name: "Applied Workers",
        appliedWorkers: true,
        mini: "All",
        component: AppliedWorkers,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Users,
        action: [PermissionActionEnum.View],
      },
      // {
      //   path: "/advanced-user-search",
      //   name: "Advanced Search",
      //   mini: "View",
      //   component: AdvancedUserSearch,
      //   layout: "/admin",
      //   hidden: false,
      //   access: ["Admin", "Owner"],
      //   module: PermissionModuleEnum.AdvancedWorkerSearch,
      //   action: [PermissionActionEnum.View],
      // },

      // Export not working correctly due to the new database structure (where sin is stored)
      // {
      //   path: "/export-database",
      //   name: "Export Selected Workers",
      //   mini: "View",
      //   component: ExportDatabase,
      //   layout: "/admin",
      //   hidden: false,
      //   access: ["Admin", "Owner"],
      //   module: PermissionModuleEnum.AdvancedWorkerSearch,
      //   action: [PermissionActionEnum.View],
      // },
      {
        path: "/create-new-user",
        name: "Register New Worker",
        mini: "New",
        component: CreateNewUser,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Users,
        action: [PermissionActionEnum.Create],
      },
      {
        path: "/user-profile/:id",
        name: "Profile",
        mini: "Usr",
        component: UserProfile,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner", "Recruiter"],
        module: PermissionModuleEnum.Users,
        action: [PermissionActionEnum.Edit],
      },
      // {
      //   path: "/business-shift-request",
      //   name: "Business Shift Requests",
      //   mini: "Req",
      //   layout: "/admin",
      //   component: BusinessShiftRequests,
      //   hidden: false,
      //   access: ["Admin", "Owner"],
      //   module: PermissionModuleEnum.Shifts,
      //   action: [PermissionActionEnum.View],
      // },
      // {
      //   path: "/shift-request",
      //   name: "Shift Requests",
      //   mini: "Req",
      //   layout: "/admin",
      //   component: ShiftRequests,
      //   hidden: false,
      //   access: ["Admin", "Owner"],
      //   module: PermissionModuleEnum.Shifts,
      //   action: [PermissionActionEnum.View],
      // },
      /** REMOVED, DEPRECIATED AND UNUSED
      {
        path: "/user-change-password",
        name: "Change Password",
        mini: "CP",
        component: ChangePassword,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Users,
        action: [PermissionActionEnum.Edit],
      },
      */
      // {
      //   path: "/cheques",
      //   name: "All Cheques",
      //   mini: "che",
      //   component: AllCheques,
      //   layout: "/admin",
      //   hidden: true,
      //   access: ["Admin", "Owner"],
      //   module: PermissionModuleEnum.Users,
      //   action: [PermissionActionEnum.View],
      // },
    ],
  },
  ,
  //Creating shifts

  {
    collapse: true,
    name: "Shifts",
    icon: "nc-icon nc-money-coins",
    state: "shiftsCollapse",
    access: ["Admin", "Owner"],
    module: PermissionModuleEnum.Shifts,
    action: [PermissionActionEnum.View],
    views: [
      {
        path: "/all-shifts",
        name: "All Shifts",
        mini: "All",
        component: AllShifts,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Shifts,
        action: [PermissionActionEnum.View],
      },
      {
        path: "/create-shift",
        name: "Create New Shift",
        mini: "New",
        layout: "/admin",
        component: CreateNewShift,
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Shifts,
        action: [PermissionActionEnum.Create],
      },
      {
        path: "/shift-profile/:id",
        name: "View Shift Profile",
        mini: "View",
        layout: "/admin",
        component: ShiftProfile,
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Shifts,
        action: [PermissionActionEnum.Edit],
      },
    ],
  },
  {
    collapse: true,
    name: "Notification",
    icon: "nc-icon nc-chat-33",
    state: "createNotificationCollapse",
    access: ["Admin", "Owner"],
    module: PermissionModuleEnum.Notification,
    action: [PermissionActionEnum.View],
    views: [
      {
        path: "/create-notification",
        name: "Create New Notification",
        mini: "NEW",
        component: CreateNotification,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Notification,
        action: [PermissionActionEnum.Create],
      },
      /** REMOVED, FUNCTIONAL BUT UNUSED
      {
        path: "/create-manual-sms",
        name: "Create Manual Notification",
        mini: "NEW",
        component: CreateManualNotification,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Notification,
        action: [PermissionActionEnum.Create],
      },
      */
      {
        path: "/view-notifications",
        name: "View Notifications Sent",
        mini: "VIEW",
        component: ViewNotifications,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Notification,
        action: [PermissionActionEnum.View],
      },
      {
        path: "/viewReplies/:id",
        name: "View Notifications Sent",
        mini: "VIEW",
        component: ViewReplies,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Notification,
        action: [PermissionActionEnum.View],
      },
    ],
  },
  {
    collapse: true,
    name: "Appointments",
    appointments: true,
    icon: "nc-icon nc-calendar-60",
    state: "appointmentsCollapse",
    access: ["Admin", "Owner"],
    module: PermissionModuleEnum.Appointments,
    action: [PermissionActionEnum.View],
    views: [
      // {
      //   path: "/view-all-appointments",
      //   name: "View All Appointments",
      //   mini: "VIEW",
      //   component: ViewAllAppointments,
      //   layout: "/admin",
      //   hidden: false,
      //   access: ["Admin", "Owner"],
      //   module: PermissionModuleEnum.Appointments,
      //   action: [PermissionActionEnum.View],
      // },
      {
        path: "/cheque-pickup-calendar",
        name: "Cheque Pickup Appointments",
        mini: "NEW",
        component: ChequePickUpBookingCalendar,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Appointments,
        action: [PermissionActionEnum.View],
      },
      // Settings page is only used to initialize appointments. Edit non functional
      {
        path: "/cheque-pickup-settings",
        name: "Appointment Settings",
        mini: "NEW",
        component: ChequePickupSettings,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Appointments,
        action: [PermissionActionEnum.View],
      },
      // {
      //   path: "/book-an-appointment",
      //   name: "Book An Appointment",
      //   mini: "VIEW",
      //   component: BookAnAppointment,
      //   layout: "/admin",
      //   hidden: false,
      //   access: ["Admin", "Owner"],
      //   module: PermissionModuleEnum.Appointments,
      //   action: [PermissionActionEnum.View],
      // },
    ],
  },
  {
    path: "/all-messages",
    name: "Chat",
    icon: "nc-icon  nc-send",
    chatBadge: true,
    layout: "/admin",
    component: AllMessages,
    hidden: false,
    access: ["Admin", "Owner", "Staff"], //default access
    module: PermissionModuleEnum.Messages, //required to check permission
    action: [PermissionActionEnum.View],
  },
  {
    collapse: true,
    name: "Cheques",
    icon: "nc-icon nc-money-coins",
    state: "chequesCollapse",
    access: ["Admin", "Owner"],
    action: [PermissionActionEnum.View],
    module: PermissionModuleEnum.Cheque,
    views: [
      // {
      //   path: "/all-cheques",
      //   name: "All Cheques",
      //   mini: "AC",
      //   component: AllCheques,
      //   layout: "/admin",
      //   hidden: false,
      //   access: ["Admin", "Owner"],
      //   module: PermissionModuleEnum.Cheque,
      //   action: [PermissionActionEnum.View],
      // },
      // {
      //   path: "/create-cheques",
      //   name: "Create New Cheque",
      //   mini: "CNC",
      //   component: CreateNewCheque,
      //   layout: "/admin",
      //   hidden: false,
      //   access: ["Admin", "Owner"],
      //   module: PermissionModuleEnum.Cheque,
      //   action: [PermissionActionEnum.Create],
      // },
      // {
      //   path: "/cheque-requests",
      //   name: "Cheque Requests",
      //   mini: "$",
      //   component: ChequesRequests,
      //   layout: "/admin",
      //   hidden: false,
      //   access: ["Admin", "Owner"],
      //   module: PermissionModuleEnum.Cheque,
      //   action: [PermissionActionEnum.View],
      // },

      {
        path: "/cheque-issues",
        name: "Cheque Issues",
        mini: "CI",
        component: ChequeIssues,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Cheque,
        action: [PermissionActionEnum.View],
      },
      {
        path: "/cheque-requests",
        name: "Cheque Requests",
        mini: "CR",
        component: ChequePickup,
        layout: "/admin",
        hidden: false,

        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Cheque,
        action: [PermissionActionEnum.View],
      },
      /** REMOVED, DEPRECIATED AND UNUSED
      {
        path: "/cheques",
        name: "All Cheques",
        mini: "che",
        component: AllCheques,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Cheque,
        action: [PermissionActionEnum.View],
      },
      */
      // {
      //   path: "/cheque-issues",
      //   name: "Cheque Issues",
      //   mini: "CI",
      //   component: ChequeIssues,
      //   layout: "/admin",
      //   hidden: false,

      //   access: ["Admin", "Owner"],
      //   module: PermissionModuleEnum.Cheque,
      //   action: [PermissionActionEnum.View],
      // },
      {
        path: "/cheque-request-history",
        name: "Cheque Request History",
        mini: "CRH",
        component: ChequePickupHistory,
        layout: "/admin",
        hidden: false,

        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Cheque,
        action: [PermissionActionEnum.View],
      },
      {
        path: "/cheque-issue-history",
        name: "Cheque Issues History",
        mini: "CRH",
        component: ChequeIssueHistory,
        layout: "/admin",
        hidden: false,

        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Cheque,
        action: [PermissionActionEnum.View],
      },
    ],
  },
  {
    collapse: true,
    name: "Businesses",
    icon: "nc-icon nc-briefcase-24",
    state: "businessesCollapse",
    access: ["Admin", "Owner"],
    module: PermissionModuleEnum.Business,
    action: [PermissionActionEnum.View],
    views: [
      {
        path: "/all-businesses",
        name: "All Businesses",
        mini: "ALL",
        component: AllBusinesses,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Business,
        action: [PermissionActionEnum.View],
      },
      {
        path: "/create-new-business",
        name: "Create New Business",
        mini: "NEW",
        component: CreateNewBusiness,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Business,
        action: [PermissionActionEnum.Create],
      },
      {
        path: "/business-profile/:id",
        name: "Profile",
        mini: "Usr",
        component: BusinessProfile,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Business,
        action: [PermissionActionEnum.Edit],
      },
      /** REMOVED, DEPRECIATED AND UNUSED
      {
        path: "/business-profile",
        name: "View Business Profile",
        mini: "View",
        component: BusinessSearch,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Business,
        action: [PermissionActionEnum.View],
      },
      */
      {
        path: "/staff-business-profile/:id",
        name: "View Staff Profile",
        mini: "View",
        component: StaffBusinessProfile,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        action: [PermissionActionEnum.Edit],
      },
      /** REMOVED, DEPRECIATED AND UNUSED
      {
        path: "/business/all-sales-lead",
        name: "All Sales Lead",
        mini: "All",
        component: AllSalesLeads,
        layout: "/admin",
        hidden: false,
        module: PermissionModuleEnum.BusinessSales,
        access: ["Admin", "Owner"],
        action: [PermissionActionEnum.View],
      },
      {
        path: "/business/create-new-sales-lead",
        name: "Create New Sales Lead",
        mini: "New",
        component: CreateNewSalesLead,
        layout: "/admin",
        hidden: false,
        module: PermissionModuleEnum.BusinessSales,
        access: ["Admin", "Owner"],
        action: [PermissionActionEnum.Create],
      },
      {
        path: "/business/sale-lead-profile/:id",
        name: "Sale Lead Profile",
        mini: "View",
        component: SalesLeadProfile,
        layout: "/admin",
        hidden: true,
        module: PermissionModuleEnum.BusinessSales,
        access: ["Admin", "Owner"],
        action: [PermissionActionEnum.Edit],
      },
      */
      {
        path: "/create-business-staff",
        name: "Create New Business Staff",
        mini: "View",
        component: CreateNewBusinessStaff,
        layout: "/admin",
        hidden: false,
        module: PermissionModuleEnum.BusinessStaff,
        access: ["Admin", "Owner"],
        action: [PermissionActionEnum.Create],
      },
      {
        path: "/business-staff-profile/:id",
        name: "Business Staff Profile",
        mini: "View",
        component: BusinessStaffProfile,
        layout: "/admin",
        hidden: true,
        module: PermissionModuleEnum.BusinessStaff,
        access: ["Admin", "Owner"],
        action: [PermissionActionEnum.Edit],
      },
      {
        path: "/all-business-staff",
        name: "All Business Staff",
        mini: "View",
        component: AllBusinessStaff,
        layout: "/admin",
        hidden: false,
        module: PermissionModuleEnum.BusinessStaff,
        access: ["Admin", "Owner"],
        action: [PermissionActionEnum.View],
      },
    ],
  },
  {
    collapse: true,
    name: "Agency Staff",
    icon: "nc-icon nc-single-02",
    state: "staffCollapse",
    access: ["Admin", "Owner"],
    module: PermissionModuleEnum.Staff,
    action: [PermissionActionEnum.View],
    views: [
      {
        path: "/all-staff",
        name: "All Staff",
        mini: "ALL",
        component: AllStaff,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Staff,
        action: [PermissionActionEnum.View],
      },

      {
        path: "/create-new-staff",
        name: "Create New Staff",
        mini: "New",
        component: CreateNewStaff,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Staff,
        action: [PermissionActionEnum.Create],
      },
      {
        path: "/all-call-centre-staff",
        name: "All Call Centre Staff",
        mini: "ALL",
        component: AllCallCentreStaff,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Staff,
        action: [PermissionActionEnum.View],
      },
      {
        path: "/create-new-call-centre-staff",
        name: "Create New Call Centre Staff",
        mini: "New",
        component: CreateCallCentreStaff,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Staff,
        action: [PermissionActionEnum.Create],
      },
      {
        path: "/staff-profile/:id",
        name: "View Staff Profile",
        mini: "View",
        component: StaffProfile,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Staff,
        action: [PermissionActionEnum.Edit],
      },
      {
        path: "/call-centre-staff-profile/:id",
        name: "View Call Centre Staff Profile",
        mini: "View",
        component: CallCentreStaffProfile,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Staff,
        action: [PermissionActionEnum.Edit],
      },
      // Logs have been disabled due to performance issues. Need to restore. TODO FIX
      {
        path: "/staff-analytics/:id",
        name: "View Staff Statistics",
        mini: "View",
        component: StaffStatistics,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Staff,
        action: [PermissionActionEnum.View],
      },
      // Logs have been disabled due to performance issues. Need to restore. TODO FIX
      {
        path: "/employee-statistics",
        name: "Employee Stats",
        mini: "ES",
        component: EmployeeStatistics,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Staff,
        action: [PermissionActionEnum.EmployeeStats],
      },
      // Logs have been disabled due to performance issues. Need to restore. TODO FIX
      {
        path: "/employee-details-statistics",
        name: "Employee Stats",
        mini: "ES",
        component: EmployeeStatisticsDetails,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Staff,
        action: [PermissionActionEnum.EmployeeStats],
      },
      /** REMOVED, DEPRECIATED AND UNUSED
      {
        path: "/recruiter-statistics",
        name: "Recruiter Stats",
        mini: "ES",
        component: RecruiterStatistics,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Log,
        action: [PermissionActionEnum.View],
      },
      */

      // {
      //   path: "/create-new-manager",
      //   name: "Create New Manager",
      //   mini: "New",
      //   component: CreateNewManager,
      //   layout: "/admin",
      //   hidden: true,
      //   access: ["Admin", "Owner"],
      // },
      // {
      //   path: "/manager-profile/:id",
      //   name: "View Manager Profile",
      //   mini: "View",
      //   component: ManagerProfile,
      //   layout: "/admin",
      //   hidden: true,
      //   access: ["Admin", "Owner"],
      // },
    ],
  },
  {
    collapse: true,
    name: "Agency Drivers",
    icon: "nc-icon nc-single-02",
    state: "driverCollapse",
    access: ["Admin", "Owner"],
    module: PermissionModuleEnum.Driver,
    action: [PermissionActionEnum.View],
    views: [
      {
        path: "/all-assigned-workers",
        name: "All Asssigned Workers",
        mini: "ALL",
        component: AllAssignedWorkers,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Driver,
        action: [PermissionActionEnum.View],
      },
      {
        path: "/export-all-rides",
        name: "Export All Rides",
        mini: "ALL",
        component: ExportRides,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Driver,
        action: [PermissionActionEnum.View],
      },
      {
        path: "/all-drivers",
        name: "All Drivers",
        mini: "ALL",
        component: AllDrivers,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Driver,
        action: [PermissionActionEnum.View],
      },
      {
        path: "/create-new-driver",
        name: "Create New Driver",
        mini: "New",
        component: CreateNewDriver,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Driver,
        action: [PermissionActionEnum.Create],
      },
      {
        path: "/driver-profile/:id",
        name: "View Driver Profile",
        mini: "View",
        component: DriverProfile,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Driver,
        action: [PermissionActionEnum.Edit],
      },
    ],
  },
  {
    collapse: true,
    name: "Trainings",
    icon: "nc-icon nc-single-02",
    state: "trainingCollapse",
    access: ["Admin", "Owner"],
    module: PermissionModuleEnum.Training,
    action: [PermissionActionEnum.View],
    views: [
      {
        path: "/all-trainings",
        name: "All Trainings",
        mini: "ALL",
        component: AllTrainings,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Training,
        action: [PermissionActionEnum.View],
      },
      {
        path: "/create-new-training",
        name: "Create New Training",
        mini: "New",
        component: CreateNewTraining,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Training,
        action: [PermissionActionEnum.Create],
      },
      {
        path: "/edit-training/:id",
        name: "Edit Training",
        mini: "New",
        component: EditTraining,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Training,
        action: [PermissionActionEnum.Edit],
      },
    ],
  },
  {
    collapse: true,
    name: "Qualifications",
    icon: "nc-icon nc-hat-3",
    state: "qualificationCollapse",
    access: ["Admin", "Owner"],
    module: PermissionModuleEnum.Qualification,
    action: [PermissionActionEnum.View],
    views: [
      {
        path: "/all-qualifications",
        name: "All Qualifications",
        mini: "ALL",
        component: AllQualifications,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Qualification,
        action: [PermissionActionEnum.View],
      },
      {
        path: "/create-new-qualification",
        name: "Create New Qualification",
        mini: "New",
        component: CreateNewQualification,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Qualification,
        action: [PermissionActionEnum.Create],
      },
      {
        path: "/qualification-profile/:id",
        name: "View Qualification Profile",
        mini: "View",
        component: QualificationProfile,
        layout: "/admin",
        hidden: true,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Qualification,
        action: [PermissionActionEnum.Edit],
      },
    ],
  },
  /** REMOVED, UNUSED DUE TO BUGS. TODO FIX
  {
    collapse: true,
    name: "Analytics",
    icon: "nc-icon nc-chart-bar-32",
    state: "analyticsCollapse",
    access: ["Admin", "Owner"],
    module: PermissionModuleEnum.Analytics,
    action: [PermissionActionEnum.View],
    views: [
      {
        path: "/agency-analytics",
        name: "Agency Analytics",
        mini: "View",
        component: AgencyAnalytics,
        layout: "/admin",
        hidden: false,
        access: ["Admin", "Owner"],
        module: PermissionModuleEnum.Analytics,
        action: [PermissionActionEnum.View],
      },
      // {
      //   path: "/bug-report",
      //   name: "Bug Report",
      //   mini: "View",
      //   component: BugReport,
      //   layout: "/admin",
      //   hidden: false,
      //   access: ["Admin", "Owner"],
      //   module: PermissionModuleEnum.Analytics,
      //   action: [PermissionActionEnum.View],
      // },
    ],
  },
   */

  // Payroll is non-functioning. Sign in/out is unused
  // {
  //   collapse: true,
  //   name: "Payroll",
  //   icon: "nc-icon nc-money-coins",
  //   state: "payrollCollapse",
  //   access: ["Admin", "Owner"],
  //   module: PermissionModuleEnum.Payroll,
  //   action: [PermissionActionEnum.View],
  //   views: [
  //     {
  //       path: "/payroll-timesheets",
  //       name: "All Timesheets",
  //       mini: "View",
  //       component: AllTimeSheets,
  //       layout: "/admin",
  //       hidden: false,
  //       access: ["Admin", "Owner"],
  //       module: PermissionModuleEnum.Payroll,
  //       action: [PermissionActionEnum.View],
  //     },
  //     {
  //       path: "/payroll-settings",
  //       name: "Payroll Settings",
  //       mini: "View",
  //       component: PayrollSettings,
  //       layout: "/admin",
  //       hidden: false,
  //       access: ["Admin", "Owner"],
  //       module: PermissionModuleEnum.Payroll,
  //       action: [PermissionActionEnum.SettingsView],
  //     },
  //     // {
  //     //   path: "/payroll-cheque/:id",
  //     //   name: "Payroll Cheque",
  //     //   mini: "View",
  //     //   component: PayrollCheque,
  //     //   layout: "/admin",
  //     //   hidden: false,
  //     //   access: ["Admin", "Owner"],
  //     //   module: PermissionModuleEnum.Payroll,
  //     //   action: [PermissionActionEnum.SettingsView],
  //     // },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Sign in / Sign out",
  //   icon: "nc-icon nc-watch-time",
  //   state: "SignInCollapse",
  //   access: ["Admin", "Owner"],
  //   module: PermissionModuleEnum.SignIn,
  //   action: [PermissionActionEnum.Create],
  //   views: [
  //     {
  //       path: "/qr-scanner",
  //       name: "QR Code Scanner",
  //       mini: "New",
  //       component: QRScanner,
  //       layout: "/admin",
  //       hidden: false,
  //       access: ["Admin", "Owner"],
  //       module: PermissionModuleEnum.SignIn,
  //       action: [PermissionActionEnum.Create],
  //     },
  //     /** REMOVED, UNUSED. NOT SURE WHAT THIS IS FOR
  //     {
  //       path: "/sign-in-sign-out",
  //       name: "Sign In / Sign out",
  //       mini: "New",
  //       component: QrSignIn,
  //       layout: "/admin",
  //       hidden: false,
  //       access: ["Admin", "Owner"],
  //       module: PermissionModuleEnum.SignIn,
  //       action: [PermissionActionEnum.Create],
  //     },
  //     */
  //   ],
  // },
];

export default routes;

import React, { useContext, useEffect } from "react";
import AllPlans from "../plans/AllPlans";
// reactstrap components

import RegisterContext from "context/RegisterContext";

function SelectPlan(props) {
  const registerContext = useContext(RegisterContext);

  useEffect(() => {
    console.log(registerContext.registration);
    if (!registerContext.registration.user || !registerContext.registration.agency) {
      props.history.push("/staffing/register/register-admin");
    }
    registerContext.registrationDispatch({
      type: "SET_PROGRESS_STEP",
      payload: { progressBarIndex: 2 },
    });
  }, []);

  const naviateToRegisterCheckout = (plan) => {
    if (!plan) {
      return;
    } else {
      registerContext.registrationDispatch({
        type: "SET_PLAN",
        payload: { plan: plan },
      });
      props.history.push("/staffing/register/checkout");
    }
  };

  return (
    <>
      <AllPlans onSelect={naviateToRegisterCheckout} />
    </>
  );
}

export default SelectPlan;

import React, { useEffect, useRef, useState } from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function ExcelExport({ dataset, download, filename, setDownload }) {
  const downloadButtonRef = useRef(null);

  useEffect(() => {
    if (download) {
      document.getElementById("button").click();
      setDownload(false);
    }
  }, [download]);
  return (
    <ExcelFile
      element={
        <button style={{ display: "none" }} ref={downloadButtonRef} id="button">
          Download Data
        </button>
      }
      filename={filename}
    >
      {dataset.map((d, i) => {
        return <ExcelSheet dataSet={[d]} name={d.title} key={i} />;
      })}
    </ExcelFile>
  );
}

import React, { useEffect, useContext, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner as ReactSpinner,
} from "reactstrap";
import Background from "../../../assets/img/agency/charles-forerunner-3fPXt37X6UQ-unsplash.jpg";
import api from "../../../components/API/api";
import AgencyContext from "../../../context/AgencyContext";

function Login(props) {
  const { agency, agencyDispatch } = useContext(AgencyContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [marginTop, setMarginTop] = useState("calc(50vh - 230px)");
  const [slug, setSlug] = useState(null);

  useEffect(() => {
    const slug = props.match.params.slug.toLowerCase();
    setSlug(slug);
  }, []);

  const processLoginData = ({ user, token }) => {
    const profile = user.profile.find(
      (p) =>
        (p.role === "Admin" ||
          p.role === "Owner" ||
          p.role === "AgencyAdmin" ||
          p.role === "Supervisor" ||
          p.role === "Recruiter" ||
          p.role === "CallCentreStaff" ||
          p.role === "Staff") &&
        (p.agency.slug === slug || p.agency._id === (agency && agency._id)),
    );

    if (profile && profile.agency && profile.agency._id) {
      let agencyId = profile.agency._id;

      localStorage.setItem("Token", token);
      localStorage.setItem("AgencyId", agencyId);
      agencyDispatch({ type: "SET_USER", payload: { user: user } });
      agencyDispatch({
        type: "SET_PROFILE",
        payload: { profile: profile },
      });
      props.history.push(`/${slug}/admin/all-users`);
    } else {
      noSuccessAlert("Invalid email or password, please try again.");
    }
  };

  const buttonClick = async () => {
    setLoading(true);
    try {
      if (!agency?.agency) {
        throw new Error("No agency data found. Most likely this is a network issue.");
      }
      const send = {
        email,
        password,
        agency: agency.agency._id,
      };

      const { data } = await api().post("/users/agency/login", send);
      processLoginData(data);
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
      noSuccessAlert("Invalid email or password, please try again.");
    }
    setLoading(false);
  };

  const keyPress = (e) => {
    if (e.key === "Enter") {
      buttonClick();
    }
  };

  //What : Sent an Alert if the sign in is not successful
  //Input : No input required.
  //Output : Alert
  const noSuccessAlert = (input) => {
    setAlert(
      <ReactBSAlert
        style={{ display: "block" }}
        title="Login Error"
        onConfirm={() => setAlert(null)}
        onCancel={hideAlert}
        confirmBtnBsStyle="info"
      >
        {input}
      </ReactBSAlert>,
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    window.addEventListener("resize", windowCheck);

    // cleanup listners
    return () => {
      window.removeEventListener("resize", windowCheck);
    };
  }, []);

  //What : Changes the marginTop property for the Login Component to ensure a dynamically centered component
  //Input : No input required.
  //Output : Changes marginTop property in state
  const windowCheck = () => {
    if (window.innerHeight > 550) {
      setMarginTop("calc(50vh - 230px)");
    } else {
      setMarginTop("50px");
    }
  };

  /**
   *
   * @returns {string}: Beautified string of name from match.params.slug
   */
  const beautifyName = () => {
    let nameCleaned = props.match.params.slug.replace(/[^A-Z0-9]/gi, " ");
    let nameBeautified = nameCleaned.replace(/(\b[a-z](?!\s))/g, function (x) {
      return x.toUpperCase();
    });
    return nameBeautified;
  };

  return (
    <div className="login-page">
      <div
        className="full-page-background"
        style={{
          backgroundColor: "rgb(221,221,221)",
          backgroundImage: `url(${Background})`,
          zIndex: 2,
        }}
      />

      <Container>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col className="ml-auto mr-auto" lg="4" md="6" xs="11">
            <Form
              style={{
                marginTop: marginTop,
                fontFamily: "Trebuchet MS, Helvetica, sans-serif",
              }}
              className="form"
            >
              {loading ? (
                <div style={{ position: "fixed", top: "50%", left: "50%" }}>
                  <ReactSpinner className="text-center" type="border" color="secondary" size="6" />
                </div>
              ) : (
                <Card className="card-login no-transition">
                  <CardHeader style={{ height: "80px", paddingTop: "10px" }}>
                    <CardHeader>
                      <h3 className="header text-center">Login</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        style={{
                          fontFamily: "Trebuchet MS, Helvetica, sans-serif",
                        }}
                        placeholder="Email"
                        type="email"
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        value={password}
                        onKeyPress={(e) => keyPress(e)}
                        style={{
                          fontFamily: "Trebuchet MS, Helvetica, sans-serif",
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                    <br />
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="info"
                      onClick={buttonClick}
                      style={{ backgroundColor: "rgb(30,30,30)", width: "100%" }}
                    >
                      Login
                    </Button>
                  </CardFooter>
                </Card>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
      {alert}
    </div>
  );
}

export default Login;

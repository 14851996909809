export const firstLetterUpperCase = (objs) => {
  String.prototype.toUpperCaseFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

  if (objs instanceof Array) {
    return objs.map((obj) => {
      return firstLetterUpperCase(obj); //recursive function
    });
  } else if (typeof objs === "string") {
    return objs.toUpperCaseFirstLetter();
  } else {
    let newObj = {};
    for (let prop in objs) {
      if (typeof objs[prop] === "string") {
        newObj[prop] = firstLetterUpperCase(objs[prop]);
      } else {
        newObj[prop] = objs[prop];
      }
    }
    return newObj;
  }
};

//Convert every obj element to lowercase if it has string
export const toLowerCase = (obj) => {
  let newObj = {};
  for (let prop in obj) {
    //console.log(prop, obj[prop]);
    if (typeof obj[prop] === "string") {
      newObj[prop] = obj[prop].toLowerCase();
    } else {
      newObj[prop] = obj[prop];
    }
  }
  return newObj;
};

export const toUpperCase = (obj) => {
  String.prototype.toUpperCaseFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };
  let newObj = {};
  for (let prop in obj) {
    //console.log(prop, obj[prop]);
    if (typeof obj[prop] === "string") {
      newObj[prop] = obj[prop].toUpperCaseFirstLetter();
    } else {
      newObj[prop] = obj[prop];
    }
  }
  return newObj;
};

export const provinceOptions = [
  { value: "Alberta", label: "Alberta" },
  { value: "British Columbia", label: "British Columbia" },
  { value: "Manitoba", label: "Manitoba" },
  { value: "New Brunswick", label: "New Brunswick" },
  {
    value: "Newfoundland and Labrador",
    label: "Newfoundland and Labrador",
  },
  {
    value: "Northwest Territories",
    label: "Northwest Territories",
  },
  { value: "Nova Scotia", label: "Nova Scotia" },
  { value: "Nunavut", label: "Nunavut" },
  { value: "Ontario", label: "Ontario" },
  {
    value: "Prince Edward Island",
    label: "Prince Edward Island",
  },
  { value: "Quebec", label: "Quebec" },
  { value: "Saskatchewan", label: "Saskatchewan" },
  { value: "Yukon", label: "Yukon" },
];

export const workStatusOptions = [
  {
    value: 0,
    label: "Permanent Residence/Citizen",
  },
  { value: 1, label: "Work Permit" },
  {
    value: 2,
    label: "Study Permit",
  },
];

export const DocumentType = {
  id: 0,
  workPermit: 1,
  sin: 2,
  studyPermit: 3,
  sinApplicationProof: 4,
  signature: 5,
}


export const DocumentStatus = {
  pending: 0,
  valid: 1,
  invalid: 2,
};

import axios from "axios";

export const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://staffing-api.cjtechnologies.ca"
    : "https://staffing-api.cjtechnologies.ca";
//: "https://staffing-dev-node.herokuapp.com"; // Dev DB you can connect with to avoid running backend

const timeout = 15 * 60 * 1000; //min

const api = (config = { headers: { client: "browser" } }, token = localStorage.getItem("Token")) => {
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + token,
      AgencyId: localStorage.getItem("AgencyId"),
    };
  }
  const customAxios = axios.create({
    baseURL,
    timeout: 4500000,
    ...config,
  });
  customAxios.interceptors.response.use(
    (response) => {
      if (response?.headers?.token) {
        localStorage.setItem("_expire", Date.now() + timeout);
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return customAxios;
};

// const api = (
//   config = { headers: { client: "browser" } }, //add
//   token = localStorage.getItem("Token"),
// ) => {
//   //const config = {};
//   if (token) {
//     config.headers = {
//       ...config.headers,
//       Authorization: "Bearer " + token,
//       AgencyId: localStorage.getItem("AgencyId"),
//     };
//   }

//   const customAxios = axios.create({
//     baseURL,
//     timeout: 4500000,
//     ...config,
//   });
//   customAxios.interceptors.response.use(
//     (response) => {
//       // console.log(response.data);
//       // console.log(response);
//       if (response?.headers?.token) {
//         // console.log(JSON.stringify(response.headers.token));
//         localStorage.setItem("Token", response.headers.token);
//         localStorage.setItem("_expire", Date.now() + timeout);
//       }
//       return response;
//     },
//     function (error) {
//       // console.log(error.response);
//       // localStorage.setItem("Token");
//       // window.location.href = "/staffing/login";
//       return Promise.reject(error);
//     },
//   );
//   return customAxios;
// };

export const useEventSource = (url, updateData) => {
  let newBaseUrl = baseURL.endsWith("/") ? baseURL.slice(0, -1) : baseURL;
  let newUrl = newBaseUrl.concat(url);
  const source = new EventSource(newUrl);

  source.onmessage = function logEvents(event) {
    updateData(JSON.parse(event.data));
  };
  return source;
};

export default api;

import Invoice from "../../components/Invoice";

import React, { useEffect, useContext } from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

import RegisterContext from "context/RegisterContext";

function RegisterAdmin(props) {
  const registerContext = useContext(RegisterContext);
  document.documentElement.classList.remove("nav-open");
  useEffect(() => {
    document.body.classList.add("register-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
      document.body.classList.remove("full-screen");
    };
  }, []);

  useEffect(() => {
    console.log(registerContext.registration);
    if (
      !registerContext.registration.user ||
      !registerContext.registration.agency ||
      !registerContext.registration.plan ||
      !registerContext.registration.success
    ) {
      props.history.push("/staffing/register/register-admin");
    }
    registerContext.registrationDispatch({
      type: "SET_PROGRESS_STEP",
      payload: { progressBarIndex: 4 },
    });
  }, []);

  return (
    <>
      <div className="section section-pricing cd-section" id="pricing">
        {/* ********* PRICING 2 ********* */}
        <div className="pricing-2">
          <div className="filter" />
          <Container>
            <Row>
              <Col className="ml-auto" lg="6" md="6" sm="7" xs="12">
                <div className="info info-horizontal">
                  <div className="icon">
                    <i className="fa fa-check" />
                  </div>
                  <div className="description">
                    <h3>Registration Successful</h3>
                    <p>Congratulations! Your business has been successfully registered.</p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon">
                    <i className="fa fa-sign-in" />
                  </div>
                  <div className="description">
                    <h3>Login</h3>
                    <p>Please log in to set up your business URL.</p>
                    <Button
                      color="primary"
                      style={{ marginLeft: "5px" }}
                      outline
                      onClick={() => props.history.push("/staffing/login")}
                    >
                      <span className="align-middle ml-50">Login</span>
                    </Button>
                  </div>
                </div>
              </Col>
              <Col className="mr-auto" lg="6" md="6" sm="5" xs="12">
                <Invoice registration={registerContext.registration} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default RegisterAdmin;

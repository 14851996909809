import { Modal } from "@mui/material";
import React, { useState } from "react";
import { Button, FormGroup } from "reactstrap";
import PinComponent from "./PinComponent";

const PinCell = ({ workerId, value, onChange, workerPin, businessList, agencyId }) => {
  const [openPinModal, setOpenPinModal] = useState(false);

  const [workerpin, setWorkerpin] = useState(workerPin);
  return (
    <div className="w-100 h-100">
      {workerPin && workerPin.length > 0 ? (
        <p
          onClick={() => setOpenPinModal(true)}
          className="w-100 h-100"
          style={{ fontWeight: 400, cursor: "pointer" }}
        >
          {value || "-"}
        </p>
      ) : (
        <p
          onClick={() => setOpenPinModal(true)}
          className="w-100 h-100"
          style={{ fontWeight: 400, cursor: "pointer" }}
        ></p>
      )}

      <Modal
        open={openPinModal}
        onClose={() => setOpenPinModal(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex", margin: 20 }}>
              <h5>Worker's Pin</h5>
            </div>

            {workerPin &&
              workerPin.length > 0 &&
              workerPin.map((w) => {
                return (
                  <div key={w._id}>
                    {w ? (
                      <PinComponent
                        workerPin={w}
                        workerId={workerId}
                        onSuccess={(success) => {
                          setOpenPinModal(false);
                          if (success) {
                            onChange();
                          }
                        }}
                      />
                    ) : null}
                  </div>
                );
              })}

            <PinComponent
              workerId={workerId}
              manual={true}
              onSuccess={(success, value) => {
                let updatedValue = value;
                if (success) {
                  setWorkerpin(...workerPin, updatedValue);
                  // this.setState({
                  //   workerPin: [...this.state.workerPin, updatedValue],
                  // });
                  onChange();
                }
              }}
              businessList={businessList}
              agency={agencyId}
              // onSuccess={(success) => {
              //   setOpenPinModal(false);
              //   if (success) {
              //     onChange();
              //   }
              // }}
            />

            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                onClick={() => {
                  setOpenPinModal(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    </div>
  );
};

export default PinCell;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { Clear, Mail, Visibility } from "@mui/icons-material";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDatetime from "react-datetime";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

import { startOfDay, startOfNextDay } from "utils/dateUtils.js";
import api from "components/API/api";

import AgencyContext from "context/AgencyContext";

import { statusEnum } from "utils/statusVariables";
const useStyles = makeStyles({

  root: {
      "& .MuiTableCell-head": {
          fontWeight: 800
      },
  }
});

const AgencyAnalytics = (props) => {
  const classes = useStyles();
  const agencyContext = useContext(AgencyContext);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [filter, setFilter] = useState({
    from: null,
    till: null,
  });
  const [data, setData] = useState({
    downloads: 0,
    activeWorkers: 0,
    trainedWorkers: 0,
    untrainedWorkers: 0,
    shiftApplications: 0,
    uniqueWorkers: 0,
    shiftsFilled: 0,
    chequesGiven: 0,
    completedTraining: 0,
    requiredWorkers: 0,
    workerMessages: 0,
    staffMessages: 0,
  }); 

  const list = [
    { displayName: "Active Workers", key: "activeWorkers", type:  "ActiveWorkers" },
    { displayName: "Trained Workers", key: "trainedWorkers", type:  "TotalTrainees" },
    { displayName: "Untrained Workers", key: "untrainedWorkers", type: "TotalUntrained" },
    { displayName: "Shift Applications ", key: "shiftApplications", type:  "WorkerApplications" },
    { displayName: "Unique Workers (backup/confirmed)", key: "uniqueWorkers", type:  "UniqueWorkers" },
    { displayName: "Shifts Filled", key: "shiftsFilled", type:  "ShiftsFilled" },
    { displayName: "Cheques Given", key: "chequesGiven", type:  "ChequesHandedOut" },
    { displayName: "Workers Completed Training", key: "completedTraining", type:  "ActiveWorkers" },
    { displayName: "Required Workers", key: "requiredWorkers", type:  "RequiredWorkers" },
    { displayName: "Worker Messages", key: "workerMessages", type:  "WorkerMessages" },
    { displayName: "Staff Messages", key: "staffMessages", type:  "AgencyMessages" },
  ];

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if(filter.from && filter.till){
      const { data } = await api().get("/analytics/analytic-totals", {
        params: {
          from: startOfDay(filter.from),
          till: startOfNextDay(filter.till) 
        },
      });
      // console.log("DATA", data);
      setData(transformRawData(data));   
    }
    setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const transformRawData = (analytics) => {
    const tempData = data;
    list.map(listItem=>{
      const total = analytics.find(a=>a?._id?.type == listItem.type)?.total;
      // console.log("total", total);
      if(total) tempData[listItem.key] = total
    })
    return  tempData;
  };

  //Loading Overlay
  const hideAlert = () => {
    setAlert(null);
  };

 

  return (
    <div className="content">
      {isLoading && <LoadingOverlay />}
      <Row>
        <Col md="12">
          <FormGroup></FormGroup>
          <Card className="no-transition">
            <CardHeader>
              <Row>
                <Col md="10">
                  <CardTitle tag="h4">Agency Analytics</CardTitle>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row className="mt-1 justify-content-center align-items-center">
              <Col md="2"></Col>
                <Col md="3">
                  <FormGroup>
                    <Label>Start date</Label>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Date Picker Here",
                      }}
                      timeFormat={false}
                      value={filter.from}
                      onChange={(startDate) => {
                        setFilter({ ...filter, from: startDate });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label>End date</Label>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Date Picker Here",
                      }}
                      timeFormat={false}
                      value={filter.till}
                      onChange={(endDate) => {
                        setFilter({ ...filter, till: endDate });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <Button color="info" onClick={() => fetchData()}>
                    Generate
                  </Button>
                </Col>
                <Col md="2"></Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" md="12">
                  <Card className="card-plain card-subcategories">
                    <CardBody>
                      <Row md="12">
                        <Col md="3"></Col>

                        <Col md="6">
                          <TableContainer component={Paper}>
                            <Table size="medium" aria-label="simple table">
                              <TableHead>
                                <TableRow className={classes.root}>
                                  <TableCell>Category</TableCell>
                                  <TableCell align="right">Total</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {list.map((listItem) => (
                                  <TableRow
                                    key={listItem.key}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {listItem.displayName}
                                    </TableCell>
                                    <TableCell align="right">{data[`${listItem.key}`]}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Col>
                        <Col md="3"></Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {alert}
    </div>
  );
};

export default AgencyAnalytics;

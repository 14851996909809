import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";

export default function CartDetails(props) {
  const subTotal = props.price;
  const taxes = (subTotal * 13) / 100;
  const orderTotal = subTotal + taxes;

  return (
    <Container className="cart-details-container">
      <Container className="mt-4">
        <Row>
          <Col xs="9">
            <h6>Subtotal: </h6>
          </Col>
          <Col xs="3">
            <h6>${subTotal}</h6>
          </Col>
        </Row>
        <Row>
          <Col xs="9">
            <h6>Taxes:</h6>
          </Col>
          <Col xs="3">
            <h6>${taxes}</h6>
          </Col>
        </Row>
        <Row>
          <Col xs="9">
            <h6>Total: </h6>
          </Col>
          <Col xs="3">
            <h6>${orderTotal}</h6>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Input, Container, Row, Col, Card, CardTitle } from "reactstrap";
import { STRIPE_PUBLIC_KEY } from "../../../constants";
import "../../../assets/css/credit-card.css";
import Cards from "./CardUI.jsx";
import PaymentForm from "./PaymentForm";

import { loadStripe } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export function Payment(props) {
  const [card, setCard] = useState({ cvc: "", expiry: "", focus: "", name: "", number: "" });

  useEffect(() => {
    //props.setCard(card);
  }, [card]);

  return (
    <div className="container-fluid payment-btn-group">
      <div>
        <FormGroup>
          <Container>
            <Row>
              <Col>
                <Cards
                  cvc={card.cvc}
                  expiry={card.expiry}
                  focused={card.focus}
                  name={card.name}
                  number={card.number}
                  callback={(e, d) => {
                    console.log(e);
                    console.log("callback");
                  }}
                  onChange={(value) => console.log(value)}
                />
              </Col>
              <Col>
                <Elements stripe={stripePromise}>
                  <PaymentForm setCard={setCard} card={card} confirm={props.confirm} />
                </Elements>
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </div>
    </div>
  );
}

export default Payment;

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Card, CardTitle, Form, Input, Container, Row, Col, Label, CardBody } from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import { Spinner as ReactSpinner } from "reactstrap";

import api from "components/API/api";
import StaffingContext from "context/StaffingContext";
// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import { useEffect } from "react";

function ForgotPage(props) {

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
      document.body.classList.remove("full-screen");
    };
  });

  const staffingContext = useContext(StaffingContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [loginData, setLoginData] = useState({});
  const [loginResponse, setLoginResponse] = useState(null)

  // useEffect(() => {
  //   if (localStorage.Token) {
  //     localStorage.Token = null;
  //     staffingContext.staffingDispatch({ type: "REMOVE_USER" });
  //   }
  // }, []);

  const forgotSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await api().post("/users/request/reset-password", loginData);
      setLoginResponse(data)
      setAlert(
        <SweetAlert
          title="Email was sent!"
          onConfirm={() => { props.history.push(`/staffing/login`) }} />
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
      setAlert(
        <SweetAlert
          error
          title="Your Login credentials are incorrect. Please try again."
          onConfirm={() => setAlert(null)}
        />,
      );
    }
  };


  const loginForm = () => {
    return (
      <Row>
        <Col className="ml-auto mr-auto" lg="4" md="6" sm="10">
          <Card className="card-register no-transition">
            <CardTitle tag="h3">Forgot password</CardTitle>
            <CardBody>
              <p className={"font-color"} style={{ marginTop: "8px" }}>Please enter your account email below and tap the
              "Reset password" button. A email will be sent with a link to reset your password.</p>
              <Form className="register-form">
                <label>Email</label>
                <Input
                  className="no-border"
                  placeholder="Email"
                  type="email"
                  name="email"
                  onChange={(e) => {
                    setLoginData({ ...loginData, email: e.target.value });
                  }}
                />
                <Button block className="btn-round btn-primary" onClick={forgotSubmit}>
                  Reset password
                      </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }


  return (
    <>
      <ColorNavbar />
      <div className="wrapper">
        <div
          className="page-header"
          style={{
            backgroundImage: "url(" + require("assets/img/sections/bruno-abatti.jpg") + ")",
          }}
        >
          <div className="filter" />
          <Container>
            {loginForm()}
            <div className="demo-footer text-center mt-5" style={{ position: "relative" }}>
              <h6>
                © {new Date().getFullYear()}, made with <i className="fa fa-heart heart" /> by
                Creative Tim
              </h6>
            </div>
          </Container>
        </div>
      </div>
      {alert}
    </>
  );
}

export default ForgotPage;

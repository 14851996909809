import Error404 from "../pages/Error404";
import { Modal } from "@mui/material";
import {
  Add,
  CheckBox,
  Clear,
  Comment,
  IndeterminateCheckBox,
  Refresh,
  Visibility,
  DirectionsRun,
} from "@mui/icons-material";
import api from "components/API/api";
import CustomCalendar from "components/Calendar/CustomCalendar";
import ImageDisplay from "components/ImageDisplay";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import Permission from "components/permissions/Permission";
import AgencyContext from "context/AgencyContext";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDatetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import QrReader from "react-qr-reader";
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactTable from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Button as MButton } from "@mui/material";
import FormText from "reactstrap/lib/FormText";
import { startOfDay, startOfNextDay } from "utils/dateUtils";
import "./timeSlotCounter.css";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Edit, Delete } from "@mui/icons-material";
import DeleteAppointmentSlot from "./modals/DeleteAppointmentSlot";
import EditAppointmentSlot from "./modals/EditAppointmentSlot";

const CAMERA_TIME = 20; // seconds that the QR scanner is active
const appointmentStatus = {
  pending: "pending",
  ready: "ready",
  processing: "processing",
  success: "success",
  missed: "missed",
};

const DocumentType = {
  id: 0,
  workPermit: 1,
  sin: 2,
  studyPermit: 3,
  sinApplicationProof: 4,
  signature: 5,
};

function NoTimeSpecified() {
  return (
    <Card className="no-transition">
      <CardHeader>
        <CardTitle>No Time Slots available for the day</CardTitle>
      </CardHeader>
      <CardBody></CardBody>
    </Card>
  );
}

function AllTimeSlots({ date }) {
  const [timeSlots, setTimeSlots] = useState([]);
  const [businessList] = useState([]);
  let _selected = false;
  const agencyContext = useContext(AgencyContext);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(
    localStorage.getItem("slotDate") === date ? JSON.parse(localStorage.getItem("slot")) : null,
  );
  const [alert, setAlert] = useState(null);

  const fetchAllTimeSlots = async function (date) {
    let { data } = await api().get("/appointment-slot/cheque-pickup", {
      params: {
        from: new Date(moment(date).startOf("day")),
        till: new Date(moment(date).endOf("day")),
        agencyId: agencyContext.agency.agency._id,
      },
    });
    // console.log(data);
    data.sort((a, b) => {
      return new Date(a.start) - new Date(b.start);
    });
    setTimeSlots(data);
    _selected = false;
  };

  useEffect(() => {
    fetchAllTimeSlots(date);
  }, [date]);

  const hideAlert = () => {
    setAlert(null);
  };

  return timeSlots.length > 0 ? (
    <>
      <Row>
        <Col lg="3" md="3" sm="8" xs="12">
          <Card className="no-transition">
            <CardHeader>
              <CardTitle>All Time Slots</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="d-flex flex-column" style={{ overflowY: "scroll", height: "70vh" }}>
                {timeSlots.map((slot) => {
                  let selected = false;
                  // console.log('selectedTimeSlot', selectedTimeSlot);
                  if (selectedTimeSlot) {
                    if (selectedTimeSlot._id === slot._id) {
                      selected = true;
                      _selected = true;
                    }
                  } else {
                    let currentTime = moment();
                    if (currentTime.isBetween(slot.start, slot.end)) {
                      setSelectedTimeSlot(slot);
                      localStorage.setItem("slot", JSON.stringify(slot));
                      _selected = true;
                    }
                  }

                  return (
                    <div
                      className={`d-flex justify-content-between p-2 ${
                        selected ? "bg-primary text-white" : ""
                      }`}
                      key={slot._id}
                      onClick={() => {
                        console.log("clicked");
                        setSelectedTimeSlot(slot);
                        localStorage.setItem("slot", JSON.stringify(slot));
                      }}
                      style={{ cursor: "pointer", borderBottom: "1px solid rgb(193 193 193)" }}
                    >
                      <p>
                        {moment(slot.start).format("hh:mm")}-{moment(slot.end).format("hh:mm a")}
                      </p>
                      <div className="d-flex">
                        <div className={`numberCircle ${selected ? "bg-primary" : ""}`}>
                          {slot.noRegistered}/{slot.noPeopleAllowed}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* if different date and the time doesn't match current time then just select the first time slot */}
                {(() => {
                  if (selectedTimeSlot === null && !_selected) {
                    timeSlots.length && timeSlots.length > 0 && setSelectedTimeSlot(timeSlots[0]);
                  }
                })()}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="9" md="9" sm="8" xs="12">
          <Card className="no-transition">
            <CardBody>
              <AllUsers
                timeSlot={selectedTimeSlot}
                onUpdate={(title, body) => {
                  fetchAllTimeSlots(date);
                  setAlert(
                    <SweetAlert
                      success
                      title={title}
                      onConfirm={() => {
                        hideAlert();
                      }}
                      showConfirm={true}
                    >
                      {body}
                    </SweetAlert>,
                  );
                }}
                onError={(title, body) => {
                  setAlert(
                    <SweetAlert error title={title} onConfirm={hideAlert} showConfirm={true}>
                      {body}
                    </SweetAlert>,
                  );
                }}
                businessList={businessList}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {alert}
    </>
  ) : (
    <NoTimeSpecified />
  );
}
AllTimeSlots.propTypes = {
  date: PropTypes.object,
};

function AllUsers({ timeSlot, onUpdate, onError, businessList }) {
  const [usersData, setUsersData] = useState([]);
  const agencyContext = useContext(AgencyContext);
  const [isLoading, setIsLoading] = useState(false);
  // const [facingMode, setFacingMode] = useState("user");
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const initModal = {
    commentModal: false,
    confirmMissedAppointmentModal: false,
    openSecondModal: false,
    chequeIssueModal: false,
    verifySignatureModal: false,
    chequePickupModal: false,
    verifyUserModal: false,
    deleteModal: false,
  };
  const [openModal, setOpenModal] = useState(initModal);
  const [comment, setComment] = useState("");
  const [pickedByOther, setPickedByOther] = useState(false);
  const [pickedByOwner, setPickedByOwner] = useState(false);
  const [business, setBusiness] = useState(null);
  const [weekStart, setWeekStart] = useState(null);
  const [weekEnd, setWeekEnd] = useState(null);
  const [errorLocation, setErrorLocation] = useState("");
  const [errorWeekStart, setErrorWeekStart] = useState("");
  const [errorWeekEnd] = useState("");
  const [errorComment] = useState("");
  const [deletionReason, setDeletionReason] = useState("");
  const [errorDeletion, setErrorDeletion] = useState("");
  const lastScanned = useRef(new Date());
  const scanned = useRef([]);
  const [images, setImages] = useState(null); // Images for worker to verify
  // cheque picked up by other = > other details
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    relation: "",
  });
  const [errorDetails, setErrorDetails] = useState({
    firstName: "",
    lastName: "",
    relation: "",
  });
  const [agree, setAgree] = useState(false);

  const [openDeleteSlot, setOpenDeleteSlot] = useState(false);
  const [openEditSlot, setOpenEditSlot] = useState(false);

  /**
   * Deletes appointment slot
   */
  const deleteAppointmentSlot = async () => {
    setOpenDeleteSlot(false);
    try {
      await api().delete(`/appointment-slot/${timeSlot._id}`);
      onUpdate("Success!", "Successfully Deleted the Time Slot");
    } catch (e) {
      onError("Error Deleting!", e.response?.data?.message || e.message);
    }
  };

  const updateAppointmentSlot = async (noPeopleAllowed) => {
    setOpenEditSlot(false);
    try {
      await api().patch(`/appointment-slot/${timeSlot._id}`, { noPeopleAllowed });
      onUpdate("Success!", "Successfully updated the Time Slot");
    } catch (e) {
      onError("Error Updating!", e.response?.data?.message || e.message);
    }
  };

  const handleCloseCommentModal = () => {
    setOpenModal(initModal);
    setComment("");
    setSelectedAppointment(null);
  };

  const handleCloseChequeIssueMOdal = () => {
    setOpenModal(initModal);
    setSelectedAppointment(null);
  };

  const handleCloseChequePickupModal = () => {
    setOpenModal(initModal);
    setSelectedAppointment(null);
    setComment("");
    setAgree(false);
    setImages(null);
  };

  const handleCloseMissedAppointmentModal = () => {
    setOpenModal(initModal);
    setSelectedAppointment(null);
  };

  const handleCloseDeleteModal = () => {
    setOpenModal(initModal);
    setSelectedAppointment(null);
    setErrorDeletion("");
    setDeletionReason("");
  };

  const handleCloseVerifySignatureModal = () => {
    setOpenModal(initModal);
    setImages(null);
    setSelectedAppointment(null);
    setComment("");
  };

  const handleCloseVerifyChequePickupModal = () => {
    setDetails({
      firstName: "",
      lastName: "",
      relation: "",
    });
    setErrorDetails({
      firstName: "",
      lastName: "",
      relation: "",
    });

    setOpenModal(initModal);
    setSelectedAppointment(null);
    setComment(null);
    setPickedByOther(false);
    setPickedByOwner(false);
  };

  const viewCommentsModal = () => {
    if (!selectedAppointment) return null;
    return (
      <Modal
        open={openModal.commentModal}
        onClose={handleCloseCommentModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>{`Admin notes for ${selectedAppointment.fullName}'s appointment`}</h5>
            </div>
            <div
              style={{ justifyContent: "space-between", display: "flex", flexDirection: "column" }}
            >
              <textarea
                name="adminNotes"
                cols="40"
                rows="5"
                autoComplete="off"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={handleCloseCommentModal}
              >
                Cancel
              </Button>{" "}
              <Button
                color="primary"
                onClick={() => {
                  if (!comment) {
                    // console.log("no comment");
                    return;
                  }
                  api()
                    .patch("/appointment-slot/cheque-pickup/comment", {
                      slot: timeSlot._id,
                      user: selectedAppointment.user,
                      comment: comment,
                    })
                    .then(() => {
                      handleCloseCommentModal();
                      onUpdate(
                        "SuccessFully",
                        `Successfully Added Comment for ${selectedAppointment.fullName}`,
                      );
                      getUsers(selectedAppointment._id);
                    })
                    .catch((e) => {
                      onError("Error adding comment", e.response?.data?.message || e.message);
                    });
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  function confirmMissedAppointmentModal() {
    if (!selectedAppointment) return null;
    return (
      <Modal
        open={openModal.confirmMissedAppointmentModal}
        onClose={handleCloseMissedAppointmentModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                {`Change status of ${selectedAppointment.fullName}'s appointment as Missed ?`}
              </h5>
            </div>

            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={handleCloseMissedAppointmentModal}
              >
                Cancel
              </Button>

              <Button
                color="primary"
                onClick={async () => {
                  setOpenModal({ openSecondModal: true });
                }}
              >
                Mark Appointment as Missed
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  }

  function openSecondModal() {
    return (
      <Modal
        open={openModal.openSecondModal}
        onClose={() => setOpenModal(initModal)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 400, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Confirm marking the Appointment as Missed? This action cannot be undone</h5>
            </div>

            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={handleCloseMissedAppointmentModal}
              >
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={async () => {
                  const dataToSend = {
                    slotId: timeSlot._id,
                    userId: selectedAppointment.user,
                  };

                  try {
                    await api().post("/appointment-slot/cheque-pickup/missed", dataToSend);
                    onUpdate("SuccessFull", `Marked user missed the appointment`);
                    getUsers(selectedAppointment._id);
                  } catch (e) {
                    onError("Error changing status", e.response?.data?.message || e.message);
                  }
                  handleCloseMissedAppointmentModal();
                }}
              >
                Mark Appointment as Missed
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  }

  const viewChequeIssueModal = () => {
    if (!selectedAppointment) return null;
    return (
      <Modal
        open={openModal.chequeIssueModal}
        onClose={handleCloseChequeIssueMOdal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>{`Move status of ${selectedAppointment.fullName}'s appointment to cheque issues?`}</h5>
            </div>
            <div
              style={{ justifyContent: "space-between", display: "flex", flexDirection: "column" }}
            >
              <Row>
                <Label sm="4">* Location</Label>
                <Col sm="8">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={business}
                      onChange={(value) => setBusiness(value)}
                      id="dayOfWeek"
                      options={businessList}
                      placeholder="Select a location"
                    />
                    <FormText color="danger" tag="span">
                      {errorLocation}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Label sm="4">* Start of Week</Label>
                <Col sm="8">
                  <FormGroup>
                    <ReactDatetime
                      timeFormat={false}
                      value={weekStart}
                      onChange={(value) => {
                        setWeekStart(value);
                      }}
                    />
                    <FormText color="danger" tag="span">
                      {errorWeekStart}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Label sm="4">* End of Week</Label>
                <Col sm="8">
                  <FormGroup>
                    <ReactDatetime
                      timeFormat={false}
                      value={weekEnd}
                      onChange={(value) => {
                        setWeekEnd(value);
                      }}
                    />
                    <FormText color="danger" tag="span">
                      {errorWeekEnd}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={handleCloseChequeIssueMOdal}
              >
                Cancel
              </Button>{" "}
              <Button
                color="primary"
                onClick={async () => {
                  let error = false;
                  if (!weekStart) {
                    error = true;
                    setErrorWeekStart("Must enter week start.");
                  }
                  if (!weekEnd) {
                    error = true;
                    setErrorWeekStart("Must enter week end.");
                  }
                  if (!business?.value) {
                    setErrorLocation("Must select a business!");
                    error = true;
                  }

                  if (error) return;
                  try {
                    await api().patch("/appointment-slot/cheque-pickup/status", {
                      slot: timeSlot._id,
                      user: selectedAppointment.user,
                      status: appointmentStatus.chequeIssues,
                    });

                    await api().post("/cheque-issues/admin", {
                      user: selectedAppointment.user,
                    });

                    onUpdate(
                      "SuccessFully",
                      `Successfully added status to cheque picked up for ${selectedAppointment.fullName}`,
                    );
                    getUsers(selectedAppointment._id);
                  } catch (e) {
                    onError("Error changing status", e.response?.data?.message || e.message);
                  }
                  handleCloseChequeIssueMOdal();
                }}
              >
                Change
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const verifyUserSignatureModal = () => {
    if (!selectedAppointment) return null;
    // console.log(selectedAppointment);
    return (
      selectedAppointment && (
        <Modal
          open={openModal.verifySignatureModal}
          onClose={handleCloseVerifySignatureModal}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "scroll",
            paddingTop: 80,
            paddingBottom: 20,
          }}
        >
          {isLoading ? (
            <LoadingOverlay />
          ) : (
            <FormGroup style={{ width: 800, backgroundColor: "#fff", borderRadius: 10 }}>
              <div style={{ padding: 15 }}>
                {/* <div style={{ justifyContent: "space-between", display: "flex" }}> */}
                <Row
                  md="12"
                  style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}
                >
                  <Col md="10">
                    <h5 style={{ fontWeight: "bold" }}>
                      {selectedAppointment.proof
                        ? "Verify the worker signature"
                        : "Please wait for worker to upload signature"}
                    </h5>
                  </Col>
                  <Col md="2">
                    <Button
                      onClick={() => {
                        // fetch signature proof
                        getUsers(selectedAppointment._id);
                      }}
                      color="default"
                      size="md"
                      className="btn"
                      outline
                    >
                      <Refresh style={{ marginLeft: -1 }} />
                    </Button>
                  </Col>
                </Row>
                {/* </div> */}
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    padding: 30,
                  }}
                >
                  <Row>
                    <Label
                      md="2"
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      * Notes
                    </Label>
                    <Col md="10">
                      <FormGroup>
                        <textarea
                          name="adminNotes"
                          cols="40"
                          rows="3"
                          autoComplete="off"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                        <FormText color="danger" tag="span">
                          {errorComment}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                {selectedAppointment?.pickupBy?.owner ? (
                  <div style={{ justifyContent: "center", padding: 30 }}>
                    <h6 style={{ textAlign: "center" }}>Picked Up By Owner</h6>

                    <Row
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Full Name: {selectedAppointment?.fullName}
                    </Row>
                  </div>
                ) : (
                  <div style={{ justifyContent: "center", padding: 30 }}>
                    <h6 style={{ textAlign: "center" }}>Picked Up By Other</h6>
                    <Row
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Full Name:
                      {selectedAppointment?.pickupBy?.firstName +
                        " " +
                        selectedAppointment?.pickupBy?.lastName}
                    </Row>
                    <Row>Relationship: {selectedAppointment?.pickupBy?.relationship}</Row>
                  </div>
                )}

                <Row>{images?.map((doc) => doc.img && <ImageDisplay document={doc} />)}</Row>
                <span>* please click on image to enlarge</span>

                <Row style={{ marginTop: 30 }}>
                  <Col>
                    <FormGroup check inline>
                      <Label check>
                        <Input type="checkbox" checked={agree} onChange={() => setAgree(!agree)} />
                        <span className="form-check-sign" />I agree that the signature is verified
                        and that the cheque has been issued.
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <div style={{ justifyContent: "space-between", display: "flex" }}>
                  <Button
                    color="danger"
                    style={{ marginRight: "12px" }}
                    onClick={async () => {
                      try {
                        await api().post("/appointment-slot/cheque-pickup/sign/approve", {
                          slot: timeSlot._id,
                          user: selectedAppointment.user,
                          approve: false,
                        });
                        onUpdate(
                          "Not Verified",
                          `Successfully rejected signature for ${selectedAppointment.fullName}`,
                        );
                        getUsers(selectedAppointment._id);
                      } catch (e) {
                        onError("Error occured", e.response?.data?.message || e.message);
                      }
                      handleCloseChequePickupModal();
                    }}
                  >
                    Not Verified/ Reject
                  </Button>{" "}
                  <Button
                    color="success"
                    disabled={!agree}
                    onClick={async () => {
                      try {
                        if (comment !== selectedAppointment.note) {
                          await api().patch("/appointment-slot/cheque-pickup/comment", {
                            slot: timeSlot._id,
                            user: selectedAppointment.user,
                            comment: comment,
                          });
                        }
                        await api().post("/appointment-slot/cheque-pickup/sign/approve", {
                          slot: timeSlot._id,
                          user: selectedAppointment.user,
                          approve: true,
                        });

                        await api().post("/cheque-pickup/admin", {
                          user: selectedAppointment.user,
                          pickedUp: true,
                          pickupBy: selectedAppointment.pickupBy,
                          proof: selectedAppointment.proof,
                          note: comment,
                          weekStart: selectedAppointment.weekStart,
                          weekEnd: selectedAppointment.weekEnd,
                          business: selectedAppointment.business,
                        });

                        onUpdate(
                          "SuccessFully",
                          `Successfully marked as cheque pickup for ${selectedAppointment.fullName}`,
                        );
                        getUsers(selectedAppointment._id);
                      } catch (e) {
                        onError("Error changing status", e.response?.data?.message || e.message);
                      }
                      handleCloseChequePickupModal();
                    }}
                  >
                    Verified/Cheque Issued
                  </Button>
                </div>
              </div>
            </FormGroup>
          )}
        </Modal>
      )
    );
  };

  const viewChequePickupModal = () => {
    if (!selectedAppointment) return null;
    return (
      <Modal
        open={openModal.chequePickupModal}
        onClose={handleCloseChequePickupModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                {`Change status of ${selectedAppointment.fullName}'s appointment as Ready for Pickup ?`}
              </h5>
            </div>
            <div style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
              <Row>
                <Label md="2">* Notes</Label>
                <Col md="10">
                  <FormGroup>
                    <textarea
                      name="adminNotes"
                      cols="40"
                      rows="5"
                      autoComplete="off"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                    <FormText color="danger" tag="span">
                      {errorComment}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={handleCloseChequePickupModal}
              >
                Cancel
              </Button>{" "}
              <Button
                color="primary"
                onClick={async () => {
                  try {
                    if (comment !== selectedAppointment.note) {
                      await api().patch("/appointment-slot/cheque-pickup/comment", {
                        slot: timeSlot._id,
                        user: selectedAppointment.user,
                        comment: comment,
                      });
                    }
                    await api().post("/appointment-slot/cheque-pickup/ready-for-pickup", {
                      slotId: timeSlot._id,
                      userId: selectedAppointment.user,
                    });

                    onUpdate(
                      "SuccessFully",
                      `Successfully marked as cheque pickup for ${selectedAppointment.fullName}`,
                    );
                    getUsers(selectedAppointment._id);
                  } catch (e) {
                    onError("Error changing status", e.response?.data?.message || e.message);
                  }
                  handleCloseChequePickupModal();
                }}
              >
                Mark as Ready for Pickup
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const handleError = (err) => {
    console.error(err);
  };

  const verifyChequePickupUserModal = () => {
    if (!selectedAppointment) return null;
    return (
      <Modal
        open={openModal.verifyUserModal}
        onClose={handleCloseVerifyChequePickupModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "scroll",
          paddingTop: 80,
          paddingBottom: 20,
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
              <h5>Who is Picking up Cheque ?</h5>
              <Button
                onClick={handleCloseVerifyChequePickupModal}
                color="default"
                size="md"
                className="btn-link remove btn-icon"
                style={{ marginTop: -4 }}
              >
                <Clear style={{ marginLeft: -1 }} />
              </Button>
            </div>
            <div style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
              <Row>
                <Label md="2">* Notes</Label>
                <Col md="10">
                  <FormGroup>
                    <textarea
                      name="adminNotes"
                      cols="40"
                      rows="5"
                      autoComplete="off"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                    <FormText color="danger" tag="span">
                      {errorComment}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="warning"
                style={{ marginRight: "12px" }}
                onClick={() => {
                  setPickedByOther(true);
                  setPickedByOwner(false);
                }}
              >
                PickUp By Other
              </Button>{" "}
              <Button
                color="success"
                style={{ marginRight: "12px" }}
                onClick={() => {
                  setPickedByOwner(true);
                  setPickedByOther(false);
                }}
              >
                PickUp By Owner
              </Button>{" "}
              <Button
                color="danger"
                style={{ marginRight: "12px" }}
                onClick={() => {
                  // console.log(selectedAppointment);
                  setIsLoading(true);
                  api()
                    .post("/appointment-slot/cheque-pickup/owner", {
                      userId: selectedAppointment.user,
                      slot: timeSlot._id,
                    })
                    .then(() => {
                      onUpdate(
                        "Notification send successfully",
                        `Please wait for worker to upload signature`,
                      );
                      getUsers(selectedAppointment._id);
                      handleCloseVerifyChequePickupModal();
                      setIsLoading(false);
                    })
                    .catch((e) => {
                      console.log(e);
                      setIsLoading(false);
                      handleCloseVerifyChequePickupModal();
                      onError("Worker not found!", e.response?.data?.message || e.message);
                    });
                }}
              >
                Skip Qr Scan
              </Button>{" "}
            </div>
            {pickedByOwner && (
              <div style={{ padding: 15 }}>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <h5>Please scan owner Qr code from app.</h5>
                </div>
                <div style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
                  <QrReader
                    delay={1000}
                    onError={handleError}
                    onScan={(code) => {
                      // console.log(code);
                      if (
                        moment(lastScanned.current).isBefore(
                          moment().subtract(CAMERA_TIME, "second"),
                        )
                      ) {
                        return;
                      }
                      code && readCode(code);
                    }}
                    style={{ width: 500, padding: 35 }}
                    facingMode={"user"}
                  />
                </div>
              </div>
            )}
            {pickedByOther && (
              <div style={{ padding: 15 }}>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <h5>Please Enter details.</h5>
                </div>
                <div>
                  <Row>
                    <Label sm="3">First Name:</Label>
                    <Col sm="9">
                      <FormGroup>
                        <Input
                          type="text"
                          value={details.firstName}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              firstName: e.target.value,
                            });
                            setErrorDetails({
                              ...errorDetails,
                              firstName: "",
                            });
                          }}
                        />
                        <FormText color="danger" tag="span">
                          {errorDetails.firstName}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="3">Last Name:</Label>
                    <Col md="9">
                      <FormGroup>
                        <Input
                          type="text"
                          value={details.lastName}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              lastName: e.target.value,
                            });
                            setErrorDetails({
                              ...errorDetails,
                              lastName: "",
                            });
                          }}
                        />
                        <FormText color="danger" tag="span">
                          {errorDetails.lastName}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="3">Relation:</Label>
                    <Col md="9">
                      <FormGroup>
                        <Input
                          type="text"
                          value={details.relation}
                          onChange={(e) => {
                            setDetails({
                              ...details,
                              relation: e.target.value,
                            });
                            setErrorDetails({
                              ...errorDetails,
                              relation: "",
                            });
                          }}
                        />
                        <FormText color="danger" tag="span">
                          {errorDetails.relation}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button
                    color="success"
                    style={{ marginRight: "12px" }}
                    onClick={() => handleNotification()}
                  >
                    Send Notification
                  </Button>{" "}
                </div>
              </div>
            )}
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const handleNotification = async () => {
    try {
      let hasError = false;
      let errors = errorDetails;
      if (!details.firstName) {
        hasError = true;
        errors.firstName = "* Must enter first name.";
      }
      if (!details.lastName) {
        hasError = true;
        errors.lastName = "* Must enter last name.";
      }
      if (!details.relation) {
        hasError = true;
        errors.relation = "* Must enter relation with owner.";
      }

      if (hasError) {
        setErrorDetails({ ...errors });
        return;
      } else {
        setIsLoading(true);
        const { data } = await api().post("/appointment-slot/cheque-pickup/other", {
          firstName: details.firstName,
          lastName: details.lastName,
          relationship: details.relation,
          user: selectedAppointment.user,
          slot: timeSlot._id,
        });
        // console.log(data);
        onUpdate("Notification send successfully", `Please wait for worker to upload signature`);
        getUsers(selectedAppointment._id);
        handleCloseVerifyChequePickupModal();
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      handleCloseVerifyChequePickupModal();
      onError("Error occured!", e.response?.data?.message || e.message);
    }
  };

  const readCode = async (result) => {
    lastScanned.current = new Date();

    scanned.current = scanned.current.filter((_scanned) =>
      moment(_scanned.time).isAfter(moment().subtract(5, "second")),
    );
    if (scanned.current.find((scan) => scan.code === result)) {
      return;
    }
    scanned.current.push({
      time: new Date(),
      code: result,
    });
    // return;
    // console.log(result);
    try {
      setIsLoading(true);
      lastScanned.current = new Date();
      const { data } = await api().post("/appointment-slot/cheque-pickup/owner", {
        qrcode: result,
        slot: timeSlot._id,
      });
      // console.log(data);
      onUpdate("Notification send successfully", `Please wait for worker to upload signature`);
      getUsers(selectedAppointment._id);
      handleCloseVerifyChequePickupModal();
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      handleCloseVerifyChequePickupModal();
      onError("Worker not found!", e.response?.data?.message || e.message);
    }
  };

  const deleteChequePickupModal = () => {
    if (!selectedAppointment) return null;
    return (
      <Modal
        open={openModal.deleteModal}
        onClose={handleCloseDeleteModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 550, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>{`Delete ${selectedAppointment.fullName}'s appointment?`}</h5>
            </div>
            <div>
              <Row>
                <Col md="3">Reason:</Col>
                <Col md="9">
                  <Input
                    type="textarea"
                    value={deletionReason}
                    onChange={(e) => {
                      setDeletionReason(e.target.value);
                    }}
                  />
                  <p style={{ color: "red" }}>{errorDeletion}</p>
                </Col>
              </Row>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={handleCloseDeleteModal}
              >
                Close
              </Button>{" "}
              <Button
                color="warning"
                onClick={async () => {
                  const dataToSend = {
                    slotId: timeSlot._id,
                    userId: selectedAppointment.user,
                    reason: deletionReason,
                  };
                  if (!deletionReason) {
                    setErrorDeletion("Must enter a reason of appointment cancellation");
                    return;
                  } else {
                    setErrorDeletion("");
                  }
                  try {
                    await api().post("/appointment-slot/cheque-pickup/cancel", dataToSend);
                    onUpdate("SuccessFull", `Successfully removed cheque pickup appointment`);
                    getUsers(selectedAppointment._id);
                  } catch (e) {
                    onError("Error removing appointment", e.response?.data?.message || e.message);
                  }
                  handleCloseDeleteModal();
                }}
              >
                Cancel Appointment
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  //load images of user
  const loadImages = async (userData) => {
    try {
      setIsLoading(true);
      let images = [];
      const { data } = await api().get(`/users/documents?userId=${userData.user}`);
      // console.log(data);
      data.map((doc) => {
        if (doc.type === DocumentType.id || doc.type === DocumentType.signature) {
          console.log(doc);
          images.push({
            id: doc.id,
            img: doc.url,
            displayName: doc.type === DocumentType.id ? "Photo ID" : "Original Signature",
          });
        }
      });

      if (userData.proof && userData.proof.status === "pending") {
        const proof = await api().get(
          `image/sign-s3/get?file-path=signature/${userData.user}/${userData?.proof?.signature}`,
        );
        images.push({ id: "proof", displayName: "Signature Proof", img: proof?.data?.signedUrl });
      }
      // console.log(images);
      setImages([...images]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const RenderActionButtons = (data) => {
    return (
      <div className="actions-center">
        {data.status === appointmentStatus.processing && (
          <Button
            onClick={() => {
              setSelectedAppointment(data);
              setOpenModal({ verifySignatureModal: true });
              setComment(data.note);
              data && loadImages(data);
            }}
            color="success"
            size="md"
            className="btn-link btn-icon"
          >
            <Add style={{ marginLeft: -1 }} />
          </Button>
        )}
        {data.status === appointmentStatus.ready && (
          <Button
            onClick={() => {
              setSelectedAppointment(data);
              setOpenModal({ verifyUserModal: true });
              setComment(data.note);
            }}
            color="success"
            size="md"
            className="btn-link btn-icon"
          >
            <CheckBox style={{ marginLeft: -1 }} />
          </Button>
        )}
        {data.status === appointmentStatus.pending ? (
          <>
            <Button
              onClick={() => {
                setSelectedAppointment(data);
                setOpenModal({ chequePickupModal: true });
                setComment(data.note);
              }}
              color="success"
              size="md"
              className="btn-link btn-icon"
            >
              <IndeterminateCheckBox style={{ marginLeft: -1 }} />
            </Button>
          </>
        ) : null}
        <Button
          onClick={() => {
            setSelectedAppointment(data);
            setOpenModal({ commentModal: true });
            setComment(data.note);
          }}
          color="info"
          size="md"
          className="btn-link remove btn-icon"
        >
          <Comment style={{ marginLeft: -1 }} />
        </Button>
        <Button
          tag={Link}
          to={`/${agencyContext.agency.agency.slug}/admin/user-profile/${data.user}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
        {/* 
        missed button 
        Will display if appointment status is NOT missed or success
        Button is available only if appointmentStart > now
        */}
        {data.status !== appointmentStatus.success && data.status !== appointmentStatus.missed ? (
          <Button
            disabled={!moment().isAfter(timeSlot.start)}
            color={"danger"}
            style={{ backgroundColor: "transparent" }}
            size="md"
            className="btn-link btn-icon"
            onClick={() => {
              setSelectedAppointment(data);
              setOpenModal({ confirmMissedAppointmentModal: true });
            }}
          >
            <DirectionsRun />
          </Button>
        ) : null}

        {data.status === appointmentStatus.pending ? (
          <Permission
            module="Cheque"
            action="Delete"
            error="Not enough permission to perform this action."
          >
            <Button
              onClick={() => {
                setSelectedAppointment(data);
                setOpenModal({ deleteModal: true });
              }}
              color="danger"
              size="md"
              className="btn-link btn-icon"
            >
              <Clear style={{ marginLeft: -1 }} />
            </Button>
          </Permission>
        ) : null}
      </div>
    );
  };

  const getUsers = async function (selectedAppointmentId) {
    // console.log("selectedAppointment", selectedAppointment);
    try {
      let { data } = await api().get("/appointment-slot/cheque-pickup/all-users", {
        params: { slotId: timeSlot._id },
      });
      console.log("data", data);
      setUsersData(
        data.map((data) => {
          data.fullName = data.firstName + " " + data.lastName;
          data.weekStart = data.weekStart ? moment(data.weekStart).format("DD MMM YYYY") : null;
          data.weekEnd = data.weekEnd ? moment(data.weekEnd).format("DD MMM YYYY") : null;
          data.actions = RenderActionButtons(data);
          return data;
        }),
      );
      if (selectedAppointmentId) {
        let tempAppointment = data.find((d) => d._id === selectedAppointmentId);
        if (tempAppointment) {
          setSelectedAppointment(tempAppointment);
          if (tempAppointment.proof && tempAppointment.proof.status === "pending") {
            const proof = await api().get(
              `image/sign-s3/get?file-path=signature/${tempAppointment.user}/${tempAppointment?.proof?.signature}`,
            );
            //  remove if any proof existed before in images and then add
            const tempImages = images.filter((img) => img.id !== "proof");
            setImages([
              ...tempImages,
              { id: "proof", displayName: "Signature Proof", img: proof?.data?.signedUrl },
            ]);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    timeSlot && timeSlot._id && getUsers();
  }, [timeSlot]);

  return (
    <>
      <div className="d-flex justify-content-end">
        <div>
          {/* <MButton>
            <Add />
          </MButton> */}
          <MButton
            onClick={() => {
              setOpenEditSlot(true);
            }}
          >
            <Edit />
          </MButton>
        </div>
        <div>
          <MButton
            onClick={() => {
              setOpenDeleteSlot(true);
            }}
          >
            <Delete />
          </MButton>
        </div>
      </div>
      {usersData.length > 0 ? (
        <>
          <ReactTable
            data={usersData}
            minRows={1}
            showPaginationBottom={false}
            style={{ width: "100%", overflow: "auto" }}
            columns={[
              {
                Header: "hdId",
                accessor: "hdId",
                maxWidth: 70,
              },
              {
                Header: "Name",
                accessor: "fullName",
                maxWidth: 100,
                style: { whiteSpace: "unset" },
              },

              {
                Header: "phone",
                accessor: "phone",
                maxWidth: 100,
              },
              {
                Header: "Email",
                accessor: "email",
                maxWidth: 100,
                style: { whiteSpace: "unset" },
              },
              {
                Header: "Start of Week",
                accessor: "weekStart",
                maxWidth: 80,
                // wrap word
                style: { whiteSpace: "unset" },
              },
              {
                Header: "End of Week",
                accessor: "weekEnd",
                maxWidth: 80,
                style: { whiteSpace: "unset" },
              },
              {
                Header: "Business",
                accessor: "businessName",
                maxWidth: 120,
                style: { whiteSpace: "unset" },
              },
              {
                Header: "Status",
                accessor: "status",
                maxWidth: 80,
              },
              {
                Header: "Actions",
                accessor: "actions",
                width: 240,
                sortable: false,
                filterable: false,
                headerStyle: { textAlign: "center" },
              },
            ]}
            className="-striped -highlight primary-pagination"
            // manual // informs React Table that you'll be handling sorting and pagination server-side
          />
          {viewCommentsModal()}
          {viewChequeIssueModal()}
          {viewChequePickupModal()}
          {confirmMissedAppointmentModal()}
          {openSecondModal()}
          {verifyChequePickupUserModal()}
          {deleteChequePickupModal()}
          {verifyUserSignatureModal()}
        </>
      ) : (
        <div>No user has been booked for this time slot</div>
      )}
      <DeleteAppointmentSlot
        open={openDeleteSlot}
        onClose={() => setOpenDeleteSlot(false)}
        onDelete={deleteAppointmentSlot}
      />
      <EditAppointmentSlot
        open={openEditSlot}
        onClose={() => setOpenEditSlot(false)}
        onUpdate={updateAppointmentSlot}
      />
    </>
  );
}

AllUsers.propTypes = {
  timeSlot: PropTypes.array,
  onUpdate: PropTypes.func,
  onError: PropTypes.func,
  businessList: PropTypes.any,
};

export default function AllChequePickup() {
  const agencyContext = useContext(AgencyContext);
  const history = useHistory();

  const [rules, setRules] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentCount, setAppointmentCount] = useState(0);
  const getAppointmentRule = async function () {
    let { data } = await api().get(`/appointment-rule/${agencyContext.agency.agency._id}`);
    setRules(data);
    setIsLoading(false);
  };

  const countAppointment = async function (date) {
    let { data } = await api().get("/appointment-slot/count", {
      params: {
        from: startOfDay(date),
        till: startOfNextDay(date),
      },
    });
    // console.log("data?.count", data);
    setAppointmentCount(data);
  };

  useEffect(() => {
    setIsLoading(true);
    getAppointmentRule();
  }, []);

  const saveDate = () => {
    const date = new Date();
    localStorage.setItem("slotDate", date);
    return date;
  };

  const [date, setDate] = useState(
    JSON.parse(localStorage.getItem("slot")) ? localStorage.getItem("slotDate") : saveDate(),
  );

  useEffect(() => {
    countAppointment(date);
  }, [date]);
  return (
    <>
      <div className="content">
        {isLoading && <LoadingOverlay />}
        <>
          {rules ? (
            <Card className="no-transition">
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <CardTitle tag="h4">All Appointments</CardTitle>
                  <CardTitle tag="h4">Total: {appointmentCount?.count}</CardTitle>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <CardTitle tag="h5">Pending: {appointmentCount?.pending}</CardTitle>
                  <CardTitle tag="h5">Ready: {appointmentCount?.ready}</CardTitle>
                  <CardTitle tag="h5">Processing: {appointmentCount?.processing}</CardTitle>
                  <CardTitle tag="h5">Success: {appointmentCount?.success}</CardTitle>
                  <CardTitle tag="h5">Missed: {appointmentCount?.missed}</CardTitle>
                </div>
                <CustomCalendar
                  initialDate={date}
                  onChangeDate={(date) => {
                    localStorage.setItem("slotDate", date);
                    setDate(date);
                  }}
                />
              </CardHeader>
              <CardBody>
                <AllTimeSlots date={date} />
              </CardBody>
            </Card>
          ) : (
            <>
              <div>Please initialize rules</div>
              <Button
                onClick={() => {
                  history.push(`/${agencyContext.agency.agency.slug}/admin/cheque-pickup-settings`);
                }}
              >
                Create Appointment Rules
              </Button>
            </>
          )}
        </>
      </div>
    </>
  );
}

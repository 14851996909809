import React, { useState } from "react";
import {
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
  CardHeader,
  Collapse,
  FormGroup
} from "reactstrap";
import { ExpandMore, AddCircleOutline, MoreVert } from "@mui/icons-material";
import "./transition.css";
import { Modal } from "@mui/material";

const Module = function ({ module, index, addNewModule, onEdit, onDelete }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showAddModule, setShowAddModule] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const showMultipleChoiceContent = (quiz) =>{
    // console.log(quiz)
    return(
      <>
      <p>Question: {quiz.question}</p>
      <p>Options: {quiz.options.map(opt=><p>{quiz.options.indexOf(opt)+1}{". "}{opt}</p>)}</p>
      <p>Correct Answer: {quiz.options[quiz.correctAnswer]}</p>
      <p>Explanation: {quiz.explanation}</p>
     
      </>
    )
  }

  const showShortAnswerContent = (quiz) =>{
    // console.log(quiz)
    return(
      <>
      <p>Question: {quiz.question}</p>
      <p>Correct Answer: {quiz.correctAnswer}</p>
      </>
    )
  }

  const showTerms = (text) =>{
    return(
    <p>{text}</p>
    )
  }

 


  return (
    <Card
      onMouseOver={() => {
        setShowAddModule(true);
      }}
      onMouseLeave={() => {
        setShowAddModule(false);
      }}
    >
      <CardHeader>
        <div className="d-flex justify-content-between">
          <div>
            <CardTitle tag="h6">{module.title}</CardTitle>
            <CardSubtitle tag="h7" className="mb-2 text-muted">
              {module.type}
            </CardSubtitle>
          </div>
          <div style={{ position: "relative" }}>
            <ExpandMore onClick={toggle} style={{ cursor: "pointer" }} />
            <MoreVert onClick={() => setShowMenu(!showMenu)} style={{ cursor: "pointer" }} />
            {showMenu && (
              <div className="customMenu">
                <div className="customCard">
                  <div
                    style={{
                      borderBottom: "1px solid  #d5e5e6",
                    }}
                    class="customMenuItem"
                    onClick={() => {
                      onEdit(index);
                      setShowMenu(false);
                    }}
                  >
                    Edit
                  </div>
                  <div
                    onClick={() => {
                      setShowMenu(false);
                      onDelete(module);
                    }}
                    class="customMenuItem"
                  >
                    Delete
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </CardHeader>

      <Collapse isOpen={isOpen}>
        <CardBody style={{ padding: "1rem" }}>
          {/* {console.log({module})} */}
          {module.type === "video" ? `Video Url: ${module.url}` : ""}
          {module.type === "multipleChoice" ? showMultipleChoiceContent(module.quiz) : ""}
          {module.type === "shortNumberAnswer" ? showShortAnswerContent(module.quiz) : ""}
          {module.type === "shortTextAnswer" ? showShortAnswerContent(module.quiz) : ""}
          {module.type === "shortCommunicationAnswer" ? showShortAnswerContent(module.quiz) : ""}
          {module.type === "terms" ? showTerms(module.text) : ""}
        </CardBody>
      </Collapse>

      <div className={`d-flex justify-content-center p-1 hidden ${showAddModule ? "active" : ""}`}>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            console.log("clicked");
            addNewModule(index);
          }}
        >
          <AddCircleOutline />
          Add new Module below
        </div>
      </div>
    </Card>
    
  );
};

export default function TrainingList({ modules, addNewModule, onEdit, onDelete }) {
  return (
    <>
      {modules.map((module, i) => (
        <Module
          module={module}
          key={i}
          index={i}
          addNewModule={addNewModule}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </>
  );
}

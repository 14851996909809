import { Modal } from "@mui/material";
import api from "components/API/api";
import { useModal } from "context/ModalContext";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Col, FormGroup, Input, Label, Spinner } from "reactstrap";
import DateTime from "react-datetime";

const CheckInTimeCell = ({ checkIn, selectedForWorkId, onChange, defaultTime, type }) => {
  const [openCheckInModal, setOpenCheckInModal] = useState(false);
  const [time, setTime] = useState();
  const [loading, setLoading] = useState();
  const { addAlert } = useModal();

  useEffect(() => {
    checkIn?.time
      ? setTime(new Date(checkIn?.time))
      : defaultTime && setTime(moment(defaultTime).toDate());
  }, [checkIn, defaultTime]);

  const signIn = (data) => {
    api()
      .patch("/connectCode/signIn", data)
      .then((res) => {
        onChange(time);
        setOpenCheckInModal(false);
      })
      .catch((e) =>
        addAlert({
          error: true,
          title: e.response?.data?.message || e.message,
        }),
      )
      .finally(() => setLoading(false));
  };

  const addNew = (data) => {
    api()
      .post("/connectCode/manual/signIn", data)
      .then((res) => {
        onChange(time);
        setOpenCheckInModal(false);
      })
      .catch((e) =>
        addAlert({
          error: true,
          title: e.response?.data?.message || e.message,
        }),
      )
      .finally(() => setLoading(false));
  };
  // console.log("diff", Math.abs(moment(checkIn.time).diff(moment(defaultTime), "minute")));
  // console.log(
  //   "====>",
  //   moment(checkIn.time).format("hh:mm a"),
  //   moment(defaultTime).format("hh:mm a"),
  // );
  return (
    <div className="w-100 h-100">
      {checkIn?.time ? (
        <p
          onClick={() => setOpenCheckInModal(true)}
          className="w-100 h-100"
          style={{
            fontWeight: 400,
            cursor: "pointer",
            color:
              Math.abs(moment(checkIn.time).diff(moment(defaultTime), "minute")) > 30
                ? "red"
                : "green",
          }}
        >
          {moment(checkIn.time).format("hh:mm a") || "-"}
        </p>
      ) : (
        <p
          onClick={() => setOpenCheckInModal(true)}
          className="w-100 h-100"
          style={{ fontWeight: 400, cursor: "pointer" }}
        >
          -
        </p>
      )}

      <Modal
        open={openCheckInModal}
        onClose={() => setOpenCheckInModal(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex", margin: 20 }}>
              <h5>{type === "in" ? "Check In" : "Check Out"}</h5>
            </div>
            <FormGroup row className="align-items-right">
              {/* <Label for="Search" sm={5}>
                {businessName}
              </Label> */}
              <Col sm={7}>
                {/* <Input
                  type="text"
                  name="pin"
                  value={pin}
                  onChange={(e) => {
                    setPin(e.target.value);
                  }}
                /> */}
                <DateTime
                  dateFormat={false}
                  value={time}
                  onChange={(time) => time?.toDate && setTime(time.toDate())}
                />
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    setLoading(true);
                    if (checkIn) {
                      const data = {
                        selectedForWorkId,
                        checkInId: checkIn._id,
                        time: moment(defaultTime)
                          .set({
                            hour: moment(time).get("hour"),
                            minute: moment(time).get("minute"),
                            second: moment(time).get("second"),
                          })
                          .format(),
                        type,
                      };
                      signIn(data);
                    } else {
                      addNew({
                        time: moment(defaultTime)
                          .set({
                            hour: moment(time).get("hour"),
                            minute: moment(time).get("minute"),
                            second: moment(time).get("second"),
                          })
                          .format(),
                        selectedForWorkId,
                        type,
                      });
                    }
                  }}
                  color="success"
                  size="md"
                  outline
                  className="btn m-0 w-100"
                >
                  {checkIn ? "Update" : "Add"}
                  {loading ? <Spinner size="sm" /> : null}
                </Button>
              </Col>{" "}
            </FormGroup>
          </div>

          <div style={{ justifyContent: "center", display: "flex" }}>
            <Button
              color="secondary"
              onClick={() => {
                setOpenCheckInModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </FormGroup>
      </Modal>
    </div>
  );
};

export default CheckInTimeCell;

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";

class BusinessSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Search Entry
      searchEntry: null,

      //Errors
      errorSearchEntry: "",
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress); //Now Enter will submit as well
  }

  loadBusinesses = () => { };

  handleKeyPress(e) {
    //13 is the keycode for Enter
    if (e.keyCode === 13) {
      console.log("You just pressed Enter!");
      this.loadBusinesses();
    }
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="3"></Col>
            <Col md="6" className="align-center">
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">Business Search by HDID</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="#" method="#">
                    <label>Enter Business HDID</label>
                    <FormGroup>
                      <Input
                        type="text"
                        autoComplete="off"
                        onChange={(e) => this.setState({ searchEntry: e.target.value })}
                      />
                      <FormText color="danger" tag="span">
                        {this.state.errorSearchEntry}
                      </FormText>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button type="submit" color="success" onClick={this.loadBusinesses}>
                    Search
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col md="3"></Col>
          </Row>
        </div>
      </>
    );
  }
}

export default BusinessSearch;

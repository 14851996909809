/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Select from "react-select";
//import { Prompt } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import AgencyContext from "./../../../context/AgencyContext";
import api from "../../../components/API/api";
class CreateNewTraining extends Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.defaultState = {
      trainingName: null,
      expires: 0,
      hasExpiry: false,
      //Errors
      errortrainingName: "",
      errorExpires: "",
      errorHasExpiry: "",
      //Loading
      isLoading: false,
    };
    this.state = this.defaultState;
  }

  componentDidMount() {}

  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling
    if (tempState.trainingName == null || tempState.trainingName == "") {
      this.setState({ errortrainingName: "* Must have a training name." });
      hasError = true;
    } else {
      this.setState({ errortrainingName: "" });
    }

    if(tempState.hasExpiry){
      if (tempState.expires== null || tempState.expires == "") {
        this.setState({ errorExpires: "* Must specify expiry period in days." });
        hasError = true;
      } else {
        this.setState({ errorExpires: "" });
      }
    } else {
      this.setState({ errorExpires: "" });
    }

    if (hasError) {
      return;
    } else {
      this.prepareAndSendDataToServer(tempState); // runs function after loading is set as true.
    }
  };

  prepareAndSendDataToServer = async (tempState) => {
    this.setState({ isLoading: true }); // Start the loading
    // to load the values again after creating manager without fectching again
    //Upload the changelog into the deeper user history collection! This uploads the admin email, timestamp, and action taken
    let dataToSend = null; // Data prepared to send.

    dataToSend = {
      name: tempState.trainingName,
      expire: tempState.hasExpiry ? tempState.expires : 10000 //default expiry is given as 10000
    };

    try {
      const resp = await api().post("/training", dataToSend);
      //to make all permission to default active state
      this.redirect(resp.data.training && resp.data.training._id);
    } catch (error) {
      this.setState({ isLoading: false })
      console.log(error);
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleRadio = (event) => {
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  redirect = (id) => {
    let path = `/${this.context.agency.agency.slug}/admin/edit-training/${id}`;
    this.props.history.push(path);
  };

  render() {
    return (
      <div className="content">
        {/* <Prompt //prevent page jumps, temporarily disabled for testing
          when={true}
          message={() => "All the data will lost if you leave"}
        />*/}
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">Create New Training for Agency</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="/" className="form-horizontal" method="get">
                    <Row>
                      <Label sm="2">* Training Name</Label>

                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => this.setState({ trainingName: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errortrainingName}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">* Expiry</Label>
                      <Row className="checkbox-radios" sm="5" style={{ marginLeft: "1.5%" , marginTop: "0.8%"}}>
                        <div className="form-check-radio" style={{ marginRight: 8 }}>
                          <Label check>
                            <Input
                              defaultValue={false}
                              id="N"
                              name="expiryRadio"
                              type="radio"
                              onChange={() => this.setState({ hasExpiry: false })}
                              checked={this.state.hasExpiry === false}
                            />
                            No <span className="form-check-sign" />
                          </Label>
                        </div>
                        <div className="form-check-radio">
                          <Label check>
                            <Input
                              defaultValue={true}
                              id="Y"
                              name="expiryRadio"
                              type="radio"
                              checked={this.state.hasExpiry}
                              onChange={() => this.setState({ hasExpiry: true })}
                            />
                            Yes <span className="form-check-sign" />
                          </Label>
                          <FormText color="danger" tag="span">
                            {this.state.errorHasExpiry}
                          </FormText>
                        </div>
                      </Row>
                    </Row>
                    {this.state.hasExpiry ? (
                      <Row>
                        <Label sm="2">* Expires In(days)</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              onChange={(e) => this.setState({ expires: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorExpires}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      <Label sm="2"></Label>
                      <Col sm="3">
                        <Button color="success" onClick={() => this.handleSubmit()}>
                          Create Training
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {this.state.alert}
      </div>
    );
  }
}

export default CreateNewTraining;

import React from "react";
import moment from "moment";
import { validateEmail, validateSin } from "components/Utilities/utilities.js";
import api from "components/API/api";
import SweetAlert from "react-bootstrap-sweetalert";

/**
 * Description : Check if the create new user form filled in all required sections,
 * if yes, send to backend and create new user in database; if no, provide error response on frontend
 * @param {ARRAY} form {USERS' PROFILE VARIABLES INPUT}
 * @param {FUNCTION} setForm
 * @param {ARRAY} error {ERROR HANDLING VARIABLES TO INDICATE MISSING VALUE IN THE FORM}
 * @param {FUNCTION} setError
 * @param {FUNCTION} hideAlert {HIDE PROMPT ALERT AFTER USER CLICKED}
 * @param {FUNCTION} setIsLoading
 * @param {BOOLEAN} isLoading
 * @returns : Response for success / fail to create user
 */

export const handleErrors = ({
  form,
  setForm,
  error,
  setError,
  setAlert,
  hideAlert,
  setIsLoading,
  isLoading,
}) => {
  let tempState = { ...form }; // Copy of state
  console.log("this is tempstate", tempState);
  let hasError = false;
  let allError = {};
  // Start of Error Handling
  if (tempState.firstName == null || tempState.firstName.trim() == "") {
    allError.errorFirstName = "* Must have a first name.";
    hasError = true;
  } else {
    setError({ ...error, errorFirstName: "" });
  }

  if (tempState.lastName == null || tempState.lastName.trim() == "") {
    allError.errorLastName = "* Must have a last name.";
    hasError = true;
  } else {
    setError({ ...error, errorLastName: "" });
  }

  if (
    tempState.availability[0].active == false &&
    tempState.availability[1].active == false &&
    tempState.availability[2].active == false &&
    tempState.availability[3].active == false &&
    tempState.availability[4].active == false &&
    tempState.availability[5].active == false &&
    tempState.availability[6].active == false
  ) {
    hasError = true;
    allError.errorAvailability = "* Must have at least 1 day available for work.";
  } else {
    setForm({ ...form, errorAvailability: "" });
  }

  if (tempState.gender == null || tempState.gender.trim() == "") {
    allError.errorGender = "* Must select a gender.";
    hasError = true;
  } else {
    setForm({ ...form, errorGender: "" });
  }

  if (!tempState.dob) {
    allError.errorDob = "* Must have date of birth.";
    hasError = true;
  } else if (new Date().getYear() - new Date(tempState.dob).getYear() < 18) {
    allError.errorDob = "* Must be 18 or older.";
    hasError = true;
  } else {
    setForm({ ...form, errorDob: "" });
  }

  if (tempState.phone == null || tempState.phone.trim() == "") {
    allError.errorPhone = "* Must have a phone number."; // No phone number
    hasError = true;
  } else {
    if (tempState.phone.length != 10 || tempState.phone < 0) {
      allError.errorPhone = "* Please input a valid phone number in the format ###-###-####."; //invalid phone number
      hasError = true;
    } else {
      setForm({ ...form, errorPhone: "" });
    }
  }

  if (tempState.email == null || tempState.email.trim() == "") {
    allError.errorEmail = "* Must have an email"; // No email text
  } else {
    if (tempState.email != null && validateEmail(tempState.email) === false) {
      allError.errorEmail = "* Please input a valid email"; //invalid email text
      hasError = true;
    } else {
      setForm({ ...form, errorEmail: "" });
    }
  }

  if (tempState.address == null || tempState.address.trim() == "") {
    allError.errorAddress = "* Must have a street address.";
    hasError = true;
  } else {
    setForm({ ...form, errorAddress: "" });
  }

  if (tempState.city == null || tempState.city.trim() == "") {
    allError.errorCity = "* Must have a city.";
    hasError = true;
  } else {
    setForm({ ...form, errorCity: "" });
  }

  if (tempState.province == null || tempState.province == "") {
    allError.errorProvince = "* Must have a province.";
    hasError = true;
  } else {
    setForm({ ...form, errorProvince: "" });
  }

  if (tempState.postalCode == null || tempState.postalCode.trim() == "") {
    allError.errorPostalCode = "* Must have a postal code.";
    hasError = true;
  } else {
    let regExp = "^[A-Za-z][0-9][A-Za-z][0-9][A-Za-z][0-9]$"; //logic for postal code format A1B2C3, capital letters only
    if (tempState.postalCode.match(regExp) == null || tempState.postalCode.length != 6) {
      allError.errorPostalCode = "* Please input a valid postal code in the format A1A1A1."; //invalid postal code
      hasError = true;
    } else {
      setForm({ ...form, errorPostalCode: "" });
    }
  }

  if (tempState.sin == null || tempState.sin.trim() == "") {
    allError.errorSin = "* Must have a SIN."; //No sin
    hasError = true;
  } else {
    if (validateSin(tempState.sin) === false || tempState.sin.length != 9) {
      allError.errorSin = "* Please input a valid SIN in the format ###-###-###"; //invalid sin
      hasError = true;
    } else if (validateSin(tempState.sin) === true) {
      setForm({ ...form, errorSin: "" });
    }
  }

  if (tempState.sin?.startsWith("9")) {
    if (!tempState.sinExpiry) {
      allError.errorSinExpiry = "* Must have expiry date for sin starting with 9.";
      hasError = true;
    } else if (moment().isSameOrAfter(tempState.sinExpiry, "date")) {
      allError.errorSinExpiry = "* The sin is already expired.";
      hasError = true;
    } else {
      setForm({ ...form, errorSinExpiry: "" });
    }
  } else {
    setForm({ ...form, errorSinExpiry: "" });
  }

  if (tempState.legallyWork == null || tempState.emergencyPhone.trim() == "") {
    allError.errorLegallyWork = "* Must select yes or no";
    hasError = true;
  } else {
    setForm({ ...form, errorLegallyWork: "" });
  }

  if (tempState.workStatus == null) {
    allError.errorWorkStatus = "* Must select a work status";
    hasError = true;
  } else {
    setForm({ ...form, errorWorkStatus: "" });
  }

  if (tempState.emergencyFirstName == null || tempState.emergencyPhone.trim() == "") {
    allError.errorEmergencyFirstName = "* Must have a first name for the emergency contact.";
    hasError = true;
  } else {
    setForm({ ...form, errorEmergencyFirstName: "" });
  }

  if (tempState.emergencyLastName == null || tempState.emergencyPhone.trim() == "") {
    allError.errorEmergencyLastName = "* Must have a last name for the emergency contact.";
    hasError = true;
  } else {
    setForm({ ...form, errorEmergencyLastName: "" });
  }

  if (tempState.emergencyPhone == null || tempState.emergencyPhone.trim() == "") {
    allError.errorEmergencyPhone = "* Must have a phone number for the emergency contact."; // No phone number
    hasError = true;
  } else {
    if (tempState.emergencyPhone.length != 10 || tempState.emergencyPhone < 0) {
      allError.errorEmergencyPhone =
        "* Please input a valid phone number in the format ###-###-#### for the emergency contact."; //invalid phone number
      hasError = true;
    } else {
      setForm({ ...form, errorEmergencyPhone: "" });
    }
  }

  if (tempState.emergencyRelationship == null || tempState.emergencyPhone.trim() == "") {
    allError.errorEmergencyRelationship = "* Must have a relationship for the emergency contact.";
    hasError = true;
  } else {
    setForm({ ...form, errorEmergencyRelationship: "" });
  }

  if (tempState.confirmEmail == null || tempState.confirmEmail.trim() == "") {
    allError.errorConfirmEmail = "* Must confirm email.";
    hasError = true;
  } else if (tempState.confirmEmail !== tempState.email) {
    allError.errorConfirmEmail = "* Your emails do not match";
    hasError = true;
  } else {
    setForm({ ...form, errorConfirmEmail: "" });
  }

  setError(allError);

  if (hasError) {
    setAlert(
      <SweetAlert
        warning
        title="Please ensure all mandatory fields (*) are filled"
        onConfirm={hideAlert}
      />,
    );
  } else {
    prepareAndSendDataToServer(tempState, setIsLoading, hideAlert, isLoading, setAlert); // runs function after loading is set as true.
  }
};

const prepareAndSendDataToServer = async (
  tempState,
  setIsLoading,
  hideAlert,
  isLoading,
  setAlert,
) => {
  setIsLoading(true); // Start the loading
  if (isLoading) {
    return;
  }
  setIsLoading(true);
  let emergency = [
    {
      firstName: tempState.emergencyFirstName,
      lastName: tempState.emergencyLastName,
      phone: tempState.emergencyPhone,
      relationship: tempState.emergencyRelationship,
    },
  ];

  if (tempState.emergencyFirstName2) {
    emergency.push({
      firstName: tempState.emergencyFirstName2,
      lastName: tempState.emergencyLastName2,
      phone: tempState.emergencyPhone2,
      relationship: tempState.emergencyRelationship2,
    });
  }

  let dataToSend = {
    // General Information
    firstName: tempState.firstName,
    lastName: tempState.lastName,
    availability: tempState.availability,
    gender: tempState.gender,
    dob: new Date(tempState.dob),
    phone: tempState.phone,
    email: tempState.email,
    address: tempState.address,
    city: tempState.city,
    province: tempState.province.value,
    postalCode: tempState.postalCode,
    sin: tempState.sin,
    legallyWork: tempState.legallyWork,
    workStatus: tempState.workStatus.value,
    emergency,
  };

  if (tempState.qualifications) {
    dataToSend.qualifications = tempState.qualifications.map((q) => q.value);
  }

  if (tempState.sin === "000000000") {
    dataToSend.sinExpiry = moment().add(90, "days").format();
  }

  try {
    const { data } = await api().post("/users/register-worker-no-password", dataToSend);
    setIsLoading(false);
    setAlert(
      <SweetAlert
        success
        title="User successfully created"
        onConfirm={() => {
          hideAlert();
          window.location.reload();
        }}
      />,
    );
  } catch (error) {
    const description = error?.response?.data?.message || error.message;
    setIsLoading(false);
    setAlert(
      <SweetAlert warning title="An error occurred" onConfirm={hideAlert}>
        {description}
      </SweetAlert>,
    );
  }
};

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useContext, useEffect, useState } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { filterCaseInsensitive } from "components/Utilities/utilities";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import {
  Visibility,
  Comment,
  CallEnd,
  Clear,
  Check,
  RemoveCircle,
  PersonAdd,
} from "@mui/icons-material";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import { Input } from "reactstrap";
import AgencyContext from "./../../../context/AgencyContext";
import api from "components/API/api";
import moment from "moment";
import ViewRepliesModals from "./ViewRepliesModals";


// Bring out of class to manage it's own state.
const responseStatusEnum = {
  notCalled: 0,
  accepted: 1,
  declined: 2,
  noAnswer: 3,
};

function ViewReplies(props) {

    const context = useContext(AgencyContext);
    const [userData, setUserData] = useState([]);
    const [userDataNo, setUserDataNo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("Not Called");
    const [selectedNote, setSelectedNote] = useState("");
    const [selectedPhone, setSelectedPhone] = useState("");
    const [users, setUsers] = useState([]);
    const [modal, setModal] = useState(null);
    const [pageTabs, setpageTabs] = useState("yes");
    const [reactTableState, setReactTableState] = useState({
      page: 1,
      size: 10,
      field: "createdAt",
      sort: "desc",
      filter: "",
    });
    const [pages, setPages] = useState(-1);
    const [selectedResponse, setSelectedResponse] = useState(null);
    const [modalStatus, setModalStatus] = useState(false);
    const [modalNotes, setModalNotes] = useState(false);
    const [alert, setAlert] = useState(null);

    const [openCommonModal, setOpenCommonModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const notificationId = props.match.params.id;

  useEffect(() => {
    getAppliedUsers();
  }, []);

    // open common user modals
    const handleOpenCommonModal = userId => {
      setSelectedUserId(userId);
      setOpenCommonModal(true);
    };


     // close common user modals
     const handleCloseCommonModal = () => {
      setSelectedUserId(null);
      setOpenCommonModal(false);
    };

  const getAppliedUsers = async function () {

    try {
      const params = { reactTableState, notificationId };
      setIsLoading(true);
      const { data } = await api().get(`/notification/responses`, {
        params,
      });

      let newResponses = data.responses.map((response) => {
        response.updatedAt = moment(response.updatedAt).format("YYYY-MM-DD hh:mm a");
        response.actions = RenderActionButtons(response, response.phone);

        response.replyDate = moment(response.replyDate).format("YYYY-MM-DD hh:mm a");
        if (response.status === 1) {
          response.statusText = "Accepted";
        } else if (response.status === 0) {
          response.statusText = "Not Called";
        } else if (response.status === 2) {
          response.statusText = "Declined";
        } else if (response.status === 3) {
          response.statusText = "No Answer";
        } else {
          response.statusText = "Not Called";
        }
        return response;
      });

      setUserData(newResponses.filter((_resp) => _resp.response?.toLowerCase().trim() === "yes"));
      setUserDataNo(newResponses.filter((_resp) => _resp.response?.toLowerCase().trim() === "no"));
      setPages(data.noPages);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const setNote = async (notificationId, response, note) => {
    updateResponse(notificationId, response, null, null, note);
  };

  const openModalStatus = (phone, status, response) => {
    setModalStatus(true);
    setSelectedPhone(phone);
    setSelectedStatus(status);
    setSelectedResponse(response);
  };


  const openModalNotes = (phone, note, response) => {
    setModalNotes(true);
    setSelectedPhone(phone);
    setSelectedNote(note);
    setSelectedResponse(response);
  };

  const updateResponse = async (notificationId, response, status, active, note) => {
    let updateBody = { notificationId: notificationId, responseId: response?._id, };
    (status || status === 0) && (updateBody.status = status);
    active && (updateBody.active = active);
    note && (updateBody.note = note);
    try {
     await api().patch(`/notification/response`, updateBody);
      getAppliedUsers();
      if(updateBody.status === responseStatusEnum.accepted) handleOpenCommonModal(response.user);
    } catch (e) {
      console.log(e);
    }
  };


 const RenderActionButtons = (response, phone) => {
    return (
      <div>
        {response.status === responseStatusEnum.noAnswer ? (
          <Button
            onClick={() => {
              openModalStatus(phone, response.status, response);
            }}
            color="warning"
            size="md"
            className="btn-link btn-icon"
          >
            <CallEnd style={{ marginLeft: -1 }} />
          </Button>
        ) : response.status === responseStatusEnum.declined ? (
          <Button
            onClick={() => {
              openModalStatus(phone, response.status, response);
            }}
            color="danger"
            size="md"
            className="btn-link btn-icon"
          >
            <Clear style={{ marginLeft: -1 }} />
          </Button>
        ) : (
          
          <Button
            onClick={() => {
              openModalStatus(phone, response.status, response);
            }}
            color="secondary"
            size="md"
            className="btn-link btn-icon"
          >
            <PersonAdd style={{ marginLeft: -1 }} />
          </Button>
        )}
        <Button
          onClick={() => {
            handleOpenProfile(response);
          }}
          color="info"
          size="md"
          className="btn-link btn-icon"
          disabled={!response.active}
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
        <Button
          onClick={() => {
            openModalNotes(phone, response.note, response);
          }}
          color="info"
          size="md"
          className="btn-link btn-icon"
          disabled={!response.active}
        >
          <Comment style={{ marginLeft: -1 }} />
        </Button>
        <Button
          onClick={() => {
            // select for shift
            checkOffUser(response);
          }}
          color="danger"
          size="md"
          className="btn-link btn-icon"
        >
          <RemoveCircle style={{ marginLeft: -1 }} />
        </Button>
      </div>
    );
  };

  const checkOffUser = async (response) => {
    updateResponse(notificationId, response, null, false);
  };

  const handleOpenProfile = (response) => {
    if (!response.user) return;
    let path = `/${context.agency.agency.slug}/admin/user-profile/${response.user}`;
    props.history.push(path);
  };

  const displayDataInTable = (data) => {
    return (
      <ReactTable
        data={data}
        columns={[
          {
            Header: "Name",
            accessor: "userName",
            getProps: (state, rowInfo, column) => {
              if (rowInfo && rowInfo.original) {
                return {
                  style: {
                    color: rowInfo.original.checked ? "gray" : "black",
                  },
                };
              }
              return {};
            },
          },
          {
            Header: "Phone",
            accessor: "phone",
            width: 150,
            getProps: (state, rowInfo, column) => {
              if (rowInfo && rowInfo.original) {
                return {
                  style: {
                    color: rowInfo.original.checked ? "gray" : "black",
                  },
                };
              }
              return {};
            },
          },
          {
            Header: "Reply",
            accessor: "response",
            width: 80,
            getProps: (state, rowInfo, column) => {
              if (rowInfo && rowInfo.original) {
                return {
                  style: {
                    color: rowInfo.original.checked ? "gray" : "black",
                  },
                };
              }
              return {};
            },
          },
          {
            Header: "Reply Date",
            accessor: "replyDate",
            maxWidth: 180,
            getProps: (state, rowInfo, column) => {
              if (rowInfo && rowInfo.original) {
                return {
                  style: {
                    color: rowInfo.original.checked ? "gray" : "black",
                  },
                };
              }
              return {};
            },
          },
          {
            Header: "Note",
            accessor: "note",
            getProps: (state, rowInfo, column) => {
              if (rowInfo && rowInfo.original) {
                return {
                  style: {
                    color: rowInfo.original.checked ? "gray" : "black",
                  },
                };
              }
              return {};
            },
          },
          {
            Header: "Status",
            accessor: "statusText",
            maxWidth: 110,
            getProps: (state, rowInfo, column) => {
              if (rowInfo && rowInfo.original) {
                return {
                  style: {
                    color: rowInfo.original.checked ? "gray" : "black",
                  },
                };
              }
              return {};
            },
          },
          {
            Header: "Last Edit By",
            accessor: "lastEdit",
            getProps: (state, rowInfo, column) => {
              if (rowInfo && rowInfo.original) {
                return {
                  style: {
                    color: rowInfo.original.checked ? "gray" : "black",
                  },
                };
              }
              return {};
            },
            width: 200,
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false,
            width: 190,
          },
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom={false}
        filterable
        defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
        className="-striped -highlight primary-pagination"
      />
    );
  };

    return (
      <>
        <div className="content">
          {isLoading ? (
            <LoadingOverlay />
          ) : (
            <Row>
              <Col md="12">
                <FormGroup></FormGroup>
                <Card className="no-transition">
                  <CardBody>
                    <Nav
                      className="nav-pills-primary nav-pills-icons justify-content-center"
                      pills
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          role="tablist"
                          className={pageTabs === "yes" ? "active" : ""}
                          onClick={() => setpageTabs("yes")}
                        >
                          <i className="now-ui-icons objects_umbrella-13" />
                          Replied Yes
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          role="tablist"
                          className={pageTabs === "no" ? "active" : ""}
                          onClick={() => setpageTabs("no")}
                        >
                          <i className="now-ui-icons ui-2_settings-90" />
                          Replied No
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      className="tab-space tab-subcategories"
                      activeTab={pageTabs}
                    >
                      <TabPane tabId="yes">{displayDataInTable(userData)}</TabPane>
                      <TabPane tabId="no">
                        {displayDataInTable(userDataNo)}
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {alert}
          <Modal isOpen={modalNotes} toggle={() => setModalNotes(false)}>
            <ModalBody>
              <FormGroup>
                <Label for="note">Note</Label>
                <Input
                  type="text"
                  name="email"
                  id="note"
                  placeholder=""
                  value={selectedNote}
                  onChange={(e) => setSelectedNote(e.target.value)}
                />
              </FormGroup>
            </ModalBody>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                marginTop: 15,
                padding: 40,
              }}
            >
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={() =>{
                  setModalNotes(false);
                  setSelectedNote("");
                  setSelectedResponse(null);
                }}
              >
                Cancel
              </Button>{" "}
              <Button
                color="primary"
                onClick={() => {
                  setNote(
                    notificationId,
                    selectedResponse,
                    selectedNote,
                  );
                  setModalNotes(false);
                  setSelectedResponse(null);
                }}
              >
                Save
              </Button>{" "}
            </div>
          </Modal>

          <Modal
            isOpen={modalStatus}
            toggle={() =>  setModalNotes(false)}
          >
            <ModalBody>
              <FormGroup tag="fieldset">
                <legend>Set status</legend>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="setState"
                      checked={selectedStatus === responseStatusEnum.accepted}
                      onChange={(e) =>
                        setSelectedStatus(responseStatusEnum.accepted)
                      }
                    />{" "}
                    Accepted
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="setState"
                      checked={selectedStatus === responseStatusEnum.declined}
                      onChange={(e) =>
                        setSelectedStatus(responseStatusEnum.declined)
                      }
                    />{" "}
                    Declined
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="setState"
                      checked={selectedStatus === responseStatusEnum.notCalled}
                      onChange={(e) =>
                        setSelectedStatus(responseStatusEnum.notCalled)
                      }
                    />
                    Not Called
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="setState"
                      checked={selectedStatus === responseStatusEnum.noAnswer}
                      onChange={(e) =>
                        setSelectedStatus(responseStatusEnum.noAnswer)
                      }
                    />
                    No Answer
                  </Label>
                </FormGroup>
              </FormGroup>
            </ModalBody>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                marginTop: 15,
                padding: 40,
              }}
            >
              <Button
                color="secondary"
                style={{ marginRight: "12px" }}
                onClick={() => setModalStatus(false)}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  updateResponse(
                    notificationId,
                    selectedResponse,
                    selectedStatus,
                  );
                  setModalStatus(false);
                }}
              >
                Select
              </Button>
            </div>
          </Modal>
          <ViewRepliesModals
            selectedUserId={selectedUserId}
            handleCloseCommonModal={handleCloseCommonModal}
            openCommonModal={openCommonModal}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>
      </>
  )};

export default ViewReplies;

/* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import MaskedInput from "react-text-mask";
//import ReactDatetime from "react-datetime";
import { validateEmail } from "components/Utilities/utilities.js";
import AgencyContext from "context/AgencyContext";
import api from "components/API/api";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
  Container,
} from "reactstrap";
import { Modal } from "@mui/material";
import ReactDatetime from "react-datetime";

// import { fs, auth, firebase } from "components/Firebase/firebase.js";

class ChequeIssues extends Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.defaultState = {
      hdId: "",
      firstName: null,
      lastName: null,
      weekStart: null,
      weekEnd: null,
      business: null,
      phone: null,
      pinBadgeNumber: null,

      errorFirstName: "",
      errorLastName: "",
      errorWeekStart: "",
      errorWeekEnd: "",
      errorLocation: "",
      errorPhone: "",
      errorPinBadgeNumber: "",

      thours: 0,
      tminutes: 0,
      tseconds: 0,

      // Loading
      isLoading: false,

      isPickupTime: false,
      businessList: [],
      agencyData: false,
    };
    this.state = this.defaultState;
    this.fromNumber = "6479057870";
  }

  componentDidMount() {
    // this.getAllBusiness();
    // this.chequeIssuesDataRef = fs.collection("cheque_issues");
    // this.chequeIssuesCountersRef = fs.collection("counters").doc("cheque_issues");
  }

  componentDidUpdate() {
    if (
      this.context.agency &&
      this.context.agency.agency &&
      this.context.agency.agency._id &&
      !this.state.agencyData
    ) {
      console.log(this.context);
      this.setState({ agencyData: true });
      this.getAllBusiness();
    }
  }

  getAllBusiness = async () => {
    try {
      console.log(this.props);
      // this.setState({ loading: true });
      const { data } = await api().get(`business/list`, {
        params: {
          agency:
            this.context.agency && this.context.agency.agency && this.context.agency.agency._id,
        },
      });
      //Looping through the business data for action button implementation
      console.log(data);
      this.setState({
        businessList: data.map((business) => {
          return { value: business._id, label: business.name };
        }),
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  scrollToFirstError = () => {
    //Convert to camelcase by adding prototype to String
    String.prototype.toLowerCaseFirstLetter = function () {
      return this.charAt(0).toLowerCase() + this.slice(1);
    };
    try {
      for (let prop in this.state) {
        if (prop.match(/^error+/) && this.state[prop]) {
          //console.log(prop, this.state[prop]);
          const id = prop.replace(/^error+/, "").toLowerCaseFirstLetter();
          var element = document.getElementById(id);
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          //By default scrollintoview takes some time to scroll smoothly so I set a settimeout function to
          //delay the focus so that it can normally scroll
          setTimeout(() => {
            element.focus();
          }, 500);
          break;
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling
    if (tempState.hdId === null || tempState.hdId === "") {
      this.setState({ errorHdId: "* Must have a hdId." });
      hasError = true;
    } else if (!(tempState.hdId > 10000 && tempState.hdId < 999999)) {
      this.setState({ errorHdId: "* Must have a valid hdId." });
      hasError = true;
    } else {
      this.setState({ errorHdId: "" });
    }

    // if (tempState.lastName === null || tempState.lastName === "") {
    //   this.setState({ errorLastName: "* Must have a last name." });
    //   hasError = true;
    // } else {
    //   this.setState({ errorLastName: "" });
    // }

    if (tempState.shift === null || tempState.shift === "") {
      this.setState({ errorShift: "* Must have a shift." });
      hasError = true;
    } else {
      this.setState({ errorShift: "" });
    }

    if (tempState.business === null || tempState.business === "") {
      this.setState({ errorLocation: "* Must have a business." });
      hasError = true;
    } else {
      this.setState({ errorLocation: "" });
    }

    if (tempState.comments === null || tempState.comments === "") {
      this.setState({ errorComments: "* Please tell us about your problem." });
      hasError = true;
    } else {
      this.setState({ errorComments: "" });
    }

    if (hasError) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            title="Please ensure all mandatory fields (*) are filled"
            onConfirm={this.hideAlert}
          />
        ),
      });
      return;
    } else {
      this.prepareAndSendDataToServer(tempState); // runs function after loading is set as true.
    }
  };

  //Convert every obj element to lowercase if it has string
  toLowerCase(obj) {
    let newObj = {};
    for (let prop in obj) {
      //console.log(prop, obj[prop]);
      if (typeof obj[prop] === "string") {
        newObj[prop] = obj[prop].toLowerCase();
      } else {
        newObj[prop] = obj[prop];
      }
    }
    return newObj;
  }

  prepareAndSendDataToServer = async (tempState) => {
    if (this.state.isLoading === true) {
      return;
    }
    this.setState({ isLoading: true });
    api()
      .post("/cheque-pickup/v2", {
        hdId: tempState.hdId,
        weekStart: new Date(tempState.weekStart._d),
        weekEnd: new Date(tempState.weekEnd._d),
        business: tempState.business?.value,
        businessName: tempState.business?.label,
        pin: tempState.pinBadgeNumber,
        agency: this.context.agency && this.context.agency.agency && this.context.agency.agency._id,
      })
      .then(({ data }) => {
        this.props.loadCheques(); // pull latest data
        this.setState({
          ...this.defaultState,
          isLoading: false,
          alert: (
            <SweetAlert
              success
              title="Your pickup request has been sent. You will receive a confirmation sms shortly."
              onConfirm={this.hideAlert}
            >
              Your cheque request number is: {data.chequePickUpNumber}
            </SweetAlert>
          ),
        });
        this.props.closeModal();
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          alert: (
            <SweetAlert warning title="Error creating request!" onConfirm={this.hideAlert}>
              {e.response.data.message}
            </SweetAlert>
          ),
        });
      });
  };

  disableFutureDates = (current) => {
    const today = new Date();
    return current.isBefore(today);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
    this.scrollToFirstError();
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  disableFutureDates = (current) => {
    const today = new Date();
    return current.isBefore(today);
  };

  render() {
    return (
      <Modal
        open={this.props.openModal}
        onClose={this.props.closeModal}
        style={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          overflow: "scroll",
        }}
      >
        <div className="content" style={{ padding: 0 }}>
          {this.state.isLoading ? (
            this.loadingOverlay()
          ) : (
            <Row style={{ margin: 10, padding: 10, width: 600 }}>
              <Col>
                <Card style={{ marginTop: 50 }} className="no-transition">
                  <Container>
                    <CardHeader style={{ alignItems: "center", textAlign: "center" }}>
                      <CardTitle tag="h4" style={{ fontWeight: "bold" }}>
                        Create Cheque request
                      </CardTitle>
                      <p>Create a cheque request on behalf of a worker.</p>
                      <div
                        style={{
                          borderColor: "#D8CBC7",
                          borderStyle: "solid",
                          borderWidth: "0.3px",
                          width: "100%",
                          height: 0,
                          marginTop: "35px",
                          marginBottom: "25px",
                        }}
                      />
                    </CardHeader>
                    <CardBody>
                      <Form action="/" className="form-horizontal" method="get">
                        <Row style={{ marginTop: 10 }}>
                          <Label sm="3">* Worker HDID</Label>
                          <Col sm="7">
                            <FormGroup>
                              <Input
                                value={this.state.hdId}
                                placeholder="Enter HdId"
                                type="text"
                                onChange={(e) => {
                                  this.setState({ hdId: e.target.value });
                                }}
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorHdId}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Label sm="3">* Location</Label>
                          <Col sm="7">
                            <FormGroup>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={this.state.business}
                                onChange={(value) => this.setState({ business: value })}
                                id="dayOfWeek"
                                options={this.state.businessList}
                                placeholder="Select a location"
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorLocation}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Label sm="3">* Pin OR Badge Number</Label>
                          <Col sm="7">
                            <FormGroup>
                              <Input
                                type="text"
                                autoComplete="off"
                                placeholder="Must have pin OR badge #"
                                onChange={(e) =>
                                  this.setState({
                                    pinBadgeNumber: e.target.value,
                                  })
                                }
                                value={this.state.pinBadgeNumber || ""}
                                id="pinBadgeNumber"
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorPinBadgeNumber}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Label sm="3">* Start of Week</Label>
                          <Col sm="7">
                            <FormGroup>
                              <ReactDatetime
                                input={false}
                                timeFormat={false}
                                isValidDate={this.disableFutureDates}
                                value={this.state.weekStart}
                                onChange={(value) => {
                                  this.setState({
                                    weekStart: value,
                                  });
                                }}
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorWeekStart}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Label sm="3">* End of Week</Label>
                          <Col sm="7">
                            <FormGroup>
                              <ReactDatetime
                                input={false}
                                timeFormat={false}
                                isValidDate={this.disableFutureDates}
                                value={this.state.weekEnd}
                                onChange={(value) => {
                                  this.setState({
                                    weekEnd: value,
                                  });
                                }}
                              />
                              <FormText color="danger" tag="span">
                                {this.state.errorWeekEnd}
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>

                        <div
                          style={{
                            width: "100%",
                            height: 0,
                            marginTop: "35px",
                            marginBottom: "35px",
                          }}
                        />
                        <Row >
                          <Col sm="12" align="middle">
                            <Button color="success" size="lg" onClick={() => this.handleSubmit()}>
                              Create Request
                            </Button>
                          </Col>
                        </Row>
                        <div
                          style={{
                            width: "100%",
                            height: 0,
                            marginTop: "35px",
                            marginBottom: "25px",
                          }}
                        />
                      </Form>
                    </CardBody>
                  </Container>
                </Card>
              </Col>
            </Row>
          )}
          {this.state.alert}
        </div>
      </Modal>
    );
  }
}

export default ChequeIssues;

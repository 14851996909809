// line style for separating form section
export const lineStyle = {
  borderColor: "#D8CBC7",
  borderStyle: "solid",
  borderWidth: "0.3px",
  width: "100%",
  height: 0,
  marginTop: "35px",
  marginBottom: "25px",
};

// gap style for emergency contact
export const emergencyContactGap = {
  width: "100%",
  height: 0,
  marginTop: "35px",
  marginBottom: "25px",
};

// Sweet Alert style for loadingOverLay
export const loadingOverlaySweetAlert = {
  display: "block",
  marginTop: "-100px",
  background: "transparent",
};

// Spinner style for loadingOverLay
export const loadingOverlaySpinner = {
  width: "3rem",
  height: "3rem",
};

// Align checkboxes with the corresponding fields
export const radioStyle = { marginTop: 8 };

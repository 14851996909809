import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

export default function RenderPermission(props) {
  const [active, setActive] = useState(props.active);
  const [categories, updateCategories] = useState(props.categories);
  const [firstMount, setFirstMount] = useState(true);

  useEffect(() => {
    setFirstMount(false);
    if (!firstMount) {
      props.onChange(active, categories);
    }
  }, [active, categories]);

  useEffect(() => {
    setActive(props.active);
    updateCategories(props.categories);
  }, [props.active, props.categories]);

  const toggleCategories = (type, active) => {
    const newCategories = categories.map((c) => {
      if (c.type === type) {
        return { ...c, active: !active };
      } else {
        return c;
      }
    });
    //if all the categories are unchecked then permission gets unchecked
    if (
      newCategories.every((c) => {
        return !c.active;
      })
    )
      setActive(!active);
    updateCategories(newCategories);
  };

  return (
    <>
      <Row>
        <Col xs="3">
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                disabled={props.disabled || false}
                checked={active}
                onChange={() => {
                  setActive((prev) => {
                    return !prev;
                  });
                  //when permission checked, all categories of that permission should get automatically checked
                  if (!active) {
                    let temp = [...categories];
                    return temp.map((c) => {
                      c.active = true;
                    });
                  }
                }}
              />
              <span className="form-check-sign" />
              {props.displayName}
            </Label>
          </FormGroup>
        </Col>
        <Col xs="9">
          <Row>
            {active
              ? categories.map((c, i) => {
                  return (
                    <Col md="3" sm="4">
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            key={i}
                            disabled={props.disabled || false}
                            type="checkbox"
                            checked={c.active}
                            onChange={() => {
                              toggleCategories(c.type, c.active);
                            }}
                          />
                          <span className="form-check-sign" />
                          {c.type}
                        </Label>
                      </FormGroup>
                    </Col>
                  );
                })
              : null}
          </Row>
        </Col>
      </Row>
    </>
  );
}

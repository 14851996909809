import React, { Component } from "react";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import ReactTable from "react-table";
import PropTypes from "prop-types";
const SelectTable = selectTableHOC(ReactTable);

class MyTable extends Component {
  static defaultProps = {
    keyField: "id",
  };

  static propTypes = {
    keyField: PropTypes.string,
  };

  /**
   * Toggle a single checkbox for select table
   */
  toggleSelection = (key, shift, row) => {
    // console.log("key", key, row)
    // console.log("props", this.props)
    // start off with the existing state
    let selection = [...this.props.checkedShifts];
    const keyIndex = selection.indexOf(key);
    let tempList = this.props.selectedShifts;

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];

      tempList.map((temp, i) => {
        if (`select-${temp._id}` === key) {
          tempList.splice(i, 1);
        }
      });
    } else {
      // it does not exist so add it
      tempList.push(row);
      selection.push(key);
    }
    
    // update the state
    this.props.setSelectedShifts([...tempList]);
    this.props.setCheckedShifts(selection);
  };

  /**
   * Whether or not a row is selected for select table
   */
  isSelected = (key) => {
    return this.props.checkedShifts.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => {
    const selection = this.props.checkedShifts;

    return {
      onClick: (e, handleOriginal) => {
        console.log("It was in this row:", rowInfo);

        // IMPORTANT! React-Table uses onClick internally to trigger
        // events like expanding SubComponents and pivots.
        // By default a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.
        if (handleOriginal) {
          handleOriginal();
        }
      },
      style: {
        background:
          rowInfo &&
          selection.includes(`select-${rowInfo.original.id}`) &&
          "lightblue",
      },
    };
  };

  render() {
    return (
      <SelectTable
        {...this.props}
        ref={(r) => (this.checkboxTable = r)}
        toggleSelection={this.toggleSelection}
        selectType="checkbox"
        isSelected={this.isSelected}
        getTrProps={this.rowFn}

      />
    );
  }
}

export default MyTable;

import React, { useEffect } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

//import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

function ImageUpload(props) {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(null);
  const fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    var ext = e.target.value.match(/\.(.+)$/)[1];
    switch (ext) {
      case "jpg":
      case "png":
      case "jpeg":
        console.log("allowed file type");
        break;
      default:
        console.log("illegal file type.");
        return;
    }
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      props.onChange(reader.result, file); // Sends image data to the parent component as soon as it changed. Putting function here to ensure no errors.
      setFile(file);
      //setImagePreviewUrl(reader.result);
      //props.onChange(image);
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    if (props.preview !== imagePreviewUrl) {
      setImagePreviewUrl(null);
    }
  }, [props.preview]);
  // const handleSubmit = e => {
  // e.preventDefault();
  // this.state.file is the file/image uploaded
  // in this function you can save the image (this.state.file) on form submit
  // you have to call it yourself
  // };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    if (props.preview) {
      setFile(null);
      setImagePreviewUrl(defaultAvatar);
      //console.log(fileInput.current.files);
      fileInput.current.value = null;
      props.onRemove && props.onRemove();
    }
  };
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} accept=".jpg,.jpeg,.png" />
      <div className={"thumbnail" + (props.preview ? " img-circle" : "")}>
        <img
          src={imagePreviewUrl || props.preview}
          alt="..."
          onError={() => {
            setImagePreviewUrl(defaultAvatar);
          }}
        />
      </div>
      <div>
        {imagePreviewUrl ? (
          <Button className="btn-round" color="default" outline onClick={handleClick}>
            {/* {props.preview ? "Add Photo" : "Select image"} */}
            {props.previewText || "Add Photo"}
          </Button>
        ) : (
          <span>
            <Button className="btn-round" outline color="default" onClick={handleClick}>
              Change
            </Button>
            {props.preview ? <br /> : null}
            <Button color="danger" className="btn-round btn-link" onClick={handleRemove}>
              <i className="fa fa-times" />
              Remove
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
};

export default ImageUpload;

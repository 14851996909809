import React, { useEffect, useState } from "react";
import ExcelExport from "./ExcelExport";
import moment from "moment";
import { morningTimeRange, afternoonTimeRange, nightTimeRange } from "utils/dateUtils";
// import ExcelExport from "./ExcelExport";

const  HDORANGE = "FFFAA632";
let headerCols = [
  { width: { wpx: 90 }, title: "" }, //pixels width
  { width: { wch: 25 }, title: "" }, //char width
  { width: { wch: 25 }, title: "" },
  { width: { wpx: 80 }, title: "" }, //pixels width
  { width: { wpx: 80 }, title: "" }, //char width
  { width: { wpx: 90 }, title: "" },
  { width: { wpx: 90 }, title: "" },
  { width: { wpx: 130 }, title: "" },
  { width: { wpx: 90 }, title: "" },
  { width: { wpx: 100 }, title: "" },
];
export const customExcelData = function ({
  headers,
  regular,
  extras,
  businessName,
  date,
  day,
  shiftType,
  time,
}) {
  console.log(shiftType);
  let emptyCell = { value: "" };
  let backgroundStyle = {
    fill: { fgColor: { rgb: HDORANGE } },
    font: { color: { rgb: "FFFFFFFF" }, bold: true },
  };
  let emptyWithStyle = { value: "", style: backgroundStyle };

  let row1 = [
    { ...emptyCell },
    { value: "LYR FOODS" },
    { ...emptyCell },
    { value: businessName },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
  ];
  let row2 = [
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { value: "SHIFT:" },
    { value: shiftType },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
  ];
  let row3 = [
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { value: "DATE:" },
    { value: date },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
  ];
  let row4 = [
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { value: "DAY:" },
    { value: day },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
  ];

  let titleTable = [row1, row2, row3, row4];
  //section 2 headers

  //section 3 data for regular workers
  let headersArray = headers.map((h) => h.key);
  const regularWorkersTable = [
    headers.map((h) => ({
      value: h.label,
      style: backgroundStyle,
    })),
    ...regular.map((_row, i) => {
      _row.productionNo = i + 1;
      let dataRow = [];
      for (let headerCol of headersArray) {
        dataRow.push({
          value: _row[headerCol] || "",
        });
      }
      return dataRow;
    }),
  ];
  //section 4 total for regular workers
  let totalRow = [
    { ...emptyWithStyle },
    { value: `TOTAL:- ${regular.length}`, style: backgroundStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
  ];
  //section 5 extras
  let extraRow1 = [
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
    { ...emptyCell },
  ];
  let extraRowsTitle = [
    { ...emptyWithStyle },
    { value: "EXTRAS", style: backgroundStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
  ];
  const extrasWorkersTable = extras.map((_row, i) => {
    let dataRow = [];
    _row.productionNo = i + 1;
    for (let headerCol of headersArray) {
      dataRow.push({
        value: _row[headerCol] || "",
      });
    }
    return dataRow;
  });
  //section 6 total for extras
  let totalExtrasRow = [
    { ...emptyWithStyle },
    { value: `TOTAL:- ${extras.length}`, style: backgroundStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
    { ...emptyWithStyle },
  ];
  console.log(extrasWorkersTable);
  let allData = [
    ...titleTable,
    ...regularWorkersTable,
    totalRow,
    extraRow1,
    extraRowsTitle,
    ...extrasWorkersTable,
    totalExtrasRow,
  ];

  return {
    columns: headerCols,
    data: allData,
    title: day + "_" + shiftType + "_" + time,
  };
};

export default function CustomExportBusiness({ excelData, download, setDownload, fileName }) {
  const [customDownload, setCustomDownload] = useState(false);

  useEffect(() => {
    if (download) {
      setCustomDownload(download);
    }
  }, [download]);

  return download && excelData.length ? (
    <ExcelExport
      download={customDownload}
      dataset={excelData}
      setDownload={() => {
        setCustomDownload(false);
        setDownload(false);
      }}
      filename={fileName}
    />
  ) : null;
}

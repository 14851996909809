import { Button } from "reactstrap";
import React, { useEffect, useRef } from "react";
import { RotateLeft } from "@mui/icons-material";

function Messages({ messages, receiverId, receiverFullName, setLoadMore, scroll, end }) {

  const myRef = useRef();

  useEffect(() => {
    if (scroll) {
      myRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
  }, [messages]);

  return (
    <>
      <div className="mesgs">
        <div className="msg_history">
          {end ? null : (
            <Button className="loadMore" onClick={() => setLoadMore(true)}>
              Load more{"  "}
              <RotateLeft />
            </Button>
          )}

          {messages.map((m, i) =>
            m.user === receiverId ? (
              <div className="incoming_msg" key={i}>
                <div className="received_msg">
                  <div className="received_withd_msg">
                    <p>{m.text}</p>
                    <div className="chat_list">
                      <div className="chat_people">
                        <div className="chat_ib">
                          <h5 style={{ marginRight: "4px" }}>
                            {m.senderName || receiverFullName}
                            {","}{" "}
                          </h5>
                          <span style={{ color: "#747474", fontSize: "12px" }}>{m.time}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="outgoing_msg" key={i}>
                <div className="sent_msg">
                  <p>{m.text}</p>
                  <div className="chat_list">
                    <div className="chat_people">
                      <div className="chat_ib">
                        <h5 style={{ marginRight: "4px" }}>
                          {m.senderName || m.user}
                          {","}{" "}
                        </h5>
                        <h5 style={{ color: "#747474", fontSize: "12px" }}>
                          {m.time} {m.note ? `( ${m.note})` : null}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ),
          )}
          <div ref={myRef} />
        </div>
      </div>
    </>
  );
}

export default Messages;

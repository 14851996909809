import React, { useContext, useEffect, useReducer, useState } from "react";
import { Route, Switch, Redirect, withRouter, useParams } from "react-router-dom";
import AuthLayout from "./Auth.jsx";
import AdminLayout from "./Admin.jsx";
import AgencyContext from "../../context/AgencyContext";
import agencyReducer from "../../reducers/agencyReducer";
import AgencyLandingPage from "../../views/Agency/pages/LandingPage.jsx";
import CreatePassword from "../../views/Agency/CreateAdminPassword/CreatePassword.jsx";
import Error404 from "../../views/Agency/pages/Error404.jsx";
import api from "../../components/API/api";
import moment from "moment-timezone";
import SweetAlert from "react-bootstrap-sweetalert";
import { SocketContext } from "context/SocketContext.js";
import { SocketProvider } from "context/SocketContext.js";
import { useSocket } from "context/SocketContext.js";

function Admin(props) {
  const { agency, agencyDispatch } = useContext(AgencyContext);
  let { slug } = useParams();
  const [showNotifications, setShowNotifications] = useState(false);

  // const timeO

  useEffect(() => {
    loadAgencyFromSlug(slug);
  }, []);

  const loadAgencyFromSlug = (slug) => {
    api()
      .get(`/agency/get/${slug}`)
      .then((result) => {
        agencyDispatch({ type: "SET_AGENCY", payload: { agency: result.data } });
      })
      .catch((e) => {
        props.history.push(`/${slug}/auth/login`);
        console.log(e);
      });
  };

  return (
    <SocketProvider>
      <Switch>
        <Route path="/:slug" exact render={(props) => <AgencyLandingPage {...props} />} />
        <Route path="/:slug/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/:slug/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/:slug/create-password" render={(props) => <CreatePassword {...props} />} />
        {/* <Route path="/:slug/admin/create-shift" render={(props) => <CreateShifts {...props} />} /> */}
        <Route path="/:slug/error" render={(props) => <Error404 {...props} />} />
        <Route render={(props) => <Error404 {...props} />} />
      </Switch>
    </SocketProvider>
    // </AgencyContext.Provider>
  );
}

export default Admin;

import React, { useRef } from "react";
import { Row } from "reactstrap";
import moment from "moment";
import LoadingOverlay from "components/OverLay/LoadingOverlay";

function Comments({ comments, isLoading }) {
  const myRef = useRef();

  return (
    <>
      {isLoading && LoadingOverlay()}
      <Row>
        <div className="mesgs">
          <div className="msg_history">
            {comments &&
              comments.map((m, i) => (
                <div className="incoming_msg" key={i}>
                  <div className="received_msg">
                    <div className="received_withd_msg">
                      <p>{m.note}</p>
                      <div className="chat_list">
                        <div className="chat_people">
                          <div className="chat_ib">
                            <h5 style={{ marginRight: "4px" }}>{m.noteBy}</h5>
                            <span
                              style={{
                                color: "#747474",
                                fontSize: "12px",
                                marginLeft: "4px",
                                marginTop: "-6px",
                              }}
                            >
                              {moment(m.time).format("YYYY-MM-DD, hh:mm a") ||
                                moment(m.createdAt).format("YYYY-MM-DD, hh:mm a")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div ref={myRef} />
          </div>
        </div>
      </Row>
    </>
  );
}

export default Comments;

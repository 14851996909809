/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Select from "react-select";
//import { Prompt } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import AgencyContext from "../../../../context/AgencyContext";
import { validateEmail, getCurrentDate } from "components/Utilities/utilities.js";
import api from "components/API/api";
import { Business } from "@mui/icons-material";

class CreateNewStaff extends Component {
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.defaultState = {
      progress: 0,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      phone: null,
      businessPortalLock: false,
      role: { value: "BusinessStaff", label: "Business Staff" },
      business: null, // to select one business
      businessId: null,
      businesses: null, // to select multiple business
      rolesList: [
        { value: "BusinessStaff", label: "Business Staff" },
        { value: "BusinessHr", label: "Business Hr Manager" },
        { value: "BusinessShiftSupervisor", label: "Business Shift Supervisor" },
        { value: "BusinessQrSignin", label: "Business Qr Signin" },
      ],
      shiftTypes: [
        { value: "Morning", label: "Morning" },
        { value: "Afternoon", label: "Afternoon" },
        { value: "Night", label: "Night" },
      ],
      selectedShift: [], // supervisor shift

      // logged in user role
      loggedUserRole: null,

      //locations list
      businessesList: [],

      //Errors
      errorFirstName: "",
      errorLastName: "",
      errorPhone: "",
      errorEmail: "",
      errorRole: "",
      errorPassword: "",
      errorBusiness: "",
      errorSelectedShift: "",

      //Loading
      isLoading: false,
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    this.fetchBusinesses();
  }

  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  // fetch the business list
  fetchBusinesses = async () => {
    try {
      const { data } = await api().get(`/business/list`, {
        params: { slug: this.context.agency.agency.slug },
      });
      let tempBusinessList = [];
      data.map((business) => {
        tempBusinessList.push({
          value: business._id,
          label: business.name,
        });
      });
      this.setState({ businessesList: tempBusinessList });
    } catch (err) {
      console.log(err);
      console.log("Error while fetching business list");
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling
    if (tempState.firstName == null || tempState.firstName == "") {
      this.setState({ errorFirstName: "* Must have a first name." });
      hasError = true;
    } else {
      this.setState({ errorFirstName: "" });
    }

    if (tempState.lastName == null || tempState.lastName == "") {
      this.setState({ errorLastName: "* Must have a last name." });
      hasError = true;
    } else {
      this.setState({ errorLastName: "" });
    }

    if (tempState.phone == null || tempState.phone == "") {
      this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
      hasError = true;
    } else {
      if (tempState.phone.length != 10 || tempState.phone < 0) {
        this.setState({
          errorPhone: "* Please input a valid phone number in the format ###-###-####.",
        }); //invalid phone number
        hasError = true;
      } else {
        this.setState({ errorPhone: "" });
      }
    }

    if (tempState.email == null || tempState.email == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }

    if (tempState.password == null || tempState.password == "") {
      this.setState({ errorPassword: "* Must have a password" }); // No password text
    } else {
      if (tempState.password.length < 8) {
        this.setState({ errorPassword: "* The minimum password length is 8 characters" }); //invalid password text
        hasError = true;
      } else {
        this.setState({ errorPassword: "" });
      }
    }

    if (tempState.role == null || tempState.role == "") {
      this.setState({ errorRole: "* Must have a role" }); // No role
      hasError = true;
    } else {
      this.setState({ errorRole: "" });
    }

    if (tempState.role?.value == "BusinessShiftSupervisor" && !tempState.selectedShift?.length) {
      this.setState({ errorSelectedShift: "* Must select a shift type" }); // No shift type
      hasError = true;
    } else {
      this.setState({ errorSelectedShift: "" });
    }

    if (tempState.businesses == null || tempState.businesses == "") {
      this.setState({ errorBusiness: "* Must have a business" }); // No role
      hasError = true;
    } else {
      this.setState({ errorBusiness: "" });
    }

    if (hasError) {
      return;
    } else {
      this.prepareAndSendDataToServer(tempState); // runs function after loading is set as true.
    }
  };

  prepareAndSendDataToServer = async (tempState) => {
    this.setState({ isLoading: true }); // Start the loading
    // to load the values again after creating manager without fectching again
    //Upload the changelog into the deeper user history collection! This uploads the admin email, timestamp, and action taken
    let dataToSend = null; // Data prepared to send.
    let reloadBusinessList = this.state.businessesList; // save business list
    // let tempBusinessList = this.state.businesses.map((business) => business.value);
    let tempBusinessList =
      this.state.role?.value === "BusinessHr"
        ? this.state.businesses.map((business) => business.value)
        : [this.state.businesses.value];
    // console.log(tempBusinessList);

    dataToSend = {
      firstName: tempState.firstName,
      lastName: tempState.lastName,
      phone: tempState.phone,
      email: tempState.email,
      profileType: tempState.role.value,
      password: tempState.password,
      business: tempBusinessList,
      businessPortalLock: tempState.businessPortalLock,
    };

    if (tempState.role.value === "BusinessShiftSupervisor") {
      const shiftType = {
        Morning: false,
        Afternoon: false,
        Night: false,
      };
      this.state.selectedShift.map((shift) => {
        shiftType[`${shift.value}`] = true;
      });
      // console.log("shiftType", shiftType);
      dataToSend.shiftType = shiftType;
    }

    try {
      const resp = await api().post("/users/register-manager", dataToSend);
      // console.log("resp", resp);
      this.setState({
        ...this.defaultState,
        businessesList: reloadBusinessList,
        isLoading: false,
        alert: <SweetAlert success title="staff successfully created" onConfirm={this.hideAlert} />,
      });
    } catch (error) {
      console.log(error.response);
      this.setState({
        isLoading: false,
        alert: (
          <SweetAlert
            error
            title={error?.response?.data?.message || error.message}
            onConfirm={this.hideAlert}
          />
        ),
      });
      console.log(error);
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  render() {
    return (
      <div className="content">
        {/* <Prompt //prevent page jumps, temporarily disabled for testing
          when={true}
          message={() => "All the data will lost if you leave"}
        />*/}
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">Create New Business Staff</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="/" className="form-horizontal" method="get">
                    <Row>
                      <Label sm="2">* First Name</Label>

                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => this.setState({ firstName: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorFirstName}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Last Name</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => this.setState({ lastName: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorLastName}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Phone Number</Label>
                      <Col sm="5">
                        <FormGroup>
                          <MaskedInput
                            mask={[
                              "(",
                              /[1-9]/,
                              /\d/,
                              /\d/,
                              ")",
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            className="form-control"
                            autoComplete="off"
                            maxLength="15"
                            onChange={(e) => {
                              //Allows the user to type in any format. Only numbers will be added to the state
                              let input = e.target.value;
                              let replacedPhone = input.replace(/[^0-9]/g, "");
                              this.setState({ phone: replacedPhone });
                            }}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorPhone}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Email</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="email"
                            autoComplete="off"
                            onChange={(e) => this.setState({ email: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorEmail}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Password</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Input
                            type="text"
                            autoComplete="off"
                            onChange={(e) => this.setState({ password: e.target.value })}
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorPassword}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">* Role</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            // isDisabled={true}
                            options={this.state.rolesList}
                            onChange={(value) => {
                              this.setState({ role: value });
                            }}
                            value={this.state.role}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {this.state.role?.value === "BusinessShiftSupervisor" && (
                      <Row>
                        <Label sm="2">* Shift Types</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              isMulti
                              options={this.state.shiftTypes}
                              onChange={(value) => {
                                this.setState({ selectedShift: value });
                              }}
                              value={this.state.selectedShift}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorSelectedShift}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Label sm="2">* Businesses</Label>
                      <Col sm="5">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            isMulti={this.state.role?.value === "BusinessHr"}
                            options={this.state.businessesList}
                            value={this.state.businesses}
                            onChange={(value) => {
                              this.setState({ businesses: value });
                            }}
                            placeholder="Select businesses"
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorBusiness}
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "20px" }}>
                      <Label sm="6">Hide staff from Business Portal</Label>
                      <Col sm="4" className="checkbox-radios">
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={() =>
                                this.setState({
                                  businessPortalLock: !this.state.businessPortalLock,
                                })
                              }
                              checked={this.state.businessPortalLock}
                              id={"shiftBoard"}
                            />
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="9"></Col>
                      <Col sm="3">
                        <Button color="success" onClick={(e) => this.handleSubmit(e)}>
                          Create Staff
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {this.state.alert}
      </div>
    );
  }
}

export default CreateNewStaff;

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useContext } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Spinner,
  Label,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import { Visibility, Clear, AvTimer } from "@mui/icons-material";
import { Modal } from "@mui/material";
import { useHasPermissions } from "hooks/usePermissions";
import api from "components/API/api";
import { Input } from "reactstrap";
import AgencyContext from "context/AgencyContext";
import BusinessTab from "components/BusinessTab";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const shiftTypeEnum = { once: "once", single: "single", recurrent: "recurrent" };
const tabTypeEnum = {
  activeSingle: "activeSingle",
  deletedSingle: "deletedSingle",
  activeRecurring: "activeRecurring",
  expiredRecurring: "expiredRecurring",
};
const days = ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"];

function AllShifts(props) {
  const context = useContext(AgencyContext);
  const [pageTabs, setPageTabs] = useState(tabTypeEnum.activeRecurring);
  const [date, setDate] = useState(new Date());
  const [regularShifts, setRegularShifts] = useState([]);
  const [recurrentShifts, setRecurrentShifts] = useState([]);
  const shiftDataHeaders = [
    {
      label: "Day",
      key: "day",
    },
    {
      label: "Start Time",
      key: "startTime",
    },
    {
      label: "End Time",
      key: "endTime",
    },
    {
      label: "Business",
      key: "businessName",
    },
    {
      label: "Description",
      key: "description",
    },
    {
      label: "Pay/Hour",
      key: "payRate",
    },
    {
      label: "Required People",
      key: "noPeople",
    },
    {
      label: "Estimated Pay Date",
      key: "estimatedPayDate",
    },
  ];

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [selectedShiftId, setSelectedShiftId] = useState(null);
  const [shiftsModalOpen, setShiftsModalOpen] = useState(false);
  const [selectedWeeklySchedule, setSelectedWeeklySchedule] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const canDeleteShift = useHasPermissions({
    module: "Shifts",
    action: "Delete",
  });

  const [reactTableParams, setReactTableParams] = useState({
    page: 1,
    size: 20,
    field: "_id",
    sort: "desc",
    filter: "",
  });
  const [pages, setPages] = useState(0);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);

  useEffect(() => {
    switch (pageTabs) {
      case tabTypeEnum.activeRecurring:
        fetchRecurrentShiftsData(false); // @expired param as false
        break;
      case tabTypeEnum.expiredRecurring:
        fetchRecurrentShiftsData(true); // @expired param as true
        break;
      case tabTypeEnum.activeSingle:
        fetchOneTimeShiftsData(true); // @active param as true
        break;

      default: // @active param as false
        fetchOneTimeShiftsData(false);
        break;
    }
  }, [selectedBusiness]);

  const [alert, setAlert] = useState(null);

  const RenderActionButtons = (shift, weeklySchedule) => {
    console.log(shift);
    return (
      <div className="actions-right">
        <Button
          tag={Link}
          to={`/${context.agency.agency.slug}/admin/shift-profile/${
            shift.type == shiftTypeEnum.once ? shiftTypeEnum.single : shiftTypeEnum.recurrent
          }-${shift._id}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>{" "}
        {shift.type == shiftTypeEnum.once ? 
        null
        : (
          <Button
            onClick={() => {
              setSelectedShiftId(shift._id);
              setSelectedWeeklySchedule(weeklySchedule);
              setShiftsModalOpen(true);
            }}
            color="info"
            size="md"
            className="btn-link remove btn-icon"
          >
            <AvTimer style={{ marginLeft: -1 }} />
          </Button>
        )}
        {canDeleteShift && shift.active ? (
          <Button
            onClick={() => {
              setSelectedShiftId(shift._id);

              handleOpenDeleteModal();
            }}
            color="danger"
            size="md"
            className="btn-link remove btn-icon"
          >
            <Clear style={{ marginLeft: -1 }} />
          </Button>
        ) : null}
      </div>
    );
  };

  //Loading Overlay
  const hideAlert = () => {
    setAlert(null);
  };

  const loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={hideAlert}
        showConfirm={false}
      />
    );
  };

  const handleOpenDeleteModal = () => {
    setConfirmDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setConfirmDeleteModalOpen(false);
  };

  const deleteShift = async (shiftId) => {
    setIsLoading(true);
    try {
      const url =
        pageTabs === tabTypeEnum.activeSingle || pageTabs === tabTypeEnum.deletedSingle
          ? `/shift/${shiftId}`
          : `/recurrent-shift/${shiftId}`;
      await api().delete(url);
      pageTabs === tabTypeEnum.activeRecurring
        ? fetchRecurrentShiftsData(false)
        : fetchRecurrentShiftsData(true);
      setIsLoading(false);
      setAlert(
        <SweetAlert
          success
          title="Shift successfully deleted"
          onConfirm={hideAlert}
          showConfirm={true}
        />,
      );

      handleCloseDeleteModal();
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const confirmDeleteBody = () => {
    // console.log(selectedDate)
    return (
      <Modal
        open={confirmDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
      >
        <FormGroup style={{ width: 300, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Delete ?</h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => handleCloseDeleteModal()}>Cancel</Button>
              <Button color="danger" onClick={() => deleteShift(selectedShiftId)}>
                Delete
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const showWeeklyScheduleModal = () => {
    // console.log(selectedDate)
    return (
      <Modal
        open={shiftsModalOpen}
        onClose={() => {
          setShiftsModalOpen(false);
          setSelectedShiftId(null);
          setSelectedWeeklySchedule([]);
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10, padding: 20 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <ReactTable
                columns={[
                  {
                    Header: "Day",
                    accessor: "day",
                    maxWidth: 100,
                  },
                  {
                    Header: "Start",
                    accessor: "start",
                    maxWidth: 150,
                  },
                  {
                    Header: "End",
                    accessor: "end",
                    maxWidth: 150,
                  },
                ]}
                data={selectedWeeklySchedule}
                filterable={false}
                sortable={false}
                minRows={1}
                className="-striped -highlight primary-pagination"
                showPagination={false}
              />
            </div>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Button
                onClick={() => {
                  setShiftsModalOpen(false);
                  setSelectedShiftId(null);
                  setSelectedWeeklySchedule([]);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const fetchRecurrentShiftsData = async (expired, filterOptions = reactTableParams) => {
    setIsLoadingTable(true);
    let url = "recurrent-shift";
    const { data } = await api().get(url, {
      params: {
        ...filterOptions,
        expired,
        businessId: selectedBusiness?._id,
      },
    });
    // console.log(data);
    let shifts = data.recurrentShifts || [];

    // console.log(shifts);

    shifts = shifts.map((s, index) => {
      return {
        ...s,
        actions: RenderActionButtons(
          s,
          s.weekSchedule &&
            s.weekSchedule.map((day) => {
              return {
                day: days[day.day],
                start: moment()
                  .set({ hour: day.start.hour, minute: day.start.minute, second: day.start.second })
                  .format("hh:mm a"),
                end: moment()
                  .set({ hour: day.end.hour, minute: day.end.minute, second: day.end.second })
                  .format("hh:mm a"),
              };
            }),
        ),
        qualification: s.qualifications.map((q) => <p>{q.name}</p>),
        estimatedPayDateAccessor: moment(s.estimatedPayDate).format("MMM Do YY"),
        recurrentDays:
          s.weekSchedule &&
          s.weekSchedule.reduce((sum, current) => {
            sum += days[current.day] + ", ";
            return sum;
          }, ""),
      };
    });

    setRecurrentShifts(shifts);
    setPages(data.noPages);
    setIsLoadingTable(false);
  };

  const fetchOneTimeShiftsData = async (active, filterOptions = reactTableParams) => {
    setIsLoadingTable(true);
    let url = "/single-shift";
    const { data } = await api().get(url, {
      params: {
        // date: date,
        active,
        agencyId: context.agency.agency._id,
        businessId: selectedBusiness?._id,
        ...filterOptions,
      },
    });
    // console.log(data.shifts);
    let shifts = data.shifts || [];

    shifts = shifts.map((s, index) => ({
      ...s,
      actions: RenderActionButtons(s),
      qualification: s.qualifications.map((q) => <p>{q.name}</p>),
      startTime: moment(s.start).format("MMM Do YY, h:mm a"),
      endTime: moment(s.end).format("MMM Do YY, h:mm a"),
    }));
    // console.log("data", data);

    setRegularShifts(shifts);
    setPages(data.noPages);
    setIsLoading(false);
    setIsLoadingTable(false);
  };

  //<Button color="default" outline>Export All</Button>
  return (
    <>
      <div className="content">
        {isLoading ? (
          loadingOverlay()
        ) : (
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">All Shifts</CardTitle>
                </CardHeader>
                <CardBody>
                  <Nav
                    className="nav-pills-primary nav-pills-icons justify-content-center"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        role="tablist"
                        className={pageTabs === tabTypeEnum.activeRecurring ? "active" : ""}
                        onClick={() => {
                          setPageTabs(tabTypeEnum.activeRecurring);
                          fetchRecurrentShiftsData(false);
                        }}
                      >
                        <i className="now-ui-icons ui-2_settings-90" />
                        Active Recurring
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        role="tablist"
                        className={pageTabs === tabTypeEnum.expiredRecurring ? "active" : ""}
                        onClick={() => {
                          setPageTabs(tabTypeEnum.expiredRecurring);
                          fetchRecurrentShiftsData(true);
                        }}
                      >
                        <i className="now-ui-icons objects_umbrella-13" />
                        Expired Recurring
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        role="tablist"
                        className={pageTabs === tabTypeEnum.activeSingle ? "active" : ""}
                        onClick={() => {
                          setPageTabs(tabTypeEnum.activeSingle);
                          fetchOneTimeShiftsData(true);
                        }}
                      >
                        <i className="now-ui-icons ui-2_settings-90" />
                        Active One Time
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        role="tablist"
                        className={pageTabs === tabTypeEnum.deletedSingle ? "active" : ""}
                        onClick={() => {
                          setPageTabs(tabTypeEnum.deletedSingle);
                          fetchOneTimeShiftsData(false);
                        }}
                      >
                        <i className="now-ui-icons ui-2_settings-90" />
                        Deleted One Time
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <BusinessTab
                    onSelect={(business) => {
                      setSelectedBusiness(business);
                    }}
                  />
                  <ReactTable
                    getTrProps={(state, rowInfo, instance) => {
                      if (
                        (pageTabs === tabTypeEnum.deletedSingle ||
                          pageTabs === tabTypeEnum.activeSingle) &&
                        rowInfo?.row?._original &&
                        moment().isAfter(moment(rowInfo?.row?._original?.start))
                      ) {
                        return {
                          style: {
                            color: "#EE9999", //light red
                          },
                        };
                      }
                      return {};
                    }}
                    columns={[
                      {
                        Header: "Shift Name",
                        accessor: "shiftName",
                        filterable: true,
                      },
                      {
                        Header: "Business",
                        accessor: "businessName",
                      },
                      {
                        Header: "Qualifications",
                        accessor: "qualification",
                      },
                      pageTabs === tabTypeEnum.activeSingle ||
                      pageTabs === tabTypeEnum.deletedSingle
                        ? {
                            Header: "Start",
                            accessor: "startTime",
                            sortable: true,
                          }
                        : {
                            Header: "Pay/Hour",
                            accessor: "payRate",
                            maxWidth: 70,
                          },
                      pageTabs === tabTypeEnum.activeSingle ||
                      pageTabs === tabTypeEnum.deletedSingle
                        ? {
                            Header: "End",
                            accessor: "endTime",
                            sortable: true,
                          }
                        : {
                            Header: "Recurrent Days",
                            accessor: "recurrentDays",
                          },

                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        maxWidth: 160,
                      },
                    ]}
                    manual
                    loading={isLoadingTable}
                    pages={pages}
                    data={
                      pageTabs === tabTypeEnum.activeSingle ||
                      pageTabs === tabTypeEnum.deletedSingle
                        ? regularShifts
                        : recurrentShifts
                    }
                    onFetchData={(state, instance) => {
                      let field = null;
                      switch (field) {
                        case "startTime":
                          field = "start";
                          break;
                        case "endTime":
                          field = "end";
                          break;
                        case "estimatedPayDateAccessor":
                          field = "estimatedPayDate";
                          break;
                        case "businessName":
                          field = "business";
                          break;
                      }
                      let searchParams = {
                        page: state.page + 1,
                        size: state.pageSize,
                        field: field,
                        sort: state.sorted.length
                          ? state.sorted[0].desc
                            ? "desc"
                            : "asc"
                          : "desc",
                        filter: state.filtered,
                      };

                      console.log(searchParams);
                      setReactTableParams(searchParams);
                      pageTabs === tabTypeEnum.activeSingle ||
                      pageTabs === tabTypeEnum.deletedSingle
                        ? fetchOneTimeShiftsData(
                            pageTabs === tabTypeEnum.activeSingle ? true : false,
                            searchParams,
                          )
                        : fetchRecurrentShiftsData(
                            pageTabs === tabTypeEnum.activeRecurring ? false : true,
                            searchParams,
                          );
                    }}
                    // sorted={sorted}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom
                    /*
                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                      */
                    className="-striped -highlight primary-pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {alert}
        {confirmDeleteBody()}
        {showWeeklyScheduleModal()}
      </div>
    </>
  );
}

export default AllShifts;

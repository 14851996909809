import React, { useState, useEffect } from "react";
// reactstrap components
import { CardHeader, CardBody, CardTitle, Label, Button, FormGroup } from "reactstrap";
import ReactTable from "react-table";
import api from "components/API/api";
import moment from "moment";
import Select from "react-select";
import { Modal } from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingOverlay from "components/OverLay/LoadingOverlay";

function UserTrainingHistory({ userId }) {
  const [trainingHistory, setTrainingHistory] = useState([]);
  const [qualificationInviteHistory, setQualificationInviteHistory] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);
  const [selectedQualification, setSelectedQualification] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({});
  const [alert, setAlert] = useState(null);
  //react table states
  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 5,
    field: "completedDate",
    sort: "desc",
    filter: [],
  });
  const [loadingTable, setLoadingTable] = useState(false);
  const [pages, setPages] = useState(-1);

  useEffect(() => {
    getWorkerTrainingHistory();
    getWorkerQualificationInviteHistory();
    getQualificationList();
    setLoadingTable(true);
  }, []);

  const hideAlert = () => {
    setAlert(null);
  };

  //function to get history
  const getWorkerTrainingHistory = async (filterOptions = reactTableState) => {
    try {
      setIsLoading(true);
      let params = {
        ...filterOptions,
        userId,
      };
      const { data } = await api().get(`/training-history`, { params });
      let tempHistory = [];
      data.trainingHistories.map((training) => {
        let history = {
          completedDate: moment(training.completedDate).format("MMM Do YYYY, h:mm a"),
          expires: moment(training.expires).format("MMM Do YYYY, h:mm a"),
          name: training.name,
        };
        tempHistory.push(history);
      });
      setTrainingHistory(tempHistory);
      setIsLoading(false);
      setLoadingTable(false);
      setPages(data.noPages);
    } catch (e) {
      console.log("error loading training history", e);
      setIsLoading(false);
      setLoadingTable(false);
    }
  };

  const getWorkerQualificationInviteHistory = async () => {
    try {
      const { data } = await api().get(`/qualification-invite/history`, { params: { userId } });
      setQualificationInviteHistory(data);
    } catch (error) {
      console.log("could not fetch qualification invites", error);
    }
  };

  const getQualificationList = async () => {
    try {
      const { data } = await api().get("/qualification", { params: { size: 1000 } });
      setQualificationList(data.qualifications);
    } catch (error) {
      console.log("could not fetch qualification invites", error);
    }
  };

  const fetchData = async (filterOptions = reactTableState) => {
    try {
      if (trainingHistory) {
        const { data } = await api().get("/training-history", {
          params: {
            ...filterOptions,
            userId,
          },
        });
        let tempHistory = [];
        data.trainingHistories.map((training) => {
          let history = {
            completedDate: moment(training.completedDate).format("MMM Do YYYY, h:mm a"),
            expires: moment(training.expires).format("MMM Do YYYY, h:mm a"),
            name: training.name,
          };
          tempHistory.push(history);
        });
        setPages(data.noPages);
        setTrainingHistory(tempHistory);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const qualificationInviteModal = () => {
    return (
      <Modal
        open={openModal.qualificationInvite}
        onClose={handleCloseQualificationInviteModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "scroll",
          paddingTop: "25%",
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex", marginBottom: "8px" }}>
              <h3>Invite user to do a qualification</h3>
            </div>
            <h5>
              <b>Previous invitations: </b>
              {qualificationInviteHistory.length === 0 && <span>No previous invitations</span>}
            </h5>
            {qualificationInviteHistory.map((invite, i) => (
              <div key={i} style={{ marginBottom: "12px" }}>
                <p>Qualification: {invite?.qualification?.name}</p>
                <p>Invitation date: {moment(invite.createdAt).format("L")}</p>
                <p>Expiration date: {moment(invite.expires).format("L")}</p>
                <p>Used: {invite.used ? "Yes" : "No"}</p>
                <p>Code: {invite.code}</p>
                <p>Invited By: {invite.invitedBy || "-"}</p>
                <hr />
              </div>
            ))}

            <h5>
              <b>Send new qualification invitation:</b>
            </h5>
            <Select
              onChange={(value) => setSelectedQualification(value)}
              options={qualificationList.map((q) => ({ value: q._id, label: q.name }))}
            />

            <div style={{ justifyContent: "right", display: "flex", alignItems: "right" }}>
              <Button color="secondary" onClick={handleCloseQualificationInviteModal}>
                Cancel
              </Button>

              <Button color="primary" onClick={() => handleSendNewQualificationInvitation()}>
                Send new invitation
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const handleOpenQualificationInviteModal = () => {
    setOpenModal({ qualificationInvite: true });
  };

  const handleCloseQualificationInviteModal = () => {
    setOpenModal({});
  };

  const handleSendNewQualificationInvitation = async () => {
    handleCloseQualificationInviteModal();

    try {
      const { data } = await api().post("/qualification-invite", {
        user: userId,
        qualification: selectedQualification.value,
      });

      getWorkerQualificationInviteHistory();

      setAlert(
        <SweetAlert success title="New invitation sent" onConfirm={hideAlert} showConfirm={true}>
          Registered code is: {data.qualificationInvite.code}
        </SweetAlert>,
      );
    } catch (error) {
      setIsLoading(false);
      setAlert(
        <SweetAlert danger title="An error occurred" onConfirm={hideAlert} showConfirm={true}>
          {error.message}
        </SweetAlert>,
      );
      console.log(error);
    }
  };

  return (
    <div className="content">
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          <CardHeader style={{ justifyContent: "space-between", display: "flex" }}>
            <CardTitle tag="h4">Training History</CardTitle>
            <Button color="info" onClick={() => handleOpenQualificationInviteModal()}>
              Invite user to a new qualification
            </Button>
          </CardHeader>
          <CardBody>
            {trainingHistory.length > 0 ? (
              <ReactTable
                filterable
                columns={[
                  {
                    Header: "Training name",
                    accessor: "name",
                  },
                  {
                    Header: "Completed Date",
                    accessor: "completedDate",
                    maxWidth: 200,
                  },
                  {
                    Header: "expires",
                    accessor: "expires",
                    maxWidth: 200,
                  },
                ]}
                manual
                data={trainingHistory}
                onFetchData={(state, instance) => {
                  let searchParams = {
                    page: state.page + 1,
                    size: state.pageSize,
                    field: "completedDate",
                    sort: state.sorted.length && state.sorted[0].desc ? "desc" : "asc",
                    filter: state.filtered,
                  };
                  fetchData(searchParams);
                }}
                pages={pages}
                loading={loadingTable}
                defaultPageSize={5}
                pageSizeOptions={[5, 10, 20, 50]}
                showPaginationTop={true}
                showPaginationBottom={false}
                className="-striped -highlight primary-pagination"
              />
            ) : (
              <Label>No training history found for this user</Label>
            )}
          </CardBody>
        </>
      )}
      {qualificationInviteModal()}
      {alert}
    </div>
  );
}

export default UserTrainingHistory;

import React from "react";
import ReactTable from "react-table";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import * as utils from "components/Utilities/utilities.js";
//import { firebase, fs } from "components/Firebase/firebase.js";
import { CSVLink } from "react-csv";
import UploadCheque from "./UploadCheque";

class AllCheques extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chequeData: [],
      chequeDataHeaders: [
        {
          label: "HD ID",
          key: "hdId",
        },
        {
          label: "Cheque ID",
          key: "chequeId",
        },
        {
          label: "Amount ($)",
          key: "value",
        },
        {
          label: "Cheque Status",
          key: "status",
        },
      ],
      selectedChequeId: null,
      selectedDocId: null,
    };
  }
/*
  componentDidMount() {
    this.chequeDataRef = fs.collection("cheque_data");
    this.loadCheques();
  }

  loadCheques = () => {
    let tempArr = [];
    this.chequeDataRef
      .orderBy("createdAt", "desc")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          let tempData = { id: doc.id, ...doc.data() };
          console.log(doc);
          tempData.actions = this.renderActionButton(tempData.chequeId, doc.id);
          console.log("tempdata chequeId: ", tempData.chequeId);
          tempArr.push(tempData);
        });
      })
      .then(() => {
        this.setState({ chequeData: tempArr });
        utils.setStorageChequesData(tempArr);
        console.log("chequeData : ", tempArr);
        console.log("chequeDataHeaders : ", this.state.chequeDataHeaders);
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  };

  changeStatus = async (status, docId) => {
    this.setState({
      chequeData: this.state.chequeData.map((c) => {
        if (c.id === docId) {
          c.status = status;
        }
        return { ...c };
      }),
    });
    await this.chequeDataRef.doc(docId).update({ status });
  };

  renderActionButton = (chequeId, docId) => {
    const dropDown = (
      <UncontrolledDropdown direction="down">
        <DropdownToggle
          aria-expanded={false}
          aria-haspopup={true}
          caret
          className="btn-round"
          color="primary"
          data-toggle="dropdown"
          id="dropdownMenuButton"
          type="button"
        >
          Change Status
        </DropdownToggle>
        <DropdownMenu aria-labelledby="dropdownMenuButton">
          <DropdownItem
            onClick={() => {
              this.changeStatus("Unpaid", docId);
            }}
          >
            Unpaid
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              this.changeStatus("Paid", docId);
            }}
          >
            Paid
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
    return <div>{dropDown}</div>;
  };
*/
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <div className="d-flex justify-content-between">
                <FormGroup>
                  <CSVLink
                    data={this.state.chequeData}
                    headers={this.state.chequeDataHeaders}
                    filename={"Cheque_Data.csv"}
                  >
                    <Button color="default" outline>
                      Export All
                    </Button>
                  </CSVLink>
                </FormGroup>
                <UploadCheque loadCheques={this.loadCheques} />
              </div>
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">Cheques </CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    getTdProps={(state, rowInfo, column, instance) => ({
                      style: {
                        overflow: "visible",
                      },
                    })}
                    data={this.state.chequeData}
                    filterable
                    columns={[
                      {
                        Header: "badge id",
                        accessor: "badge",
                        maxWidth: 70,
                      },
                      {
                        Header: "Cheque ID",
                        accessor: "num",
                      },
                      {
                        Header: "Amount ($)",
                        accessor: "amount",
                      },
                      {
                        id: "status",
                        Header: "Cheque Status",
                        maxWidth: 170,
                        headerStyle: { textAlign: "left" },
                        accessor: (row) =>
                          row.status === "Unpaid"
                            ? "Unpaid"
                            : row.status === "Paid"
                              ? "Paid"
                              : "Error",

                        getProps: (state, rowInfo) => {
                          if (rowInfo && rowInfo.row) {
                            return {
                              style: {
                                color:
                                  rowInfo.row._original.status === "Unpaid"
                                    ? "green"
                                    : rowInfo.row._original.status === "Paid"
                                      ? "blue"
                                      : "red",
                              },
                            };
                          }
                          return {};
                        },
                      },
                      {
                        Header: "Status Change",
                        headerStyle: { textAlign: "left" },
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        width: 180,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight primary-pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default AllCheques;

import { Assessment, DirectionsBus, Visibility } from "@mui/icons-material";
import React, { useContext, useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  FormText,
  Row,
  Input,
} from "reactstrap";
import api from "../../../components/API/api";
import AgencyContext from "./../../../context/AgencyContext";
import { useHasPermissions } from "hooks/usePermissions";
import { Modal } from "@mui/material";
import Select from "react-select";
import ReactDateTime from "react-datetime";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { midDay, startOfDay, startOfNextDay } from "utils/dateUtils";
import { shiftTimeType } from "utils/dateUtils";
import ShiftTimingTypeEnum from "enums/shiftTiming";
import CustomCalendar from "components/Calendar/CustomCalendar";

function AllDrivers(props) {
  const context = useContext(AgencyContext);
  const [driverData, setDriverData] = useState([]);
  const [driverDataHeaders, setDriverDataHeaders] = useState([
    {
      label: "Driver HDID",
      key: "hdId",
    },
    {
      label: "Driver firstName",
      key: "firstName",
    },
    {
      label: "Driver lastName",
      key: "lastName",
    },
    {
      label: "Driver Email",
      key: "email",
    },
    {
      label: "Driver Phone Number",
      key: "phone",
    },
    {
      label: "Vehicle Capacity",
      key: "vehicleCapacity",
    },
  ]);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [hdId, setHdId] = useState(null);
  const [errorHdId, setErrorHdId] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [errorSelectedWorker, setErrorSelectedWorker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tableQuery, setTableQuery] = useState("");
  const [pages, setPages] = useState(0);
  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 5,
    field: "hdId",
    sort: "desc",
    filter: [],
  });
  const [timer, setTimer] = useState(null);
  const [alert, setAlert] = useState(null);
  const [date, setDate] = useState(new Date());
  const [note, setNote] = useState(null);
  const [errorNote, setErrorNote] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);
  const [startSearch, setStartSearch] = useState("Searching...");
  const [errorSelectedShift, setErrorSelectedShift] = useState(null);

  const [firstLoad, setFirstLoad] = useState(true);

  // useEffect(() => {
  //   setFirstLoad(false);
  //   !firstLoad && ;
  // }, [date]);

  const searchWorker = async (filterValue) => {
    try {
      const { data } = await api().get("/users", {
        params: {
          filter: [{ id: "hdId", value: filterValue }],
        },
      });
      console.log(data);
      let users = [];
      data.users.map((user) => {
        users.push({
          label: `${user.firstName} ${user.lastName} (${user.hdId})`,
          value: user._id,
          ...user,
        });
      });
      users.length === 1
        ? setSelectedWorker(users[0])
        : setStartSearch("Worker not found, please try again.");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const RenderActionButtons = (user) => {
    return (
      <div className="actions-right">
        <Button
          tag={Link}
          to={`/${context?.agency?.agency?.slug}/admin/driver-profile/${user._id}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>{" "}
        <Button
          onClick={() => handleOpenAssignModal(user)}
          color="warning"
          size="md"
          className="btn-link btn-icon"
        >
          <DirectionsBus style={{ marginLeft: -1 }} />
        </Button>{" "}
      </div>
    );
  };

  // Opens modal to assign user to driver
  const handleOpenAssignModal = (user) => {
    setOpenAssignModal(true);
    setSelectedDriver(user);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
    setSelectedDriver(null);
    setSelectedWorker(null);
    setSelectedShift(null);
    setHdId(null);
    setNote(null);
  };

  const disablePreviousDates = (current) => {
    const today = new Date();
    return moment(today).isBefore(current);
  };

  ////////////////////////////////////////////////////
  //ASSIGN USER
  ////////////////////////////////////////////////////
  const AssignModal = () => {
    return (
      <Modal
        open={openAssignModal}
        onClose={handleCloseAssignModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 450, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            {/* <div style={{ justifyContent: "center", display: "flex" }}> */}
            <Row>
              <Col md="12">
                <h5>Please Select Worker to Assign.</h5>
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md="3">Enter HDID</Col>
              <Col md="9">
                <Input
                  value={hdId}
                  placeholder="Enter HdId"
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value;
                    setHdId(e.target.value);
                    setErrorHdId(null);
                    setStartSearch(null);
                    setSelectedWorker(null);
                    console.log(value);
                    if (value > 10000 && value < 999999) {
                      setStartSearch("Searching...");
                      setTimeout(() => {
                        searchWorker(value);
                      }, 1000);
                    }
                  }}
                />
              </Col>
            </Row>
            {errorHdId && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorHdId}
                  </FormText>
                </Col>
              </Row>
            )}

            {!selectedWorker ? (
              hdId && (
                <Row>
                  <Col md="3"></Col>
                  <Col md="9">
                    <FormText color="info" tag="span">
                      {errorHdId ? null : startSearch}
                    </FormText>
                  </Col>
                </Row>
              )
            ) : (
              <>
                {selectedWorker && (
                  <Row>
                    <Col md="3"></Col>
                    <Col md="9" style={{ marginTop: 5, fontSize: 14 }}>
                      Full Name: {" " + selectedWorker.label}
                      <br />
                      Phone: {" " + selectedWorker.phone}
                      <br />
                      Email: {" " + selectedWorker.email}
                    </Col>
                  </Row>
                )}
              </>
            )}

            <Row style={{ marginTop: 10 }}>
              <Col md="3">Pickup Location/Note</Col>
              <Col md="9">
                <Input
                  value={note}
                  placeholder="pickup location or note"
                  type="text"
                  onChange={(e) => {
                    setNote(e.target.value);
                    setErrorNote(null);
                  }}
                />
              </Col>
            </Row>
            {errorNote && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorNote}
                  </FormText>
                </Col>
              </Row>
            )}

            <Row style={{ marginTop: 10 }}>
              {/* <Col md="3">Select Shift</Col>
              <Col md="9">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  options={shiftsList.map((b) => {
                    let timeOfDay = shiftTimeType(b.start);
                    return {
                      label:
                        b.businessName +
                        " : " +
                        timeOfDay +
                        ` @${moment(b.start).format("hh:mm a")}-${moment(
                          b.end,
                        ).format("hh:mm a")}`,
                      value: b._id,
                      business: b.business,
                      shift: b,
                    };
                  })}
                  defaultValue={selectedShift}
                  onChange={(value) => {
                    console.log(value);
                    setSelectedShift(value);
                    setErrorSelectedShift(null);
                  }}
                  placeholder="Select Shift to assign"
                />
              </Col> */}
              <Col md="3">Select Shift Timing</Col>
              <Col md="9">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  options={[
                    { value: "Morning", label: "Morning" },
                    { value: "Afternoon", label: "Afternoon" },
                    { value: "Night", label: "Night" },
                  ]}
                  defaultValue={selectedShift}
                  onChange={(value) => {
                    console.log(value);
                    setSelectedShift(value.value);
                    setErrorSelectedShift(null);
                  }}
                  placeholder="Select Shift Timing"
                />
              </Col>
            </Row>
            {errorSelectedShift && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorSelectedShift}
                  </FormText>
                </Col>
              </Row>
            )}
            {/* </div> */}
            <div style={{ justifyContent: "space-between", display: "flex", marginTop: 15 }}>
              <Button onClick={() => handleCloseAssignModal()}>Cancel</Button>
              <Button color="success" onClick={() => assignUser()}>
                Assign
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  //asign user function
  const assignUser = async () => {
    try {
      console.log(selectedShift);
      let hasError = false;
      if (!hdId || hdId.trim() === "") {
        hasError = true;
        setErrorHdId("* please enter hdId to search");
        return;
      }
      if (!selectedWorker?.value || selectedWorker?.value.trim() === "") {
        hasError = true;
        setErrorHdId("* please enter a valid hdId");
      }
      if (!note || note.trim() === "") {
        hasError = true;
        setErrorNote("* please add a note to driver e.g pick up location");
      }

      if (!selectedShift) {
        hasError = true;
        console.log(selectedShift);
        setErrorSelectedShift("* please select the shift");
      }
      if (hasError) {
        return;
      } else {
        let dataToSend = {
          driver: selectedDriver._id,
          employee: selectedWorker.value,
          date: midDay(date),
          start: startOfDay(date),
          end: startOfNextDay(date),
          note: note,
          //shiftTiming: shiftTimeType(selectedShift?.shift?.start),
          shiftTiming: selectedShift,
        };
        await api().post("/driver-pickup/assign-worker", dataToSend);
        handleCloseAssignModal();
        setAlert(
          <SweetAlert success title="Worker assigned successfully " onConfirm={hideAlert} />,
        );
      }
    } catch (error) {
      console.log(error);
      handleCloseAssignModal();
      setAlert(
        <SweetAlert
          error
          title={error.response.data.message || error.message}
          onConfirm={hideAlert}
        />,
      );
    }
  };

  //Loading Overlay
  const hideAlert = () => {
    setAlert(null);
  };

  const fetchData = async (filterOptions = reactTableState, date = date) => {
    setIsLoading(true);
    const { data } = await api().get("/users/get-drivers", {
      params: {
        ...filterOptions,
        start: startOfDay(date),
        end: startOfNextDay(date),
      },
    });
    setIsLoading(false);
    console.log(data);
    let users = data.users || [];
    users = users.map((u, index) => ({
      ...u,
      morning: u?.driverPickups[0]?.assignedEmployees?.filter(
        (user) => user.shiftTiming === "Morning",
      )?.length,
      afternoon: u?.driverPickups[0]?.assignedEmployees?.filter(
        (user) => user.shiftTiming === "Afternoon",
      )?.length,
      night: u?.driverPickups[0]?.assignedEmployees?.filter((user) => user.shiftTiming === "Night")
        ?.length,
      roleDisplayName: u.profile.map((p) => p.roleDisplayName || p.role).join(", "),
      actions: RenderActionButtons(u),
    }));
    setDriverData(users);
    setPages(data.noPages);
  };

  // render() {
  //<Button color="default" outline>Export All</Button>
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <FormGroup>
              <CSVLink data={driverData} headers={driverDataHeaders} filename={"Drivers_Data.csv"}>
                <Button color="default" outline>
                  Export All
                </Button>
              </CSVLink>
            </FormGroup>
            <Card className="no-transition">
              <CardHeader>
                <CardTitle tag="h4">All Drivers</CardTitle>
                <Row>
                  <Col md="12">
                    <CustomCalendar
                      inputProps={{
                        placeholder: "Date Picker Here",
                      }}
                      initialDate={date}
                      onChangeDate={(date) => {
                        fetchData(reactTableState, date);
                        setDate({ ...date });
                      }}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <ReactTable
                  columns={[
                    {
                      Header: "ID",
                      accessor: "hdId",
                      maxWidth: 70,
                      filterable: false,
                      sortable: false,
                    },
                    {
                      Header: "FirstName",
                      accessor: "firstName",
                      filterable: false,
                      sortable: false,
                    },
                    {
                      Header: "LastName",
                      accessor: "lastName",
                      filterable: false,
                      sortable: false,
                    },
                    {
                      Header: "Contact Email",
                      accessor: "email",
                      filterable: false,
                      sortable: false,
                    },
                    {
                      Header: "Contact Phone",
                      accessor: "phone",
                      filterable: false,
                      sortable: false,
                    },
                    {
                      Header: "Morning",
                      accessor: "morning",
                      filterable: false,
                      maxWidth: 70,
                    },
                    {
                      Header: "Afternoon",
                      accessor: "afternoon",
                      filterable: false,
                      sortable: false,
                      maxWidth: 70,
                    },
                    {
                      Header: "Night",
                      accessor: "night",
                      filterable: false,
                      sortable: false,
                      maxWidth: 70,
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                      maxWidth: 110,
                    },
                  ]}
                  manual
                  loading={isLoading}
                  pages={pages}
                  data={driverData}
                  onFetchData={(state, instance) => {
                    let searchParams = {
                      page: state.page + 1,
                      size: state.pageSize,
                      field: state.sorted.length ? state.sorted[0].id : "hdId",
                      sort: state.sorted.length ? (state.sorted[0].desc ? "desc" : "asc") : "desc",
                      filter: state.filtered,
                    };
                    setReactTableState(searchParams);
                    clearTimeout(timer);

                    setTimer(
                      setTimeout(() => {
                        setIsLoading(true);
                        fetchData(searchParams, date);
                      }, 1000),
                    );
                  }}
                  // sorted={sorted}
                  defaultPageSize={50}
                  showPaginationTop
                  showPaginationBottom
                  filterable
                  sortable
                  /*
                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                      */
                  className="-striped -highlight primary-pagination"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {AssignModal()}
        {alert}
      </div>
    </>
  );
  // }
}

export default AllDrivers;

import { Add, Clear, Visibility, Edit } from "@mui/icons-material";
import React, { useContext, useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Row,
  Input,
  FormText,
} from "reactstrap";
import api from "../../../components/API/api";
import AgencyContext from "./../../../context/AgencyContext";
import { useHasPermissions } from "hooks/usePermissions";
import CustomCalender from "components/Calendar/CustomCalendar";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "@mui/material";
import Select from "react-select";
import { startOfDay, startOfNextDay, midDay } from "utils/dateUtils";

const filterOptions = [
  { value: "all", label: "All Assigned Workers" },

  { value: "pickup/dropoff", label: "Two Pickups" },

  { value: "pickup", label: "One Pickup Only" },
  { value: "nothing", label: "No Pickup" },
];

function AssignedWorkers(props) {
  const context = useContext(AgencyContext);

  const [workerData, setWorkerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState({ value: "all", label: "All Assigned Workers" });
  const [workerDataHeaders, setWorkerDataHeaders] = useState([
    {
      label: "HDID",
      key: "employeeHdId",
    },
    {
      label: "Full Name",
      key: "employeeName",
    },
    {
      label: "Note",
      key: "note",
    },
    {
      label: "Shift Timing",
      key: "shiftTiming",
    },
    {
      label: "First Pickup",
      key: "firstPickup",
    },
    {
      label: "Second Pickup",
      key: "secondPickup",
    },
    {
      label: "Assigned By",
      key: "assignedBy",
    },
  ]);
  const [hdId, setHdId] = useState(null);
  const [driverId, setDriverId] = useState(null);
  const [errorHdId, setErrorHdId] = useState(null);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [errorSelectedWorker, setErrorSelectedWorker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableQuery, setTableQuery] = useState("");
  const [pages, setPages] = useState(0);
  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 5,
    field: "hdId",
    sort: "desc",
    filter: [],
  });
  const [timer, setTimer] = useState(null);
  const [alert, setAlert] = useState(null);
  const [date, setDate] = useState(new Date());
  const [modalOpen, setModalOpen] = useState(false);
  const [workersList, setWorkersList] = useState([]);
  const [note, setNote] = useState(null);
  const [errorNote, setErrorNote] = useState(null);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);
  const [errorSelectedShift, setErrorSelectedShift] = useState(null);
  const [startSearch, setStartSearch] = useState("Searching...");

  const searchWorker = async (filterValue) => {
    try {
      const { data } = await api().get("/users", {
        params: {
          filter: [{ id: "hdId", value: filterValue }],
        },
      });
      // console.log(data);
      let users = [];
      data.users.map((user) => {
        users.push({
          label: `${user.firstName} ${user.lastName} (${user.hdId})`,
          value: user._id,
          ...user,
        });
      });
      users.length === 1
        ? setSelectedWorker(users[0])
        : setStartSearch("Worker not found, please try again.");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const RenderActionButtons = (user) => {
    // console.log(user)
    return (
      <div className="actions-right">
        <Button
          onClick={() => handleOpenEditAssignModal(user)}
          color="warning"
          size="md"
          className="btn-link btn-icon"
        >
          <Edit style={{ marginLeft: -1 }} />
        </Button>{" "}
        <Button
          tag={Link}
          to={`/${context.agency.agency.slug}/admin/user-profile/${user.employee}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>{" "}
        {moment(date).isAfter(moment(new Date()).set({ hour: 0, minute: 0, second: 0 })) && (
          <>
            <Button
              onClick={() => handleOpenUnAssignModal(user)}
              color="danger"
              size="md"
              className="btn-link btn-icon"
            >
              <Clear style={{ marginLeft: -1 }} />
            </Button>{" "}
          </>
        )}
      </div>
    );
  };

  //Loading Overlay
  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    if (date) {
      //  console.log(date)
      fetchData();
    }
  }, [date]);

  useEffect(() => {
    if (filter) {
      filterData();
    }
  }, [filter]);

  const fetchData = async (filterOptions = reactTableState) => {
    try {
      let selectedUrl = window.location.pathname.split("/");
      let selectedDocId = selectedUrl[4];
      setDriverId(selectedDocId);
      setIsLoading(true);
      const { data } = await api().get("/driver-pickup/employees", {
        params: {
          // ...filterOptions,
          driverId: selectedDocId,
          start: startOfDay(date),
          end: startOfNextDay(date),
        },
      });
      setIsLoading(false);
      console.log("data", data);
      let users = data.employees || [];
      users = users.map((u, index) => ({
        ...u,
        assignedBy: u.autoAssigned ? "Driver (Auto-assigned)" : u.assignedBy,
        firstPickup: u.firstPickup
          ? moment(u.firstPickup.time).format("MMM Do YYYY, h:mm a").split(",")[1]
          : null,
        secondPickup: u?.secondPickup
          ? moment(u.secondPickup.time).format("MMM Do YYYY, h:mm a").split(",")[1]
          : null,
        actions: RenderActionButtons(u),
      }));
      setWorkerData(users);
      setFilteredData(users);
      setFilter({ value: "all", label: "Default (Assigned Workers)" });
      setPages(data.noPages);
    } catch (error) {
      console.log(error);
    }
  };

  //filter data based on selected filter option
  const filterData = () => {
    console.log("filter.value", filter.value);
    let tempData = [];
    if (filter.value === "all") {
      tempData = workerData;
    } else if (filter.value === "pickup/dropoff") {
      workerData.map((u) => {
        if (u.firstPickup && u.secondPickup) tempData.push(u);
      });
    } else if (filter.value === "pickup") {
      workerData.map((u) => {
        if (u.firstPickup && !u.secondPickup) tempData.push(u);
      });
    } else {
      workerData.map((u) => {
        if (!u.firstPickup && !u.secondPickup) tempData.push(u);
      });
    }
    setFilteredData(tempData);
  };

  // Opens modal to unassign user to worker
  const handleOpenUnAssignModal = (user) => {
    setModalOpen(true);
    setSelectedWorker(user);
  };

  const handleCloseUnAssignModal = () => {
    setModalOpen(false);
    setSelectedWorker(null);
  };

  const disablePreviousDates = (current) => {
    const today = new Date();
    return moment(today).isBefore(current);
  };

  ////////////////////////////////////////////////////
  //UNASSIGN USER
  ////////////////////////////////////////////////////
  const unAssignModal = () => {
    return (
      <Modal
        open={modalOpen}
        onClose={handleCloseUnAssignModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Are you sure want to unassign {selectedWorker?.firstName} {selectedWorker?.lastName}
                ?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => handleCloseUnAssignModal()}>Cancel</Button>
              <Button color="danger" onClick={() => unAssignUser()}>
                UnAssign
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  // unAssign user function
  const unAssignUser = async () => {
    try {
      let dataToSend = {
        driverId: driverId,
        employeeId: selectedWorker.employee,
        start: startOfDay(date),
        end: startOfNextDay(date),
      };
      await api().patch("/driver-pickup/unassign-employee", dataToSend);
      handleCloseUnAssignModal();
      setAlert(
        <SweetAlert success title="Worker unAssigned successfully " onConfirm={hideAlert} />,
      );
      fetchData();
    } catch (error) {
      console.log(error);
      handleCloseUnAssignModal();
      setAlert(
        <SweetAlert
          error
          title={error.response.data.message || error.message}
          onConfirm={hideAlert}
        />,
      );
    }
  };

  ////////////////////////////////////////////////////
  //ASSIGN USER
  ////////////////////////////////////////////////////
  const AssignModal = () => {
    return (
      <Modal
        open={openAssignModal}
        onClose={handleCloseAssignModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 450, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            {/* <div style={{ justifyContent: "center", display: "flex" }}> */}
            <Row>
              <Col md="12">
                <h5>Please Select Worker to Assign.</h5>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md="3">Enter HDID</Col>
              <Col md="9">
                <Input
                  value={hdId}
                  placeholder="Enter HdId"
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value;
                    setHdId(e.target.value);
                    setErrorHdId(null);
                    setStartSearch(null);
                    setSelectedWorker(null);
                    console.log(value);
                    if (value > 10000 && value < 999999) {
                      setStartSearch("Searching...");
                      setTimeout(() => {
                        searchWorker(value);
                      }, 1000);
                    }
                  }}
                />
              </Col>
            </Row>
            {errorHdId && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorHdId}
                  </FormText>
                </Col>
              </Row>
            )}

            {!selectedWorker ? (
              hdId && (
                <Row>
                  <Col md="3"></Col>
                  <Col md="9">
                    <FormText color="info" tag="span">
                      {errorHdId ? null : startSearch}
                    </FormText>
                  </Col>
                </Row>
              )
            ) : (
              <>
                {selectedWorker && (
                  <Row>
                    <Col md="3"></Col>
                    <Col md="9" style={{ marginTop: 5, fontSize: 14 }}>
                      Full Name: {" " + selectedWorker.label}
                      <br />
                      Phone: {" " + selectedWorker.phone}
                      <br />
                      Email: {" " + selectedWorker.email}
                    </Col>
                  </Row>
                )}
              </>
            )}

            <Row style={{ marginTop: 10 }}>
              <Col md="3">Pickup Location/Note</Col>
              <Col md="9">
                <Input
                  value={note}
                  placeholder="pickup location or note"
                  type="text"
                  onChange={(e) => {
                    setNote(e.target.value);
                    setErrorNote(null);
                  }}
                />
              </Col>
            </Row>
            {errorNote && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorNote}
                  </FormText>
                </Col>
              </Row>
            )}
            <Row style={{ marginTop: 10 }}>
              <Col md="3">Select Shift</Col>
              <Col md="9">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  options={[
                    { value: "Morning", label: "Morning" },
                    { value: "Afternoon", label: "Afternoon" },
                    { value: "Night", label: "Night" },
                  ]}
                  defaultValue={selectedShift}
                  onChange={(value) => {
                    console.log(value);
                    setSelectedShift(value);
                    setErrorSelectedShift(null);
                  }}
                  placeholder="Select Shift Timing"
                />
              </Col>
            </Row>
            {errorSelectedShift && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorSelectedShift}
                  </FormText>
                </Col>
              </Row>
            )}
            {/* </div> */}
            <div style={{ justifyContent: "space-between", display: "flex", marginTop: 15 }}>
              <Button onClick={() => handleCloseAssignModal()}>Cancel</Button>
              <Button color="success" onClick={() => assignUser()}>
                Assign
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  //asign user function
  const assignUser = async () => {
    try {
      let hasError = false;
      if (!hdId || hdId.trim() === "") {
        hasError = true;
        setErrorHdId("* please enter hdId to search");
        return;
      }
      if (!selectedWorker?.value || selectedWorker?.value.trim() === "") {
        hasError = true;
        setErrorHdId("* please enter a valid hdId");
      }
      if (!note || note.trim() === "") {
        hasError = true;
        setErrorNote("* please add a note to driver e.g pick up location");
      }
      if (!selectedShift) {
        hasError = true;
        setErrorSelectedShift("* please select the shift timing");
      }
      if (hasError) {
        return;
      } else {
        let dataToSend = {
          driver: driverId,
          employee: selectedWorker.value,
          date: midDay(date),
          start: startOfDay(date),
          end: startOfNextDay(date),
          note: note,
          shiftTiming: selectedShift.value,
        };
        await api().post("/driver-pickup/assign-worker", dataToSend);
        handleCloseAssignModal();
        fetchData();
        setAlert(
          <SweetAlert success title="Worker assigned successfully " onConfirm={hideAlert} />,
        );
      }
    } catch (error) {
      console.log(error);
      handleCloseAssignModal();
      setAlert(
        <SweetAlert
          error
          title={error.response.data.message || error.message}
          onConfirm={hideAlert}
        />,
      );
    }
  };

  // Opens modal to assign user to driver
  const handleOpenAssignModal = () => {
    setOpenAssignModal(true);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
    setSelectedWorker(null);
    setWorkersList([]);
    setSelectedShift(null);
    setHdId(null);
    setNote(null);
  };

  // Opens modal to edit assign user to driver
  const handleOpenEditAssignModal = (user) => {
    setOpenEditModal(true);
    setSelectedWorker(user);
    setNote(user?.assigned?.note)
    setSelectedShift({value: user?.assigned?.shiftTiming, label: user?.assigned?.shiftTiming})
  };

  const handleCloseEditAssignModal = () => {
    setOpenEditModal(false);
    setSelectedWorker(null);
    setSelectedShift(null);
    setNote(null);
  };

  ////////////////////////////////////////////////////
  // Edit Assign USER
  ////////////////////////////////////////////////////
  const editAssignModal = () => {
    return (
      <Modal
        open={openEditModal}
        onClose={handleCloseEditAssignModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 450, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            {/* <div style={{ justifyContent: "center", display: "flex" }}> */}
            <Row>
              <Col md="12">
                <h5>Edit Info.</h5>
              </Col>
            </Row>

          
            <Row style={{ marginTop: 10 }}>
              <Col md="3">Pickup Location/Note</Col>
              <Col md="9">
                <Input
                  value={note}
                  placeholder="pickup location or note"
                  type="text"
                  onChange={(e) => {
                    setNote(e.target.value);
                    setErrorNote(null);
                  }}
                />
              </Col>
            </Row>
            {errorNote && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorNote}
                  </FormText>
                </Col>
              </Row>
            )}
            <Row style={{ marginTop: 10 }}>
              <Col md="3">Select Shift</Col>
              <Col md="9">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  options={[
                    { value: "Morning", label: "Morning" },
                    { value: "Afternoon", label: "Afternoon" },
                    { value: "Night", label: "Night" },
                  ]}
                  defaultValue={selectedShift}
                  onChange={(value) => {
                    console.log(value);
                    setSelectedShift(value);
                    setErrorSelectedShift(null);
                  }}
                  placeholder="Select Shift Timing"
                />
              </Col>
            </Row>
            {errorSelectedShift && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {errorSelectedShift}
                  </FormText>
                </Col>
              </Row>
            )}
            {/* </div> */}
            <div style={{ justifyContent: "space-between", display: "flex", marginTop: 15 }}>
              <Button onClick={() => handleCloseEditAssignModal()}>Cancel</Button>
              <Button color="success" onClick={() => editAssignUser()}>
                Save
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  //edit asign user function
  const editAssignUser = async () => {
    try {
      let hasError = false;
      if (!note || note.trim() === "") {
        hasError = true;
        setErrorNote("* please add a note to driver e.g pick up location");
      }
      if (!selectedShift) {
        hasError = true;
        setErrorSelectedShift("* please select the shift");
      }
      if (hasError) {
        return;
      } else {
        let dataToSend = {
          driverId: driverId,
          employeeId: selectedWorker.employee,
          start: startOfDay(date),
          end: startOfNextDay(date),
          note: note,
          shiftTiming: selectedShift.value,
        };
        await api().patch("/driver-pickup/update-employee", dataToSend);
        handleCloseEditAssignModal();
        fetchData();
        setAlert(<SweetAlert success title="Worker edited successfully " onConfirm={hideAlert} />);
      }
    } catch (error) {
      console.log(error);
      handleCloseEditAssignModal();
      setAlert(
        <SweetAlert
          error
          title={error.response.data.message || error.message}
          onConfirm={hideAlert}
        />,
      );
    }
  };

  // render() {
  //<Button color="default" outline>Export All</Button>
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Row>
              <Col md="8">
                <FormGroup>
                  <CSVLink
                    data={workerData}
                    headers={workerDataHeaders}
                    filename={"Drivers_Data.csv"}
                  >
                    <Button color="default" outline>
                      Export All
                    </Button>
                  </CSVLink>
                </FormGroup>
              </Col>
              <Col md="4">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  options={filterOptions}
                  defaultValue={filter}
                  onChange={(value) => {
                    // console.log(value);
                    setFilter(value);
                  }}
                  placeholder="Select"
                />
              </Col>
            </Row>

            <Card className="no-transition">
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <CardTitle tag="h4">All Assigned Workers</CardTitle>
                </div>
                <CustomCalender
                  initialDate={date}
                  onChangeDate={(date) => {
                    setDate(date);
                    // fetchData(date);
                  }}
                />
                {moment(date).isAfter(
                  moment(new Date()).set({ hour: 0, minute: 0, second: 0 }),
                ) && (
                  <Row
                    style={{
                      margin: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button onClick={() => handleOpenAssignModal()} color="success" size="md">
                      Assign Worker
                    </Button>{" "}
                  </Row>
                )}
              </CardHeader>
              <CardBody>
                <ReactTable
                  columns={[
                    {
                      Header: "ID",
                      accessor: "employeeHdId",
                      // sortable: false,
                      // filterable: false,
                      maxWidth: 70,
                    },
                    {
                      Header: "Full Name",
                      accessor: "employeeName",
                      // sortable: false,
                      // filterable: false,
                    },
                    {
                      Header: "Note",
                      accessor: "note",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Shift Timing",
                      accessor: "shiftTiming",
                      // sortable: false,
                      // filterable: false,
                    },
                    {
                      Header: "First Pickup",
                      accessor: "firstPickup",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Second Pickup",
                      accessor: "secondPickup",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Assigned By",
                      accessor: "assignedBy",
                      // sortable: false,
                      // filterable: false,
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                      maxWidth: 160,
                    },
                  ]}
                  // manual
                  loading={isLoading}
                  pages={pages}
                  data={filteredData}
                  // onFetchData={(state, instance) => {
                  //   let searchParams = {
                  //     page: state.page + 1,
                  //     size: state.pageSize,
                  //     field: state.sorted.length ? state.sorted[0].id : "hdId",
                  //     sort: state.sorted.length ? (state.sorted[0].desc ? "desc" : "asc") : "desc",
                  //     filter: state.filtered,
                  //   };
                  //   setReactTableState(searchParams);
                  //   clearTimeout(timer);

                  //   setTimer(
                  //     setTimeout(() => {
                  //       setIsLoading(true);
                  //       fetchData(searchParams);
                  //     }, 1000),
                  //   );
                  // }}
                  getTrProps={(state, rowInfo, instance) => {
                    const autoAssigned = rowInfo?.row?.assignedBy === "Driver (Auto-assigned)";
                    if (autoAssigned) {
                      return {
                        style: {
                          background: "#ddeff5",
                        },
                      };
                    }
                    return {};
                  }}
                  // sorted={sorted}
                  defaultPageSize={50}
                  showPaginationTop
                  showPaginationBottom
                  filterable
                  sortable
                  /*
                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                      */
                  className="-striped -highlight primary-pagination"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {unAssignModal()}
        {AssignModal()}
        {editAssignModal()}
        {alert}
      </div>
    </>
  );
  // }
}

export default AssignedWorkers;

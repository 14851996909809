import { Modal } from "@mui/material";
import Axios from "axios";
import api from "components/API/api";
import ImageUpload from "components/CustomUpload/ImageUploadControlled.jsx";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import { validateSin } from "components/Utilities/utilities";
import { useModal } from "context/ModalContext";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDateTime from "react-datetime";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { FormGroup, CustomInput } from "reactstrap";
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import Fade from "reactstrap/lib/Fade";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import Row from "reactstrap/lib/Row";

const DocumentStatus = {
  pending: 0,
  valid: 1,
  invalid: 2,
};

// interface IDocument {
//   type: DocumentType;
//   status: DocumentStatus;
//   fileName: string;
//   documentNumber?: string;
//   expires?: Date;
// }

const DocumentType = {
  id: 0,
  workPermit: 1,
  sin: 2,
  studyPermit: 3,
  sinApplicationProof: 4,
  signature: 5,
  agreement: 6,
};

const DocumentTypeString = [
  "* Government Issued Photo ID (passport or driver's license)",
  "* Work Permit",
  "* Proof of Sin",
  "* Study Permit",
  "* Sin Application Proof",
  "* Signature",
  "* Agreement",
];

const DocumentStatusString = ["Pending Verification", "Valid/Verified", "Invalid"];

// const DocumentStatus = {
//   pending: 0,
//   valid: 1,
//   invalid: 2,
// };
const getDocumentType = (index) => {
  switch (index) {
    case 1:
      return DocumentType.id;
    case 3:
      return DocumentType.sin;
    case 5:
      return DocumentType.workPermit;
    case 7:
      return DocumentType.studyPermit;
    case 8:
      return DocumentType.signature;
  }
};

const blobTo64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

function generateFileName(len) {
  let text = "";
  const charset = "abcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < len; i++) text += charset.charAt(Math.floor(Math.random() * charset.length));
  return text;
}

const EditImageModal = ({
  addImageModal,
  closeAddImageModal,
  document,
  onUpdate,
  onAdd,
  firstName,
  lastName,
  hdId,
}) => {
  const [_loading, _setLoading] = useState(false);
  const [image, setImage] = useState();
  const [docType, setDocType] = useState({
    value: "",
    label: "",
  });
  const [documentNumber, setDocumentNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [documentStatus, setDocumentStatus] = useState({
    value: DocumentStatus.valid,
    label: DocumentStatusString[DocumentStatus.valid],
  });
  const [showImageModal, setShowImageModal] = useState(false);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [invalidFile, setInvalidFile] = useState(false);
  const { id } = useParams();
  const { addAlert } = useModal();
  const [error, setError] = useState("");

  useEffect(() => {
    if (document) {
      document.expires && setExpiryDate(moment(document.expires).toDate());
      (document.status || document.status === 0) &&
        setDocumentStatus({
          value: document.status,
          label: DocumentStatusString[document.status],
        });
      (document.type || document.type === 0) &&
        setDocType({ value: document.type, label: DocumentTypeString[document.type] });
      document.documentNumber && setDocumentNumber(document.documentNumber);
    }
  }, [document]);

  const uploadFile = (file, signedRequest) => {
    Axios.put(signedRequest, file)
      .then(() => {
        console.log("successfully uploaded the image");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleImages = async (image, fileName) => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("file-path", `userDocs/${id}/${fileName}`);
    formData.append("file-type", image.type);
    formData.append("documentType", docType.value);
    formData.append("userHdId", hdId);
    formData.append("userFirstName", firstName);
    formData.append("userLastName", lastName);

    api()
      .post("/image/sign-s3/put", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        // console.log("data");
        uploadFile(image, data.signedUrl);
        return true;
      })
      .catch((e) => console.log(e));
  };

  const update = async (imageId, userId, updates) => {
    try {
      await api().patch("/users/documents", {
        updates,
        userId: userId,
        documentId: imageId,
      });

      addAlert({
        title: "User successfully updated",
        success: true,
      });
    } catch (e) {
      addAlert({
        title: e.response?.data?.message || e.message,
        error: true,
      });
    } finally {
      setShowImageModal(false);
    }
  };

  const insert = async (userId, document) => {
    try {
      const { data } = await api().post("/users/documents", {
        document,
        userId: userId,
      });

      addAlert({
        title: "Document successfully updated",
        success: true,
      });
      return data._id;
    } catch (e) {
      addAlert({
        title: e.response?.data?.message || e.message,
        error: true,
      });
    } finally {
      setShowImageModal(false);
    }
  };

  const ImageModal = () => {
    return (
      <Modal
        open={showImageModal}
        onClose={() => {
          setShowImageModal(false);
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showImageModal}>
          <div>
            <img
              src={document?.imagePreviewUrl}
              alt="..."
              // style={{ maxHeight: "100%", maxWidth: "100%" }}
              style={{
                margin: "auto",
                display: "block",
                width: "auto",
                maxHeight: "90vh",
                maxWidth: "80%",
                height: "auto",
                // border: "5px solid #fff",
                borderRadius: 5,
              }}
            />
          </div>
        </Fade>
        {/* </FormGroup> */}
      </Modal>
    );
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    setImage(file);
  };

  return (
    <>
      <Modal
        open={addImageModal}
        onClose={closeAddImageModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 600, backgroundColor: "#fff", borderRadius: 10 }}>
          {_loading && <LoadingOverlay />}
          <div style={{ padding: 25, marginTop: 20 }}>
            <Row className="d-flex justify-content-center mt-2">
              {docType.value === DocumentType.agreement ? (
                <CustomInput
                  style={{
                    paddingBottom: 40,
                  }}
                  type="file"
                  id="exampleCustomFileBrowser"
                  name="customFile"
                  accept={"application/pdf"}
                  label={"choose an agreement file"}
                  onChange={handleFileChange}
                  invalid={invalidFile}
                />
              ) : (
                <ImageUpload
                  localImage={{ imagePreviewUrl: document?.imagePreviewUrl }}
                  handleImages={(_image) => {
                    // handleImages(_image, image.fileName);
                    setImage(_image);
                  }}
                  handleRemove={() => {
                    setImage(null);
                  }}
                  onSelect={() => {
                    setShowImageModal(true);
                  }}
                />
              )}
            </Row>
            <Row className="mt-2">
              <Col md="4">Document Type</Col>{" "}
              <Col md="8">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  options={Object.values(DocumentType).map((docType) => ({
                    label: DocumentTypeString[docType],
                    value: docType,
                  }))}
                  value={docType}
                  onChange={(value) => {
                    setDocType(value);
                  }}
                />
              </Col>
            </Row>
            {(docType.value === DocumentType.sin ||
              docType.value === DocumentType.sinApplicationProof) && (
              <Row className="mt-2">
                <Col md="4">Document Number</Col>{" "}
                <Col md="8">
                  <Input
                    maxLength={docType.value === DocumentType.sin ? "9" : "10"}
                    value={documentNumber}
                    onChange={(e) => {
                      setDocumentNumber(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            )}
            {((docType.value === DocumentType.sin && documentNumber.startsWith("9")) ||
              docType.value === DocumentType.studyPermit ||
              docType.value === DocumentType.workPermit) && (
              // <Row className="mt-2">
              //   <Col md="4">Expiry Date</Col>

              //   <Col md="6" className="checkbox-radios">
              //     <FormGroup check inline>
              //       <Label check>
              //         <Input
              //           type="checkbox"
              //           onChange={() => setNeverExpires(!neverExpires)}
              //           checked={neverExpires}
              //           id={"shiftBoard"}
              //         />
              //         Never Expires
              //         <span className="form-check-sign" />
              //       </Label>
              //     </FormGroup>
              //   </Col>
              // </Row>
              <Row className="mt-2">
                <Col md="4">Expiry Date</Col>
                <Col md="6" className="checkbox-radios">
                  <ReactDateTime
                    defaultValue={expiryDate ? moment(expiryDate).format("MM/DD/YYYY") : ""}
                    timeFormat={false}
                    closeOnSelect={true}
                    value={expiryDate ? moment(expiryDate).format("MM/DD/YYYY") : ""}
                    onChange={async (input) => {
                      //
                      setExpiryDate(input);
                    }}
                  />
                </Col>
              </Row>
            )}

            <Row className="mt-2">
              <Col md="4">Document Status</Col>{" "}
              <Col md="8">
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  options={Object.values(DocumentStatus).map((status) => ({
                    label: DocumentStatusString[status],
                    value: status,
                  }))}
                  value={documentStatus}
                  onChange={(value) => {
                    setDocumentStatus(value);
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md="4"></Col>{" "}
              <Col md="8">
                <p style={{ color: "red" }}>{error}</p>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-2">
              <Button
                onClick={async () => {
                  // _setLoading(true);
                  // await addDocument();
                  if (document) {
                    const updates = {};
                    if (document.status !== documentStatus.value) {
                      updates.status = documentStatus.value;
                    }
                    if (document.type !== docType.value) {
                      updates.type = docType.value;
                    }
                    if (
                      docType.value === DocumentType.sin ||
                      docType.value === DocumentType.sinApplicationProof
                    ) {
                      if (!documentNumber) {
                        setError(
                          "Must add document number for sin number or sin application proof",
                        );
                        return;
                      }
                    }

                    if (
                      docType.value === DocumentType.sin &&
                      validateSin(documentNumber) === false
                    ) {
                      setError("Must enter valid sin number");
                      return;
                    } else if (documentNumber !== document.documentNumber) {
                      updates.documentNumber = documentNumber;
                    }

                    if (
                      docType.value === DocumentType.sinApplicationProof &&
                      documentNumber.length !== 10
                    ) {
                      setError("Must enter valid sin application proof");
                      return;
                    } else if (documentNumber !== document.documentNumber) {
                      updates.documentNumber = documentNumber;
                    }

                    if (
                      (docType.value === DocumentType.sin && documentNumber?.startsWith("9")) ||
                      docType.value === DocumentType.studyPermit ||
                      docType.value === DocumentType.workPermit
                    ) {
                      if (
                        !expiryDate &&
                        docType.value === DocumentType.sin &&
                        documentNumber?.startsWith("9")
                      ) {
                        setError("Must add valid expiry date!");
                        return;
                      } else if (!moment(document.expires).isSame(expiryDate, "day")) {
                        updates.expires = moment(expiryDate).format();
                      }
                    } else if (
                      docType.value === DocumentType.sinApplicationProof 
                    ) {
                      updates.expires = moment().add(100, "years").format();
                    } else if (!moment(document.expires).isAfter(moment().add(50, "years"))) {
                      updates.expires = moment().add(100, "years").format();
                    }
                    if (Object.keys(updates).length <= 0) {
                      setError("No change!");
                      return;
                    }

                    _setLoading(true);
                    // console.log("HERE")
                    await update(document._id, id, updates);
                    onUpdate(updates);
                    _setLoading(false);
                    closeAddImageModal();
                  } else {
                    if (!image) {
                      setError("Must add an image!");
                      return;
                    }
                    const fileName = `${docType.value}_${generateFileName(4)}.jpeg`;
                    const newDocument = {
                      status: documentStatus.value,
                      type: docType.value,
                      fileName,
                    };
                    if (
                      docType.value === DocumentType.sin ||
                      docType.value === DocumentType.sinApplicationProof
                    ) {
                      if (!documentNumber) {
                        setError(
                          "Must add document number for sin number or sin application proof",
                        );
                        return;
                      } else {
                        newDocument.documentNumber = documentNumber;
                      }
                    }
                    if (
                      (docType.value === DocumentType.sin && documentNumber?.startsWith("9")) ||
                      docType.value === DocumentType.studyPermit ||
                      docType.value === DocumentType.workPermit
                    ) {
                      if (!expiryDate || moment(expiryDate).isBefore(moment())) {
                        setError("Must add valid expiry date!");
                        return;
                      } else {
                        newDocument.expires = moment(expiryDate).format();
                      }
                    } else if (
                      docType.value === DocumentType.sin &&
                      !documentNumber?.startsWith("9")
                    ) {
                      newDocument.expires = moment().add(100, "years").format();
                    } else {
                      newDocument.expires = moment().add(100, "years").format();
                    }

                    _setLoading(true);
                    const base64 = await blobTo64(image);
                    await handleImages(image, fileName);
                    const documentId = await insert(id, newDocument);
                    // console.log('base64', base64);
                    // console.log('image', image);
                    onAdd({ ...newDocument, _id: documentId, imagePreviewUrl: base64 });
                    _setLoading(false);
                    closeAddImageModal();
                  }
                }}
                color="danger"
              >
                {document ? "Update Document" : "Add Document"}
              </Button>
              <Button onClick={closeAddImageModal}>Close</Button>
            </Row>
          </div>
        </FormGroup>
      </Modal>

      <ImageModal />
    </>
  );
};

export default EditImageModal;

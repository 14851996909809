import { Assessment, Visibility } from "@mui/icons-material";
import React, { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";
// reactstrap components
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Row } from "reactstrap";
import api from "../../../components/API/api";
import AgencyContext from "./../../../context/AgencyContext";
import { useHasPermissions } from "hooks/usePermissions";

function AllStaff(props) {
  const context = useContext(AgencyContext);
  const [staffData, setStaffData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 5,
    field: "hdId",
    sort: "desc",
    filter: [],
  });
  const canViewAnalytics = useHasPermissions({ module: "Analytics", action: "View" });
  const [timer, setTimer] = useState(null);
  const staffDataHeaders = [
      {
        label: "Staff HDID",
        key: "hdId",
      },
      {
        label: "Staff firstName",
        key: "firstName",
      },
      {
        label: "Staff lastName",
        key: "lastName",
      },
      {
        label: "Contact Email",
        key: "email",
      },
      {
        label: "Contact Phone Number",
        key: "phone",
      },
    ];
  
  const reactTableColumns = [
      {
        Header: "ID",
        accessor: "hdId",
        maxWidth: 70,
      },
      {
        Header: "FirstName",
        accessor: "firstName",
      },
      {
        Header: "LastName",
        accessor: "lastName",
      },
      {
        Header: "Contact Email",
        accessor: "email",
      },
      {
        Header: "Contact Phone",
        accessor: "phone",
      },
      {
        Header: "Role",
        accessor: "roleDisplayName",
        filterable: false,
      },
      {
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        filterable: false,
        maxWidth: 110,
      },
    ];
  
  const defaultPageSize = 50

  const RenderActionButtons = (docId) => {
    return (
      <div className="actions-right">
        <Button
          tag={Link}
          to={`/${context.agency.agency.slug}/admin/staff-profile/${docId}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility />
        </Button>
        {canViewAnalytics ? (
          <Button
            tag={Link}
            to={`/${context.agency.agency.slug}/admin/staff-analytics/${docId}`}
            onClick={() => {
              handleOpenStatistics(docId);
            }}
            color="black"
            size="md"
            className="btn-link btn-icon"
          >
            <Assessment />
          </Button>
        ) : null}
      </div>
    );
  };

  const handleOpenStatistics = (docId) => {
    let path = `/${context.agency.agency.slug}/admin/staff-analytics/${docId}`;
    props.history.push(path);
  };

  const fetchData = async (filterOptions = reactTableState) => {
    setIsLoading(true);
    const { data } = await api().get("/users/get-managers", {
      params: {
        ...filterOptions,
        includeAdmin: false,
      },
    });
    setIsLoading(false);
    let users = data.users || [];
    users = users.map((u, index) => ({
      ...u,
      roleDisplayName: u.profile.map((p) => p.roleDisplayName || p.role).join(", "),
      actions: RenderActionButtons(u._id),
    }));
    setStaffData(users);
    setPages(data.noPages);
  };

  return (
    <div className="content">
      <FormGroup>
        <CSVLink data={staffData} headers={staffDataHeaders} filename={"Staff_Data.csv"}>
          <Button color="default" outline>
            Export All
          </Button>
        </CSVLink>
      </FormGroup>
      <Card className="no-transition">
        <CardHeader>
          <CardTitle tag="h4">All Staff</CardTitle>
        </CardHeader>
        <CardBody>
          <ReactTable
            columns={reactTableColumns}
            manual
            loading={isLoading}
            pages={pages}
            data={staffData}
            onFetchData={(state, instance) => {
              let searchParams = {
                page: state.page + 1,
                size: state.pageSize,
                field: state.sorted.length ? state.sorted[0].id : "hdId",
                sort: state.sorted.length ? (state.sorted[0].desc ? "desc" : "asc") : "desc",
                filter: state.filtered,
              };
              setReactTableState(searchParams);
              clearTimeout(timer);
              setTimer(
                setTimeout(() => {
                  setIsLoading(true);
                  fetchData(searchParams);
                }, 1000),
              );
            }}
            defaultPageSize={defaultPageSize}
            showPaginationTop
            showPaginationBottom
            filterable
            sortable
            className="-striped -highlight primary-pagination"
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default AllStaff;

import { Modal } from "@mui/material";
import api from "components/API/api";
import React, { useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";

const VaccinationCell = ({ _id, value, onChange }) => {
  const [newValue, setNewValue] = useState(parseInt(value));
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleUpdate = () => {
    if (newValue !== value) {
      const data = {
        vaccineStatus: newValue,
        userId: _id,
      };
      setLoading(true);
      api()
        .patch("/users", data)
        .then(() => {
          setLoading(false);
          setOpenModal(false);
          onChange(newValue, _id);
        })
        .catch(() => {
          setLoading(false);
          setNewValue(value);
        });
    } else {
      setNewValue(value);
    }
  };

  return (
    <div className="w-100 h-100" style={{ cursor: "pointer" }}>
      {loading ? (
        <Spinner />
      ) : openModal ? (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
            <div style={{ padding: 15 }}>
              <div style={{ justifyContent: "center", display: "flex", margin: 20 }}>
                <h5>Vaccination Status</h5>
              </div>
              <Row>
                <Col className="checkbox-radios" sm="10">
                  <div className="form-check-radio" style={{ marginTop: 8 }}>
                    <Label check>
                      <Input
                        id="0"
                        name="40hRadio"
                        type="radio"
                        defaultChecked={value === 0 ? true : false}
                        onChange={() => setNewValue(0)}
                      />
                      No Vaccine <span className="form-check-sign" />
                    </Label>
                  </div>
                  <div className="form-check-radio">
                    <Label check>
                      <Input
                        defaultValue
                        id="1"
                        name="40hRadio"
                        type="radio"
                        defaultChecked={value === 1 ? true : false}
                        onChange={() => setNewValue(1)}
                      />
                      1st dose <span className="form-check-sign" />
                    </Label>
                  </div>
                  <div className="form-check-radio">
                    <Label check>
                      <Input
                        defaultValue
                        id="2"
                        name="40hRadio"
                        type="radio"
                        defaultChecked={value === 2 ? true : false}
                        onChange={() => setNewValue(2)}
                      />
                      Fully Vaccinated <span className="form-check-sign" />
                    </Label>
                  </div>
                </Col>
              </Row>
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <Button
                  color="secondary"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  Close
                </Button>
                <Button
                  color="success"
                  onClick={() => {
                    handleUpdate();
                  }}
                >
                  Update
                </Button>
              </div>
            </div>
          </FormGroup>
        </Modal>
      ) : (
        <p onClick={() => setOpenModal(true)} className="w-100 h-100">
          {value === 0
            ? "No Vaccine"
            : value === 1
            ? "1st dose"
            : value === 2
            ? "Fully Vaccinated"
            : ""}
        </p>
      )}
    </div>
  );
};

export default VaccinationCell;

import { Modal } from "@mui/material";
import api from "components/API/api";
import { useModal } from "context/ModalContext";
import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Spinner } from "reactstrap";

const ShiftLength = ({ shiftLength, payRate, timeSheetId, onChange, type }) => {
  const [openShiftLengthModal, setOpenShiftLengthModal] = useState(false);
  const [hours, setHours] = useState(shiftLength);
  const [minutes, setMinutes] = useState();
  const [loading, setLoading] = useState();
  const { addAlert } = useModal();

  const ShiftLengthModal = () => {
    return (
      <Modal
        open={openShiftLengthModal}
        onClose={() => setOpenShiftLengthModal(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 400, backgroundColor:  "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex", margin: 20 }}>
              <h5>{ type == "shiftLength" ? "Shift Length" : "Break Duration"}</h5>
            </div>
            <FormGroup row className="align-items-right">
              <Col sm={6}>
                {/* <Label>Total Length</Label> */}
                <Input
                  type="number"
                  min={0}
                  // max={13}
                  value={hours}
                  onChange={(e) => {
                    let value = e.target.value;
                    if(value >= 0)
                    setHours(value);
                  }}
                />
              {/* </Col>
              <Col sm={4}>
              <Label>Min</Label>
                <Input
                  type="number"
                  min={0}
                  max={59}
                  value={minutes}
                  onChange={(e) => {
                    let value = e.target.value;
                    if(value >=0 && value <=59)
                    setMinutes(value);
                  }}
                /> */}
              </Col>
              <Col>
              {/* <Label>Total Hours</Label> */}
                <Button
                  onClick={() => hours > 0 && updateShiftLength()}
                  color="success"
                  size="md"
                  outline
                  className="btn m-0 w-100"
                >
                  {shiftLength ? "Update" : "Add"}
                  {loading ? <Spinner size="sm" /> : null}
                </Button>
              </Col>{" "}
            </FormGroup>
          </div>

          <div style={{ justifyContent: "center", display: "flex" }}>
            <Button
              color="secondary"
              onClick={() => {
                setOpenShiftLengthModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const updateShiftLength = async()=>{
   try {
     console.log(payRate)
     const dataToUpdate = type === "shiftLength" ? {  
       totalHours: hours,
       grossPay: parseFloat(hours * (payRate || 0)).toFixed(2)
       } : { breakDuration: hours };
     const {data } = await api().patch(`/timesheets/${timeSheetId}`, dataToUpdate);
     console.log('resp', data);
     onChange();
     setOpenShiftLengthModal(false);

   } catch (error) {
     console.log("Error updating shift length", error)
   }
  }

  return (
    <div className="w-100 h-100">
      {shiftLength ? (
        <p
          onClick={() => setOpenShiftLengthModal(true)}
          className="w-100 h-100"
          style={{
            fontWeight: 400,
            cursor: "pointer",
          }}
        >
          {shiftLength}
        </p>
      ) : (
        <p
          onClick={() => setOpenShiftLengthModal(true)}
          className="w-100 h-100"
          style={{ fontWeight: 400, cursor: "pointer" }}
        >
          -
        </p>
      )}

      {ShiftLengthModal()}
    </div>
  );
};

export default ShiftLength;

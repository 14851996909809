// const removeUser = () => {
//   saveUserToLocalStorage({});
//   saveProfileToLocalStorage({});
// };

// const saveUserToLocalStorage = (user) => {
//   localStorage.setItem("agencyUser", JSON.stringify(user));
//   return user;
// };
// const saveProfileToLocalStorage = (user) => {
//   localStorage.setItem("agencyUserProfile", JSON.stringify(user));
//   return user;
// };

// const saveAgencyToLocalStorage = (user) => {
//   localStorage.setItem("agency", JSON.stringify(user));
//   return user;
// };

const agencyReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.payload.user };
    case "SET_PROFILE":
      return { ...state, profile: action.payload.profile };
    case "REMOVE_USER":
      return { ...state, user: {}, profile: {} };
    case "SET_AGENCY":
      return { ...state, agency: action.payload.agency };
    case "SET_LIVE_BUSINESSES":
      return { ...state, liveBusinesses: action.payload.liveBusinesses };

    case "SET_SELECTED_LIVE_BUSINESS":
      return { ...state, selectedLiveBusiness: action.payload.selectedLiveBusiness };
    case "SET_SELECTED_LIVE_DATE":
      return { ...state, selectedLiveDate: action.payload.selectedLiveDate };
    case "SET_SELECTED_LIVE_SHIFT":
      return { ...state, selectedLiveShift: action.payload.selectedLiveShift };
    // case "UPDATE_UNREAD_MESSAGES":
    //   return { ...state, unreadMessages: action.unread };
    case "SET_TIMEOUT":
      if (state.timeout) {
        clearTimeout(state.timeout);
        const newTimeout = setTimeout(() => {
          action.payload.timeout_callback();
        }, 1 * 60 * 1000);
        return { ...state, timeout: newTimeout };
      }
    case "SET_BUSINESS_LIST":
      return { ...state, businesses: action.payload.businesses };
    default:
      return state;
  }
};

export default agencyReducer;

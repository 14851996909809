import React, { useEffect, useState } from "react";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";
import api from "components/API/api";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import AvailabilityTable from "./AvailabilityTable";
import { handleErrors } from "./CreateNewUserFunctions";
import BulkUploadInfoModal from "./BulkUploadInfoModal";
import BulkCreateUser from "./BulkCreateUser";
import BulkWorkerUploadTemplate from "../../BulkWorkerUploadTemplate.csv";
import {
  workStatusOptions,
  provinceOptions,
  maskedPhone,
  maskedPostalCode,
  maskedSin,
  sinInstruction,
} from "./CreateNewUserDataset";
import {
  emergencyContactGap,
  lineStyle,
  loadingOverlaySpinner,
  loadingOverlaySweetAlert,
  radioStyle,
} from "./CreateNewUserStyles";

const CreateNewUser = () => {
  const [form, setForm] = useState({
    profileType: "Worker",
    firstName: null,
    lastName: null,
    availability: [
      //0 : Monday 6: Sunday
      { active: false, m: true, a: true, n: true },
      { active: false, m: true, a: true, n: true },
      { active: false, m: true, a: true, n: true },
      { active: false, m: true, a: true, n: true },
      { active: false, m: true, a: true, n: true },
      { active: false, m: true, a: true, n: true },
      { active: false, m: true, a: true, n: true },
    ],
    gender: null,
    dob: null, // must be 18 +
    phone: null,
    address: null,
    city: null,
    province: null,
    postalCode: null,
    sin: null,
    sinExpiry: null,
    legallyWork: null,
    selected: false,
    workStatus: null,
    email: null,
    confirmEmail: null,
    showWorkPermit: false,
    showStudentVisa: false,
    qualificationsList: [],
    qualifications: [],

    // emergency contact variables
    emergencyFirstName: null,
    emergencyLastName: null,
    emergencyPhone: null,
    emergencyRelationship: null,
    emergencyFirstName2: null, //the second emergency contact is optional, so no error handling or checks
    emergencyLastName2: null,
    emergencyPhone2: null,
    emergencyRelationship2: null,
  });
  const [error, setError] = useState({
    errorFirstName: "",
    errorLastName: "",
    errorAvailability: "",
    errorGender: "",
    errorDob: "",
    errorPhone: "",
    errorAddress: "",
    errorCity: "",
    errorProvince: "",
    errorPostalCode: "",
    errorSin: "",
    errorLegallyWork: "",
    errorWorkStatus: "",
    errorEmergencyFirstName: "",
    errorEmergencyLastName: "",
    errorEmergencyPhone: "",
    errorEmergencyRelationship: "",
    errorEmail: "",
    errorConfirmEmail: "",
    errorSinExpiry: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openBulkUploadInfoModal, setOpenBulkUploadInfoModal] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    loadQualificationsList();
  }, []);

  // load qualification list from database and show on form
  const loadQualificationsList = () => {
    api()
      .get("/qualification")
      .then((res) => {
        const data = res.data.qualifications;
        let tempArray = [];
        data.map((q) => {
          tempArray.push({
            label: q.name,
            value: q._id,
          });
        });
        setForm({
          ...form,
          qualificationsList: tempArray,
        });
      })
      .catch((err) => console.log("Failed to load qualification list", err));
  };

  const loadingOverlay = () => {
    return (
      <SweetAlert
        style={loadingOverlaySweetAlert}
        title={<Spinner color="light" style={loadingOverlaySpinner} />}
        onConfirm={hideAlert}
        showConfirm={false}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
    setIsLoading(false);
  };

  const handleCloseBulkUploadInfoModal = () => {
    setOpenBulkUploadInfoModal(false);
  };

  const handleStatus = (value) => {
    setForm({ ...form, workStatus: value });
    if (value.value === 1) {
      setForm({
        ...form,
        workStatus: value,
        showWorkPermit: true,
        showStudentVisa: false,
      });
    } else if (value.value === 2) {
      setForm({
        ...form,
        workStatus: value,
        showStudentVisa: true,
        showWorkPermit: false,
      });
    } else {
      setForm({
        ...form,
        workStatus: value,
        showWorkPermit: false,
        showStudentVisa: false,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleErrors({
      form,
      setForm,
      error,
      setError,
      setAlert,
      hideAlert,
      setIsLoading,
      isLoading,
    });
  };

  return (
    <div className="content">
      {isLoading ? (
        loadingOverlay()
      ) : (
        <Card className="no-transition">
          <CardHeader>
            <Row>
              <CardTitle tag="h4">Create New Worker</CardTitle>
            </Row>
            <Row>
              <Col lg="3" sm="0"></Col>
              <Col lg="3" sm="4">
                <a
                  href={BulkWorkerUploadTemplate}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <Button color="success">Download CSV Template</Button>
                </a>
              </Col>
              <BulkCreateUser
                setIsLoading={setIsLoading}
                setAlert={setAlert}
                hideAlert={hideAlert}
              />
              <Col lg="3" sm="4">
                <Button color="success" onClick={() => setOpenBulkUploadInfoModal(true)}>
                  Data Upload Guide
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form className="form-horizontal">
              <Row>
                <Label sm="2">* First Name</Label>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="text"
                      autoComplete="off"
                      onChange={(e) => setForm({ ...form, firstName: e.target.value })}
                      value={form.firstName}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorFirstName}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">* Last Name</Label>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="text"
                      autoComplete="off"
                      onChange={(e) => setForm({ ...form, lastName: e.target.value })}
                      value={form.lastName}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorLastName}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <AvailabilityTable form={form} setForm={setForm} error={error} />
              <Row>
                <Label sm="2">* Gender</Label>
                <Col className="checkbox-radios" sm="10">
                  <div className="form-check-radio" style={radioStyle}>
                    <Label check>
                      <Input
                        defaultValue="M"
                        id="M"
                        name="genderRadio"
                        type="radio"
                        onChange={() => setForm({ ...form, gender: "M" })}
                        checked={form.gender === "M"}
                      />
                      <span className="form-check-sign" />
                      Male
                    </Label>
                  </div>
                  <div className="form-check-radio">
                    <Label check>
                      <Input
                        defaultValue="F"
                        id="F"
                        name="genderRadio"
                        type="radio"
                        checked={form.gender === "F"}
                        onChange={() => setForm({ ...form, gender: "F" })}
                      />
                      <span className="form-check-sign" />
                      Female
                    </Label>
                    <FormText color="danger" tag="span">
                      {error.errorGender}
                    </FormText>
                  </div>
                </Col>
              </Row>
              <Row>
                <Label sm="2">* Date of Birth</Label>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="date"
                      autoComplete="off"
                      maxLength="3"
                      onChange={(e) => {
                        setForm({ ...form, dob: e.target.value });
                      }}
                      value={form.dob || ""}
                      id="dob"
                    />
                    <FormText color="danger" tag="span">
                      {error.errorDob}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">* Phone Number</Label>
                <Col sm="5">
                  <FormGroup>
                    <MaskedInput
                      mask={maskedPhone}
                      className="form-control"
                      autoComplete="off"
                      maxLength="15"
                      placeholder="(xxx)-xxx-xxxx"
                      onChange={(e) => {
                        //Allows the user to type in any format. Only numbers will be added to the state
                        let input = e.target.value;
                        let replacedPhone = input.replace(/[^0-9]/g, "");
                        setForm({ ...form, phone: replacedPhone });
                      }}
                      value={form.phone}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorPhone}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">* Street Address</Label>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="text"
                      autoComplete="off"
                      onChange={(e) => setForm({ ...form, address: e.target.value })}
                      value={form.address}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorAddress}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">* City</Label>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="text"
                      autoComplete="off"
                      onChange={(e) => setForm({ ...form, city: e.target.value })}
                      value={form.city}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorCity}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">* Province</Label>
                <Col sm="5">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={form.province}
                      onChange={(value) => setForm({ ...form, province: value })}
                      options={provinceOptions}
                      placeholder="Select a province"
                    />
                    <FormText color="danger" tag="span">
                      {error.errorProvince}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">* Postal Code</Label>
                <Col sm="5">
                  <FormGroup>
                    <MaskedInput
                      mask={maskedPostalCode}
                      className="form-control"
                      autoComplete="off"
                      maxLength="8"
                      placeholder="A1B 2C3"
                      onChange={(e) => {
                        let input = e.target.value;
                        let replacedPostalCode = input.replace(/[^a-zA-Z_0-9-]/g, "");
                        setForm({ ...form, postalCode: replacedPostalCode });
                      }}
                      value={form.postalCode}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorPostalCode}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2"></Label>
                <Col sm="5">{sinInstruction}</Col>
              </Row>
              <Row>
                <Label sm="2">* Social Insurance Number</Label>
                <Col sm="5">
                  <FormGroup>
                    <MaskedInput
                      mask={maskedSin}
                      className="form-control"
                      autoComplete="off"
                      maxLength="12"
                      placeholder="xxx xxx xxx"
                      onChange={(e) => {
                        //Allows the user to type in any format. Only numbers will be added to the state
                        let input = e.target.value;
                        let replacedSin = input.replace(/[^0-9]/g, "");
                        setForm({ ...form, sin: replacedSin });
                      }}
                      value={form.sin}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorSin}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {form.sin?.startsWith("9") ? (
                  <>
                    <Label sm="2">*Sin Expiry Date</Label>
                    <Col sm="5">
                      <FormGroup>
                        <Input
                          type="date"
                          autoComplete="off"
                          maxLength="3"
                          onChange={(e) => {
                            setForm({ ...form, sinExpiry: e.target.value });
                          }}
                          value={form.sinExpiry || ""}
                          id="sinExpiry"
                        />
                        <FormText color="danger" tag="span">
                          {error.errorSinExpiry}
                        </FormText>
                      </FormGroup>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Row>
                <Label sm="2">* Legally Allowed to Work?</Label>
                <Col className="checkbox-radios" sm="10">
                  <div className="form-check-radio" style={radioStyle}>
                    <Label check>
                      <Input
                        id="Yes"
                        name="40hRadio"
                        type="radio"
                        onChange={() => setForm({ ...form, legallyWork: true })}
                      />
                      <span className="form-check-sign" />
                      Yes
                    </Label>
                  </div>
                  <div className="form-check-radio">
                    <Label check>
                      <Input
                        defaultValue
                        id="No"
                        name="40hRadio"
                        type="radio"
                        onChange={() => setForm({ ...form, legallyWork: false })}
                      />
                      <span className="form-check-sign" />
                      No
                    </Label>
                    <FormText color="danger" tag="span">
                      {error.errorLegallyWork}
                    </FormText>
                  </div>
                </Col>
              </Row>
              <Row>
                <Label sm="2">* Work Status</Label>
                <Col sm="5">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={form.workStatus}
                      onChange={(value) => handleStatus(value)}
                      options={workStatusOptions}
                      placeholder="Select a work status"
                    />
                    <FormText color="danger" tag="span">
                      {error.errorWorkStatus}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Qualifications (Optional)</Label>
                <Col sm="4">
                  <FormGroup>
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder=" Qualifications"
                      name="multipleSelect"
                      closeMenuOnSelect={false}
                      isMulti
                      value={form.qualifications}
                      onChange={(value) => setForm({ ...form, qualifications: value })}
                      options={form.qualificationsList}
                    />
                  </FormGroup>
                  <FormText color="danger" tag="span">
                    {error.errorQualifications}
                  </FormText>
                </Col>
              </Row>
              <div style={lineStyle} /> {/* first line */}
              <Row>
                <Label sm="2">
                  * Emergency Contact:<br></br>First Name
                </Label>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="text"
                      autoComplete="off"
                      value={form.emergencyFirstName}
                      onChange={(e) => setForm({ ...form, emergencyFirstName: e.target.value })}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorEmergencyFirstName}
                    </FormText>
                  </FormGroup>
                </Col>
                <Label sm="2">* Emergency Contact: Phone Number</Label>
                <Col sm="3">
                  <FormGroup>
                    <MaskedInput
                      mask={maskedPhone}
                      className="form-control"
                      autoComplete="off"
                      maxLength="15"
                      placeholder="(xxx)-xxx-xxxx"
                      onChange={(e) => {
                        //Allows the user to type in any format. Only numbers will be added to the state
                        let input = e.target.value;
                        let replacedEmergencyPhone = input.replace(/[^0-9]/g, "");
                        setForm({ ...form, emergencyPhone: replacedEmergencyPhone });
                      }}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorEmergencyPhone}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">
                  * Emergency Contact:<br></br>Last Name
                </Label>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="text"
                      autoComplete="off"
                      value={form.emergencyLastName}
                      onChange={(e) => setForm({ ...form, emergencyLastName: e.target.value })}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorEmergencyLastName}
                    </FormText>
                  </FormGroup>
                </Col>
                <Label sm="2">* Emergency Contact: Relationship</Label>
                <Col sm="3">
                  <FormGroup>
                    <Input
                      type="text"
                      autoComplete="off"
                      value={form.emergencyRelationship}
                      onChange={(e) => setForm({ ...form, emergencyRelationship: e.target.value })}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorEmergencyRelationship}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <div style={emergencyContactGap} /> {/* gap between emergency contact */}
              <Row>
                <Label sm="2">
                  Emergency Contact 2:<br></br>First Name
                </Label>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="text"
                      autoComplete="off"
                      placeholder="(Optional)"
                      value={form.emergencyFirstName2}
                      onChange={(e) => setForm({ ...form, emergencyFirstName2: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Label sm="2">Emergency Contact 2: Phone Number</Label>
                <Col sm="3">
                  <FormGroup>
                    <MaskedInput
                      mask={maskedPhone}
                      className="form-control"
                      autoComplete="off"
                      maxLength="15"
                      placeholder="(xxx)-xxx-xxxx"
                      value={form.emergencyPhone2}
                      onChange={(e) => {
                        //Allows the user to type in any format. Only numbers will be added to the state
                        let input = e.target.value;
                        let replacedEmergencyPhone2 = input.replace(/[^0-9]/g, "");
                        setForm({ ...form, emergencyPhone2: replacedEmergencyPhone2 });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">
                  Emergency Contact 2:<br></br>Last Name
                </Label>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="text"
                      autoComplete="off"
                      placeholder="(Optional)"
                      value={form.emergencyLastName2}
                      onChange={(e) => setForm({ ...form, emergencyLastName2: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Label sm="2">Emergency Contact 2: Relationship</Label>
                <Col sm="3">
                  <FormGroup>
                    <Input
                      type="text"
                      autoComplete="off"
                      placeholder="(Optional)"
                      value={form.emergencyRelationship2}
                      onChange={(e) => setForm({ ...form, emergencyRelationship2: e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div style={lineStyle} /> {/* second line */}
              <Row>
                <Label sm="2">* Email</Label>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="email"
                      autoComplete="off"
                      placeholder="Enter user email"
                      value={form.email}
                      onChange={(e) => setForm({ ...form, email: e.target.value })}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorEmail}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">* Confirm Email</Label>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="email"
                      autoComplete="off"
                      placeholder="Confirm user email"
                      value={form.confirmEmail}
                      onChange={(e) => setForm({ ...form, confirmEmail: e.target.value })}
                    />
                    <FormText color="danger" tag="span">
                      {error.errorConfirmEmail}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <div style={lineStyle} /> {/* third line */}
              <Row>
                <Col sm="9"></Col>
                <Col sm="3">
                  <Button color="success" onClick={handleSubmit}>
                    Create User
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      )}
      <BulkUploadInfoModal
        openBulkUploadInfoModal={openBulkUploadInfoModal}
        handleCloseBulkUploadInfoModal={handleCloseBulkUploadInfoModal}
      />
      {alert}
    </div>
  );
};

export default CreateNewUser;

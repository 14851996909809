import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";

import AsyncSelect from "react-select/async";
import Select from "react-select";

import { ExpandMore, AddCircleOutline, Remove } from "@mui/icons-material";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  FormText,
  Label,
  Input,
  Container,
} from "reactstrap";

let modulesTypeEnum = {
  video: "video",
  multipleChoice: "multipleChoice",
  shortNumberAnswer: "shortNumberAnswer",
  shortTextAnswer: "shortTextAnswer",
  terms: "terms",
  shortCommunicationAnswer: "shortCommunicationAnswer",
};

const Options = ({ options = [], correctAnswer, onChangeOptions, onChangeCorrectAnswer }) => {
  const [optionList, setOptionList] = useState([]);

  useEffect(() => {
    if (options.length <= 0) {
      setOptionList([""]);
    } else {
      setOptionList([...optionList, ...options]);
    }
  }, []);

  useEffect(() => {
    onChangeOptions(optionList);
  }, [optionList]);

  return (
    <>
      <FormGroup>
        {optionList.map((option, i) => {
          return (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ cursor: "pointer" }}>
                  <Input
                    type="radio"
                    name="options"
                    style={{ position: "relative", padding: "10px" }}
                    value={i}
                    checked={i === parseInt(correctAnswer)}
                    onChange={(event) => {
                      onChangeCorrectAnswer(event.target.value);
                    }}
                  />
                </div>
                <Input
                  type="text"
                  autoComplete="off"
                  value={option}
                  placeholder={`Option ${i}`}
                  onChange={(event) => {
                    let tempList = [...optionList];
                    tempList[i] = event.target.value;
                    setOptionList(tempList);
                  }}
                />
                <div style={{ cursor: "pointer" }}>
                  <Remove
                    onClick={() => {
                      let tempList = [...optionList];
                      if (tempList.length <= 1) {
                        return;
                      }
                      tempList.splice(i, 1);
                      setOptionList(tempList);
                    }}
                  />
                </div>
              </div>
              {/* <FormText color="danger" tag="span">
      {error.trainingName}
    </FormText> */}
            </>
          );
        })}
        <Button
          style={{ padding: "0.4rem" }}
          outline
          color="primary"
          type="button"
          onClick={() => {
            // let currentOptions = optionList;
            setOptionList([...optionList, ""]);
          }}
        >
          <span>Add Option</span>
          <span className="btn-inner--icon">
            <AddCircleOutline />
          </span>
        </Button>
      </FormGroup>

      {/* <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          console.log("clicked");
          addNewModule(index);
        }}
      >
        <AddCircleOutline />
        Add Option
      </div> */}
    </>
  );
};

export default function CreateTrainingModule({ open, onClose, module, onSubmit, edit, index }) {
  const [selectedModuleType, setSelectedModuleType] = useState(null);
  //const [selectedModule, setSelectedModule] = useState(null);
  // const [insertAfter, setInsertAfter] = useState(null);
  // const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [errorSelectedProfile, seterrorSelectedProfile] = useState(null);
  const [text, setText] = useState("");
  const [type, setType] = useState("");
  const [url, setURL] = useState("");
  const [quiz, setQuiz] = useState({
    question: "",
    options: [],
    correctAnswer: "",
    explanation: "",
  });
  const [moduleId, setModuleId] = useState("");
  const [tempOptions, setTempOptions] = useState("");
  const [title, setTitle] = useState("");
  const moduleType = [
    { value: modulesTypeEnum.video, label: "video" },
    { value: modulesTypeEnum.multipleChoice, label: "Multiple Choice" },
    { value: modulesTypeEnum.shortNumberAnswer, label: "Short Number Answer" },
    { value: modulesTypeEnum.shortTextAnswer, label: "Short Text Answer" },
    { value: modulesTypeEnum.shortCommunicationAnswer, label: "Short Communication Answer" },
    { value: modulesTypeEnum.terms, label: "Terms" },
  ];



  useEffect(() => {
    console.log(module);
    if (module) {
      setText(module.text);
      setType(module.type);
      setURL(module.url);
      setQuiz(module.quiz);
      setTitle(module.title);
      setSelectedModuleType(moduleType.find((mType) => mType.value === module.type));
      module._id && setModuleId(module._id);
    } else {
      setText("");
      setType("");
      setURL("");
      setQuiz({
        question: "",
        options: [],
        correctAnswer: "",
        explanation: "",
      });
      setTitle("");
      setSelectedModuleType(null);
    }
  }, [module]);

  const displayModule = (selectedModuleType) => {
    switch (selectedModuleType.value) {
      case modulesTypeEnum.video:
        return (
          <>
            <Row>
              <Label sm="3">* Url</Label>
              <Col sm="9">
                <FormGroup>
                  <Input
                    type="text"
                    autoComplete="off"
                    value={url}
                    onChange={(event) => {
                      setURL(event.target.value);
                    }}
                  />
                  {/* <FormText color="danger" tag="span">
                    {error.trainingName}
                  </FormText> */}
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case modulesTypeEnum.terms:
        return (
          <>
            <Row>
              <Label sm="3">* Terms</Label>
              <Col sm="9">
                <FormGroup>
                  <Input
                    type="textarea"
                    autoComplete="off"
                    value={text}
                    rows={5}
                    style={{ maxHeight: "500px" }}
                    onChange={(event) => {
                      setText(event.target.value);
                    }}
                  />
                  {/* <FormText color="danger" tag="span">
                    {error.trainingName}
                  </FormText> */}
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case modulesTypeEnum.multipleChoice:
        return (
          <>
            <Row>
              <Label sm="3">* Question</Label>
              <Col sm="9">
                <FormGroup>
                  <Input
                    type="textarea"
                    autoComplete="off"
                    value={quiz.question}
                    placeholder="Question"
                    onChange={(event) => {
                      setQuiz({ ...quiz, question: event.target.value });
                    }}
                  />

                  {/* <FormText color="danger" tag="span">
                    {error.trainingName}
                  </FormText> */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="3">* Options</Label>
              <Col sm="9">
                <Options
                  options={quiz.options}
                  correctAnswer={quiz.correctAnswer}
                  onChangeOptions={(options) => {
                    setQuiz({ ...quiz, options: options });
                  }}
                  onChangeCorrectAnswer={(answer) => {
                    console.log(answer);
                    setQuiz({ ...quiz, correctAnswer: answer });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Label sm="3">* Explanation</Label>
              <Col sm="9">
                <FormGroup>
                  <Input
                    autoComplete="off"
                    value={quiz.explanation}
                    type="textarea"
                    onChange={(event) => {
                      setQuiz({ ...quiz, explanation: event.target.value });
                    }}
                  />
                  {/* <FormText color="danger" tag="span">
                    {error.trainingName}
                  </FormText> */}
                </FormGroup>
              </Col>
            </Row>
          </>
        );
      case modulesTypeEnum.shortNumberAnswer:
      case modulesTypeEnum.shortCommunicationAnswer:
      case modulesTypeEnum.shortTextAnswer:
        return (
          <>
            <Row>
              <Label sm="3">* Question</Label>
              <Col sm="9">
                <FormGroup>
                  <Input
                    type="text"
                    autoComplete="off"
                    placeholder=""
                    value={quiz.question}
                    placeholder=""
                    onChange={(event) => {
                      setQuiz({ ...quiz, question: event.target.value });
                    }}
                  />

                  {/* <FormText color="danger" tag="span">
                    {error.trainingName}
                  </FormText> */}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Label sm="4">* Correct Answer</Label>
              <Col sm="8">
                <FormGroup>
                  <Input
                    type="text"
                    autoComplete="off"
                    value={quiz.correctAnswer}
                    placeholder=""
                    onChange={(event) => {
                      setQuiz({ ...quiz, correctAnswer: event.target.value });
                    }}
                  />
                  {/* <FormText color="danger" tag="span">
                    {error.trainingName}
                  </FormText> */}
                </FormGroup>
              </Col>
            </Row>
          </>
        );
    }
  };

  console.log(selectedModuleType);
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FormGroup
        style={{
          width: 600,
          backgroundColor: "#fff",
          borderRadius: 10,
        }}
      >
        <div style={{ padding: 25 }}>
          <div style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
            <div style={{ width: "400px" }}>
              {/* <AsyncSelect
                cacheOptions
                defaultOptions={this.state.initialReactSelectBusinessData}
                loadOptions={this.searchPromise}
                onChange={(value) =>
                  this.setState({ selectedBusiness: value, addingBusinessError: "" })
                }
                placeholder="Search by Business name, address or city..."
              /> */}
              {/* <p style={{ color: "#800000" }}>{this.state.addingBusinessError}</p> */}
            </div>
            <div style={{ width: "100%" }}>
              <Row>
                <Label sm="3">* Title</Label>
                <Col sm="9">
                  <Input
                    type="text"
                    autoComplete="off"
                    placeholder="Title"
                    value={title}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Label sm="3">* Module Type</Label>
                <Col sm="9">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Choose modules Type"
                    name="singleSelect"
                    value={selectedModuleType}
                    onChange={(value) => {
                      setSelectedModuleType(value);
                      setType(value.value);
                      setText("");
                      setURL("");
                      setQuiz({
                        question: "",
                        options: [],
                        correctAnswer: "",
                        explanation: "",
                      });
                    }}
                    options={moduleType}
                  />
                </Col>
              </Row>
              <FormText color="danger" tag="span">
                {errorSelectedProfile}
              </FormText>

              {selectedModuleType ? displayModule(selectedModuleType) : null}

              {/* <Row>
                <Label sm="3">* Add Module after</Label>
                <Col sm="9">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Insert at the end(default)"
                    name="singleSelect"
                    value={selectedModule}
                    onChange={(value) => {
                      setSelectedModule(value);
                      setInsertAfter(value.value);
                    }}
                    options={(() => {
                      let tempModules = modules.map((module, i) => ({
                        label: module.title,
                        value: i + 1,
                      }));
                      tempModules.unshift({ label: "Insert at the beginning", value: 0 });
                      return tempModules;
                    })()}
                  />
                </Col>
              </Row> */}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              justifyContent: "center",
              alignContent: "center",
              marginLeft: "120px",
            }}
          >
            <Button
              color="success"
              onClick={() => {
                let newModule = { title, type, url, quiz, text };
                if (edit) {
                  newModule._id = moduleId;
                }
                onSubmit(newModule, index + 1, edit);
                onClose();
              }}
            >
              {edit ? "Update" : "Add Module"}
            </Button>
            <Button onClick={() => onClose()}>Cancel</Button>

            {/* <Button onClick={() => this.removeBusinessFromAgency(this.state.selectedBId)}>Delete</Button> */}
          </div>
        </div>
      </FormGroup>
    </Modal>
  );
}

import React, { useEffect, useContext, useState } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  FormText,
} from "reactstrap";

import RegisterContext from "context/RegisterContext";

import RegisterText from "views/Staffing/register/RegisterText.jsx";

function RegisterBusiness(props) {
  const registerContext = useContext(RegisterContext);
  const [agency, setAgency] = useState({});
  document.documentElement.classList.remove("nav-open");

  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  //const [employeesError, setEmployeesError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [businessNoError, setBusinessNoError] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    document.body.classList.add("register-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("register-page");
      document.body.classList.remove("full-screen");
    };
  }, []);

  useEffect(() => {
    if (!registerContext.registration.user) {
      props.history.push("/staffing/register/register-admin");
    }
    if (registerContext.registration.agency) {
      setAgency(registerContext.registration.agency);
    }
    registerContext.registrationDispatch({
      type: "SET_PROGRESS_STEP",
      payload: { progressBarIndex: 1 },
    });
  }, []);

  const navigateToRegisterSelectPlan = (e) => {
    e.preventDefault();

    let hasError = false;

    //Validation
    if (agency.name == null || agency.name == "") {
      setNameError("* Must enter Business name");
      hasError = true;
    } else {
      setNameError("");
    }

    if (agency.address == null || agency.address == "") {
      setAddressError("* Must enter Business Address");
      hasError = true;
    } else {
      setAddressError("");
    }

    if (agency.city == null || agency.city == "") {
      setCityError("* Must enter City");
      hasError = true;
    } else {
      setCityError("");
    }
    if (agency.phone == null || agency.phone == "") {
      setPhoneError("* Must enter Business Phone");
      hasError = true;
    } else {
      setPhoneError("");
    }

    if (agency.businessNo == null || agency.businessNo == "") {
      setBusinessNoError("* Must enter Business Number ");
      hasError = true;
    } else {
      setBusinessNoError("");
    }

    if (agency.email == null || agency.email == "") {
      setEmailError("* Must enter Email");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (hasError) {
      return;
    } else {
      registerContext.registrationDispatch({
        type: "SET_AGENCY",
        payload: { agency },
      });
      props.history.push("/staffing/register/select-plan");
    }
  };
  return (
    <>
      <div className="section section-pricing cd-section" id="pricing">
        {/* ********* PRICING 2 ********* */}
        <div className="pricing-2">
          <div className="filter" />
          <Container>
            <Row>
              <Col className="ml-auto" lg="6" md="6" sm="7" xs="12">
                <RegisterText />
              </Col>
              <Col className="mr-auto" lg="6" md="6" sm="5" xs="12" style={{ marginTop: "-100px" }}>
                <Card
                  className="card-register"
                  style={{ paddingTop: "0px", backgroundColor: "white" }}
                >
                  <CardTitle className="text-center" tag="h3">
                    Register
                  </CardTitle>

                  {/* <div className="social">
                    <Button className="btn-just-icon mr-1" color="facebook">
                      <i className="fa fa-facebook" />
                    </Button>
                    <Button className="btn-just-icon mr-1" color="google">
                      <i className="fa fa-google" />
                    </Button>
                    <Button className="btn-just-icon" color="twitter">
                      <i className="fa fa-twitter" />
                    </Button>
                  </div> */}

                  <div
                    style={{
                      // backgroundColor: "black",
                      marginTop: "35px",
                      marginBottom: "10px",
                      color: "#66615b",
                    }}
                  >
                    <h6 className="text-center" color="#66615b">
                      Next, tell us about your business.
                    </h6>
                  </div>
                  <Form className="register-form">
                    <FormGroup>
                      <Input
                        placeholder="* Business Name"
                        type="text"
                        value={agency.name}
                        onChange={(e) => {
                          setAgency({ ...agency, name: e.target.value });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {nameError}
                        {/* {console.log("Span Error: ", nameError)} */}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        placeholder="* Business Address"
                        type="text"
                        value={agency.address}
                        onChange={(e) => {
                          setAgency({ ...agency, address: e.target.value });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {addressError}
                        {/* {console.log("Span Error: ", nameError)} */}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        placeholder="* Business City"
                        type="text"
                        value={agency.city}
                        onChange={(e) => {
                          setAgency({ ...agency, city: e.target.value });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {cityError}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        placeholder="# of Employees (optional)"
                        type="text"
                        value={agency.employees}
                        onChange={(e) => {
                          setAgency({ ...agency, employees: e.target.value });
                        }}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Input
                        placeholder="* Business Phone"
                        type="text"
                        value={agency.phone}
                        onChange={(e) => {
                          setAgency({ ...agency, phone: e.target.value });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {phoneError}
                      </FormText>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        placeholder="* Business Number"
                        type="text"
                        value={agency.businessNo}
                        onChange={(e) => {
                          setAgency({ ...agency, businessNo: e.target.value });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {businessNoError}
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        placeholder="* Business Email"
                        type="text"
                        value={agency.email}
                        onChange={(e) => {
                          setAgency({ ...agency, email: e.target.value });
                        }}
                      />
                      <FormText color="danger" tag="span">
                        {emailError}
                      </FormText>
                    </FormGroup>

                    <Button
                      className="btn-round w-100"
                      color="success"
                      href="#pablo"
                      onClick={navigateToRegisterSelectPlan}
                    >
                      Continue
                    </Button>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default RegisterBusiness;

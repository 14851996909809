import React from 'react'
import ReactTable from "react-table";

const ShiftTable = ({
  selectedForWorkData, 
  pages, 
  timer, setTimer,
  setReactTableState,
  loadingTable, setLoadingTable,
  fetchData
}) => {
  const defaultPageSize = 10
  const columns = [
    {
      Header: "HD Id",
      accessor: "hdId",
      maxWidth: 70,
    },
    {
      Header: "First Name",
      accessor: "firstName",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
    },
    {
      Header: "verified",
      accessor: "verified",
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            backgroundColor:
              rowInfo?.row?.verified === "Verified"
                ? "#D2FFCB" //light green
                : rowInfo?.row?.verified === "Invalid" || rowInfo?.row?.verified === "Expired"
                ? "#EE9999" //light red
                : rowInfo?.row?.verified === "Pending"
                ? "#ffffc2" // light yellow
                : rowInfo?.row?.verified === "Incomplete"
                ? "#FFCBCB"
                : "", // no data
            fontWeight: 400,
          },
        };
      },
      filterable: false,
      sortable: false,
      maxWidth: 70,
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: (row) => {
        let telephone = "tel:" + row.original.phone;
        return (
          <a href={telephone} style={{ color: "orange", textDecoration: "underline" }}>
            {row.original.phone}
          </a>
        );
      },
      maxWidth: 120,
    },
    {
      Header: "Business",
      accessor: "businessName",
      filterable: false,
    },
    {
      Header: "Shift",
      accessor: "shiftName",
      filterable: false,
    },
    {
      Header: "Start",
      accessor: "startTime",
      filterable: false,
    },
    {
      Header: "End",
      accessor: "endTime",
      filterable: false,
    },
    {
      Header: "Last Active (App)",
      accessor: "lastAppActive",
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false,
      width: 155,
    },
  ]

  return (
    <ReactTable
      filterable
      data={selectedForWorkData}
      getTrProps={(state, rowInfo, instance) => {
        if (rowInfo?.row?._original?.rowColor) {
          return { style: { color: rowInfo?.row?._original?.rowColor } };
        }
        return {};
      }}
      columns={columns}
      manual
      loading={loadingTable}
      defaultPageSize={defaultPageSize}
      showPaginationTop
      showPaginationBottom
      pages={pages}
      onFetchData={(state, instance) => {
        let searchParams = {
          page: state.page + 1,
          size: state.pageSize,
          field: "hdId",
          sort: "desc",
          filter: state.filtered,
        };
        setReactTableState(searchParams);
        clearTimeout(timer);
        setTimer(
          setTimeout(() => {
            setLoadingTable(true);
            fetchData(searchParams);
          }, 1000),
        );
      }}
      className="-striped -highlight primary-pagination"
    />
  );
};

export default ShiftTable
/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
//import { Prompt } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingOverlay from "components/OverLay/LoadingOverlay";
import Permission from "components/permissions/Permission";

import api from "components/API/api";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  CustomInput,
} from "reactstrap";

import { Modal, ThemeProvider } from "@mui/material";
import Select from "react-select";
import { PermissionActionEnum, PermissionModuleEnum } from "../AllStaff/permissionTemplate";

class CreateNotification extends Component {
  constructor(props) {
    super(props);
    this.today = new Date();
    this.tomorrow = new Date();
    this.tomorrow.setDate(this.today.getDate() + 1);
    this.allowedSmsChar = 1550; //1550 for sms, 178 for app
    this.allowedPushChar = 178;
    this.allowedAndroidTitleChar = 64; // it's actually 65, but we leave a space at end when joining android header
    // notif title & body to iOS body only notif. iOS does not support title in
    //this.untrainedMessageSample = `Your HD group registration is incomplete, will you be completing your training in order to be scheduled for work?${"\n"} Reply: yes/no.${"\n"}- HD Group`;
    //this.trainedMessageSample = `Workers needed, morning shift, Vaughan. Let us know if you can attend.${"\n"}  Reply: yes/no ${"\n"} - HD Group`;

    //This will use this designated number for now. for trained and untrained users.
    this.designatedNumbers = {
      // trained: "8106710072", //Sajit's number
      // untrained: "8106710072", //Sajit's number
      trained: "6474243531",
      untrained: "2892742003",
    };

    this.defaultSendLimit = 1;

    this.state = {
      message: "",
      isLoading: false,
      errorMessage: "",
      error: false,
      allowedChar: 160, //1550 for email, 160 for App/Sms
      charLeft: 160,
      keyPressed: null,
      sendSmsModalOpen: false,
      sendPushModalOpen: false,
      sendEmailModalOpen: false,
      userType: null,
      alert: null,
      loadingMessage: "",
      noSent: 0,
      noErrors: 0,
      sendSms: false,
      sendPush: false,
      sendEmail: false,
      businessData: [], // list of all business with any agency
      selectedBusinesses: [],
      messageTitle: "",
      errorBusiness: "",
      errorTitle: "",

      //State only for form
      isTrainedSelected: true,
      isUnTrainedSelected: false,
      businessList: [],
      businessListReactSelect: [{ label: "All", value: 0 }],
      businessSelected: { label: "All", value: 0 }, //default value for business dropdown

      // For counting characters.
      titleChar: 0,
      messageChar: 0,

      //availability
      availability: [
        {
          // 0: Monday
          active: false,
          m: true,
          a: true,
          n: true,
        },
        {
          active: false,
          m: true,
          a: true,
          n: true,
        },
        {
          active: false,
          m: true,
          a: true,
          n: true,
        },
        {
          active: false,
          m: true,
          a: true,
          n: true,
        },
        {
          active: false,
          m: true,
          a: true,
          n: true,
        },
        {
          active: false,
          m: true,
          a: true,
          n: true,
        },
        {
          // 6: Sunday
          active: false,
          m: true,
          a: true,
          n: true,
        },
      ],
    };
  }

  componentDidMount() {
    // Database References
    this.getAllBusiness();
    document.addEventListener("keydown", this.KeyCheck);

    //load all businesses at the beginning (This feature is not available now so just commented)
    //this.businessDataRef = fs.collection("business_data");
    //this.loadBusinesses();
  }

  componentDidUpdate(prevProps, prevState) {
    // character limit is 160 for APP/SMS and 1550 for email
    if (this.state.sendEmail !== prevState.sendEmail) {
      if (this.state.sendEmail) {
        this.setState({
          allowedChar: 1550,
          charLeft: 1550 - this.state.message.length,
        });
      } else {
        this.setState({
          allowedChar: 160,
          charLeft: 160 - this.state.message.length,
        });
      }
    }
  }

  getAllBusiness = async () => {
    try {
      this.setState({ isLoading: true });
      const { data } = await api().get(`/business/associated`);
      //Looping through the business data for action button implementation
      console.log(data);
      let tempBusinessList = [
        {
          value: "0",
          label: "Never Worked",
        },
      ];
      data.businesses.map((business) => {
        tempBusinessList.push({
          value: business._id,
          label: business.name,
        });
      });
      this.setState({
        businessData: tempBusinessList,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
    }
  };

  availabilityToString = (availabilities) => {
    //console.log(availabilities);
    let text = "";
    availabilities.map((availability, i) => {
      if (availability.active) {
        text = text + "\n";
        switch (i) {
          case 0:
            text = text + "Monday ";
            break;
          case 1:
            text = text + "Tuesday ";
            break;
          case 2:
            text = text + "Wednesday ";
            break;
          case 3:
            text = text + "Thursday ";
            break;
          case 4:
            text = text + "Friday ";
            break;
          case 5:
            text = text + "Saturday ";
            break;
          case 6:
            text = text + "Sunday ";
            break;
          default:
            break;
        }
        if (availability.m) {
          text = text + " Morning, ";
        }
        if (availability.a) {
          text = text + " Afternoon, ";
        }
        if (availability.n) {
          text = text + " Night, ";
        }
      }
    });
    return text;
  };

  availabilityDayToString = (availabilities) => {
    //console.log(availabilities);
    let text = "";
    availabilities.map((availability, i) => {
      if (availability.active) {
        switch (i) {
          case 0:
            text = text + "Monday, ";
            break;
          case 1:
            text = text + "Tuesday, ";
            break;
          case 2:
            text = text + "Wednesday, ";
            break;
          case 3:
            text = text + "Thursday, ";
            break;
          case 4:
            text = text + "Friday, ";
            break;
          case 5:
            text = text + "Saturday, ";
            break;
          case 6:
            text = text + "Sunday, ";
            break;
          default:
            break;
        }
      }
    });
    return text;
    //console.log(text);
  };

  calculateDesignatedNumber() {
    let designatedNumber = this.designatedNumbers.untrained;
    if (this.state.isTrainedSelected && !this.state.isUnTrainedSelected) {
      //check if the businesslist has a number added for the business
      let currentBusiness = this.state.businessList.find((business) => {
        return business.bId === this.state.businessSelected.value;
      });
      designatedNumber =
        (currentBusiness && currentBusiness.designatedNumber) || this.designatedNumbers.trained;
    }
    return designatedNumber;
  }

  loadBusinesses = () => {
    this.businessDataRef
      .where("bId", ">=", 100) // Basically loads all businesses.
      //.limit(3)
      .onSnapshot((querySnapshot) => {
        var businessList = [];

        //Adding initial all value in case they want to send message that is not specific to one business
        var businessListReactSelect = [...this.state.businessListReactSelect];

        querySnapshot.forEach((doc, i) => {
          //let tempData = doc.data();
          let businessName = doc.data().businessName;
          businessListReactSelect.push({
            label: businessName,
            value: doc.data().bId,
          });
          businessList.push(doc.data());
        });
        //console.log(businessList);
        this.setState({ businessList, businessListReactSelect });
      });
  };
  KeyCheck = (event) => {
    this.setState({ keyPressed: event.keyCode });
  };

  handleSubmit = () => {
    //let autoFilledEmptyMessage = false;
    // START ERROR HANDLING //
    let { message, titleChar, messageChar } = this.state;
    let hasError = false;
    //if message exceeds length
    if (message.length > 160 && this.state.sendSms) {
      this.setState({
        errorMessage: "Maximum character limit for SMS blasts is 160 characters.",
      });
      hasError = true;
    }
    if (message.length > 1550 && this.state.sendEmail) {
      this.setState({
        errorMessage: "Maximum character limit for email blasts is 1550 characters.",
      });
      hasError = true;
    }
    if (titleChar + messageChar >= 178 && this.state.sendPush) {
      this.setState({
        errorMessage: "Maximum character limit for in app push notifications is 178 characters.",
      });
      hasError = true;
    }
    if (!this.state.sendSms && !this.state.sendPush && !this.state.sendEmail) {
      this.setState({
        errorMethod: "Please select at least one sending method",
      });
      hasError = true;
    } else {
      this.setState({
        errorMethod: null,
      });
    }
    if (
      (this.state.sendPush || this.state.sendEmail) &&
      (this.state.messageTitle === "" || this.state.messageTitle === null)
    ) {
      this.setState({
        errorTitle: "Please provide subject/title for the message",
      });
      hasError = true;
    } else {
      this.setState({
        errorTitle: null,
      });
    }

    if (!message) {
      this.setState({
        errorMessage: "Please add a message.",
      });
      hasError = true;
    }

    if (hasError) {
      return;
    }
    // END ERROR HANDLING //

    // START SEND SMS/APP NOTIF/EMAIL MODALS
    if (this.state.sendPush) {
      // sendPush happens in the sendPushModal.
      this.setState({ sendPushModalOpen: true });
    } else if (this.state.sendEmail) {
      // sendEmail triggered in the sendEmailModal.
      this.setState({ sendEmailModalOpen: true });
    } else if (this.state.sendSms) {
      // sendSms triggered in the sendSmsModal.
      this.setState({ sendSmsModalOpen: true });
    }
    // END SEND SMS/APP NOTIF/EMAIL MODALS
  };

  // Send Message. Handles: SMS, Email, Push.
  send = (type, message, availability, number) => {
    //type->trained/untrained/all
    //message->message
    //availability->true/false/availabilibty
    //number->number to be sent

    this.setState({
      isLoading: true,
      loadingMessage: "Hold tight! the message is getting sent.",
    });

    let businessId = [];
    if (this.state.selectedBusinesses) {
      this.state.selectedBusinesses.forEach((business) => businessId.push(business.value));
    }
    // console.log({
    //   message,
    //   fromNumber: number,
    //   push: this.state.sendPush,
    //   sms: this.state.sendSms,
    //   email: this.state.sendEmail,
    //   title: this.state.messageTitle,
    //   business: businessId,
    //   availability: this.state.availability,
    //   isTrainedSelected: this.state.isTrainedSelected,
    //   isUnTrainedSelected: this.state.isUnTrainedSelected,
    // });

    // Different API calls Depending on Type of Notification Method
    if (this.state.sendSms) {
      api()
        .post("/notification/create", {
          message,
          push: this.state.sendPush,
          sms: this.state.sendSms,
          email: this.state.sendEmail,
          title: this.state.messageTitle,
          businesses: businessId,
          availability: this.state.availability,
          isTrainedSelected: this.state.isTrainedSelected,
          isUnTrainedSelected: this.state.isUnTrainedSelected,
        })
        .then((res) => {
          console.log(res.data);
          console.log("Ready to send data");
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                success
                title={`Successfully sent sms notifications to ${res.data.count} users.`}
                onConfirm={this.hideAlert}
                showConfirm={true}
              />
            ),
          });
        })
        .catch((e) => {
          console.log(e);
          //     console.log(e);
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                error
                title={`Error sending Notifications!`}
                onConfirm={this.hideAlert}
                showConfirm={true}
              >
                {e.response?.data?.message ||
                  "There was an error sending the sms message. If the issue persists please contact tech support."}
              </SweetAlert>
            ),
          });
          //Hide the alert after some time.
        });
    } else if (this.state.sendPush) {
      console.log("Send Push Triggered");
      // App Push Notification
      api()
        .post("/notification/create", {
          message,
          push: this.state.sendPush,
          sms: this.state.sendSms,
          email: this.state.sendEmail,
          title: this.state.messageTitle,
          businesses: businessId,
          availability: this.state.availability,
          isTrainedSelected: this.state.isTrainedSelected,
          isUnTrainedSelected: this.state.isUnTrainedSelected,
        })
        .then((res) => {
          console.log(res.data);
          console.log("Ready to send data");
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                success
                title={`Successfully sent push notifications to ${res.data.count} devices (ios/android).`}
                onConfirm={this.hideAlert}
                showConfirm={true}
              />
            ),
          });
        })
        .catch((e) => {
          console.log(e);
          //     console.log(e);
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                error
                title={`Error sending Notifications!`}
                onConfirm={this.hideAlert}
                showConfirm={true}
              >
                {e.response?.data?.message ||
                  "There was an error sending the push message. If the issue persists please contact tech support."}
              </SweetAlert>
            ),
          });
          //Hide the alert after some time.
        });
    } else if (this.state.sendEmail) {
      // App Push Notification
      console.log("Send Email Triggered");

      api()
        .post("/notification/create", {
          message,
          push: this.state.sendPush,
          sms: this.state.sendSms,
          email: this.state.sendEmail,
          title: this.state.messageTitle,
          businesses: businessId,
          availability: this.state.availability,
          isTrainedSelected: this.state.isTrainedSelected,
          isUnTrainedSelected: this.state.isUnTrainedSelected,
        })
        .then((res) => {
          console.log(res.data);
          console.log("Ready to send data");
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                success
                title={`Successfully sent email notifications to ${res.data.count} users.`}
                onConfirm={this.hideAlert}
                showConfirm={true}
              />
            ),
          });
        })
        .catch((e) => {
          console.log(e);
          //     console.log(e);
          this.setState({
            isLoading: false,
            alert: (
              <SweetAlert
                error
                title={`Error sending Notifications!`}
                onConfirm={this.hideAlert}
                showConfirm={true}
              >
                {e.response?.data?.message ||
                  "There was an error sending the email message. If the issue persists please contact tech support."}
              </SweetAlert>
            ),
          });
          //Hide the alert after some time.
        });
    }
  };

  generateMessage = () => {
    let message = "";

    if (this.state.sendEmail) {
      message =
        "Morning Shift available for Tomorrow Morning 7AM-3PM June 28th 2021. Please click the following button if your are available to work.";
    } else if (
      (this.state.isTrainedSelected && this.state.isUnTrainedSelected) ||
      (!this.state.isTrainedSelected && !this.state.isUnTrainedSelected)
    ) {
      message = `Hi there! we have a job. Please response yes/no if you are interested or want to be scheduled. \n -HD Group`;
    } else if (this.state.isUnTrainedSelected) {
      message = `Your HD group registration is incomplete, will you be completing your training in order to be scheduled for work?${"\n"} Reply: yes/no.${"\n"}- HD Group`;
    } else if (this.state.isTrainedSelected) {
      let availabilityString = this.availabilityToString(this.state.availability);
      if (availabilityString.length > 80) {
        availabilityString = this.availabilityDayToString(this.state.availability);
      }
      message = `Workers needed: ${availabilityString}${
        this.state.businessSelected.label !== "All"
          ? "\nLocation: " + this.state.businessSelected.label
          : ""
      }\nReply: yes/no ${"\n"} - HD Group`;
    }
    this.resetMessageCharacters(message);
    this.setState({ message, errorMessage: false });
  };

  clearMessageBox = () => {
    this.setState({ message: "" });
    this.resetMessageCharacters("");
  };

  hideAlert = () => {
    this.setState({
      alert: null,
      isLoading: false,
    });
  };

  resetMessageCharacters(message) {
    this.setState({
      charLeft: this.allowedSmsChar - message.length,
    });
  }

  // Confirm send push notification
  sendPushModal = () => {
    return (
      <Modal
        open={this.state.sendPushModalOpen}
        onClose={() => this.setState({ sendPushModalOpen: false })}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 400, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div
              style={{ justifyContent: "center", display: "flex" }}
            >{`Are you sure you want to send the app push notification blast?`}</div>
            <div style={{ justifyContent: "space-between", display: "flex" }}></div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => this.setState({ sendPushModalOpen: false })}>Cancel</Button>
              <Button
                color="danger"
                onClick={() => {
                  if (this.state.message) {
                    //if fully available then just send true
                    let fullyAvailable =
                      this.state.availability.filter((a) => a.active).length >= 7;
                    this.send(
                      (this.state.isTrainedSelected && this.state.isUnTrainedSelected) ||
                        (!this.state.isTrainedSelected && !this.state.isUnTrainedSelected)
                        ? "all"
                        : this.state.isTrainedSelected
                        ? "trained"
                        : "untrained",
                      this.state.message,
                      this.state.isTrainedSelected &&
                        !this.state.isUnTrainedSelected &&
                        (fullyAvailable || this.state.availability),
                      this.calculateDesignatedNumber(),
                    );
                    this.setState({
                      userType: null,
                      message: "",
                      sendPushModalOpen: false,
                    });
                  } else {
                    this.setState({
                      sendPushModalOpen: false,
                      errorMessage: "Sorry, couldn't send the app notification blast.",
                    });
                  }
                }}
              >
                Send
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  // Confirm send email notifications
  sendEmailModal = () => {
    return (
      <Modal
        open={this.state.sendEmailModalOpen}
        onClose={() => this.setState({ sendEmailModalOpen: false })}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 400, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div
              style={{ justifyContent: "center", display: "flex" }}
            >{`Are you sure you want to send the Email blast?`}</div>
            <div style={{ justifyContent: "space-between", display: "flex" }}></div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => this.setState({ sendEmailModalOpen: false })}>Cancel</Button>
              <Button
                color="danger"
                onClick={() => {
                  if (this.state.message) {
                    //if fully available then just send true
                    let fullyAvailable =
                      this.state.availability.filter((a) => a.active).length >= 7;
                    this.send(
                      (this.state.isTrainedSelected && this.state.isUnTrainedSelected) ||
                        (!this.state.isTrainedSelected && !this.state.isUnTrainedSelected)
                        ? "all"
                        : this.state.isTrainedSelected
                        ? "trained"
                        : "untrained",
                      this.state.message,
                      this.state.isTrainedSelected &&
                        !this.state.isUnTrainedSelected &&
                        (fullyAvailable || this.state.availability),
                      this.calculateDesignatedNumber(),
                    );
                    this.setState({
                      userType: null,
                      message: "",
                      sendEmailModalOpen: false,
                    });
                  } else {
                    this.setState({
                      sendEmailModalOpen: false,
                      errorMessage: "Sorry, couldn't send the email blast.",
                    });
                  }
                }}
              >
                Send
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };
  // Confirm send sms notificaiton
  sendSmsModal = () => {
    return (
      <Modal
        open={this.state.sendSmsModalOpen}
        onClose={() => this.setState({ sendSmsModalOpen: false })}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 400, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div
              style={{ justifyContent: "center", display: "flex" }}
            >{`Are you sure you want to send the sms notification blast?`}</div>
            <div style={{ justifyContent: "space-between", display: "flex" }}></div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => this.setState({ sendSmsModalOpen: false })}>Cancel</Button>
              <Button
                color="danger"
                onClick={() => {
                  if (this.state.message) {
                    //if fully available then just send true
                    let fullyAvailable =
                      this.state.availability.filter((a) => a.active).length >= 7;
                    this.send(
                      (this.state.isTrainedSelected && this.state.isUnTrainedSelected) ||
                        (!this.state.isTrainedSelected && !this.state.isUnTrainedSelected)
                        ? "all"
                        : this.state.isTrainedSelected
                        ? "trained"
                        : "untrained",
                      this.state.message,
                      this.state.isTrainedSelected &&
                        !this.state.isUnTrainedSelected &&
                        (fullyAvailable || this.state.availability),
                      this.calculateDesignatedNumber(),
                    );
                    this.setState({
                      userType: null,
                      message: "",
                      sendSmsModalOpen: false,
                    });
                  } else {
                    this.setState({
                      sendSmsModalOpen: false,
                      errorMessage: "Sorry, couldn't send the sms blast.",
                    });
                  }
                }}
              >
                Send
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  _renderAvailability = (dayOfWeekText, day) => {
    return (
      <Row>
        <Col sm="2">
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                onChange={() => this.toggleCheckMark(day)}
                checked={this.state.availability[day].active === true}
                id={dayOfWeekText === "Monday" ? "availability" : null}
              />
              <span className="form-check-sign" />
              {dayOfWeekText}
            </Label>
          </FormGroup>
        </Col>

        {this.state.availability[day].active ? (
          <Col sm="8">
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.availability[day].m}
                  onChange={() => this.toggleShifts("m", day)}
                />
                <span className="form-check-sign" />
                Morning
              </Label>
            </FormGroup>

            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.availability[day].a}
                  onChange={() => this.toggleShifts("a", day)}
                />
                <span className="form-check-sign" />
                Afternoon
              </Label>
            </FormGroup>

            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.availability[day].n}
                  onChange={() => this.toggleShifts("n", day)}
                />
                <span className="form-check-sign" />
                Night
              </Label>
            </FormGroup>
          </Col>
        ) : null}
      </Row>
    );
  };

  // day = number between 0 (monday) to 6 (sunday)
  toggleCheckMark = (day) => {
    let tempAvailability = this.state.availability;
    tempAvailability[day].active = !tempAvailability[day].active;
    this.setState({ availability: tempAvailability });
  };

  // MAN = choice of "m", "a", "n" for morning afternoon or night
  toggleShifts = (MAN, day) => {
    let tempAvailability = this.state.availability;

    MAN == "m"
      ? (tempAvailability[day].m = !tempAvailability[day].m) // m
      : MAN == "a"
      ? (tempAvailability[day].a = !tempAvailability[day].a) // a
      : (tempAvailability[day].n = !tempAvailability[day].n); // n

    this.setState({ availability: tempAvailability });
  };

  render() {
    return (
      <div className="content">
        {this.state.isLoading ? (
          <LoadingOverlay message={this.state.loadingMessage} />
        ) : (
          <Row>
            <Col md="12">
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4">Create Notification</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="/" className="form-horizontal" method="get">
                    <Row>
                      <Col
                        xs="4"
                        s="3"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <Label>Trained</Label>
                      </Col>
                      <Col xs="4" s="3">
                        <FormGroup>
                          <CustomInput
                            type="switch"
                            id="trainedSwitch"
                            name="trainedSwitch"
                            checked={this.state.isTrainedSelected}
                            className="checkBox"
                            color="success"
                            onChange={(event) => {
                              this.state.isTrainedSelected
                                ? this.setState({ isTrainedSelected: false })
                                : this.setState({ isTrainedSelected: true });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs="4"
                        s="3"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <Label>Untrained</Label>
                      </Col>
                      <Col xs="4" s="3">
                        <FormGroup>
                          <CustomInput
                            type="switch"
                            id="unTrainedSwitch"
                            name="untrainedSwitch"
                            checked={this.state.isUnTrainedSelected}
                            onChange={(event) => {
                              this.state.isUnTrainedSelected
                                ? this.setState({ isUnTrainedSelected: false })
                                : this.setState({ isUnTrainedSelected: true });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {!this.state.isUnTrainedSelected ? (
                      //only for trained people there is specific business and shift
                      <>
                        {/**This business feature is not available for now as we don't have an option for all users
                        <Row>
                          <Label sm="3">Business</Label>
                          <Col sm="3">
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="selectWorkLocation"
                              value={this.state.businessSelected}
                              onChange={(value) => {
                                console.log(value);
                                this.setState({ businessSelected: value });
                              }}
                              options={this.state.businessListReactSelect}
                              placeholder="Select Work Location"
                              isDisabled
                            />
                          </Col>
                        </Row>
                         */}

                        {/* <Row>
                          <Label sm="3">Shift</Label>
                          <Col sm="3">
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="selectWorkLocation"
                              value={this.state.shiftSelected}
                              onChange={(value) =>
                                this.setState({ shiftSelected: value })
                              }
                              options={this.shift}
                              placeholder="Select Shift"
                            />
                          </Col>
                        </Row> */}
                        <Row>
                          <Label sm="2">Availability</Label>
                          <Col className="checkbox-radios" sm="10">
                            <Row>
                              <Col sm="2">
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      onChange={() =>
                                        this.setState((prevState) => {
                                          let fullyAvailable =
                                            this.state.availability.filter((a) => a.active)
                                              .length >= 7;
                                          return {
                                            ...prevState,
                                            availability: [
                                              ...prevState.availability.map((a) => {
                                                if (fullyAvailable) {
                                                  a.active = false;
                                                } else {
                                                  a.active = true;
                                                }
                                                return a;
                                              }),
                                            ],
                                          };
                                        })
                                      }
                                      checked={
                                        this.state.availability.filter((a) => a.active).length >= 7
                                      }
                                      id="allAvailability"
                                    />
                                    <span className="form-check-sign" />
                                    All
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>

                            {this._renderAvailability("Monday", 0)}
                            {this._renderAvailability("Tuesday", 1)}
                            {this._renderAvailability("Wednesday", 2)}
                            {this._renderAvailability("Thursday", 3)}
                            {this._renderAvailability("Friday", 4)}
                            {this._renderAvailability("Saturday", 5)}
                            {this._renderAvailability("Sunday", 6)}
                            <FormText color="danger" tag="span">
                              {this.state.errorAvailability}
                            </FormText>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                    <Row>
                      <Label sm="2">* Notification method</Label>
                      <Col className="checkbox-radios" sm="10">
                        <Row>
                          <Col xs="auto">
                            <FormGroup check inline>
                              <Permission
                                module={PermissionModuleEnum.Notification}
                                action={PermissionActionEnum.Sms}
                                error="You don't have enough permission to send sms."
                              >
                                <Label check for="sendSms">
                                  <Input
                                    type="checkbox"
                                    onChange={() =>
                                      this.setState({
                                        sendSms: !this.state.sendSms,
                                        sendEmail: false,
                                        sendPush: false,
                                      })
                                    }
                                    checked={this.state.sendSms}
                                    id="sendSms"
                                  />
                                  <span className="form-check-sign" />
                                  SMS
                                </Label>
                              </Permission>
                            </FormGroup>
                          </Col>
                          <Col xs="auto">
                            <FormGroup check inline>
                              <Label check for="sendPush">
                                <Input
                                  type="checkbox"
                                  onChange={() => {
                                    // Sets the state for selection
                                    this.setState({
                                      sendPush: !this.state.sendPush,
                                      sendEmail: false,
                                      sendSms: false,
                                      charLeft: !this.state.sendPush
                                        ? this.allowedPushChar -
                                          this.state.titleChar -
                                          this.state.messageChar
                                        : this.allowedSmsChar -
                                          this.state.titleChar -
                                          this.state.messageChar,
                                    });
                                  }}
                                  checked={this.state.sendPush}
                                  id="sendPush"
                                />
                                <span className="form-check-sign" />
                                App
                              </Label>
                            </FormGroup>
                          </Col>

                          <Col xs="auto">
                            <FormGroup check inline>
                              <Permission
                                module={PermissionModuleEnum.Notification}
                                action={PermissionActionEnum.Email}
                                error="You don't have enough permission to send email notifications."
                              >
                                <Label check for="sendEmail">
                                  <Input
                                    type="checkbox"
                                    onChange={() =>
                                      this.setState({
                                        sendEmail: !this.state.sendEmail,
                                        sendSms: false,
                                        sendPush: false,
                                      })
                                    }
                                    checked={this.state.sendEmail}
                                    id="sendEmail"
                                  />
                                  <span className="form-check-sign" />
                                  Email
                                </Label>
                              </Permission>
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormText color="danger" tag="span">
                          {this.state.errorMethod}
                        </FormText>
                      </Col>
                    </Row>
                    {(this.state.sendPush || this.state.sendEmail) && (
                      <Row>
                        <Label sm="2"> Subject/Title (max 64 chars)</Label>
                        <Col sm="6">
                          <FormGroup>
                            <Input
                              type="text"
                              name="messageTitle"
                              id="messageTitle"
                              value={this.state.messageTitle}
                              onChange={(e) => {
                                if (e.target.value.length >= 64) {
                                  // do nothing if at 64 chars.
                                } else {
                                  this.setState({
                                    messageTitle: e.target.value,
                                    titleChar: e.target.value.length,
                                    charLeft: this.state.sendPush
                                      ? this.allowedPushChar -
                                        this.state.messageChar -
                                        this.state.titleChar
                                      : this.allowedSmsChar -
                                        this.state.messageChar -
                                        this.state.titleChar,
                                  });
                                }
                              }}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorTitle}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <Row style={{ marginBottom: 3 }}>
                      <Label sm="2">Businesses</Label>
                      <Col sm="6">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            isMulti
                            options={this.state.businessData}
                            value={this.state.selectedBusinesses}
                            onChange={(value) => {
                              this.setState({ selectedBusinesses: value });
                            }}
                            placeholder="Select businesses"
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorBusiness}
                          </FormText>
                        </FormGroup>
                        <FormText tag="span">
                          {`IF YOU WISH TO BLAST ALL WORKERS LEAVE THIS FIELD BLANK. 
                            Selecting the business will send the message to workers 
                            who have worked with at least one selected businesses before.`}
                        </FormText>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2"></Label>
                      <Col sm="3">
                        <Button onClick={this.clearMessageBox} className="btn-secondary">
                          Clear Message Box
                        </Button>
                      </Col>
                      <Col sm="3">
                        <Button onClick={this.generateMessage} className="btn-primary">
                          Default Message
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="2">
                        * Message{" "}
                        {`${this.state.sendPush ? "(Max 178 chars combined with Title)" : ""}`}
                      </Label>
                      <Col sm="6">
                        <FormGroup>
                          <Input
                            type="textarea"
                            name="message"
                            id="message"
                            value={this.state.message}
                            style={{ maxHeight: "200px" }}
                            rows="10"
                            onChange={(e) => {
                              const { name, value } = e.target;
                              this.setState({ messageChar: value.length });
                              if (
                                this.state.charLeft > 0 ||
                                this.state.keyPressed === 8 || //if charleft<=0 only allowed to use backspace or delete
                                this.state.keyPressed === 46
                              ) {
                                this.setState((prevState) => {
                                  return {
                                    charLeft: this.state.sendPush
                                      ? this.allowedPushChar -
                                        this.state.messageChar -
                                        this.state.titleChar
                                      : this.allowedSmsChar -
                                        this.state.messageChar -
                                        this.state.titleChar,
                                    errorMessage: "",
                                    message: value,
                                  };
                                });
                              }
                            }}
                          />
                        </FormGroup>
                        <FormText color={this.state.charLeft < 10 ? "danger" : "green"} tag="span">
                          {this.state.charLeft} characters remaining (total characters 160 for
                          App/Sms and 1550 for email).
                        </FormText>
                        <FormText color="danger" tag="span">
                          {this.state.errorMessage}
                        </FormText>
                      </Col>
                    </Row>

                    <Row>
                      <Label sm="3"></Label>
                      <Col sm="3">
                        <Button className="btn-success" onClick={this.handleSubmit}>
                          Send
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {this.state.alert}

        {/* Confirmation Modals */}
        {this.sendSmsModal()}
        {this.sendPushModal()}
        {this.sendEmailModal()}
      </div>
    );
  }
}

export default CreateNotification;

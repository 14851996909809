import React, { useReducer, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Error404 from "views/Staffing/pages/Error404.jsx";
import SelectPlan from "views/Staffing/register/SelectPlan.jsx";
import RegisterBusiness from "views/Staffing/register/RegisterBusiness.jsx";
import RegisterAdmin from "views/Staffing/register/RegisterAdmin.jsx";
import RegisterError from "views/Staffing/register/RegisterError.jsx";
import RegisterSuccess from "views/Staffing/register/RegisterSuccess.jsx";
import CompleteRegistrationPayment from "views/Staffing/register/CompleteRegistrationPayment.jsx";

import RegisterContext from "../../context/RegisterContext";
import registerReducer from "../../reducers/staffingRegisterReducer";
import Progress from "../../components/ProgressBar/StepProgressBar.js";

import RegisterNavbar from "components/Navbars/RegisterNavbar.js";

function Admin(props) {
  const initialState = () => {
    const state = localStorage.getItem("registration");
    if (state) {
      return { ...JSON.parse(state) };
    }
    return {
      agency: null,
      user: null,
      plan: null,
      success: false,
      paymentData: null,
      progressBarIndex: 0,
      allowNavigation: [false, false, false, false, false],
    };
  };
  const [registration, registrationDispatch] = useReducer(registerReducer, initialState());
  return (
    <RegisterContext.Provider value={{ registration, registrationDispatch }}>
      <div style={{paddingBottom: 70}}>
      <RegisterNavbar />
      </div>
      <div>
      <Progress
        currentIndex={registration.progressBarIndex}
        history={props.history}
        steps={[
          {
            label: "Register Admin",
            //subtitle: "20%",
            name: "step 1",
            url: "/staffing/register",
            allowNavigation: registration.allowNavigation[0],
          },
          {
            label: "Register Business",
            //subtitle: "40%",
            name: "step 2",
            url: "/staffing/register/register-business",
            allowNavigation: registration.allowNavigation[1],
          },
          {
            label: "Select Plan",
            //subtitle: "60%",
            name: "step 3",
            url: "/staffing/register/select-plan",
            allowNavigation: registration.allowNavigation[2],
          },
          {
            label: "Payment Info",
            //subtitle: "80%",
            name: "step 4",
            url: "/staffing/register/checkout",
            allowNavigation: registration.allowNavigation[3],
          },
          {
            label: "Order Completed",
            //subtitle: "100%",
            name: "step 5",
            url: "/staffing/register/success",
            navigateToOtherPages: false,
            allowNavigation: registration.allowNavigation[4],
          },
        ]}
      />
      </div>
      <Switch>
        <Route path="/staffing/register" exact render={(props) => <RegisterAdmin {...props} />} />
        {/* <Route
          path="/staffing/register/progress-bar"
          exact
          render={(props) => <ProgressBar {...props} />}
        /> */}
        <Route
          path="/staffing/register/select-plan"
          exact
          render={(props) => <SelectPlan {...props} />}
        />
        <Route
          path="/staffing/register/register-business"
          exact
          render={(props) => <RegisterBusiness {...props} />}
        />
        <Route
          path="/staffing/register/register-admin"
          exact
          render={(props) => <RegisterAdmin {...props} />}
        />
        <Route
          path="/staffing/register/checkout"
          render={(props) => <CompleteRegistrationPayment {...props} />}
        />
        <Route
          path="/staffing/register/success"
          render={(props) => <RegisterSuccess {...props} />}
        />
        <Route path="/staffing/register/error" render={(props) => <RegisterError {...props} />} />
        <Route render={(props) => <Error404 {...props} />} />
      </Switch>
      <div className="text-center">
        <h6>
          © {new Date().getFullYear()}, made with <i className="fa fa-heart heart" />
          By Omadatek
        </h6>
      </div>
    </RegisterContext.Provider>
  );
}

export default Admin;

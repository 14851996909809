import React, { useEffect, useState } from "react";

let vx = 0;
let vy = 0;
let vz = 0;

// let dx = 0;
// let dy = 0;

const freq = 4;
// const dt = 1 / freq;

let _laxForward = true;
let _layForward = true;
let _lazForward = true;

let diffX = 0;
let diffY = 0;
let diffZ = 0;

let time = new Date();
export default function useLinearAccelerometer(active) {
  const [acceleration, setAcceleration] = useState({ x: 0, y: 0, z: 0 });
  const [running, setRunning] = useState(false);

  useEffect(() => {
    if (active && window.LinearAccelerationSensor) {
      let laSensor = new window.LinearAccelerationSensor({ frequency: freq });
      laSensor.addEventListener("reading", (e) => {
        let ax = Math.abs(laSensor.x) > 2 ? laSensor.x : 0,
          ay = Math.abs(laSensor.y) > 2 ? laSensor.y : 0,
          az = Math.abs(laSensor.z) > 2 ? laSensor.z : 0;

        let currentXForward = ax > 0;
        let currentYForward = ay > 0;
        let currentZForward = az > 0;

        if (_laxForward !== currentXForward && ax != 0) {
          diffX++;
          _laxForward = currentXForward;
        }

        if (_layForward !== currentYForward && ay != 0) {
          diffY++;
          _layForward = currentYForward;
        }

        if (_lazForward !== currentZForward && az != 0) {
          diffZ++;
          _lazForward = currentZForward;
        }
        setAcceleration({
          x: Math.ceil(laSensor.x),
          y: Math.ceil(laSensor.y),
          z: Math.ceil(laSensor.z),
        });
      });
      laSensor.start();

      let intervalFn = () => {
        setRunning((running) => {
          if (running) return !running;
        });
        if (diffX > 2 || diffY > 2 || diffZ > 2) {
          setRunning(true);
          diffX = 0;
          diffY = 0;
          diffZ = 0;
          window.navigator.vibrate(200);
        }
        diffX = 0;
        diffY = 0;
        diffZ = 0;
      };
      let unsubscribeInterval = setInterval(intervalFn, 800);

      return () => {
        clearInterval(unsubscribeInterval);
      };
    }
  }, []);

  return {
    acceleration,
    running,
  };
}

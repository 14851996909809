import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";
export default function SubscriptionPlans(props) {
  if (!props.plans.length) {
    return <p className="text-center">Sorry! No {props.planType} plan available</p>;
  }
  let sideCol = "4";
  if (props.plans.length == 2) {
    sideCol = "2";
  }
  return (
    <Row>
      <Col md={sideCol} />
      <Col md="4">
        <Card
          className="card-pricing"
          data-background="image"
          style={{
            backgroundImage: "url(" + require("assets/img/sections/forest-bg.jpg") + ")",
          }}
        >
          <CardBody>
            <h6 className="card-category">{props.plans[0].name}</h6>
            <CardTitle tag="h1">
              <small>$</small>
              {props.plans[0].price}
              <small>{props.planType === "Monthly" ? " /MONTH" : " /YEAR"}</small>
            </CardTitle>
            <ul>
              <li>
                <b>100</b> Projects
              </li>
              <li>
                <b>5</b> Team Members
              </li>
              <li>
                <b>55</b> Personal Contacts
              </li>
              <li>
                <b>5.000</b> Messages
              </li>
            </ul>
            <Button
              className="btn-round"
              color="success"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                props.onSelect(props.plans[0]);
              }}
            >
              Continue
            </Button>
          </CardBody>
        </Card>
      </Col>

      {props.plans.length === 2 ? (
        <Col md="6">
          <Row>
            <Col md="8">
              <Card
                className="card-pricing"
                data-background="image"
                style={{
                  backgroundImage: "url(" + require("assets/img/sections/anders-jilden.jpg") + ")",
                }}
              >
                <CardBody>
                  <h6 className="card-category">{props.plans[1].name}</h6>
                  <CardTitle tag="h1">
                    <small>$</small>
                    {props.plans[1].price}
                    <small>{props.planType === "Monthly" ? " /MONTH" : " /YEAR"}</small>
                  </CardTitle>
                  <ul>
                    <li>
                      <b>1</b> Project
                    </li>
                    <li>
                      <b>5</b> Team Members
                    </li>
                    <li>
                      <b>55</b> Personal Contacts
                    </li>
                    <li>
                      <b>5.000</b> Messages
                    </li>
                  </ul>
                  <Button
                    className="btn-round"
                    color="danger"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      props.navigateToRegisterAdmin(props.plans[1]);
                    }}
                    disabled={true}
                  >
                    Continue
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="4"></Col>
          </Row>
        </Col>
      ) : (
        ""
      )}

      <Col md={sideCol} />
    </Row>
  );
}

/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
  FormText,
  Spinner,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactTable from "react-table";
import QRCode from "react-qr-code";
import { validateEmail } from "components/Utilities/utilities.js";
import api from "../../../components/API/api";
import AgencyContext from './../../../context/AgencyContext';

class StaffBusinessProfile extends React.Component {
  static contextType = AgencyContext;

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      //setting states for profile data
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      staffId: "",
      agencyId: "",
      businessName: "",

      //permission data
      selectedPermission: [],
      permissionsList: [],
      businessList:[],

      //history
      changeLog: [],

      //Errors
      errorFirstName: "",
      errorLastName: "",
      errorPhone: "",
      errorEmail: "",

      // Loading
      isLoading: false,
      pageTabs: "profile",
    };
  }

  componentDidMount() {
    this.fetchData();
    this.fetchBusiness();
    // //popup warning if user reloads or closes the page
    window.addEventListener("beforeunload", this.handleUserLeave);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  fetchBusiness = async () => {
    try {
      const { data } = await api().get("/business/list", {params: {slug:this.context.agency.agency.slug}});
      this.setState({businessList: data}, () => this.getBusinessName())
    } catch (error) {
      console.log(error);
    }
  };

  fetchData = () => {
    this.setState({ isLoading:true })
    //geting staff Id from URL
    let selectedUrl = window.location.pathname.split("/");
    let selectedDocId = selectedUrl[4];

    api()
      .get(`/users/agency/${selectedDocId}`)
      .then((res) => {
        let data = res.data[0];
        console.log(data);
        this.setState({
          user: data,
          staffId: data._id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          agencyId: data.profile.agency
        }, () => this.getBusinessName());


        //to get permissions from profile informations
        let permissionsArray = data.profile.permissions
        let permissionsList = this.state.permissionsList
        if (permissionsArray) {
          permissionsList.map(p => {
            p.actions[0].map(act => (act.active) ? act.active = !act.active : null)
          })
          permissionsArray.map(a => {
            permissionsList.map(p => {
              if (p.name === a.module) {
                p.active = true
                p.actions[0].map(act => {
                  /* eslint-disable */
                  act.action === a.action ? act.active = true : null

                })
              }
            })
          })
          permissionsList.map(p => {
            (p.active) ? null : p.actions[0].map(act => act.active ? null : act.active = true)
          })
          this.setState({
            selectedPermission: permissionsList,
            isLoading: false
          })
        }
      })
      .catch((err) =>{ 
        this.setState({ isLoading: false})
        console.log(err)
      });
  };

  getBusinessName = () => {
    try {
      if(this.state.user && this.state.businessList){
        const business = this.state.businessList.find(b => b._id === this.state.user.profile.business)
        business? this.setState({businessName: business.name}): null
      }
    } catch (error) {
      
    }
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = async (docId) => {
    this.setState({ isLoading: true });
    let tempState = this.state; // Copy of state
    let hasError = false;

    // Start of Error Handling
    if (tempState.firstName == null || tempState.firstName.trim() == "") {
      this.setState({ errorFirstName: "* Must have a first name." });
      hasError = true;
    } else {
      this.setState({ errorFirstName: "" });
    }

    if (tempState.lastName == null || tempState.lastName.trim() == "") {
      this.setState({ errorLastName: "* Must have a last name." });
      hasError = true;
    } else {
      this.setState({ errorLastName: "" });
    }

    if (tempState.phone == null || tempState.phone.trim() == "") {
      this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
      hasError = true;
    } else {
      if (isNaN(tempState.phone) == true || tempState.phone.length != 10 || tempState.phone < 0) {
        this.setState({ errorPhone: "* Please input a valid phone number." }); //invalid phone number
        hasError = true;
      } else {
        this.setState({ errorPhone: "" });
      }
    }

    if (tempState.email == null || tempState.email.trim() == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }


    if (hasError) {
      return;
    } else {
      let dataToUpdate = null;
      let permissionsArray = [];
      // let currentDate = new Date();
      // let dateString = JSON.stringify(currentDate)
      // let replacedDate = dateString.replace(/^\"|\.[0-9]{3}$/g, '').split("T");
      // let updateDate = replacedDate[0];
      // let replacedTime = replacedDate[1].split(".");
      // let time = replacedTime[0]
      // let updateDateTime = updateDate.concat(": ").concat(time)

      // let user = localStorage.getItem('adminEmail');
      // let replacedUser = user.replace(/^\"|\"$/g, '');
      // let updateLog = this.state.changeLog
      // updateLog.push({
      //   admin: replacedUser,
      //   time: updateDateTime,
      //   action: "Updated Manager Profile",
      // });

      dataToUpdate = {
        userId: tempState.staffId,
        firstName: tempState.firstName,
        lastName: tempState.lastName,
        phone: tempState.phone,
        email: tempState.email,
        // profileType: "Staff"
      };

      try {
        const respPatch = await api().patch("/users/", dataToUpdate)
        console.log("successfully saved Data");
        this.setState({
          isLoading: false,
          alert: (
            <SweetAlert
              success
              title="Staff data successfully updated"
              onConfirm={this.hideAlert}
            />
          ),
        })
      } catch (e) {
        console.log(e)
        this.setState({ isLoading: false })
      }
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  _renderPermission = (p) => {
    return (
      <Row>
        <Col sm="3">
          <FormGroup check inline>
            <Label check>
              <Input type="checkbox" checked={p.active} onChange={() => this.toggleCheckMark(p.name)} />
              <span className="form-check-sign" />
              {p.displayName}
            </Label>
          </FormGroup>
        </Col>

        {  /* eslint-disable */
          p.active
            ?
            p.actions ? p.actions[0].map((a) => (
              // to delete none from actions list
              a.action === "None" ? null :
                <Col sm="2">
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={a.active}
                        onChange={() => this.toggleActions(a.action, p.name)}
                      />
                      <span className="form-check-sign" />
                      {a.action}
                    </Label>
                  </FormGroup>
                </Col>
            ))
              : null
            : null}
      </Row>
    );
  };

  toggleCheckMark = (name) => {
    let tempPermission = this.state.permissionsList;
    tempPermission.map((p) => {
      p.name === name ? p.active = !p.active : null;
    });
    this.setState({ selectedPermission: tempPermission });
  };

  toggleActions = (a, name) => {
    let selected = null;
    let tempPermission = this.state.selectedPermission;
    tempPermission.map((p) => {
      if (p.name === name) {
        p.actions[0].map((act) => {
          act.action === a ? (act.active = !act.active) : null;
          this.checkAllActions(p.actions[0], p);
        });
      }
    });
    this.setState({ selectedPermission: tempPermission });
  };

  // to check if all actions are unchecked or not
  checkAllActions = (actions, p) => {
    let selected = null;
    actions.map((act) => (act.active ? (selected = true) : null));
    if (selected) {
      return;
    } else {
      // if they unselect all actions, uncheck the module and set all actions active: true(default state) in module
      p.active = false;
      actions.map((act) => (act.active = true));
    }
  };


  render() {
    return (
      <>
        <div className="content">
          {this.state.isLoading ? (
            this.loadingOverlay()
          ) : (
              <Row>
                <Col>
                  <Card className="no-transition">
                    <CardHeader>
                      <Row>
                        <Col md="6">
                          <CardTitle tag="h4">
                            {" "}
                          Profile of {`${this.state.firstName}`} {`${this.state.lastName}`}
                          </CardTitle>
                        </Col>
                        <Col md="6" align="right">
                          {/* <Button
                        type="submit"
                        color="default"
                        outline
                        onClick={this.loadShiftHistory}
                      >
                        Full Shift History
                      </Button> */}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Nav
                        className="nav-pills-primary nav-pills-icons justify-content-center"
                        pills
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            role="tablist"
                            className={this.state.pageTabs === "profile" ? "active" : ""}
                            onClick={() => this.setState({ pageTabs: "profile" })}
                          >
                            <i className="now-ui-icons objects_umbrella-13" />
                          Edit Profile
                        </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            role="tablist"
                            className={this.state.pageTabs === "changeLog" ? "active" : ""}
                            onClick={() => this.setState({ pageTabs: "changeLog" })}
                          >
                            <i className="now-ui-icons ui-2_settings-90" />
                          Change Log
                        </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        className="tab-space tab-subcategories"
                        activeTab={this.state.pageTabs}
                      >
                        <TabPane tabId="profile">
                          <CardHeader>
                            <CardTitle tag="h4">Edit Profile</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <Form action="/" className="form-horizontal" method="get">
                              <Row>
                                <Label sm="2">*First Name</Label>
                                <Col sm="5">
                                  <FormGroup>
                                    <Input
                                      defaultValue={`${this.state.firstName}`}
                                      onChange={(e) => this.setState({ firstName: e.target.value })}
                                      placeholder="First Name"
                                      type="text"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorFirstName}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2">*Last Name</Label>
                                <Col sm="6">
                                  <FormGroup>
                                    <Input
                                      defaultValue={`${this.state.lastName}`}
                                      onChange={(e) => this.setState({ lastName: e.target.value })}
                                      placeholder="Last Name"
                                      type="text"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorLastName}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2">* Phone Number</Label>
                                <Col md="5">
                                  <FormGroup>
                                    <MaskedInput
                                      mask={[
                                        "(",
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ")",
                                        "-",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        "-",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                      ]}
                                      defaultValue={`${this.state.phone}`}
                                      onChange={(e) => {
                                        let input = e.target.value;
                                        let replacedPhone = input.replace(/[^0-9]/g, "");
                                        this.setState({ phone: replacedPhone });
                                      }}
                                      placeholder="Phone Number"
                                      className="form-control"
                                      maxLength="15"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorPhone}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2">* Email</Label>
                                <Col sm="6">
                                  <FormGroup>
                                    <Input
                                      defaultValue={`${this.state.email}`}
                                      onChange={(e) => this.setState({ email: e.target.value })}
                                      placeholder="Contact Email"
                                      type="text"
                                    />
                                    <FormText color="danger" tag="span">
                                      {this.state.errorEmail}
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="2"> Business Name</Label>
                                <Col className="checkbox-radios" sm="5">
                                <Input
                                      defaultValue={`${this.state.email}`}
                                      disabled
                                      value={this.state.businessName}
                                      type="text"
                                    />
                                  
                                </Col>
                              </Row>

                              <FormGroup align="right">
                                <Button color="default" outline onClick={() => this.handleSubmit()}>
                                  Save Changes
                              </Button>
                              </FormGroup>
                            </Form>
                          </CardBody>
                        </TabPane>
                        <TabPane tabId="changeLog">
                          <CardHeader>
                            <CardTitle tag="h4">Change Log</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <ReactTable
                              data={this.state.changeLog}
                              filterable
                              columns={[
                                {
                                  Header: "Admin",
                                  accessor: "admin",
                                },
                                {
                                  Header: "Time Changed",
                                  accessor: "time",
                                },
                                {
                                  Header: "Action Taken",
                                  accessor: "action",
                                },
                              ]}
                              defaultPageSize={10}
                              showPaginationTop={true}
                              showPaginationBottom={false}
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight primary-pagination"
                            />
                          </CardBody>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          {this.state.alert}
        </div>
      </>
    );
  }
}

export default StaffBusinessProfile;

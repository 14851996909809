/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert"



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col
} from "reactstrap";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      User: "",
      Password: "",
      newPassword: "",
      retypePassword: "",
      isCorrect: false,
      button: true,
      alert: null

    };
  }

  users = {
    'q': "q",
    "David": "Car",
    "Hellen": "Why^_$#Nop_"
  }

  //What : This function will verify if the existing user name and password match the database/object values
  //Input : This function will excute when the "Verify Password Button is pressed". By default it is taking input from "users" object (hard coded)
  //Output : If password match : Throw success alert and open more form fields
  // If password not match: Throw failure alert and tell user to try again

  verifyPassword = () => {

    const database = { ...this.users }
    let user_found = false

    Object.keys(database).map(key => {

      console.log(key, this.state.User, database[key], this.state.Password)

      if (key === this.state.User && database[key] === this.state.Password) {
        this.basicAlert("Verified!", "Please Enter New Password", () => this.hideAlert())
        this.setState({
          isCorrect: true
        })
        user_found = true
      }

    })

    if (!user_found) {
      this.basicAlert("InCorrect Credentials", "Your Username and/or Password are incorrect. Please Try again.", () => this.hideAlert())
    }
  }

  //What : This function will change the Show Button to Verify Button if the existing credentials are verified
  //Input: This function will excute when the "Verify Password" Button is pressed and succeeds
  //Output : Output button changes from Verify Password to Change password

  showButton = () => {

    this.setState({
      button: !this.state.button
    })
  }

  //What : This function will verify if the new password entered by the user are matching
  //Input : When user presses the Change Password button
  //Output : If passwords verify : Give success alert and reset form
  // If passwords do not verify : Tell user to try again

  checkNewPassword = () => {

    if (this.state.newPassword === this.state.retypePassword) {
      this.successAlert()
    } else {

      this.noSuccessAlert()

    }
  }

  //What : This function will run if the new passwords entered are verified
  //Input : When user presses the Change Password button and is successful
  //Output : Give success alert and reset page

  successAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Password Changed!"
          onConfirm={() => this.resetPage()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
        </ReactBSAlert>
      )
    });

  };

  //What : This function will run if the new passwords entered are not verified
  //Input : When user presses the Change Password button and is unsuccessful
  //Output : Give unsuccess alert and user to try again

  noSuccessAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Password Mismatch"
          onConfirm={() => this.setState({ newPassword: "", retypePassword: "", alert: null })}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Your new passwords don't match. Please enter same password in both lines.
        </ReactBSAlert>
      )
    });
  };

  //What : This is a generic function which will throw out alerts 
  //Input : Give alert title, Alert message and function to execute when confirmation button clicked
  //Output : Give out alert and run function

  basicAlert = (x, y, z) => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={x}
          onConfirm={z}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {y}
        </ReactBSAlert>
      )
    });
  };

  //What : Supporter function : Hides alert
  //Input : None
  //Output : Hides an open Alert

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  //What : Supporter function : Redirects to a particlar page
  //Input : None
  //Output : Hides an open Alert

  redirect = (x) => {

    window.location.href = `${x}`

  }


  //What : Supporter function : Resets the Page by setting state to initial State
  //Input : None
  //Output : Reset the page

  resetPage = () => {

    this.setState({

      User: "",
      Password: "",
      newPassword: "",
      retypePassword: "",
      isCorrect: false,
      button: true,
      alert: null

    })

  }

  componentWillUnmount() {
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  render() {

    return (
      <>
        <div className="content">
          {this.state.alert}
          <Row>
            <Col md="3"></Col>
            <Col md="6" className="align-center">
              <Card className="no-transition">
                <CardHeader>
                  <CardTitle tag="h4" style={{ textAlign: 'center' }}>Change User Password</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="#" method="#">
                    <label >Enter Employee User Name</label>
                    <FormGroup>
                      <Input type="text" required autoComplete="off" value={this.state.User} onChange={(e) => this.setState({ User: e.target.value })} />
                    </FormGroup>
                    <label >Enter Existing Password</label>
                    <FormGroup>
                      <Input type="password" autoComplete="off" value={this.state.Password} onChange={(e) => this.setState({ Password: e.target.value })} />
                    </FormGroup>

                    {/* This part of code will not initially. However, once the password is verified, then these two lines of form will appear for entering new password*/}

                    {this.state.isCorrect ?
                      (<FormGroup className="makeAppear">
                        <label >Enter New Password</label>
                        <Input type="password" autoComplete="off" value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} />
                        <label >Re-type New Password</label>
                        <Input type="password" autoComplete="off" value={this.state.retypePassword} onChange={(e) => this.setState({ retypePassword: e.target.value })} />
                      </FormGroup>)
                      : ""}
                    {/* <Col med="3">
                            <div className="pull-right" >
                              <p className="category">"Cancel?"</p>
                              <Switch onColor="blue" offColor="default" onChange={this.showButton}/>
                            </div>
                          </Col> */}

                  </Form>
                </CardBody>
                <CardFooter>

                  {/* This part of code will load "Verify Password" button initially. However, once the password is verified, the button will change to "Change Password"*/}

                  {this.state.isCorrect ? (<Button className="picAppear" color="success" onClick={this.checkNewPassword}>
                    Change Password
                  </Button>) : (<Button className="picAppear" color="success" onClick={this.verifyPassword}>
                    Verify Password
                  </Button>)}


                  <Button
                    className="pull-right picAppear"
                    // href={`/admin/dashboard`} 
                    color="danger"
                    onClick={() => this.basicAlert("Cancelled!", "Your Password has not changed",
                      () => this.redirect("http://localhost:3001/admin/dashboard"))}
                  >
                    Cancel
                        </Button>

                </CardFooter>
              </Card>
            </Col>
            <Col md="3"></Col>
          </Row >
        </div >
      </>
    );
  }
}


export default ChangePassword;

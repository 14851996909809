import { Nav, NavItem, NavLink } from "reactstrap";

const ShiftTab = ({selectedShiftFilter, setSelectedShiftFilter }) => {
  const shiftFilterList = [
    { name: "All" },
    { name: "Morning" },
    { name: "Afternoon" },
    { name: "Night" },
  ];

  return (
    <Nav
      className="nav-pills-primary nav-pills-icons justify-content-center"
      pills
      role="tablist"
    >
      {shiftFilterList.map((shift, i) => {
        return (
          <NavItem key={i}>
            <NavLink
              data-toggle="tab"
              role="tablist"
              className={selectedShiftFilter === shift.name ? "active" : ""}
              onClick={() => { setSelectedShiftFilter(shift.name) }}
            >
              <i className="now-ui-icons objects_umbrella-13" />
              {shift.name}
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );
};

export default ShiftTab
import { Modal } from "@mui/material";
import { Clear, Comment, ContactMail, Visibility, EditLocation } from "@mui/icons-material";
// Bring out of class to manage it's own state.
import api from "components/API/api";
import CustomExportBusiness, { customExcelData } from "components/Excel/CustomExportBusiness";
import AgencyContext from "context/AgencyContext";
import useFetchBusiness from "hooks/useFetchBusiness.js";
import usePermissions from "hooks/usePermissions.js";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory, Link } from "react-router-dom";
import ReactTable from "react-table";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { shiftTimeType, startOfDay, startOfNextDay } from "utils/dateUtils.js";
import { statusEnum } from "utils/statusVariables.js";
import PinCell from "../AllUsers/AllUsers/PinCell.jsx";
import CheckInTimeCell from "../LiveSelectedUsers/CheckInTimeCell";
import {
  csvDataHeaders,
  STATUS_OPTIONS,
} from "./AllLiveShiftWorkers/AllLiveShiftWorkersConstants.js";
import GlobalModal from "./AllLiveShiftWorkers/GlobalModalTest.jsx";
import ShiftFunctionCell from "./AllLiveShiftWorkers/ShiftFunctionCell";

const AllSelectedUsers = (props) => {
  const agencyContext = useContext(AgencyContext);
  const agency = agencyContext?.agency;
  const [workersData, setWorkersData] = useState([]); //actual workers data fetched
  const { role } = usePermissions();
  const hideSomeFields = role === "Call Centre Staff" ? true : false;
  const [data, setData] = useState([]); // data just to show in table based on options
  const [alert, setAlert] = useState(null);
  const [shiftId, setShiftId] = useState(null);
  const [shift, setShift] = useState({});
  const [business, setBusiness] = useState({});
  const [counters, setCounters] = useState({ confirmed: 0, backup: 0, available: 0 });
  const [date, setDate] = useState(null);
  const [selectedSFW, setSelectedSFW] = useState(null);
  const [openModalRemoveUser, setOpenModalRemoveUser] = useState(false);
  const [openModalAcceptUser, setOpenModalAcceptUser] = useState(false);
  const [openModalCommentUser, setOpenModalCommentUser] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openEditStatusModal, setOpenEditStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [unscheduleReason, setUnscheduleReason] = useState("");
  const history = useHistory();
  const [pageTabs, setPageTabs] = useState("confirmed");
  const [action, setAction] = useState(null);
  const [businessList, setBusinessList] = useState([]);
  const [fetchedBusiness, setFetchedBusiness] = useState(false);
  const [fetchedUserData, setFetchedUserData] = useState(false);
  const [selectedWorkerComments, setSelectedWorkerComments] = useState([]);
  const [addComment, setAddComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hdId, setHdId] = useState(null);
  const [errorHdId, setErrorHdId] = useState(null);
  const [shiftFunctionError, setShiftFunctionError] = useState(null);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [startSearch, setStartSearch] = useState("Searching...");
  const [selectedShiftFunction, setSelectedShiftFunction] = useState(null);
  const [download, setDownload] = useState(false);
  const [excelData, setExcelData] = useState([]);

  useFetchBusiness();
  useEffect(() => {
    if (agency?.businesses) {
      setBusinessList(agency.businesses);
      setFetchedBusiness(true);
    }
  }, [agency?.businesses]);

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);
    const shift = urlParams.get("shift");
    const date = urlParams.get("date");

    setShiftId(shift);
    setDate(date);
    if (typeof shift === "string" && typeof date === "string") {
      fetchUsers(shift, date);
      fetchShift(shift, date);
    } else {
      let slug = agencyContext.agency.agency.slug;
      history.push(`/${slug}/admin/live-business`);
    }
  }, []);

  useEffect(() => {
    //after both business and userData are loaded use the business to add it to worker's pin
    if (fetchedUserData && fetchedBusiness) {
      setFetchedUserData(false);
      setFetchedBusiness(false);
      if (data.length > 0 && businessList.length > 0) {
        setData((tempUserData) => {
          return tempUserData.map((u) => {
            u.lastAppLoginTime = u.lastAppLogin
              ? moment(u.lastAppLogin).format("MMM Do YYYY, h:mm a")
              : null;
            if (u.workerPin && u.workerPin.length > 0) {
              u.pin = u.workerPin.map((w) => w.pin || "-").join(", ");
              u.workerPin = u.workerPin
                .map((w) => {
                  let business = businessList.find((b) => b._id === w.business);
                  w.businessName = business && business.name;
                  return w;
                })
                .filter((w) => w.businessName);
            }
            return {
              ...u,
            };
          });
        });
      }
    }
  }, [fetchedUserData, fetchedBusiness]);

  const RenderActionButtons = (s) => {
    const color = s.checkInRecord?.length % 2 == 0 ? "info" : "danger";
    return (
      <div className="actions-center">
        <Button
          onClick={() => handleOpenWorkerCommentsModal(s)}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Comment />
        </Button>
        <Button
          onClick={() => handleOpenEditStatus(s)}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <EditLocation />
        </Button>
        {/* Worker Rating / Clock Modal are not currently in use. Worker Rating Modal will cause blank page. */}
        {/* <Button
          onClick={() => {
            handleOpenWorkerRatingModal(s);
          }}
          color={s.rating ? "" : "info"}
          size="md"
          className="btn-link btn-icon"
        >
          <Star />
        </Button> */}
        {/* <Button
          onClick={() => handleOpenModalClock(s)}
          color={color}
          size="md"
          className="btn-link btn-icon"
        >
          <AccessTime />
        </Button> */}
        <Button
          tag={Link}
          to={`/${agencyContext.agency?.agency?.slug}/admin/user-profile/${s._id}`}
          onClick={() => props.history.push(`user-profile/${s._id}`)}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility />
        </Button>
        {s.status === statusEnum.selectedForWork.Applied && (
          <Button
            onClick={() => handleOpenAcceptUserModal(s)}
            disabled={hideSomeFields && s?.documentStatus?.status !== "Verified"}
            color="warning"
            size="md"
            className="btn-link btn-icon"
          >
            <ContactMail />
          </Button>
        )}
        {s.status != statusEnum.selectedForWork.UnScheduled && (
          <Button
            onClick={() => handleOpenRemoveUserModal(s)}
            color="danger"
            size="md"
            className="btn-link btn-icon"
          >
            <Clear />
          </Button>
        )}
      </div>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // location status
  const handleOpenEditStatus = (sfw) => {
    setSelectedSFW(sfw);
    setOpenEditStatusModal(true);
    setSelectedStatus("");
  };

  const handleCloseEditStatus = () => {
    setOpenEditStatusModal(false);
    setSelectedWorkerComments([]);
    setSelectedStatus("");
    setSelectedSFW(null);
  };

  const handleUpdateUserStatus = () => {
    api()
      .patch(`/selected-for-work/confirmation-status/${selectedSFW.selectedForWorkId}`, {
        confirmationUpdate: parseInt(selectedStatus.value),
      })
      .then((res) => {
        handleCloseEditStatus();
        fetchUsers();
        setAlert(
          <SweetAlert success title="User status updated successfully." onConfirm={hideAlert} />,
        );
      })
      .catch((error) => {
        console.log(error);
        handleCloseEditStatus();
        setIsLoading(false);
        setAlert(
          <SweetAlert danger title="Error occurred !" onConfirm={hideAlert}>
            {error.response.data.message || error.message}
          </SweetAlert>,
        );
      });
  };

  const handleOpenWorkerCommentsModal = (sfw) => {
    setSelectedSFW(sfw);
    setOpenModalCommentUser(true);
    loadCommentHistory(sfw._id);
    setAddComment("");
  };

  const handleCloseWorkerCommentsModal = () => {
    setOpenModalCommentUser(false);
    setSelectedWorkerComments([]);
    setAddComment("");
  };

  const loadCommentHistory = (_id) => {
    setIsLoading(true);
    api()
      .get(`/user-note?userId=${_id}&fieldType=User&size=30`)
      .then((res) => {
        const data = res.data.notes;
        let tempArray = [];
        data.map((q) => {
          let tempDate = q.createdAt.split(".")[0];
          tempArray.push({
            noteBy: q.noteBy,
            note: q.note,
            time: tempDate.split("T")[0] + " " + tempDate.split("T")[1],
          });
        });
        setSelectedWorkerComments(tempArray);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error getting comment history for worker: ", _id, err);
        setIsLoading(false);
      });
  };

  const handleAddComment = (_id, newNote) => {
    setIsLoading(true);
    api()
      .post(`/user-note`, {
        user: _id,
        commentField: "User",
        commentFieldId: _id,
        note: newNote,
      })
      .then((res) => {
        let tempCommentHistory = selectedWorkerComments;
        let tempDate = res.data.createdAt.split(".")[0];
        let newComment = {
          note: newNote,
          noteBy: agencyContext.agency.user.firstName + " " + agencyContext.agency.user.lastName,
          time: tempDate.split("T")[0] + " " + tempDate.split("T")[1],
        };
        tempCommentHistory.push(newComment);
        setAddComment("");
        setIsLoading(false);
        setSelectedWorkerComments(tempCommentHistory);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const fetchUsers = async (_id = shiftId, _date = date) => {
    setLoadingTable(true);
    try {
      api()
        .get(`selected-for-work/users/${_id}`, {
          params: {
            start: startOfDay(new Date(_date)),
            end: startOfNextDay(new Date(_date)),
          },
        })
        .then(({ data }) => {
          let newData = data.map((s) => {
            const clockIn = s.check?.in;
            const clockOut = s.check?.out;
            return {
              ...s,
              displayLastName:
                s.displayLastName || s.duplicated ? s.lastName + "-" + s.duplicated : s.lastName,
              comment: s.comment ? s.comment.note : null,
              lastAppLoginTime: s.lastAppLogin
                ? moment(s.lastAppLogin).format("MMM Do YYYY, h:mm a")
                : null,
              vaccineStatus:
                s.vaccineStatus === 2
                  ? "Fully Vaccinated"
                  : s.vaccineStatus === 1
                  ? "1st dose"
                  : "No Vaccine",
              actions: RenderActionButtons(s),
              shiftFunctionName:
                s?.shiftFunction?.name || s?.shiftFunction?.name === 0
                  ? s?.shiftFunction?.name
                  : "-",
              clockIn,
              clockOut,
              startTime: s.start,
              endTime: s.end,
              verified: s?.documentStatus?.status,
              backgroundColor:
                s.confirmationStatus === 1
                  ? "#edffe5" //light green
                  : s.confirmationStatus === 2
                  ? "#ffdcd1" //light red
                  : null,
              statusLabel: STATUS_OPTIONS.find(
                (p) => parseInt(p.value) === parseInt(s.confirmationStatus),
              )?.label,
            };
          });

          setData(newData);
          setWorkersData(newData);
          setDataByFilter(pageTabs, newData);
          setLoadingTable(false);

          const counters = {
            confirmed: newData.filter((data) => data.status === statusEnum.selectedForWork.selected)
              ?.length,
            backup: newData.filter((data) => data.status === statusEnum.selectedForWork.Backup)
              ?.length,
            available: newData.filter((data) => data.status === statusEnum.selectedForWork.Applied)
              ?.length,
            unscheduled: newData.filter(
              (data) => data.status === statusEnum.selectedForWork.UnScheduled,
            )?.length,
            startTime: data.start,
            endTime: data.end,
          };

          setCounters(counters);
          setFetchedUserData(true);
        })
        .catch((e) => {
          setLoadingTable(false);
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const fetchShift = async (_id, date) => {
    try {
      api()
        .get(`shift/${_id}`, { params: { date: new Date(date) } })
        .then(({ data }) => {
          setShift(data.shift);
          setBusiness(data.shift.business);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const setDataByFilter = (type = pageTabs, _data = workersData) => {
    if (type === "allSelected") {
      setData(_data);
    } else if (type === "confirmed") {
      setData(_data.filter((data) => data.status === statusEnum.selectedForWork.selected));
    } else if (type === "backup") {
      setData(_data.filter((data) => data.status === statusEnum.selectedForWork.Backup));
    } else if (type === "applied") {
      setData(_data.filter((data) => data.status === statusEnum.selectedForWork.Applied));
    } else if (type === "unscheduled") {
      setData(_data.filter((data) => data.status === statusEnum.selectedForWork.UnScheduled));
    }
  };

  const handleOpenRemoveUserModal = (sfw) => {
    setOpenModalRemoveUser(true);
    setSelectedSFW(sfw);
    setSelectedTimes(sfw.checkInRecord);
  };

  const handleCloseRemoveUserModal = () => {
    setSelectedSFW(null);
    setFormErrors({});
    setAction(null);
    setUnscheduleReason(null);
    setOpenModalRemoveUser(false);
  };

  const handleRemoveUser = async (selectedForWork) => {
    handleCloseRemoveUserModal();
    try {
      const { data } = await api().patch(
        `/selected-for-work/${selectedForWork.selectedForWorkId}`,
        {
          status: statusEnum.selectedForWork.UnScheduled,
          unscheduleReason: unscheduleReason?.value,
          userId: selectedForWork._id,
          start: selectedForWork.start,
          end: selectedForWork.end,
        },
      );
      setAlert(<SweetAlert success title="User successfully unscheduled" onConfirm={hideAlert} />);
      const urlParams = new URLSearchParams(props.location.search);
      const shift = urlParams.get("shift");
      const date = urlParams.get("date");
      fetchUsers(shift, date);
    } catch (error) {
      setAlert(
        <SweetAlert danger title="An error happened" onConfirm={hideAlert}>
          {error.response.data.message || error.message}
        </SweetAlert>,
      );
    }
  };

  const handleOpenAcceptUserModal = (sfw) => {
    setOpenModalAcceptUser(true);
    setSelectedSFW(sfw);
    setSelectedTimes(sfw.checkInRecord);
  };

  const handleCloseAcceptUserModal = () => {
    setOpenModalAcceptUser(false);
  };

  // const exportModal = () => {
  //   return (
  //     <div>
  //       <Modal
  //         open={openExportModal}
  //         onClose={handleCloseAcceptUserModal}
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //         }}
  //       >
  //         <FormGroup style={{ width: "500px", backgroundColor: "#fff", borderRadius: 10 }}>
  //           <div style={{ padding: 25 }}>
  //             <div style={{ justifyContent: "center", display: "flex" }}>
  //               <h5>Please select type of export</h5>
  //             </div>
  //             <div style={{ textAlign: "right", justifyContent: "space-between", display: "flex" }}>
  //               <Button color="secondary" onClick={() => handleCloseExportModal()}>
  //                 Cancel
  //               </Button>
  //               <Button
  //                 color="info"
  //                 onClick={() => {
  //                   handleAcceptUser(statusEnum.selectedForWork.Backup);
  //                 }}
  //               >
  //                 Email
  //               </Button>
  //               <Button color="success" onClick={() => setDownload(true)}>
  //                 Download
  //               </Button>
  //             </div>
  //           </div>
  //         </FormGroup>
  //       </Modal>
  //     </div>
  //   );
  // };

  const handleOpenExportModal = (user) => {
    setSelectedSFW(user);
    setOpenExportModal(true);
  };

  const handleCloseExportModal = () => {
    setOpenExportModal(false);
    setSelectedSFW(null);
  };

  // change status  of selected worker from backup to selected or selected to backup
  const changeStatus = async (status, Id) => {
    try {
      setIsLoading(true);
      const resp = await api().patch(`/selected-for-work/${Id}`, { status });
      handleCloseRemoveUserModal();
      setAlert(
        <SweetAlert
          success
          title="User work status successfully updated"
          onConfirm={hideAlert}
          showConfirm={true}
        />,
      );
      fetchUsers();
      setIsLoading(false);
    } catch (err) {
      handleCloseRemoveUserModal();
      console.log("error", err);
      setIsLoading(false);
    }
  };

  const handleAcceptUser = async (status) => {
    handleCloseAcceptUserModal();
    try {
      const { data } = await api().patch(`/selected-for-work/${selectedSFW.selectedForWorkId}`, {
        status,
        userId: selectedSFW._id,
        start: selectedSFW.start,
        end: selectedSFW.end,
      });
      setAlert(<SweetAlert success title="User was selected for work" onConfirm={hideAlert} />);
      const urlParams = new URLSearchParams(props.location.search);
      const shift = urlParams.get("shift");
      const date = urlParams.get("date");
      fetchUsers(shift, date);
    } catch (error) {
      setAlert(
        <SweetAlert danger title="An error happened" onConfirm={hideAlert}>
          {error.response.data.message || error.message}
        </SweetAlert>,
      );
    }
  };

  ////////////////////////////////////////////////////
  //ASSIGN USER
  ////////////////////////////////////////////////////
  // const AssignModal = () => {
  //   return (
  //     <Modal
  //       open={openAssignModal}
  //       onClose={handleCloseAssignModal}
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <FormGroup style={{ width: 450, backgroundColor: "#fff", borderRadius: 10 }}>
  //         <div style={{ padding: 15 }}>
  //           <Row>
  //             <Col md="12">
  //               <h5>Please Select Worker.</h5>
  //             </Col>
  //           </Row>
  //           <Row style={{ marginTop: 10 }}>
  //             <Col md="3">Enter HDID</Col>
  //             <Col md="9">
  //               <Input
  //                 value={hdId}
  //                 placeholder="Enter HdId"
  //                 type="text"
  //                 onChange={(e) => {
  //                   const value = e.target.value;
  //                   setHdId(e.target.value);
  //                   setErrorHdId(null);
  //                   console.log(value);
  //                   setStartSearch(null);
  //                   setSelectedWorker(null);
  //                   if (value > 100 && value < 999999) {
  //                     setStartSearch("Searching...");
  //                     setTimeout(() => {
  //                       searchWorker(value);
  //                     }, 1000);
  //                   }
  //                 }}
  //               />
  //             </Col>
  //           </Row>
  //           {errorHdId && (
  //             <Row>
  //               <Col md="3"></Col>
  //               <Col md="9">
  //                 <FormText color="danger" tag="span">
  //                   {errorHdId}
  //                 </FormText>
  //               </Col>
  //             </Row>
  //           )}

  //           {!selectedWorker ? (
  //             hdId && (
  //               <Row>
  //                 <Col md="3"></Col>
  //                 <Col md="9">
  //                   <FormText color="info" tag="span">
  //                     {errorHdId ? null : startSearch}
  //                   </FormText>
  //                 </Col>
  //               </Row>
  //             )
  //           ) : (
  //             <>
  //               {selectedWorker && (
  //                 <Row>
  //                   <Col md="3"></Col>
  //                   <Col md="9" style={{ marginTop: 5, fontSize: 14 }}>
  //                     Full Name: {" " + selectedWorker.label}
  //                     <br />
  //                     Phone: {" " + selectedWorker.phone}
  //                     <br />
  //                     Email: {" " + selectedWorker.email}
  //                   </Col>
  //                 </Row>
  //               )}
  //               {shift.shiftFunctions?.length ? (
  //                 <Row>
  //                   <Col md="3"></Col>
  //                   <Col md="9">
  //                     <Select
  //                       classNamePrefix="react-select"
  //                       name="selectShiftFunction"
  //                       value={selectedShiftFunction}
  //                       onChange={(value) => {
  //                         //
  //                         setSelectedShiftFunction(value);
  //                       }}
  //                       options={shift.shiftFunctions?.map((sf) => {
  //                         return {
  //                           label: sf.name,
  //                           value: sf._id,
  //                           shiftFunction: sf,
  //                         };
  //                       })}
  //                       placeholder="Select shift Function"
  //                       style={{ margin: "15px 0" }}
  //                     />
  //                   </Col>
  //                 </Row>
  //               ) : null}
  //               {shiftFunctionError && (
  //                 <Row>
  //                   <Col md="3"></Col>
  //                   <Col md="9">
  //                     <FormText color="danger" tag="span">
  //                       {shiftFunctionError}
  //                     </FormText>
  //                   </Col>
  //                 </Row>
  //               )}
  //             </>
  //           )}

  //           <div style={{ justifyContent: "space-between", display: "flex", marginTop: 15 }}>
  //             <Button onClick={() => handleCloseAssignModal()}>Cancel</Button>
  //             <Button color="info" onClick={() => assignUser(statusEnum.selectedForWork.Backup)}>
  //               Backup
  //             </Button>
  //             <Button
  //               color="success"
  //               onClick={() => assignUser(statusEnum.selectedForWork.selected)}
  //             >
  //               Select
  //             </Button>
  //           </div>
  //         </div>
  //       </FormGroup>
  //     </Modal>
  //   );
  // };

  //assign user function
  const assignUser = async (status) => {
    try {
      let hasError = false;
      if (!hdId || hdId.trim() === "") {
        hasError = true;
        setErrorHdId("* please enter hdId to search");
        return;
      }
      if (!selectedWorker?.value || selectedWorker?.value.trim() === "") {
        hasError = true;
        setErrorHdId("* please enter a valid hdId");
      }

      if (shift.shiftFunctions?.length && !selectedShiftFunction) {
        setShiftFunctionError("You must select a shift function/line!");
        setIsLoading(false);
        return;
      }

      if (hasError) {
        return;
      } else {
        let dataToSend = {
          user: selectedWorker._id,
          business: shift.business._id,
          businessName: shift.businessName,
          shiftName: shift.shiftName,
          status: status,
          shift: shift._id,
          agency: shift.agency,
          start: shift.start, //start and end of shift
          end: shift.end,
          dayStart: startOfDay(date), //start and end of current day
          dayEnd: startOfNextDay(date),
          shiftFunction: selectedShiftFunction?.shiftFunction,
        };
        await api().post(`/selected-for-work/`, dataToSend);
        handleCloseAssignModal();
        fetchUsers(shiftId, date);
        setAlert(<SweetAlert success title="Worker added successfully " onConfirm={hideAlert} />);
      }
    } catch (error) {
      handleCloseAssignModal();
      setAlert(
        <SweetAlert
          error
          title={error?.response?.data?.message || error.message}
          onConfirm={hideAlert}
        />,
      );
    }
  };

  // Opens modal to assign user to driver
  const handleOpenAssignModal = () => {
    setOpenAssignModal(true);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
    setErrorHdId(null);
    setSelectedWorker(null);
    setHdId(null);
  };

  const searchWorker = async (filterValue) => {
    try {
      const { data } = await api().get("/users", {
        params: {
          filter: [{ id: "hdId", value: filterValue }],
        },
      });
      let users = [];
      data.users.map((user) => {
        users.push({
          label: `${user.firstName} ${user.lastName} (${user.hdId})`,
          value: user._id,
          ...user,
        });
      });
      users.length === 1
        ? setSelectedWorker(users[0])
        : setStartSearch("Worker not found, please try again.");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleExportData = async () => {
    let excelData = [];
    let response = workersData;
    workersData.sort(function (a, b) {
      var nameA = a.firstName.toLowerCase(),
        nameB = b.firstName.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
    for (let r of response) {
      r.start = moment(shift.start).format("hh:mm a");
      r.lastName = r.duplicated ? r.lastName + "-" + r.duplicated : r.lastName;
      r.vaccineStatus = r.vaccineStatus;
      r.shiftId = shift._id;
      r.workerPin = r.workerPin?.find(
        (w) => w.agency === agencyContext.agency.agency._id && w.business === business._id,
      )?.pin;
      r.gender = r.gender === "F" ? "FEMALE" : "MALE";
      r.agency = "LYR";
      r.dept = "PRODUCTION";
      r.time = r.start;
    }
    if (response.length > 0) {
      excelData.push(
        customExcelData({
          regular: response.filter((r) => r.status === statusEnum.selectedForWork.selected),
          date,
          headers: csvDataHeaders,
          businessName: business.name,
          shiftType: shiftTimeType(shift.start),
          date: moment(shift.start).format("MMMM DD YYYY"),
          day: moment(shift.start).format("dddd"),
          extras: response.filter((r) => r.status === statusEnum.selectedForWork.Backup),
          time: moment(shift.start).format("hh_mm a"),
        }),
      );
    }
    setExcelData(excelData);
    handleOpenExportModal();
  };

  const handlePageTabs = (action) => {
    setPageTabs(action);
    setDataByFilter(action);
  };

  const handleUnschedule = () => {
    if (unscheduleReason === "" || unscheduleReason === null) {
      setFormErrors({
        ...formErrors,
        unscheduleReason: "Must select a reason",
      });
      return;
    }
    handleRemoveUser(selectedSFW);
  };

  const handleChangeStatus = () => {
    changeStatus(
      selectedSFW.status === statusEnum?.selectedForWork?.Backup
        ? statusEnum?.selectedForWork?.selected
        : statusEnum?.selectedForWork?.Backup,
      selectedSFW.selectedForWorkId,
    );
  };

  const handleAssignUserChange = (e) => {
    const value = e.target.value;
    setHdId(e.target.value);
    setErrorHdId(null);
    console.log(value);
    setStartSearch(null);
    setSelectedWorker(null);
    if (value > 100 && value < 999999) {
      setStartSearch("Searching...");
      setTimeout(() => {
        searchWorker(value);
      }, 1000);
    }
  };

  return (
    <div className="content">
      <Card className="no-transition">
        <CardHeader>
          <CardTitle>
            <Row style={RowTitle}>
              <Col md="10">
                <h4>All Users at Business: {(business.name, business.address)}</h4>
              </Col>

              <Col md="2">
                <Row style={RowDate}>
                  {moment(date).format("DD MMM YYYY")}
                  <Button onClick={() => handleOpenAssignModal()} color="success" size="md">
                    Add Worker
                  </Button>
                </Row>
              </Col>
            </Row>
          </CardTitle>
          <Row>
            <Col>
              <h6>
                Shift Start:
                {shift.start && moment(shift.start).format(" hh:mm A")}
              </h6>
              <h6>
                Shift End:
                {shift.end && moment(shift.end).format(" hh:mm A")}
              </h6>
              <h6>
                Required workers:{" "}
                {shift.noRequiredWorkers ? shift.noRequiredWorkers : " Not specified"}
              </h6>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button onClick={() => handleExportData()}>EXPORT</Button>
              <CustomExportBusiness
                excelData={excelData}
                download={download}
                setDownload={(download) => setDownload(download)}
                fileName={`${business.name}-${moment(shift.start).format("dddd")}-${moment(
                  shift.start,
                ).format("MMM Do YYYY")}-${moment(shift.start).format("h:mm a")}`}
              />
            </Col>
          </Row>

          <Nav
            className="nav-pills-primary nav-pills-icons justify-content-center"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={pageTabs === "confirmed" ? "active" : ""}
                onClick={() => handlePageTabs("confirmed")}
              >
                <i className="now-ui-icons ui-2_settings-90" />
                Selected Workers ({counters.confirmed})
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={pageTabs === "backup" ? "active" : ""}
                onClick={() => handlePageTabs("backup")}
              >
                <i className="now-ui-icons ui-2_settings-90" />
                Backup Workers ({counters.backup})
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={pageTabs === "applied" ? "active" : ""}
                onClick={() => handlePageTabs("applied")}
              >
                <i className="now-ui-icons ui-2_settings-90" />
                Available Workers ({counters.available})
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={pageTabs === "unscheduled" ? "active" : ""}
                onClick={() => handlePageTabs("unscheduled")}
              >
                <i className="now-ui-icons ui-2_settings-90" />
                Unscheduled Workers ({counters.unscheduled})
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <ReactTable
            getTrProps={(state, rowInfo, instance) => {
              if (rowInfo?.row?._original?.rowColor || rowInfo?.row?._original?.backgroundColor) {
                return {
                  style: {
                    color: rowInfo?.row?._original?.rowColor,
                    background: rowInfo?.row?._original?.backgroundColor,
                  },
                };
              }
              return {};
            }}
            columns={[
              {
                Header: "hdId",
                accessor: "hdId",
                maxWidth: 100,
              },
              {
                Header: "First Name",
                accessor: "firstName",
              },
              {
                Header: "Last Name",
                accessor: "displayLastName",
              },
              {
                Header: "Verified",
                accessor: "verified",
                getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      backgroundColor:
                        rowInfo?.row?.verified === "Verified"
                          ? "#D2FFCB" //light green
                          : rowInfo?.row?.verified === "Invalid" ||
                            rowInfo?.row?.verified === "Expired"
                          ? "#EE9999" //light red
                          : rowInfo?.row?.verified === "Pending"
                          ? "#ffffc2" // light yellow
                          : rowInfo?.row?.verified === "Incomplete"
                          ? "#FFCBCB"
                          : "", // no data
                      fontWeight: 400,
                    },
                  };
                },
              },
              {
                Header: "Vaccine Status",
                accessor: "vaccineStatus",
              },
              {
                Header: "Pin",
                accessor: "pin",
                maxWidth: 80,
                Cell: (row) => {
                  return (
                    <PinCell
                      value={row.value}
                      workerPin={row.original.workerPin}
                      onChange={() => fetchUsers()}
                      workerId={row.original._id}
                      businessList={businessList}
                      agencyId={agencyContext.agency.agency._id}
                    />
                  );
                },
              },
              {
                Header: "email",
                accessor: "email",
                maxWidth: 200,
              },

              {
                Header: "Gender",
                accessor: "gender",
                maxWidth: 90,
              },
              {
                Header: "Phone",
                accessor: "phone",
                Cell: (row) => {
                  let telephone = "tel:" + row.original.phone;
                  return (
                    <a
                      href={telephone}
                      style={{
                        color: "#51bcda",
                        textDecoration: "underline",
                        fontWeight: 400,
                      }}
                    >
                      {row.original.phone}
                    </a>
                  );
                },
                maxWidth: 130,
              },
              {
                Header: "Selected By",
                accessor: "selectedToWorkByName",
              },
              {
                Header: "Shift Function/Line",
                accessor: "shiftFunctionName",
                Cell: (row) => {
                  return (
                    <ShiftFunctionCell
                      shiftFunction={row.original.shiftFunction}
                      selectedForWorkId={row.original.selectedForWorkId}
                      shiftFunctions={shift.shiftFunctions}
                      onChange={() => {
                        fetchUsers();
                      }}
                      shiftId={shiftId}
                    />
                  );
                },
              },

              {
                Header: "Comment",
                accessor: "comment",
              },
              {
                Header: "Last App Login",
                accessor: "lastAppLoginTime",
              },
              {
                Header: "Status",
                accessor: "statusLabel",
              },
              {
                Header: "Clocked In",
                accessor: "clockIn",
                filterable: false,
                Cell: (row) => {
                  return (
                    <CheckInTimeCell
                      checkIn={row.original.clockIn}
                      selectedForWorkId={row.original.selectedForWorkId}
                      onChange={() => fetchUsers()}
                      defaultTime={row.original.startTime}
                      type="in"
                    />
                  );
                },
              },
              {
                Header: "Clocked Out",
                accessor: "clockOut",
                filterable: false,
                Cell: (row) => {
                  return (
                    <CheckInTimeCell
                      checkIn={row.original.clockOut}
                      selectedForWorkId={row.original.selectedForWorkId}
                      defaultTime={row.original.endTime}
                      onChange={() => fetchUsers()}
                      type="out"
                    />
                  );
                },
              },
              {
                Header: "Actions",
                accessor: "actions",
                width: 298,
              },
            ]}
            data={data}
            defaultPageSize={10}
            loading={loadingTable}
            showPaginationTop
            showPaginationBottom
            className="-striped -highlight primary-pagination"
          />
        </CardBody>
      </Card>
      {/* {AssignModal()} */}
      {/* {exportModal()} */}
      <GlobalModal
        open={openAssignModal}
        close={handleCloseAssignModal}
        title="Please Select Worker."
        cancelAction={handleCloseAssignModal}
        btnColor="success"
        action={() => assignUser(statusEnum.selectedForWork.selected)}
        btnMessage="Select"
        // modal specific props
        assignModal="true"
        hdId={hdId}
        handleAssignUserChange={handleAssignUserChange}
        errorHdId={errorHdId}
        selectedWorker={selectedWorker}
        startSearch={startSearch}
        shift={shift}
        selectedShiftFunction={selectedShiftFunction}
        setSelectedShiftFunction={setSelectedShiftFunction}
        shiftFunctionError={shiftFunctionError}
        assignBackUp={() => assignUser(statusEnum.selectedForWork.Backup)}
      />
      <GlobalModal
        open={openModalRemoveUser}
        close={handleCloseRemoveUserModal}
        title="Do you want to reject/change status of user for this shift?"
        cancelAction={handleCloseRemoveUserModal}
        // modal specific props
        removeUserModal="true"
        selectedUser={selectedSFW}
        statusEnum={statusEnum}
        changeStatus={() =>
          changeStatus(statusEnum?.selectedForWork?.Rejected, selectedSFW.selectedForWorkId)
        }
        action={action}
        setAction={setAction}
        unscheduleReason={unscheduleReason}
        setUnscheduleReason={setUnscheduleReason}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        handleUnschedule={handleUnschedule}
        handleChangeStatus={handleChangeStatus}
      />
      <GlobalModal
        open={openModalAcceptUser}
        close={handleCloseAcceptUserModal}
        title="Do you want to confirm this user to work in this shift?"
        cancelAction={handleCloseAcceptUserModal}
        btnColor="success"
        action={() => handleAcceptUser(statusEnum.selectedForWork.selected)}
        btnMessage="Add"
        // modal specific props
        acceptModal="true"
        backUp={() => handleAcceptUser(statusEnum.selectedForWork.Backup)}
      />
      <GlobalModal
        open={openModalCommentUser}
        close={handleCloseWorkerCommentsModal}
        title="Worker Comment History"
        cancelAction={handleCloseWorkerCommentsModal}
        btnColor="primary"
        action={() => {
          handleAddComment(selectedSFW._id, addComment);
          handleCloseWorkerCommentsModal();
        }}
        btnMessage="Save"
        // modal specific props
        commentModal="true"
        selectedWorkerComments={selectedWorkerComments}
        isLoading={isLoading}
        addComment={addComment}
        setAddComment={setAddComment}
      />
      <GlobalModal
        open={openExportModal}
        close={handleCloseAcceptUserModal}
        title="Please select type of export"
        cancelAction={handleCloseExportModal}
        btnColor="success"
        action={() => setDownload(true)}
        btnMessage="Download"
        // modal specific props
        exportModal="true"
        email={() => handleAcceptUser(statusEnum.selectedForWork.Backup)}
      />
      <GlobalModal
        open={openEditStatusModal}
        close={handleCloseEditStatus}
        title="Edit user status ?"
        cancelAction={handleCloseEditStatus}
        btnColor="danger"
        action={handleUpdateUserStatus}
        btnMessage="Confirm"
        // modal specific props
        editUserStatusModal="true"
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        STATUS_OPTIONS={STATUS_OPTIONS}
      />
      {alert}
    </div>
  );
};

export default AllSelectedUsers;

const RowTitle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const RowDate = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  flexDirection: "column",
  marginTop: 20,
};

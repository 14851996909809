import React from 'react'
import LoadingOverlay  from 'components/OverLay/LoadingOverlay';
import { Redirect } from 'react-router-dom';
import { useState, useEffect } from 'react';


const Logout = (props) => {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    console.log(props.history)
    localStorage.clear();
    props.history.push("/staffing/login")
  },[])

  return <>
     <LoadingOverlay/>
  </>

}

export default Logout
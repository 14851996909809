import api from "components/API/api";
import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Spinner } from "reactstrap";
import Select from "react-select";

const ShiftFunctionCell = ({ shiftFunction, selectedForWorkId, onChange, shiftFunctions }) => {
  const [openShiftFunctionModal, setOpenShiftFunctionModal] = useState(false);
  const [loading, setLoading] = useState();
  const [selectedShiftFunction, setSelectedShiftFunction] = useState(null);
  const [selectedShiftFunctionError, setSelectedShiftFunctionError] = useState("");

  useEffect(() => {
    // console.log("selectedForWorkId ShiftFunctionCell", selectedForWorkId);
    if (shiftFunction) {
      setSelectedShiftFunction({
        label: shiftFunction.name,
        value: shiftFunction._id,
        shiftFunction: shiftFunction,
      });
    }
  }, [shiftFunction]);

  const updateShiftFunction = () => {
    setLoading(true);
    if (!selectedShiftFunction) {
      setSelectedShiftFunctionError("You must select a Shift Function!");
      setTimeout(setSelectedShiftFunctionError("", 2000));
    } else {
      setSelectedShiftFunctionError("");
    }
    api()
      .patch(`/selected-for-work/supervisor/${selectedForWorkId}`, {
        shiftFunction: selectedShiftFunction.shiftFunction,
      })
      .then(() => {
        setLoading(false);
        onChange();
      })
      .catch((error) => {
        setLoading(false);
        console.log("error updating shift functions", error);
      });
  };

  return (
    <div className="w-100 h-100">
      {shiftFunction ? (
        <p
          onClick={() => setOpenShiftFunctionModal(true)}
          className="w-100 h-100"
          style={{
            fontWeight: 400,
            cursor: "pointer",
          }}
        >
          {shiftFunction.name}
        </p>
      ) : (
        <p
          onClick={() => setOpenShiftFunctionModal(true)}
          className="w-100 h-100"
          style={{ fontWeight: 400, cursor: "pointer" }}
        >
          -
        </p>
      )}

      <Modal
        open={openShiftFunctionModal}
        onClose={() => setOpenShiftFunctionModal(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup style={{ width: 500, backgroundColor: "#fff", borderRadius: 10 }}>
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex", margin: 20 }}>
              <h5>{shiftFunction ? "Update Shift Function" : "Add Shift Function"}</h5>
            </div>
            <div style={{ justifyContent: "center", display: "flex", margin: 10 }}>
              <p>{selectedShiftFunctionError}</p>
            </div>
            <FormGroup row className="align-items-right">
              <Col sm={7}>
                {shiftFunctions?.length ? (
                  <Select
                    classNamePrefix="react-select"
                    name="selectShiftFunction"
                    value={selectedShiftFunction}
                    onChange={(value) => {
                      setSelectedShiftFunction(value);
                    }}
                    options={shiftFunctions?.map((functions) => {
                      return {
                        label: functions.name,
                        value: functions._id,
                        shiftFunction: functions,
                      };
                    })}
                    placeholder="Select Shift Function"
                  />
                ) : (
                  "This shift has no functions!"
                )}
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    updateShiftFunction();
                  }}
                  color="success"
                  size="md"
                  outline
                  className="btn m-0 w-100"
                >
                  {shiftFunction ? "Update" : "Add"}
                  {loading ? <Spinner size="sm" /> : null}
                </Button>
              </Col>{" "}
            </FormGroup>
          </div>

          <div style={{ justifyContent: "center", display: "flex" }}>
            <Button
              color="secondary"
              onClick={() => {
                setOpenShiftFunctionModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </FormGroup>
      </Modal>
    </div>
  );
};

export default ShiftFunctionCell;

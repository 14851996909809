/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// reactstrap components
import { Button, FormGroup, Table, Container, Row, Col, CustomInput } from "reactstrap";

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
import { stringifyTimeStamp } from "components/Utilities/utilities.js";

// core components
import DashboardNavbar from "../components/DashboardNavbar";
import FooterBlack from "components/Footers/FooterBlack.js";
import api from "components/API/api";
import { Link } from "react-router-dom";

function Dashboard(props) {
  const [businesses, setBusinesses] = useState([]);
  useEffect(() => {
    api()
      .get("/agency/list/admin")
      .then((result) => {
        setBusinesses(result.data);
        console.log(result.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <>
      <DashboardNavbar />
      <div className="main">
        <div className="section">
          <Container style={{ marginTop: "50px" }}>
            <Row>
              <Col md="12">
                <h4 className="title">
                  <div className="text-center">Welcome Admin</div>
                </h4>
              </Col>
              <Col className="ml-auto mr-auto" md="12">
                <Table>
                  <thead>
                    <tr>
                      <th >Business Name</th>
                      <th >Address</th>
                      <th >City</th>
                      <th >Business Slug</th>
                      <th >Status</th>
                      <th >Actions</th>
                    </tr>
                  </thead>
                  {businesses.map((business, id) => (
                    <tbody>
                      <tr>
                        <td>
                          <strong key={id}>{business.name}</strong>
                        </td>
                        <td >
                          <p key={id}>{business.address}</p>
                        </td>
                        <td>
                          <p key={id}>{business.city}</p>
                        </td>
                        <td>
                          <p key={id}>{business.slug}</p>
                        </td>
                        <td>
                          <p key={id}>
                            {business.active ? "Active" : "Inactive"}
                          </p>
                        </td>
                        <td>
                          <Button
                            className="mr-1"
                            color="info"
                            onClick={(e) => {
                              e.preventDefault();
                              props.history.push(`/staffing/admin/${business._id}`);
                            }}
                          >
                            Edit
                            </Button>
                          <Link
                            className="btn btn-info"
                            color="info"
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   props.history.push(`/${business.slug}`);
                            // }}
                            to={`/${business.slug}/auth/login`}
                            target={"_blank"}
                          >
                            Visit
                              </Link>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
                <div style={{ textAlign: "right" }}>
                  <Button color="default" size="lg" type="button" href="/staffing/add-business">
                    Add New Business
                  </Button>
                </div>
              </Col>
              <Col md="12">
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Dashboard;

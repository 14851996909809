import React, { useMemo, useRef } from "react";
import ReactTable from "react-table";
import { CSVLink } from "react-csv";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  FormText,
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Label,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import { Check, Image } from "@mui/icons-material";
import { Modal, Fade } from "@mui/material";
import api from "components/API/api";
import { Input } from "reactstrap";
// import AgencyContext from "../../../context/AgencyContext";
import ReactDatetime from "react-datetime";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

const ShowImageModal = ({ bug, openModal, onClose }) => {
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (openModal && bug) {
      console.log(bug);
      api()
        .get("/image/sign-s3/get", {
          params: {
            "file-path": `bugReports/${bug._id}/1.jpeg`,
          },
        })
        .then(({ data }) => {
          setPreviewImage(data.signedUrl);
          return true;
        })
        .catch((e) => console.log(e));
    }
  }, [openModal, bug]);
  return (
    <Modal
      open={openModal}
      onClose={onClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {/* <FormGroup
          style={{ width: "80vw", maxHeight: "70vh", backgroundColor: "#fff", borderRadius: 10 }}
        > */}
      <Fade in={openModal}>
        <img
          src={previewImage}
          alt="No Image uploaded for the report"
          // style={{ maxHeight: "100%", maxWidth: "100%" }}
          style={{
            margin: "auto",
            display: "block",
            width: "auto",
            maxHeight: "90vh",
            maxWidth: "80%",
            height: "auto",
            // border: "5px solid #fff",
            borderRadius: 5,
          }}
        />
      </Fade>
      {/* </FormGroup> */}
    </Modal>
  );
};

const AllReport = (props) => {
  //   const staffingContext = useContext(StaffingContext);
  const [reportData, setReportData] = useState([]);

  const [openModal, setOpenModal] = useState({
    imageModal: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [alert, setAlert] = useState(null);

  const [pageTabs, setPageTabs] = useState("unresolved");
  const [pages, setPages] = useState(-1);

  const [timer, setTimer] = useState(null);
  // const [page, setPage] = useState(0);
  // const [tableQuery, setTableQuery] = useState("");

  const [firstUseEffect, setFirstUseEffect] = useState(true);

  const [reactTableState, setReactTableState] = useState({
    page: 1,
    size: 5,
    field: "hdId",
    sort: "desc",
    filter: [],
  });

  const [selectedBug, setSelectedBug] = useState(null);

  useEffect(() => {
    setFirstUseEffect(false);
    if (reactTableState && !firstUseEffect) {
      fetchBugReports();
      setLoadingTable(true);
    }
  }, [pageTabs]);

  useEffect(() => {
    // fetchBugReports();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const RenderActionButtons = (bug) => {
    return (
      <div className="actions-right">
        <Button
          onClick={() => {
            //   handleOpenRemoveUserModal(hdId);
          }}
          color="success"
          size="md"
          className="btn-link btn-icon"
        >
          <Check style={{ marginLeft: -1 }} />
        </Button>

        <Button
          onClick={() => {
            // handleOpenWorkerCommentsModal(user._id);
            setOpenModal({ imageModal: true });
            setSelectedBug(bug);
          }}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Image style={{ marginLeft: -1 }} />
        </Button>
      </div>
    );
  };

  const showImageModal = () => {
    return (
      <Modal
        open={openModal.imageModal}
        // onClose={handleCloseRemoveUserModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>This is image modal</p>
      </Modal>
    );
  };

  //Loading Overlay
  const hideAlert = () => {
    setAlert(null);
  };

  // Opens modal to add user to

  const fetchBugReports = async (filterOptions = reactTableState) => {
    try {
      const { data } = await api().get("/bug-report", {
        params: {
          ...filterOptions,
          resolved: pageTabs === "resolved" ? "true" : pageTabs === "unresolved" ? "false" : null,
        },
      });
      setPages(data.noPages);

      let bugReports = data.bugReports || [];
      setReportData(
        bugReports.map((r) => {
          r.actions = RenderActionButtons(r);
          return r;
        }),
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <FormGroup></FormGroup>
            <Card className="no-transition">
              <CardHeader>
                <CardTitle tag="h4">All Bug Reports</CardTitle>
              </CardHeader>
              <CardBody>
                <Nav
                  className="nav-pills-primary nav-pills-icons justify-content-center"
                  pills
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      role="tablist"
                      className={pageTabs === "unresolved" ? "active" : ""}
                      onClick={() => setPageTabs("unresolved")}
                    >
                      <i className="now-ui-icons objects_umbrella-13" />
                      UnResolved Bug Issues
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      role="tablist"
                      className={pageTabs === "resolved" ? "active" : ""}
                      onClick={() => setPageTabs("resolved")}
                    >
                      <i className="now-ui-icons ui-2_settings-90" />
                      Resolved Bug Issues
                    </NavLink>
                  </NavItem>
                </Nav>

                <ReactTable
                  filterable
                  columns={[
                    {
                      Header: "BugId",
                      accessor: "reportId",
                      maxWidth: 70,
                    },
                    {
                      Header: "Reported By",
                      accessor: "reporterName",
                      maxWidth: 100,
                    },
                    {
                      Header: "Reporter Email",
                      accessor: "reporterEmail",
                      maxWidth: 100,
                    },
                    {
                      Header: "Issue",
                      accessor: "issue",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                      maxWidth: 110,
                    },
                  ]}
                  manual
                  loading={isLoading}
                  pages={pages}
                  data={reportData}
                  defaultPageSize={5}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  showPaginationTop
                  showPaginationBottom
                  onFetchData={(state, instance) => {
                    let searchParams = {
                      page: state.page + 1,
                      size: state.pageSize,
                      field: state.sorted.length ? state.sorted[0].id : "hdId",
                      sort: state.sorted.length ? (state.sorted[0].desc ? "desc" : "asc") : "desc",
                      filter: state.filtered,
                    };
                    setReactTableState(searchParams);
                    clearTimeout(timer);
                    setTimer(
                      setTimeout(() => {
                        setIsLoading(true);
                        fetchBugReports(searchParams);
                      }, 1000),
                    );
                  }}
                  className="-striped -highlight primary-pagination"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* {showImageModal()}
         */}
        <ShowImageModal
          bug={selectedBug}
          openModal={openModal.imageModal}
          onClose={() => {
            setOpenModal({ ...openModal, imageModal: false });
            setSelectedBug(null);
          }}
        />
      </div>
    </>
  );
};

export default AllReport;

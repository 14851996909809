/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Select from "react-select";
//import { Prompt } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import MaskedInput from "react-text-mask";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { validateEmail, getCurrentDate } from "components/Utilities/utilities.js";
import api from "../../../components/API/api";
import AgencyContext from './../../../context/AgencyContext';

class CreateNewBusinessStaff extends Component {
  
  static contextType = AgencyContext;
  constructor(props) {
    super(props);
    this.defaultState = {
      progress: 0,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      business: null,
      role: null,

      //Errors
      errorFirstName: "",
      errorLastName: "",
      errorPhone: "",
      errorEmail: "",

      // profile Data
      permissionsList: [],
      businessList: [],
      selectedPermission: [],

      //Loading
      isLoading: false,
    };
    this.state = this.defaultState;
  }

  BUSINESS_ROLES = [{
    label: "Business Owner",
    value: "BusinessOwner"
  },{
    label: "Business Staff",
    value: "BusinessStaff"
  }]

  componentDidMount() {
    this.fetchBusiness();
  }


  fetchBusiness = async () => {
    try {
      const { data } = await api().get("/business/all/list");
      console.log("allBusiness", data);

      this.setState({
        businessList: data.map(b=> ({label: b.name, value:b._id})),
      });
    } catch (error) {
      console.log(error);
    }
  };
  
  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.handleUserLeave);
  }

  handleUserLeave = (e) => {
    const confirmationMessage = "Data would be sent if you leave the page";
    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  };

  handleSubmit = () => {
    let tempState = this.state; // Copy of state
    let hasError = false;
    // Start of Error Handling
    if (tempState.firstName == null || tempState.firstName == "") {
      this.setState({ errorFirstName: "* Must have a first name." });
      hasError = true;
    } else {
      this.setState({ errorFirstName: "" });
    }

    if (tempState.lastName == null || tempState.lastName == "") {
      this.setState({ errorLastName: "* Must have a last name." });
      hasError = true;
    } else {
      this.setState({ errorLastName: "" });
    }

    if (tempState.phone == null || tempState.phone == "") {
      this.setState({ errorPhone: "* Must have a phone number." }); // No phone number
      hasError = true;
    } else {
      if (tempState.phone.length != 10 || tempState.phone < 0) {
        this.setState({
          errorPhone: "* Please input a valid phone number in the format ###-###-####.",
        }); //invalid phone number
        hasError = true;
      } else {
        this.setState({ errorPhone: "" });
      }
    }

    if (tempState.email == null || tempState.email == "") {
      this.setState({ errorEmail: "* Must have an email" }); // No email text
    } else {
      if (tempState.email != null && validateEmail(tempState.email) === false) {
        this.setState({ errorEmail: "* Please input a valid email" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorEmail: "" });
      }
    }

    if (tempState.business == null || tempState.business == "") {
      this.setState({ errorBusiness: "* Must select a business" }); // No email text
    } else {
      if (tempState.business != null && !tempState.business.value ) {
        this.setState({ errorBusiness: "* Please input a valid business" }); //invalid email text
        hasError = true;
      } else {
        this.setState({ errorBusiness: "" });
      }
    }

    
    if (tempState.role == null || tempState.role == "") {
      this.setState({ errorRole: "* Must select a role" }); // No email text
    } else {
      this.setState({ errorRole: "" });
    }

    if (hasError) {
      return;
    } else {
      this.prepareAndSendDataToServer(tempState); // runs function after loading is set as true.
    }
  };

  prepareAndSendDataToServer = async (tempState) => {
    this.setState({ isLoading: true }); // Start the loading
    // to load the values again after creating manager without fectching again
    let ReloadPermissionsList = this.state.permissionsList;
    //Upload the changelog into the deeper user history collection! This uploads the admin email, timestamp, and action taken
    let dataToSend = null; // Data prepared to send.
    let permissionsArray = [];

    if (tempState.selectedPermission) {
      let array = this.state.selectedPermission;
      array.map((m) => {
        if (m.active) {
          let module = m.name;
          m.actions[0].map((a) => {
            // to filter none from actions
            if (a.active && !(a.action === "None")) {
              let permission = {
                module: module,
                action: a.action,
              };
              permissionsArray.push(permission);
            }
          });
        }
      });
    }

    dataToSend = {
      firstName: tempState.firstName,
      lastName: tempState.lastName,
      phone: tempState.phone,
      email: tempState.email,
      profileType: tempState.role.value,
      business: tempState.business.value,
    };

    try {
      const resp = await api().post("/users/register-manager", dataToSend);
      //to make all permission to default active state
      ReloadPermissionsList.map((p) => (p.active = false));
      this.setState({
        ...this.defaultState,
        permissionsList: ReloadPermissionsList,
        isLoading: false,
        alert: <SweetAlert success title="staff successfully created" onConfirm={this.hideAlert} />,
      });
    } catch (error) {
      this.setState({ alert: <SweetAlert danger title="Staff could not be created" onConfirm={this.hideAlert} >{error.message}</SweetAlert>, isLoading: false })
      console.log(error);
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  handleRadio = (event) => {
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  };

  loadingOverlay = () => {
    return (
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
          background: "transparent",
        }}
        title={<Spinner color="light" style={{ width: "3rem", height: "3rem" }} />}
        onConfirm={this.hideAlert}
        showConfirm={false}
      />
    );
  };

  render() {
    return (
      <div className="content">
        {/* <Prompt //prevent page jumps, temporarily disabled for testing
          when={true}
          message={() => "All the data will lost if you leave"}
        />*/}
        {this.state.isLoading ? (
          this.loadingOverlay()
        ) : (
            <Row>
              <Col md="12">
                <Card className="no-transition">
                  <CardHeader>
                    <CardTitle tag="h4">Create New Staff for Business</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form action="/" className="form-horizontal" method="get">
                      <Row>
                        <Label sm="2">* First Name</Label>

                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              onChange={(e) => this.setState({ firstName: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorFirstName}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">* Last Name</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="text"
                              autoComplete="off"
                              onChange={(e) => this.setState({ lastName: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorLastName}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Label sm="2">* Phone Number</Label>
                        <Col sm="5">
                          <FormGroup>
                            <MaskedInput
                              mask={[
                                "(",
                                /[1-9]/,
                                /\d/,
                                /\d/,
                                ")",
                                "-",
                                /\d/,
                                /\d/,
                                /\d/,
                                "-",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              className="form-control"
                              autoComplete="off"
                              maxLength="15"
                              onChange={(e) => {
                                //Allows the user to type in any format. Only numbers will be added to the state
                                let input = e.target.value;
                                let replacedPhone = input.replace(/[^0-9]/g, "");
                                this.setState({ phone: replacedPhone });
                              }}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorPhone}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">* Email</Label>
                        <Col sm="5">
                          <FormGroup>
                            <Input
                              type="email"
                              autoComplete="off"
                              onChange={(e) => this.setState({ email: e.target.value })}
                            />
                            <FormText color="danger" tag="span">
                              {this.state.errorEmail}
                            </FormText>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2"> Business</Label>
                        <Col className="checkbox-radios" sm="5">
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="business"
                            value={this.state.business}
                            onChange={(value) => {
                              this.setState({ business: value });
                            }}
                            options={this.state.businessList}
                            placeholder="Select Business"
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorBusiness}
                          </FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2"> Role</Label>
                        <Col className="checkbox-radios" sm="5">
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="business"
                            value={this.state.role}
                            onChange={(value) => {
                              this.setState({ role: value });
                            }}
                            options={this.BUSINESS_ROLES}
                            placeholder="Select Business"
                          />
                          <FormText color="danger" tag="span">
                            {this.state.errorRole}
                          </FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="9"></Col>
                        <Col sm="3">
                          <Button color="success" onClick={() => this.handleSubmit()}>
                            Create Staff
                        </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

        {this.state.alert}
      </div>
    );
  }
}

export default CreateNewBusinessStaff;

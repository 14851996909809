import * as React from "react";
import styles from "./styles.module.css";
const StepStates = {
  NOT_STARTED: "not_started",
  CURRENT: "current",
  ERROR: "error",
  COMPLETED: "completed",
};
function stepsReducer(steps, action) {
  if (action.type == "INIT") {
    return action.payload.steps.map(function (step, i) {
      if (i < action.payload.index) {
        step.state = StepStates.COMPLETED;
      } else if (i === action.payload.index) {
        step.state = action.payload.state;
      } else {
        step.state = StepStates.NOT_STARTED;
      }
      return step;
    });
  }
}
export default function StepProgressBar(props) {
  const {
    steps,
    startingStep,
    wrapperClass,
    progressClass,
    stepClass,
    labelClass,
    subtitleClass,
    contentClass,
    buttonWrapperClass,
    primaryBtnClass,
    secondaryBtnClass,
    submitBtnName,
    onSubmit,
    previousBtnName,
    nextBtnName,
    currentIndex,
    history,
  } = props;
  const [state, dispatch] = React.useReducer(stepsReducer, steps);
  React.useEffect(
    function () {
      dispatch({
        type: "INIT",
        payload: { index: currentIndex, state: StepStates.CURRENT, steps: steps },
      });
    },
    [currentIndex, steps],
  );

  // React.useEffect(
  //   function () {
  //     dispatch({
  //       type: "CHANGE_STEP",
  //       payload: { index: currentIndex, state: StepStates.CURRENT, steps: steps },
  //     });
  //   },
  //   [steps],
  // );
  // function submitHandler() {
  //   onSubmit();
  // }
  // function nextHandler(): void {
  //   if (currentIndex === steps.length - 1) {
  //     return;
  //   }
  //   let isStateValid = true;
  //   const stepValidator = state[currentIndex].validator;
  //   if (stepValidator) {
  //     isStateValid = stepValidator();
  //   }
  //   dispatch({
  //     type: "next",
  //     payload: {
  //       index: isStateValid ? currentIndex + 1 : currentIndex,
  //       state: isStateValid ? StepStates.CURRENT : StepStates.ERROR,
  //     },
  //   });
  //   if (isStateValid) {
  //     setCurrentIndex(currentIndex + 1);
  //   }
  // }
  // function prevHandler(): void {
  //   if (currentIndex === 0) {
  //     return;
  //   }
  //   dispatch({
  //     type: "previous",
  //     payload: {
  //       index: currentIndex - 1,
  //       state: StepStates.CURRENT,
  //     },
  //   });
  //   setCurrentIndex(currentIndex - 1);
  // }
  return (
    <div className={`${styles["progress-bar-wrapper"]} ${wrapperClass || ""}`}>
      <ul className={`${styles["step-progress-bar"]} ${progressClass || ""}`}>
        {state.map(function (step, i) {
          return (
            <li
              key={i}
              className={`${styles["progress-step"]}${
                step.state === StepStates.COMPLETED ? ` ${styles.completed} text-success` : ""
              }${
                step.state === StepStates.CURRENT
                  ? ` ${styles.current} ${
                      step.state === StepStates.CURRENT ? "text-primary" : "text-secondary"
                    }`
                  : ""
              }${step.state === StepStates.ERROR ? ` ${styles["has-error"]}` : ""} ${
                stepClass || ""
              }`}
              onClick={() => {
                if (state[i].allowNavigation) history.push(state[i].url);
              }}
            >
              {step.state === StepStates.COMPLETED && (
                <span className={`${styles["step-icon"]} bg-success`}>
                  <svg
                    width="1.5rem"
                    viewBox="0 0 13 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 3.5L4.5 7.5L12 1" stroke="white" strokeWidth="1.5" />
                  </svg>
                </span>
              )}
              {step.state === StepStates.ERROR && (
                <span className={`${styles["step-icon"]} bg-secondary`}>!</span>
              )}
              {step.state !== StepStates.COMPLETED && step.state !== StepStates.ERROR && (
                <span
                  className={`${styles["step-index"]} ${
                    step.state === StepStates.CURRENT ? "bg-primary" : "text-muted"
                  }`}
                >
                  {i + 1}
                </span>
              )}
              <div className={`${styles["step-label"]} ${labelClass || ""} `}>
                {step.label}
                {step.subtitle && (
                  <div className={`${styles["step-label-subtitle"]} ${subtitleClass || ""}`}>
                    {step.subtitle}
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      {React.isValidElement(state[currentIndex].content) ? (
        <div className={`${styles["step-content"]} ${contentClass || ""}`}>
          {state[currentIndex].content}
        </div>
      ) : (
        ""
      )}
      {/* <div className={`${styles['step-buttons']} ${buttonWrapperClass || ''}`}>
        <a
          className={`${styles['step-action-btn']} ${styles['action-btn-secondary']} ${
            currentIndex === 0 ? styles.disabled : ''
          } ${secondaryBtnClass || ''}`}
          onClick={prevHandler}
        >
          {previousBtnName ? previousBtnName : 'Previous'}
        </a>
        {currentIndex === state.length - 1 ? (
          <a
            className={`${styles['step-action-btn']} ${styles['action-btn-primary']} ${
              primaryBtnClass || ''
            }`}
            onClick={submitHandler}
          >
            {submitBtnName || 'Submit'}
          </a>
        ) : (
          <a
            className={`${styles['step-action-btn']} ${styles['action-btn-primary']} ${
              primaryBtnClass || ''
            }`}
            onClick={nextHandler}
          >
            {nextBtnName ? nextBtnName : 'Next'}
          </a>
        )}
      </div>
    */}
    </div>
  );
}

import React from "react";
import { Modal } from "@mui/material";
import { Button, FormGroup, Input, Label, Row, Col, FormText } from "reactstrap";
import Select from "react-select";
import CommentsTab from "../../AllUsers/UserProfile/Comments.jsx";
import { UnScheduleReasonList } from "utils/statusVariables.js";
import { statusEnum } from "utils/statusVariables.js";

const GlobalModal = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      style={props.modalStyle ? props.modalStyle : modalStyle}
    >
      <FormGroup style={props.formGroupGeneral ? props.formGroupGeneral : formGroupGeneral}>
        <h5 style={props.modalHeader ? props.modalHeader : modalHeader}>{props.title}</h5>
        <p>{props.desc}</p>

        {/* modal second title */}
        {props.secTitle ? (
          <h6 style={props.modalHeader ? props.modalHeader : modalHeader}>{props.secTitle}</h6>
        ) : null}

        {/* Specific for assignModal */}
        {props.assignModal ? (
          <>
            <Row className="mt-3">
              <Col md="3">Enter HDID</Col>
              <Col md="9">
                <Input
                  value={props.hdId}
                  placeholder="Enter HdId"
                  type="text"
                  onChange={(e) => props.handleAssignUserChange(e)}
                />
              </Col>
            </Row>
            {props.errorHdId && (
              <Row>
                <Col md="3"></Col>
                <Col md="9">
                  <FormText color="danger" tag="span">
                    {props.errorHdId}
                  </FormText>
                </Col>
              </Row>
            )}

            {!props.selectedWorker ? (
              props.hdId && (
                <Row>
                  <Col md="3"></Col>
                  <Col md="9">
                    <FormText color="info" tag="span">
                      {props.errorHdId ? null : props.startSearch}
                    </FormText>
                  </Col>
                </Row>
              )
            ) : (
              <>
                {props.selectedWorker && (
                  <Row>
                    <Col md="3"></Col>
                    <Col md="9" className="mt-3">
                      Full Name: {" " + props.selectedWorker.label}
                      <br />
                      Phone: {" " + props.selectedWorker.phone}
                      <br />
                      Email: {" " + props.selectedWorker.email}
                    </Col>
                  </Row>
                )}
                {props.shift.shiftFunctions?.length ? (
                  <Row>
                    <Col md="3"></Col>
                    <Col md="9">
                      <Select
                        classNamePrefix="react-select"
                        name="selectShiftFunction"
                        value={props.selectedShiftFunction}
                        onChange={(value) => {
                          console.log("sf value:", value);
                          props.setSelectedShiftFunction(value);
                        }}
                        options={props.shift.shiftFunctions?.map((sf) => {
                          return {
                            label: sf.name,
                            value: sf._id,
                            shiftFunction: sf,
                          };
                        })}
                        placeholder="Select shift Function"
                        style={{ margin: "15px 0" }}
                      />
                    </Col>
                  </Row>
                ) : null}
                {props.shiftFunctionError && (
                  <Row>
                    <Col md="3"></Col>
                    <Col md="9">
                      <FormText color="danger" tag="span">
                        {props.shiftFunctionError}
                      </FormText>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </>
        ) : null}

        {/* Specific for removeUserModal */}
        {props.removeUserModal ? (
          <>
            {props.selectedUser?.status === statusEnum?.selectedForWork?.Applied ? (
              <>
                <FormGroup row className="align-items-right">
                  <Label for="Search" style={labelStyle}>
                    <b>Are you sure want to reject user for this shift ?</b>
                  </Label>
                </FormGroup>
                <div style={formGroupBody}>
                  <Button onClick={props.cancelAction}> Cancel </Button>
                  <Button color="danger" onClick={props.changeStatus}>
                    {" "}
                    Reject{" "}
                  </Button>
                </div>
              </>
            ) : (
              <div className="mx-4 mt-4">
                <Col className="checkbox-radios" sm="10">
                  <div className="form-check-radio mt-8">
                    <Label check>
                      <Input
                        defaultValue="Unschedule"
                        id="Unschedule"
                        name="UnscheduleRadio"
                        type="radio"
                        onChange={() => props.setAction("Unschedule")}
                        checked={props.action === "Unschedule"}
                      />
                      Unschedule <span className="form-check-sign" />
                    </Label>
                  </div>
                  <div className="form-check-radio">
                    <Label check>
                      <Input
                        defaultValue="ChangeStatus"
                        id="ChangeStatus"
                        name="ChangeStatusRadio"
                        type="radio"
                        checked={props.action === "ChangeStatus"}
                        onChange={() => props.setAction("ChangeStatus")}
                      />
                      {props.selectedUser?.status === statusEnum?.selectedForWork?.Backup
                        ? "Change to regular selected"
                        : "Change to backup"}{" "}
                      <span className="form-check-sign" />
                    </Label>
                  </div>
                </Col>
              </div>
            )}
            {props.action === "Unschedule" && (
              <>
                <Label for="Search" style={labelStyle}>
                  <b>Unschedule Reason</b>
                </Label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  value={props.unscheduleReason}
                  onChange={(value) => props.setUnscheduleReason(value)}
                  id="unscheduleReason"
                  options={UnScheduleReasonList}
                  placeholder="Select Unscheduled Reason"
                />
                <FormText color="danger" tag="span">
                  {props.formErrors.unscheduleReason}
                </FormText>
                <div className="d-flex justify-content-between">
                  <Button color="secondary" onClick={props.cancelAction}>
                    Cancel
                  </Button>
                  <Button color="danger" onClick={props.handleUnschedule}>
                    UnSchedule
                  </Button>
                </div>
              </>
            )}

            {props.action === "ChangeStatus" && (
              <>
                <FormGroup row className="align-items-right">
                  <Label for="Search" style={labelStyle}>
                    <b>Are you sure want to change status ?</b>
                  </Label>
                </FormGroup>
                <div style={formGroupBody}>
                  <Button onClick={props.cancelAction}>Cancel</Button>
                  <Button color="danger" onClick={props.handleChangeStatus}>
                    Change
                  </Button>
                </div>
              </>
            )}
          </>
        ) : null}

        {/* Specific for commentModal */}
        {props.commentModal ? (
          <>
            <CommentsTab comments={props.selectedWorkerComments} isLoading={props.isLoading} />
            <Input
              value={props.addComment}
              onChange={(e) => props.setAddComment(e.target.value)}
              placeholder="Add a comment for this user"
              type="text"
            />
          </>
        ) : null}

        {/* Specific for editUserStatusModal */}
        {props.editUserStatusModal ? (
          <>
            <Label for="Search" style={labelStyle}>
              <b>Select Status</b>
            </Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="singleSelect"
              value={props.selectedStatus}
              onChange={(value) => props.setSelectedStatus(value)}
              id="selectedStatus"
              options={props.STATUS_OPTIONS}
              placeholder="Select Status"
            />
          </>
        ) : null}

        {/* Specific for notificationModal */}
        {props.notificationModal ? (
          <Input
            type="select"
            value={props.notificationType}
            onChange={(e) => props.setNotificationType(e.target.value)}
          >
            <option value="private">Private for this business workers</option>
            <option value="public">Public for those who have never worked</option>
            <option value="allWorkers">All workers who haven't worked at this business</option>
          </Input>
        ) : null}

        {/* Button Group */}
        <div style={props.formGroupBody ? props.formGroupBody : formGroupBody}>
          {/* removeUserModal has specific button action group */}
          {props.removeUserModal ? (
            <></>
          ) : (
            // General Button Group
            <>
              <Button onClick={props.cancelAction}>Cancel</Button>

              {/* Specific for assignModal */}
              {props.assignModal ? (
                <Button color="info" onClick={props.assignBackUp}>
                  Backup
                </Button>
              ) : null}

              {/* Specific for acceptModal */}
              {props.acceptModal ? (
                <Button color="info" onClick={props.backUp}>
                  Backup
                </Button>
              ) : null}

              {/* Specific for exportModal */}
              {props.exportModal ? (
                <Button color="info" onClick={props.email}>
                  Email
                </Button>
              ) : null}

              <Button color={props.btnColor} onClick={props.action}>
                {props.btnMessage}
              </Button>
            </>
          )}
        </div>
      </FormGroup>
    </Modal>
  );
};

export default GlobalModal;

const formGroupGeneral = { width: 500, backgroundColor: "#fff", borderRadius: 10, padding: 15 };
const modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const modalHeader = { textAlign: "center" };
const formGroupBody = { justifyContent: "space-between", display: "flex" };
const optionText = { fontSize: 16, padding: 15 };
const labelStyle = { color: "black", fontSize: "14px" };
